import React, { useState } from 'react';
import { Info, Zap } from 'lucide-react';

const FacebookMetricsDashboard = () => {
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [showMetrics, setShowMetrics] = useState(false); // Novo estado para controlar a visibilidade das métricas

  const metrics = [
    {
      key: 'impressions',
      name: 'Impressões',
      description: 'Número de vezes que sua publicidade foi exibida',
      simpleExample: 'Imagine um panfleto sendo distribuído na rua. Cada vez que alguém recebe o panfleto, conta como uma impressão. Mesmo que a pessoa não leia, ainda é uma impressão.',
      realWorldAnalogy: 'É como colocar um cartaz em uma parede movimentada. Quantas pessoas passam e veem o cartaz?'
    },
    {
      key: 'reach',
      name: 'Alcance',
      description: 'Número único de pessoas que viram seu anúncio',
      simpleExample: 'Se 100 pessoas virem seu anúncio, mas 50 dessas pessoas sejam únicas, seu alcance é 50.',
      realWorldAnalogy: 'Como convidar pessoas para uma festa. Alcance são quantas pessoas diferentes receberam o convite.'
    },
    {
      key: 'frequency',
      name: 'Frequência',
      description: 'Média de vezes que uma pessoa viu seu anúncio',
      simpleExample: 'Se uma pessoa vê seu anúncio 3 vezes, a frequência para essa pessoa é 3.',
      realWorldAnalogy: 'Como um comercial de TV que você vê repetidamente durante um programa.'
    },
    {
      key: 'spend',
      name: 'Gasto',
      description: 'Quantidade total de dinheiro investida no anúncio',
      simpleExample: 'Se você gastou R$100 em um anúncio, seu gasto é R$100.',
      realWorldAnalogy: 'Como comprar ingredientes para fazer um bolo. O gasto é o valor total que você gastou.'
    },
    {
      key: 'cpm',
      name: 'CPM',
      description: 'Custo por mil impressões',
      simpleExample: 'Se você paga R$10 para mostrar seu anúncio para 1000 pessoas, o CPM é R$10.',
      realWorldAnalogy: 'Como pagar pelo aluguel de um outdoor por mil visualizações.'
    },
    {
      key: 'totalResults',
      name: 'Resultados Totais',
      description: 'Número total de ações realizadas pelo seu anúncio',
      simpleExample: 'Cliques, conversões, visualizações de vídeo, etc.',
      realWorldAnalogy: 'Como contar quantas pessoas compraram seu produto após ver um comercial.'
    },
    {
      key: 'costPerResult',
      name: 'Custo por Resultado',
      description: 'Quanto você gasta para obter um resultado específico',
      simpleExample: 'Se gastar R$50 para conseguir 10 vendas, seu custo por resultado é R$5.',
      realWorldAnalogy: 'Como calcular o custo de cada cliente que entra na sua loja.'
    },
    {
      key: 'inline_link_clicks',
      name: 'Cliques no Link',
      description: 'Número de cliques nos links do seu anúncio',
      simpleExample: 'Quantas pessoas clicaram para ir ao seu site ou página.',
      realWorldAnalogy: 'Como contar quantas pessoas entraram em sua loja após ver um panfleto.'
    },
    {
      key: 'link_click_ctr',
      name: 'CTR de Cliques no Link',
      description: 'Porcentagem de pessoas que clicam no link após ver o anúncio',
      simpleExample: 'Se 100 pessoas virem seu anúncio e 5 clicarem, o CTR é 5%.',
      realWorldAnalogy: 'Como medir quantas pessoas param para olhar seu cartaz na rua.'
    },
    {
      key: 'link_click_cpc',
      name: 'CPC de Cliques no Link',
      description: 'Custo por cada clique no link',
      simpleExample: 'Se você gasta R$10 e recebe 5 cliques, o CPC é R$2.',
      realWorldAnalogy: 'Como calcular o preço de cada visitante em sua loja.'
    },
    {
      key: 'video3SecViews',
      name: 'Visualizações de 3 Segundos',
      description: 'Número de pessoas que assistiram seu vídeo por pelo menos 3 segundos',
      simpleExample: 'Quantas pessoas pararam para assistir o início do seu vídeo.',
      realWorldAnalogy: 'Como contar quantas pessoas param para ouvir o começo de um músico de rua.'
    },
    {
      key: 'video75Views',
      name: 'Visualizações de 75%',
      description: 'Número de pessoas que assistiram 75% do seu vídeo',
      simpleExample: 'Quantas pessoas assistiram quase todo o seu vídeo.',
      realWorldAnalogy: 'Como medir quantas pessoas assistem quase todo um filme no cinema.'
    },
    {
      key: 'hookRate',
      name: 'Hook Rate',
      description: 'Porcentagem de pessoas que continuam assistindo após os primeiros segundos',
      simpleExample: 'Se 100 pessoas começam a ver o vídeo e 70 continuam, o Hook Rate é 70%.',
      realWorldAnalogy: 'Como medir quantas pessoas continuam interessadas no início de uma história.'
    },
    {
      key: 'holdRate',
      name: 'Hold Rate',
      description: 'Porcentagem de pessoas que continuam assistindo até o final',
      simpleExample: 'Se 100 pessoas começam a ver o vídeo e 40 assistem até o final, o Hold Rate é 40%.',
      realWorldAnalogy: 'Como medir quantas pessoas assistem um filme até os créditos finais.'
    },
    {
      key: 'budget',
      name: 'Orçamento',
      description: 'Valor total disponível para investir em publicidade',
      simpleExample: 'Se você tem R$1000 para gastar em anúncios, esse é seu orçamento.',
      realWorldAnalogy: 'Como planejar quanto dinheiro você vai gastar em uma festa.'
    },
    {
      key: 'budgetOptimization',
      name: 'Otimização de Orçamento',
      description: 'Estratégia para usar seu dinheiro da melhor forma possível',
      simpleExample: 'Ajustar seus anúncios para gastar menos e conseguir mais resultados.',
      realWorldAnalogy: 'Comparação entre ABO (Orçamento em Nível de Anúncio) e CBO (Orçamento em Nível de Campanha): \n\nABO (Orçamento em Nível de Anúncio):\n- Controle mais granular\n- Você define orçamento para cada anúncio individualmente\n- Melhor para campanhas com objetivos específicos ou públicos distintos\n- Ideal quando cada anúncio tem uma estratégia única\n\nCBO (Orçamento em Nível de Campanha):\n- Otimização mais automatizada\n- Facebook distribui o orçamento entre anúncios automaticamente\n- Mais eficiente para maximizar resultados gerais\n- Melhor para campanhas com objetivos semelhantes\n\nAnalogias:\n- ABO é como distribuir dinheiro para cada filho individualmente\n- CBO é como dar um valor total para a família e deixar que se organizem'
    },
    {
      key: 'campaignObjective',
      name: 'Objetivo da Campanha',
      description: 'Meta principal que você quer alcançar com o anúncio',
      simpleExample: 'Aumentar vendas, ganhar seguidores, divulgar um produto.',
      realWorldAnalogy: 'Como definir o destino antes de começar uma viagem.'
    }
  ];

 const handleMetricClick = (metric) => {
    setSelectedMetric(metric);
  };

  const closeMetricDetails = () => {
    setSelectedMetric(null);
  };

  const handleShowMetrics = () => {
    setShowMetrics(true);
  };

  const handleHideMetrics = () => {
    setShowMetrics(false);
  };

  return (
    <div className="tailwind-scope min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 p-4 md:p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-extrabold text-center text-blue-800 mb-12 drop-shadow-md">
          Métricas do Facebook Explicadas
        </h1>

        {!showMetrics ? (
          // Botão para mostrar as métricas
          <div className="flex justify-center">
            <button
              onClick={handleShowMetrics}
              className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-colors"
            >
              Mostrar Métricas
            </button>
          </div>
        ) : (
          // Se showMetrics for true, exibe as métricas e o botão de fechar
          <>
            <div className="flex justify-end mb-4">
              <button
                onClick={handleHideMetrics}
                className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-600 transition-colors"
              >
                Fechar Métricas
              </button>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {metrics.map((metric) => (
                <div 
                  key={metric.key} 
                  className="perspective-500 transform transition-all duration-300 hover:scale-105 hover:z-10"
                >
                  <button
                    onClick={() => handleMetricClick(metric)}
                    className="w-full bg-white shadow-lg rounded-2xl p-5 text-left 
                      hover:shadow-2xl transition-all duration-300 
                      border-2 border-transparent hover:border-blue-300
                      relative group overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-blue-100 opacity-0 group-hover:opacity-10 transition-opacity"></div>
                    <h3 className="font-bold text-xl text-blue-700 mb-2 flex items-center justify-between">
                      {metric.name}
                      <Info 
                        className="text-blue-400 opacity-50 group-hover:opacity-100 transition-opacity" 
                        size={24} 
                      />
                    </h3>
                    <p className="text-gray-600 text-sm line-clamp-2">
                      {metric.description}
                    </p>
                    <Zap 
                      className="absolute bottom-2 right-2 text-blue-300 opacity-0 group-hover:opacity-100 transition-opacity" 
                      size={20} 
                    />
                  </button>
                </div>
              ))}
            </div>
          </>
        )}

        {selectedMetric && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-xl shadow-2xl max-w-xl w-full max-h-[80vh] overflow-y-auto p-6 relative animate-slide-up">
              <button 
                onClick={closeMetricDetails}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
              >
                ✕
              </button>
              
              <h2 className="text-2xl font-bold text-blue-600 mb-4">{selectedMetric.name}</h2>
              
              <div className="space-y-4">
                <p className="text-gray-700">
                  <strong>O que é:</strong> {selectedMetric.description}
                </p>
                
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="font-semibold text-blue-600 mb-2">Exemplo Simples:</h3>
                  <p className="text-gray-700">{selectedMetric.simpleExample}</p>
                </div>
                
                <div className="bg-green-50 p-4 rounded-lg">
                  <h3 className="font-semibold text-green-600 mb-2">Analogia do Mundo Real:</h3>
                  <p className="text-gray-700">{selectedMetric.realWorldAnalogy}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FacebookMetricsDashboard;