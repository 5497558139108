
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { marked } from 'marked';
import './Persona.css'; // Mantido o mesmo CSS
import { 
  FaTrash, FaPlusCircle, FaPaperPlane, FaDownload, FaTimes, FaArrowDown, FaArrowLeft, FaVideo 
} from 'react-icons/fa'; // Importação do ícone de vídeo
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout, getUserEmail } from '../../auth/auth';
import { 
  Document, Packer, Paragraph, TextRun, HeadingLevel, 
  AlignmentType 
} from 'docx';
import { saveAs } from 'file-saver';
import { 
  inicializarPalavras, consumirPalavras, verificarPalavras 
} from '../../auth/palavraControle';
import { 
  startMonitoring, stopMonitoring 
} from '../../auth/userActivityMonitor'; 
import { 
  startSync, stopSync 
} from '../../auth/syncWords';
import assistenteImg from '../../imagens/assistente.png'

const Chat = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [chatBloqueado, setChatBloqueado] = useState(false);
  const [isFloatingFormOpen, setIsFloatingFormOpen] = useState(false);
  const [produto, setProduto] = useState('');
  const [publicoAlvo, setPublicoAlvo] = useState('');
  const [beneficios, setBeneficios] = useState('');
  const [condicoesPagamento, setCondicoesPagamento] = useState('');
  const [tipoProduto, setTipoProduto] = useState([]); // Novo estado para Tipo de Produto
  const [seguirPoliticas, setSeguirPoliticas] = useState(true); // Novo estado para checkbox

  const [displayedGreeting, setDisplayedGreeting] = useState('');
  const [fullGreeting, setFullGreeting] = useState('');

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatMessagesRef = useRef(null);

  const userEmail = getUserEmail();
  const userName = localStorage.getItem('userName') || 'Usuário'; // Recupera o nome do usuário
  const userFoto = localStorage.getItem(`${userEmail}-fotoPerfil`) || 'caminho/para/imagem/padrao.png'; // Recupera a foto ou usa padrão

  const MAX_REQUESTS_PER_MINUTE = 10;

  const assistantId = process.env.REACT_APP_OPENAI_ASSISTANT_ID;

  // Novo estado para controlar a abertura do tutorial
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);

  // Lógica de saudação com efeito de digitação
  useEffect(() => {
    // Função para configurar a saudação com base no horário
    const configureGreeting = () => {
      const trimmedName = userName.trim();

      const saoPauloTime = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
      const currentHour = new Date(saoPauloTime).getHours();

      let greeting = '';
      if (currentHour >= 6 && currentHour < 12) {
        greeting = 'Bom dia';
      } else if (currentHour >= 12 && currentHour < 18) {
        greeting = 'Boa tarde';
      } else if (currentHour >= 18 && currentHour < 24) {
        greeting = 'Boa noite';
      } else {
        greeting = 'Boa madrugada';
      }

      const greetingText = `Olá, ${greeting}, ${trimmedName}. Vamos criar uma Persona Para seu Produto.`;
      setFullGreeting(greetingText);
    };

    configureGreeting();
  }, [userName]);

  useEffect(() => {
    if (fullGreeting) {
      setDisplayedGreeting(''); // Resetar a saudação antes de começar a digitar
      let index = 0;
      const typingSpeed = 100; // velocidade de digitação em milissegundos

      const typeCharacter = () => {
        if (index <= fullGreeting.length) {
          setDisplayedGreeting(fullGreeting.slice(0, index));
          index++;
          setTimeout(typeCharacter, typingSpeed);
        }
      };

      typeCharacter();
    }
  }, [fullGreeting]);

  // ... (restante do código sem alterações)

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }

    const storedMessages = localStorage.getItem(`chatHistory_${userEmail}_${assistantId}`);
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`);

    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
    } else {
      setChatBloqueado(false);
    }

    startMonitoring();
    startSync();
    scrollToBottom();

    return () => {
      stopMonitoring();
      stopSync();
    };
  }, [navigate, userEmail, assistantId]);

  useEffect(() => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setCharCount(textarea.value.length);
    }
  }, [input]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const scrollToBottomSmooth = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const saveMessages = (messages) => {
    localStorage.setItem(`chatHistory_${userEmail}_${assistantId}`, JSON.stringify(messages));
    scrollToBottom();
  };

  const canMakeRequest = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}_${assistantId}`)) || [];
    const now = Date.now();
    const oneMinuteAgo = now - 60000;

    const recentRequests = requestTimestamps.filter(timestamp => timestamp > oneMinuteAgo);
    localStorage.setItem(`requestTimestamps_${userEmail}_${assistantId}`, JSON.stringify(recentRequests));

    return recentRequests.length < MAX_REQUESTS_PER_MINUTE;
  };

  const recordRequestTimestamp = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}_${assistantId}`)) || [];
    requestTimestamps.push(Date.now());
    localStorage.setItem(`requestTimestamps_${userEmail}_${assistantId}`, JSON.stringify(requestTimestamps));
  };

  // Função aprimorada para contar palavras disponíveis
  const contarTokensPorPalavra = (palavra) => {
    // Contar hífens e underscores como separadores de sub-palavras
    const separadores = /[-_]/g;
    const subPalavras = palavra.split(separadores).filter(sub => sub.length > 0).length;

    // Remover pontuação para análise de comprimento
    const palavraLimpa = palavra.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
    const length = palavraLimpa.length;

    let palavrasConsumidas = 0;
    if (length <= 3) palavrasConsumidas = 2;
    else if (length <= 6) palavrasConsumidas = 4;
    else if (length <= 9) palavrasConsumidas = 6;
    else palavrasConsumidas = 8;

    // Adicionar palavras consumidas para cada sub-palavra
    palavrasConsumidas *= subPalavras;

    // Verificar se a palavra contém caracteres não-ASCII
    const containsNonASCII = /[^\x00-\x7F]/.test(palavraLimpa);
    if (containsNonASCII) {
      palavrasConsumidas = Math.ceil(palavrasConsumidas * 1.5); // Aplicar um multiplicador
    }

    return palavrasConsumidas;
  };

  // Função aprimorada para calcular palavras consumidas
  const calcularTokensUsados = (message) => {
    // Dividir o texto mantendo a pontuação
    const tokens = message.match(/[\w'-]+|[.,!?;:]/g) || [];
    return tokens.reduce((total, token) => {
      // Verificar se o token é pontuação
      if (/^[.,!?;:]$/.test(token)) {
        return total + 1; // Cada pontuação consome 1 palavra disponível
      }
      return total + contarTokensPorPalavra(token);
    }, 0);
  };

  const verificarSaldoLocalStorage = () => {
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`);
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
      return false;
    }
    return true;
  };

  const handleSend = async (messageToSend = null) => {
    const message = messageToSend || input;

    if (message.trim() === '') return;

    const palavrasConsumidas = calcularTokensUsados(message);

    if (!verificarSaldoLocalStorage()) {
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      return;
    }

    let saldoAtual = parseInt(localStorage.getItem(`${userEmail}-palavrasDisponiveis`), 10);
    if (isNaN(saldoAtual)) saldoAtual = 0;

    if (saldoAtual < palavrasConsumidas) {
      alert('Você não tem palavras disponíveis suficientes para enviar essa mensagem.');
      return;
    }

    if (!canMakeRequest()) {
      alert('Você atingiu o limite de requisições por minuto. Por favor, aguarde.');
      return;
    }

    const userMessage = { text: message, sender: 'user' };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    saveMessages(updatedMessages);

    saldoAtual -= palavrasConsumidas;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);

    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    await consumirPalavras(userEmail, palavrasConsumidas);

    setIsTyping(true);
    recordRequestTimestamp();

    try {
      let threadId = localStorage.getItem(`chatThreadId_${userEmail}_${assistantId}`);

      if (!threadId) {
        const threadResponse = await axios.post(
          'https://api.openai.com/v1/threads',
          {},
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        threadId = threadResponse.data.id;
        localStorage.setItem(`chatThreadId_${userEmail}_${assistantId}`, threadId);
      }

      await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          role: 'user',
          content: message
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      setInput('');

      const runResponse = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: assistantId
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      let runStatus = 'queued';
      while (runStatus !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const statusResponse = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runResponse.data.id}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        runStatus = statusResponse.data.status;
      }

      const messagesResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      const assistantMessage = messagesResponse.data.data.find(
        msg => msg.role === 'assistant'
      );

      setIsTyping(false);

      if (assistantMessage) {
        const assistantText = assistantMessage.content
          .map(content => content.text?.value || '')
          .join(' ');

        const updatedMessagesWithResponse = [
          ...updatedMessages,
          { text: assistantText, sender: 'assistant' }
        ];
        setMessages(updatedMessagesWithResponse);
        saveMessages(updatedMessagesWithResponse);

        const palavrasConsumidasResposta = calcularTokensUsados(assistantText);
        saldoAtual -= palavrasConsumidasResposta;
        localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);

        if (saldoAtual <= 0) {
          setChatBloqueado(true);
          alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
        }

        await consumirPalavras(userEmail, palavrasConsumidasResposta);

      } else {
        const errorMessage = [
          ...updatedMessages,
          { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
        ];
        setMessages(errorMessage);
        saveMessages(errorMessage);
      }

    } catch (error) {
      console.error('Erro ao comunicar com o assistente:', error.response ? error.response.data : error.message);
      const errorMessage = [
        ...messages,
        { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
      ];
      setMessages(errorMessage);
      saveMessages(errorMessage);
    }
  };

  const handleDeleteConversation = async () => {
    const threadId = localStorage.getItem(`chatThreadId_${userEmail}_${assistantId}`);

    if (threadId) {
      try {
        await axios.delete(
          `https://api.openai.com/v1/threads/${threadId}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
      } catch (error) {
        console.error('Erro ao excluir a thread no servidor:', error.response ? error.response.data : error.message);
      }
    }

    localStorage.removeItem(`chatHistory_${userEmail}_${assistantId}`);
    localStorage.removeItem(`chatThreadId_${userEmail}_${assistantId}`);
    setMessages([]);
  };

  const handleNewConversation = () => {
    handleDeleteConversation();
  };

  const htmlToDocx = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.body.childNodes;

    const docxElements = [];

    elements.forEach(element => {
      switch (element.nodeName) {
        case 'H1':
          docxElements.push(new Paragraph({
            text: element.textContent,
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.LEFT,
            spacing: { after: 200 },
          }));
          break;
        case 'H2':
          docxElements.push(new Paragraph({
            text: element.textContent,
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.LEFT,
            spacing: { after: 150 },
          }));
          break;
        case 'H3':
          docxElements.push(new Paragraph({
            text: element.textContent,
            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.LEFT,
            spacing: { after: 100 },
          }));
          break;
        case 'P':
          docxElements.push(new Paragraph({
            children: convertInlineElements(element),
            spacing: { after: 100 },
          }));
          break;
        case 'UL':
          docxElements.push(...convertList(element, false));
          break;
        case 'OL':
          docxElements.push(...convertList(element, true));
          break;
        case 'BLOCKQUOTE':
          docxElements.push(new Paragraph({
            text: element.textContent,
            style: "Quote",
            spacing: { after: 100 },
          }));
          break;
        case 'PRE':
          docxElements.push(new Paragraph({
            text: element.textContent,
            style: "Code",
            spacing: { after: 100 },
          }));
          break;
        default:
          break;
      }
    });

    return docxElements;
  };

  const convertInlineElements = (element) => {
    const children = [];
    element.childNodes.forEach(child => {
      if (child.nodeName === 'STRONG') {
        children.push(new TextRun({
          text: child.textContent,
          bold: true,
        }));
      } else if (child.nodeName === 'EM') {
        children.push(new TextRun({
          text: child.textContent,
          italics: true,
        }));
      } else if (child.nodeName === 'CODE') {
        children.push(new TextRun({
          text: child.textContent,
          font: { name: "Courier New" },
          shading: { fill: "f5f5f5" },
        }));
      } else if (child.nodeName === '#text') {
        children.push(new TextRun({
          text: child.textContent,
        }));
      }
    });
    return children;
  };

  const convertList = (element, ordered) => {
    const items = [];
    element.querySelectorAll('li').forEach(li => {
      items.push(new Paragraph({
        text: li.textContent,
        bullet: ordered ? undefined : { level: 0 },
        numbering: ordered ? { reference: "numbering", level: 0 } : undefined,
        spacing: { after: 50 },
      }));
    });
    return items;
  };

  const numbering = {
    config: [
      {
        reference: "numbering",
        levels: [
          {
            level: 0,
            format: "decimal",
            text: "%1.",
            alignment: AlignmentType.LEFT,
          },
        ],
      },
    ],
  };

  const handleDownloadDocx = (message) => {
    const html = marked(message);
    const docxElements = htmlToDocx(html);

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: docxElements,
        },
      ],
      numbering: numbering,
      styles: {
        paragraphStyles: [
          {
            id: "Quote",
            name: "Quote",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              italics: true,
            },
            paragraph: {
              indent: { left: 720 },
              shading: { fill: "f5f5f5" },
              spacing: { after: 100 },
            },
          },
          {
            id: "Code",
            name: "Code",
            basedOn: "Normal",
            next: "Normal",
            run: {
              font: "Courier New",
            },
            paragraph: {
              shading: { fill: "f5f5f5" },
              indent: { left: 720 },
              spacing: { after: 100 },
            },
          },
        ],
      },
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "chat-response.docx");
    });
  };

  const handleEnviarInformacoes = () => {
    const informacoes = `
      Produto: **${produto}**
      Público Alvo: *${publicoAlvo}*
      Benefícios:
      - ${beneficios.split('\n').join('\n- ')}
      Condições de Pagamento:
      - ${condicoesPagamento.split('\n').join('\n- ')}
      Tipo de Produto:
      - ${tipoProduto.join(', ')}
    `;
    handleSend(informacoes);
    setProduto('');
    setPublicoAlvo('');
    setBeneficios('');
    setCondicoesPagamento('');
    setTipoProduto([]);
    setSeguirPoliticas(true);
    setIsFloatingFormOpen(false);
  };

 return (
    <div className="unique-chatgpt-container">
      {/* Cabeçalho */}
      <header className="unique-chatgpt-header">
        <div className="unique-chatgpt-header-left">
          <img src={logo} alt="Logo" className="unique-chatgpt-logo" />
          <Link to="/ferramentas" className="unique-chatgpt-back-button">
            <FaArrowLeft /> Voltar para o Menu
          </Link>
        </div>
        <div className="unique-chatgpt-header-right">
          <div className="unique-chatgpt-user-info">
            <img src={userFoto} alt="Foto do Usuário" className="unique-chatgpt-user-photo" />
            <span className="unique-chatgpt-user-name">{userName}</span>
          </div>
          <button className="unique-chatgpt-logout-button" onClick={logout}>
            Sair
          </button>
        </div>
      </header>

      {/* Conteúdo Principal */}
      <main className="unique-chatgpt-main">
        <div className="unique-chatgpt-chat-container">
          <div className="unique-chatgpt-chat-header">
            {/* Substituição da frase estática pela saudação com efeito de digitação */}
            <h2 className={`greeting-text ${isTyping ? 'typing' : ''}`}>
              {displayedGreeting}
              {/* Cursor piscante */}
              <span className="cursor">|</span>
            </h2>
            <div className="greeting-buttons">
              {/* Botão "Assistir Tutorial" */}
              <button className="tutorial-chat-button" title="Assistir Tutorial" onClick={() => setIsTutorialOpen(true)}>
                <FaVideo /> Assistir Tutorial
              </button>
              {/* Botão "Nova Conversa" */}
              <button className="unique-chatgpt-chat-button" title="Nova Conversa" onClick={handleNewConversation}>
                <FaPlusCircle />
              </button>
              {/* Botão "Excluir Conversa" */}
              <button className="unique-chatgpt-chat-button" title="Excluir Conversa" onClick={handleDeleteConversation}>
                <FaTrash />
              </button>
            </div>
          </div>
          <div className="unique-chatgpt-messages" ref={chatMessagesRef}>
            {messages.map((msg, index) => (
              <div key={index} className={`unique-chatgpt-message ${msg.sender}`}>
                {msg.sender === 'user' ? (
                  <img src={userFoto} alt="Foto do Usuário" className="unique-chatgpt-message-icon" />
                ) : (
                  <img src={assistenteImg} alt="Foto do Assistente" className="unique-chatgpt-message-icon" />
                )}
                {msg.sender === 'assistant' ? (
                  <ReactMarkdown className="unique-chatgpt-markdown">{msg.text}</ReactMarkdown>
                ) : (
                  <span>{msg.text}</span>
                )}
                {msg.sender === 'assistant' && (
                  <button 
                    className="unique-chatgpt-download-button" 
                    onClick={() => handleDownloadDocx(msg.text)} 
                    title="Baixar como DOCX"
                  >
                    <FaDownload />
                  </button>
                )}
              </div>
            ))}
            {isTyping && (
              <div className="unique-chatgpt-message assistant typing">
                <img src={assistenteImg} alt="Foto do Assistente" className="unique-chatgpt-message-icon" />
                <span>O assistente está digitando...</span>
              </div>
            )}
            <div ref={messagesEndRef} />
            {/* Botão de Scroll para o final */}
            <button 
              className="unique-chatgpt-scroll-button" 
              onClick={scrollToBottomSmooth} 
              title="Ir para a última mensagem"
            >
              <FaArrowDown />
            </button>
          </div>
          <div className="unique-chatgpt-input-container">
            <textarea
              ref={inputRef}
              className="unique-chatgpt-text-input"
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
                setCharCount(e.target.value.length);
              }}
              onKeyPress={(e) => !chatBloqueado && e.key === 'Enter' && !e.shiftKey && handleSend()}
              placeholder={chatBloqueado ? "Seu saldo de palavras acabou. Aguarde a renovação." : "Digite sua mensagem..."}
              disabled={chatBloqueado}
            />
            <span className="unique-chatgpt-char-count">{charCount}</span>
            <button 
              className="unique-chatgpt-send-button" 
              onClick={() => !chatBloqueado && handleSend()} 
              disabled={chatBloqueado}
            >
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </main>

      {/* Formulário Flutuante */}
      <div className="unique-chatgpt-floating-container">
        {!isFloatingFormOpen ? (
          <button
            className="unique-chatgpt-floating-button"
            onClick={() => setIsFloatingFormOpen(true)}
            title="Adicionar Informações do Produto"
          >
            <FaPlusCircle />
          </button>
        ) : (
          <div className="unique-chatgpt-floating-form open">
            <button
              className="unique-chatgpt-floating-close"
              onClick={() => setIsFloatingFormOpen(false)}
              title="Fechar Formulário"
            >
              <FaTimes />
            </button>
            <h3>Enviar Informações do Produto</h3>
            <div className="unique-chatgpt-floating-field">
              <label htmlFor="produto">Produto:</label>
              <input
                type="text"
                id="produto"
                className="unique-chatgpt-floating-input"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
                placeholder="Digite o produto"
              />
            </div>
            <div className="unique-chatgpt-floating-field">
              <label htmlFor="publicoAlvo">Público Alvo:</label>
              <input
                type="text"
                id="publicoAlvo"
                className="unique-chatgpt-floating-input"
                value={publicoAlvo}
                onChange={(e) => setPublicoAlvo(e.target.value)}
                placeholder="Digite o público alvo"
              />
            </div>
            <div className="unique-chatgpt-floating-field">
              <label htmlFor="beneficios">Benefícios:</label>
              <textarea
                id="beneficios"
                className="unique-chatgpt-floating-textarea"
                value={beneficios}
                onChange={(e) => setBeneficios(e.target.value)}
                placeholder="Digite os benefícios"
                rows={4}
              />
            </div>
            <div className="unique-chatgpt-floating-field">
              <label htmlFor="condicoesPagamento">Condições de Pagamento:</label>
              <textarea
                id="condicoesPagamento"
                className="unique-chatgpt-floating-textarea"
                value={condicoesPagamento}
                onChange={(e) => setCondicoesPagamento(e.target.value)}
                placeholder="Digite as condições de pagamento"
                rows={4}
              />
            </div>
            {/* Campo Pre-selecionado: Seguir as Políticas de Publicidade do Facebook ADS */}
            <div className="unique-chatgpt-floating-field">
              <label htmlFor="seguirPoliticas">
                <input
                  type="checkbox"
                  id="seguirPoliticas"
                  checked={seguirPoliticas}
                  onChange={(e) => setSeguirPoliticas(e.target.checked)}
                />
                {' '}Seguir as políticas de Publicidade do Facebook ADS
              </label>
            </div>
            {/* Campo: Tipo de Produto */}
            <div className="unique-chatgpt-floating-field">
              <label>Tipo de Produto:</label>
              <div className="unique-chatgpt-checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    value="Produto Físico"
                    checked={tipoProduto.includes("Produto Físico")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTipoProduto([...tipoProduto, e.target.value]);
                      } else {
                        setTipoProduto(tipoProduto.filter(item => item !== e.target.value));
                      }
                    }}
                  />
                  Produto Físico
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Estimulante Sexual"
                    checked={tipoProduto.includes("Estimulante Sexual")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTipoProduto([...tipoProduto, e.target.value]);
                      } else {
                        setTipoProduto(tipoProduto.filter(item => item !== e.target.value));
                      }
                    }}
                  />
                  Estimulante Sexual
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Menopausa"
                    checked={tipoProduto.includes("Menopausa")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTipoProduto([...tipoProduto, e.target.value]);
                      } else {
                        setTipoProduto(tipoProduto.filter(item => item !== e.target.value));
                      }
                    }}
                  />
                  Menopausa
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Produto para Pele"
                    checked={tipoProduto.includes("Produto para Pele")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTipoProduto([...tipoProduto, e.target.value]);
                      } else {
                        setTipoProduto(tipoProduto.filter(item => item !== e.target.value));
                      }
                    }}
                  />
                  Produto para Pele
                </label>
                {/* Adicione mais opções conforme necessário */}
              </div>
            </div>
            <button
              className="unique-chatgpt-floating-submit"
              onClick={handleEnviarInformacoes}
              disabled={!produto || !publicoAlvo || !beneficios || !condicoesPagamento || !seguirPoliticas}
            >
              Enviar
            </button>
          </div>
        )}
      </div>

      {/* Pop-up do Tutorial */}
      {isTutorialOpen && (
        <div className="tutorial-popup-overlay" onClick={() => setIsTutorialOpen(false)}>
          <div className="tutorial-popup" onClick={(e) => e.stopPropagation()}>
            <button className="tutorial-popup-close" onClick={() => setIsTutorialOpen(false)}>
              <FaTimes />
            </button>
            <div className="tutorial-video-container">
              <iframe 
                id="panda-6c45fb35-df32-4cef-9332-d3a098945565" 
                src="https://player-vz-2290ca43-e85.tv.pandavideo.com.br/embed/?v=6c45fb35-df32-4cef-9332-d3a098945565" 
                style={{ border: 'none', position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} 
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" 
                allowFullScreen 
                width="100%" 
                height="100%" 
                fetchPriority="high"
                title="Tutorial Video"
              ></iframe>
            </div>
          </div>
        </div>
      )}

      {/* 
        Comentário para replicação em outras telas:
        Para adicionar o botão "Assistir Tutorial" em outras telas, copie o bloco de código do Pop-up do Tutorial
        e o botão correspondente dentro da seção onde deseja que ele apareça (ao lado dos botões existentes).
        Além disso, adicione a classe CSS fornecida no arquivo CSS correspondente.
      */}
    </div>
  );
};

export default Chat;
