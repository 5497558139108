import axios from 'axios';

// Configura a base URL e o token do arquivo .env
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
  },
});

// Serviço para listar módulos
export const listarModulos = async () => {
  try {
    const response = await api.get('/modulos');
    return response.data;
  } catch (error) {
    console.error('Erro ao listar módulos:', error);
    throw error;
  }
};

// Serviço para criar um módulo
export const criarModulo = async (modulo) => {
  try {
    const response = await api.post('/modulos', modulo);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar módulo:', error);
    throw error;
  }
};

// Serviço para editar um módulo
export const editarModulo = async (moduloId, modulo) => {
  try {
    const response = await api.put(`/modulos/${moduloId}`, modulo);
    return response.data;
  } catch (error) {
    console.error('Erro ao editar módulo:', error);
    throw error;
  }
};

// Serviço para excluir um módulo
export const excluirModulo = async (moduloId) => {
  try {
    const response = await api.delete(`/modulos/${moduloId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao excluir módulo:', error);
    throw error;
  }
};

// Serviço para listar tarefas de um módulo
export const listarTarefas = async (moduloId) => {
  try {
    const response = await api.get(`/modulos/${moduloId}/tarefas`);
    return response.data;
  } catch (error) {
    console.error('Erro ao listar tarefas:', error);
    throw error;
  }
};

// Serviço para criar uma tarefa
export const criarTarefa = async (moduloId, tarefa) => {
  try {
    const response = await api.post(`/modulos/${moduloId}/tarefas`, tarefa);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar tarefa:', error);
    throw error;
  }
};

// Serviço para editar uma tarefa
export const editarTarefa = async (moduloId, tarefaId, tarefa) => {
  try {
    const response = await api.put(`/modulos/${moduloId}/tarefas/${tarefaId}`, tarefa);
    return response.data;
  } catch (error) {
    console.error('Erro ao editar tarefa:', error);
    throw error;
  }
};

// Serviço para excluir uma tarefa
export const excluirTarefa = async (moduloId, tarefaId) => {
  try {
    const response = await api.delete(`/modulos/${moduloId}/tarefas/${tarefaId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao excluir tarefa:', error);
    throw error;
  }
};

// Serviço para reordenar módulos
export const reordenarModulos = async (modulos) => {
  try {
    const response = await api.put('/modulos/reordenar', { modulos });
    return response.data;
  } catch (error) {
    console.error('Erro ao reordenar módulos:', error);
    throw error;
  }
};

// Serviço para reordenar tarefas dentro de um módulo
export const reordenarTarefas = async (moduloId, tarefas) => {
  try {
    const response = await api.put(`/modulos/${moduloId}/tarefas/reordenar`, { tarefas });
    return response.data;
  } catch (error) {
    console.error('Erro ao reordenar tarefas:', error);
    throw error;
  }
};

// Serviço para mover uma tarefa para outro módulo
export const moverTarefa = async (tarefaId, novoModuloId, novaOrdem) => {
  try {
    const response = await api.put(`/tarefas/${tarefaId}/mover`, { modulo_id: novoModuloId, ordem: novaOrdem });
    return response.data;
  } catch (error) {
    console.error('Erro ao mover tarefa:', error);
    throw error;
  }
};
