import React, { useState, useEffect } from 'react';
import { FaTrophy, FaMedal, FaStar } from 'react-icons/fa';
import { supabase } from '../../supabaseClient';
import './Gameficacao.css';

const Gameficacao = ({ aulaId, moduloId, userId }) => {
  const [conquistas, setConquistas] = useState([]);
  const [pontuacao, setPontuacao] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      carregarDadosGamificacao();
    }
  }, [userId, aulaId]);

  const carregarDadosGamificacao = async () => {
    try {
      setLoading(true);

      // Carregar pontuação do usuário
      const { data: pontuacaoData, error: pontuacaoError } = await supabase
        .from('usuarios_gamificacao')
        .select('pontuacao')
        .eq('usuario_id', userId)
        .single();

      if (pontuacaoError && pontuacaoError.code !== 'PGRST116') throw pontuacaoError;

      // Carregar conquistas do usuário
      const { data: conquistasData, error: conquistasError } = await supabase
        .from('conquistas_usuarios')
        .select(`
          conquistas (
            id,
            nome,
            descricao,
            icone,
            pontos
          )
        `)
        .eq('usuario_id', userId);

      if (conquistasError) throw conquistasError;

      setPontuacao(pontuacaoData?.pontuacao || 0);
      setConquistas(conquistasData?.map(c => c.conquistas) || []);
    } catch (error) {
      console.error('Erro ao carregar dados de gamificação:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderIcone = (tipo) => {
    switch (tipo) {
      case 'trophy':
        return <FaTrophy />;
      case 'medal':
        return <FaMedal />;
      default:
        return <FaStar />;
    }
  };

  if (loading) {
    return <div className="gameficacao-loading">Carregando...</div>;
  }

  return (
    <div className="gameficacao-container">
      <h3>Suas Conquistas</h3>
      
      <div className="gameficacao-pontuacao">
        <FaTrophy className="pontuacao-icone" />
        <span>{pontuacao} pontos</span>
      </div>

      <div className="gameficacao-conquistas">
        {conquistas.length > 0 ? (
          conquistas.map((conquista) => (
            <div key={conquista.id} className="conquista-item">
              <div className="conquista-icone">
                {renderIcone(conquista.icone)}
              </div>
              <div className="conquista-info">
                <h4>{conquista.nome}</h4>
                <p>{conquista.descricao}</p>
                <span>{conquista.pontos} pontos</span>
              </div>
            </div>
          ))
        ) : (
          <p>Complete as aulas para desbloquear conquistas!</p>
        )}
      </div>
    </div>
  );
};

export default Gameficacao;