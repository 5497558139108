import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './Chat.css';
import {
  FaTrash,
  FaPlusCircle,
  FaPaperPlane,
  FaUserCircle,
  FaDownload,
  FaChartLine,
  FaTimes,
  FaVideo 
} from 'react-icons/fa';
import { getUserEmail } from '../../auth/auth';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import {
  inicializarPalavras,
  consumirPalavras,
  verificarPalavras,
} from '../../auth/palavraControle';
import { startMonitoring, stopMonitoring } from '../../auth/userActivityMonitor';
import { startSync, stopSync } from '../../auth/syncWords';
import ReactMarkdown from 'react-markdown';
import { metricNames } from './MetricsConfig';

// Componente para Seleção de Cores das Métricas
const MetricColorSelector = ({
  metric,
  selectedMetricsColors,
  setSelectedMetricsColors,
}) => {
  const colors = ['#22c55e', '#3b82f6', '#ef4444']; // Verde, Azul, Vermelho

  const handleColorClick = (color) => {
    const currentColor = selectedMetricsColors[metric];
    let newColors = { ...selectedMetricsColors };

    if (currentColor === color) {
      // Desativar a cor se já estiver selecionada
      delete newColors[metric];
    } else {
      // Definir a nova cor
      newColors[metric] = color;
    }

    setSelectedMetricsColors(newColors);
    localStorage.setItem('selectedMetricsColors', JSON.stringify(newColors));
  };

  return (
    <div className="metric-color-selector">
      {colors.map((color) => (
        <span
          key={color}
          className={`color-circle ${
            selectedMetricsColors[metric] === color ? 'selected-color' : ''
          }`}
          style={{ backgroundColor: color }}
          onClick={() => handleColorClick(color)}
          title={`Selecionar ${metricNames[metric]}`}
        />
      ))}
    </div>
  );
};

MetricColorSelector.propTypes = {
  metric: PropTypes.string.isRequired,
  selectedMetricsColors: PropTypes.object.isRequired,
  setSelectedMetricsColors: PropTypes.func.isRequired,
};

const Chat = ({
  metricsData,
  selectedMetrics,
  currentLevel,
  onAnalyzeMetrics,
}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [chatBloqueado, setChatBloqueado] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Estados para a análise de métricas
  const [saleValue, setSaleValue] = useState('');
  const [commissionValue, setCommissionValue] = useState('');
  const [isMetricsPanelVisible, setIsMetricsPanelVisible] = useState(true);

  const userEmail = getUserEmail();
  const MAX_REQUESTS_PER_MINUTE = 10;

  const assistantId =
    process.env.REACT_APP_OPENAI_ASSISTANT_ANALIDEMETRICA_ID;

  const [isTutorialOpen, setIsTutorialOpen] = useState(false);

  const [displayedGreeting, setDisplayedGreeting] = useState('');
  const [fullGreeting, setFullGreeting] = useState('');

  useEffect(() => {
    // Configurar saudação
    const configureGreeting = () => {
      const userName = localStorage.getItem('userName') || 'Usuário';
      const trimmedName = userName.trim();
      const initial = trimmedName.charAt(0).toUpperCase() || 'U';

      const saoPauloTime = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
      const currentHour = new Date(saoPauloTime).getHours();

      let greeting = '';
      if (currentHour >= 6 && currentHour < 12) {
        greeting = 'Bom dia';
      } else if (currentHour >= 12 && currentHour < 18) {
        greeting = 'Boa tarde';
      } else if (currentHour >= 18 && currentHour < 24) {
        greeting = 'Boa noite';
      } else {
        greeting = 'Boa Madrugada';
      }

      const greetingText = `Olá, ${greeting}, ${trimmedName}. Vamos analisar suas métricas hoje.`;
      setFullGreeting(greetingText);
    };

    configureGreeting();
  }, []);

  useEffect(() => {
    if (fullGreeting) {
      setDisplayedGreeting('');
      let index = 0;
      const typingSpeed = 100;

      const typeCharacter = () => {
        if (index <= fullGreeting.length) {
          setDisplayedGreeting(fullGreeting.slice(0, index));
          index++;
          setTimeout(typeCharacter, typingSpeed);
        }
      };

      typeCharacter();
    }
  }, [fullGreeting]);

  useEffect(() => {
    const storedMessages = localStorage.getItem(
      `chatHistory_${userEmail}_${assistantId}`
    );
    const storedWords = localStorage.getItem(
      `${userEmail}-palavrasDisponiveis`
    );

    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
    } else {
      setChatBloqueado(false);
    }

    startMonitoring();
    startSync();
    scrollToBottom();

    return () => {
      stopMonitoring();
      stopSync();
    };
  }, [userEmail, assistantId]);

  useEffect(() => {
    const textarea = inputRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setCharCount(textarea.value.length);
  }, [input]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const saveMessages = (messages) => {
    localStorage.setItem(
      `chatHistory_${userEmail}_${assistantId}`,
      JSON.stringify(messages)
    );
    scrollToBottom();
  };

  const canMakeRequest = () => {
    const requestTimestamps =
      JSON.parse(
        localStorage.getItem(`requestTimestamps_${userEmail}_${assistantId}`)
      ) || [];
    const now = Date.now();
    const oneMinuteAgo = now - 60000;

    const recentRequests = requestTimestamps.filter(
      (timestamp) => timestamp > oneMinuteAgo
    );
    localStorage.setItem(
      `requestTimestamps_${userEmail}_${assistantId}`,
      JSON.stringify(recentRequests)
    );

    return recentRequests.length < MAX_REQUESTS_PER_MINUTE;
  };

  const recordRequestTimestamp = () => {
    const requestTimestamps =
      JSON.parse(
        localStorage.getItem(`requestTimestamps_${userEmail}_${assistantId}`)
      ) || [];
    requestTimestamps.push(Date.now());
    localStorage.setItem(
      `requestTimestamps_${userEmail}_${assistantId}`,
      JSON.stringify(requestTimestamps)
    );
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const contarTokensPorPalavra = (palavra) => {
    const length = palavra.length;
    if (length <= 4) return 1;
    if (length <= 8) return 2;
    return 3;
  };

  const calcularTokensUsados = (message) => {
    return message
      .trim()
      .split(/\s+/)
      .reduce((total, palavra) => total + contarTokensPorPalavra(palavra), 0);
  };

  const verificarSaldoLocalStorage = () => {
    const storedWords = localStorage.getItem(
      `${userEmail}-palavrasDisponiveis`
    );
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
      return false;
    }
    return true;
  };

  const handleSend = async (messageToSend = null) => {
    const message = messageToSend || input;

    if (message.trim() === '') return;

    const tokensUsados = calcularTokensUsados(message);

    if (!verificarSaldoLocalStorage()) {
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      return;
    }

    let saldoAtual = parseInt(
      localStorage.getItem(`${userEmail}-palavrasDisponiveis`),
      10
    );
    if (isNaN(saldoAtual)) saldoAtual = 0;

    if (saldoAtual < tokensUsados) {
      alert('Você não tem tokens suficientes para enviar essa mensagem.');
      return;
    }

    if (!canMakeRequest()) {
      alert(
        'Você atingiu o limite de requisições por minuto. Por favor, aguarde.'
      );
      return;
    }

    const userMessage = { text: message, sender: 'user' };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    saveMessages(updatedMessages);

    saldoAtual -= tokensUsados;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);
    await consumirPalavras(userEmail, tokensUsados);

    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    setIsTyping(true);
    recordRequestTimestamp();

    try {
      let threadId = localStorage.getItem(
        `chatThreadId_${userEmail}_${assistantId}`
      );

      if (!threadId) {
        const threadResponse = await axios.post(
          'https://api.openai.com/v1/threads',
          {},
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2',
            },
          }
        );
        threadId = threadResponse.data.id;
        localStorage.setItem(
          `chatThreadId_${userEmail}_${assistantId}`,
          threadId
        );
      }

      await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          role: 'user',
          content: message,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );

      setInput('');

      const runResponse = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: assistantId,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );

      let runStatus = 'queued';
      while (runStatus !== 'completed') {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const statusResponse = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runResponse.data.id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2',
            },
          }
        );
        runStatus = statusResponse.data.status;
      }

      const messagesResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );

      const assistantMessage = messagesResponse.data.data.find(
        (msg) => msg.role === 'assistant'
      );

      setIsTyping(false);

      if (assistantMessage) {
        const assistantText = assistantMessage.content
          .map((content) => content.text?.value || '')
          .join(' ');

        const updatedMessagesWithResponse = [
          ...updatedMessages,
          { text: assistantText, sender: 'assistant' },
        ];
        setMessages(updatedMessagesWithResponse);
        saveMessages(updatedMessagesWithResponse);

        const tokensResposta = calcularTokensUsados(assistantText);
        saldoAtual -= tokensResposta;
        localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);
        await consumirPalavras(userEmail, tokensResposta);

        if (saldoAtual <= 0) {
          setChatBloqueado(true);
          alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
        }
      } else {
        const errorMessage = [
          ...updatedMessages,
          { text: 'Erro ao processar a solicitação.', sender: 'assistant' },
        ];
        setMessages(errorMessage);
        saveMessages(errorMessage);
      }
    } catch (error) {
      console.error(
        'Erro ao comunicar com o assistente:',
        error.response ? error.response.data : error.message
      );
      const errorMessage = [
        ...messages,
        { text: 'Erro ao processar a solicitação.', sender: 'assistant' },
      ];
      setMessages(errorMessage);
      saveMessages(errorMessage);
    }
  };

  const handleDeleteConversation = async () => {
    const threadId = localStorage.getItem(
      `chatThreadId_${userEmail}_${assistantId}`
    );

    if (threadId) {
      try {
        await axios.delete(
          `https://api.openai.com/v1/threads/${threadId}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2',
            },
          }
        );
      } catch (error) {
        console.error(
          'Erro ao excluir a thread no servidor:',
          error.response ? error.response.data : error.message
        );
      }
    }

    localStorage.removeItem(`chatHistory_${userEmail}_${assistantId}`);
    localStorage.removeItem(`chatThreadId_${userEmail}_${assistantId}`);
    setMessages([]);
  };

  const handleNewConversation = () => {
    handleDeleteConversation();
  };

  const handleDownloadDocx = (message) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: message,
                  font: isMobile() ? 'Arial' : 'Calibri',
                  size: isMobile() ? 18 : 24,
                  break: 1,
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'chat-response.docx');
    });
  };

  const handleAnalyzeMetrics = () => {
    if (!metricsData || !metricsData.items || metricsData.items.length === 0) {
      alert('Nenhuma métrica disponível para análise.');
      return;
    }

    if (parseFloat(saleValue) <= 0 || parseFloat(commissionValue) <= 0) {
      alert('Os valores de venda e comissão devem ser positivos.');
      return;
    }

    // Filtrar as métricas válidas
    const validMetrics = selectedMetrics.filter((metric) =>
      metricNames.hasOwnProperty(metric)
    );

    if (validMetrics.length === 0) {
      alert('Nenhuma métrica válida selecionada para análise.');
      return;
    }

    // Formatar as métricas selecionadas
    const formattedMetrics = metricsData.items.map((item) => {
      const selectedItemMetrics = validMetrics.map((metric) => ({
        name: metricNames[metric],
        value:
          item.metrics[metric] !== undefined
            ? item.metrics[metric]
            : 'Não disponível',
      }));

      const additionalFields = {};

      // Incluir dateRange e creativeTypes (se existirem)
      // Para campaigns e adSets, temos arrays de adSets e ads que podem ter creativeTypes
      // Para adSets, temos creativeTypes no próprio adSet
      // Para ads, temos creativeType no nível do anúncio

      if (currentLevel === 'campaigns') {
        additionalFields['Tipo de Campanha'] = item.type;
        additionalFields['Objetivo da Campanha'] = item.objective;
        additionalFields['Otimização de Orçamento'] = item.budgetOptimization;
        
        // Incluir adSets
        if (item.adSets && item.adSets.length > 0) {
  additionalFields['Conjuntos de Anúncios'] = item.adSets.map((adSet) => {
    const adSetInfo = {
      Nome: adSet.name,
      'Tipo de Conversão': adSet.conversionType,
      'Orçamento': adSet.budget,
      'Otimização de Orçamento': adSet.budgetOptimization,
      'Objetivo da Campanha': adSet.campaignObjective,
      'Intervalo de Datas': adSet.dateRange
        ? `${adSet.dateRange.since} a ${adSet.dateRange.until}`
        : 'Não disponível',
      Métricas: adSet.metrics,
    };

    // Verificar e consolidar creativeTypes do nível de anúncio
    const creativeTypesFromAds = adSet.ads?.map((ad) => ad.creativeType) || [];
    const uniqueCreativeTypes = [...new Set([...(adSet.creativeTypes || []), ...creativeTypesFromAds])];

    if (uniqueCreativeTypes.length > 0) {
      adSetInfo['Tipos de Criativo do Conjunto'] = uniqueCreativeTypes.join(', ');
    }

    // Mapeamento dos anúncios
    if (adSet.ads && adSet.ads.length > 0) {
      adSetInfo['Anúncios'] = adSet.ads.map((ad) => ({
        Nome: ad.name,
        'Tipo de Criativo': ad.creativeType,
        'Orçamento': ad.budget,
        'Otimização de Orçamento': ad.budgetOptimization,
        'Objetivo da Campanha': ad.campaignObjective,
        'Intervalo de Datas': ad.dateRange
          ? `${ad.dateRange.since} a ${ad.dateRange.until}`
          : 'Não disponível',
        Métricas: ad.metrics,
      }));
    }

    return adSetInfo;
  });
}

      } else if (currentLevel === 'adSets') {
        additionalFields['Tipo de Conversão'] = item.conversionType;
        additionalFields['Objetivo da Campanha'] = item.campaignObjective;
        additionalFields['Otimização de Orçamento'] = item.budgetOptimization;
        additionalFields['Intervalo de Datas'] = item.dateRange
          ? `${item.dateRange.since} a ${item.dateRange.until}`
          : 'Não disponível';

        // Incluir creativeTypes do AdSet
        if (item.creativeTypes && item.creativeTypes.length > 0) {
          additionalFields['Tipos de Criativo do Conjunto'] = item.creativeTypes.join(', ');
        }

       if (item.ads && item.ads.length > 0) {
  const creativeTypesFromAds = item.ads.map((ad) => ad.creativeType);
  const uniqueCreativeTypes = [...new Set([...(item.creativeTypes || []), ...creativeTypesFromAds])];

  if (uniqueCreativeTypes.length > 0) {
    additionalFields['Tipos de Criativo do Conjunto'] = uniqueCreativeTypes.join(', ');
  }

  additionalFields['Anúncios Relacionados'] = item.ads.map((ad) => ({
    Nome: ad.name,
    'Tipo de Criativo': ad.creativeType,
    'Orçamento': ad.budget,
    'Otimização de Orçamento': ad.budgetOptimization,
    'Objetivo da Campanha': ad.campaignObjective,
    'Intervalo de Datas': ad.dateRange
      ? `${ad.dateRange.since} a ${ad.dateRange.until}`
      : 'Não disponível',
    Métricas: ad.metrics,
  }));
}

      } else if (currentLevel === 'ads') {
        additionalFields['Tipo de Criativo'] = item.creativeType;
        additionalFields['Nome do Conjunto de Anúncio'] = item.adSetName;
        additionalFields['Tipo de Conversão'] = item.conversionType;
        additionalFields['Objetivo da Campanha'] = item.campaignObjective;
        additionalFields['Otimização de Orçamento'] = item.budgetOptimization;
        additionalFields['Intervalo de Datas'] = item.dateRange
          ? `${item.dateRange.since} a ${item.dateRange.until}`
          : 'Não disponível';
      }

      return {
        id: item.id,
        name: item.name,
        metrics: selectedItemMetrics,
        ...additionalFields,
      };
    });

    // Incluindo período de análise no texto final
    const analysisMessage = `
**Análise de Métricas das ${
      currentLevel.charAt(0).toUpperCase() + currentLevel.slice(1)
    } Selecionadas**

**Período de Análise:** ${metricsData.analysisPeriod}

${formattedMetrics
  .map(
    (item) => `
**${item.name}:**
${Object.entries(item)
  .filter(
    ([key]) =>
      key !== 'id' && key !== 'name' && key !== 'metrics' && key !== 'adSets' && key !== 'ads'
  )
  .map(([key, value]) => {
    if (Array.isArray(value)) {
      return `- **${key}:**
${value
  .map((subItem) => {
    if (typeof subItem === 'object' && subItem !== null) {
      return `  - ${Object.entries(subItem)
        .map(([subKey, subValue]) => `${subKey}: ${subValue}`)
        .join(', ')}`;
    }
    return `  - ${subItem}`;
  })
  .join('\n')}`;
    }
    return `- **${key}:** ${value}`;
  })
  .join('\n')}
${item.metrics
  .map((metric) => `- **${metric.name}:** ${metric.value}`)
  .join('\n')}
`
  )
  .join('\n')}

**Valores Financeiros:**
- **Valor de Venda:** R$ ${parseFloat(saleValue).toFixed(2)}
- **Valor de Comissão:** R$ ${parseFloat(commissionValue).toFixed(2)}

_Por favor, analise essas métricas e valores._
`;

    handleSend(analysisMessage);

    setSaleValue('');
    setCommissionValue('');

    if (onAnalyzeMetrics) {
      onAnalyzeMetrics();
    }
  };

  const isAnalyzeButtonDisabled = !(
    parseFloat(saleValue) > 0 &&
    parseFloat(commissionValue) > 0 &&
    metricsData &&
    metricsData.items &&
    metricsData.items.length > 0 &&
    selectedMetrics &&
    selectedMetrics.length > 0
  );

  return (
    <div className="unique-chat-containerr">
      <div className="unique-chat-header">
        <div className="unique-chat-logo"></div>
        <h2 className={`greeting-text ${isTyping ? 'typing' : ''}`}>
          {displayedGreeting}
          <span className="cursor">|</span>
        </h2>
        <div className="unique-chat-buttons">
          <button
            className="tutorial-chat-button"
            title="Assistir Tutorial"
            onClick={() => setIsTutorialOpen(true)}
          >
            <FaVideo /> Assistir Tutorial
          </button>
          <div
            className="unique-chat-button"
            title="Nova Conversa"
          >
            <FaPlusCircle onClick={handleNewConversation} />
          </div>
          <div
            className="unique-chat-button"
            title="Excluir Conversa"
          >
            <FaTrash onClick={handleDeleteConversation} />
          </div>
        </div>
      </div>
      <div className="unique-chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`unique-chat-message ${msg.sender}`}>
            {msg.sender === 'user' ? (
              <FaUserCircle className="unique-message-icon" />
            ) : (
              <div className="unique-message-icon"></div>
            )}
            {msg.sender === 'assistant' ? (
              <ReactMarkdown className="markdown-content">
                {msg.text}
              </ReactMarkdown>
            ) : (
              <span>{msg.text}</span>
            )}
            {msg.sender === 'assistant' && (
              <button
                className="unique-chat-download-button"
                onClick={() => handleDownloadDocx(msg.text)}
              >
                <FaDownload />
              </button>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="unique-chat-message assistant typing">
            <div className="unique-message-icon"></div>
            <span>O assistente está digitando...</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="unique-chat-input-container">
        <textarea
          ref={inputRef}
          className="unique-chat-text-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
          placeholder="Digite sua mensagem..."
          disabled={chatBloqueado}
        />
        <span className="char-count">{charCount} caracteres</span>
        <button
          className="unique-chat-send-button"
          onClick={() => handleSend()}
          disabled={chatBloqueado}
        >
          <FaPaperPlane />
        </button>
      </div>

      {isMetricsPanelVisible && (
        <div className="floating-button-container">
          <button
            className="floating-close-button"
            onClick={() => setIsMetricsPanelVisible(false)}
            title="Fechar"
          >
            <FaTimes />
          </button>
          <input
            type="number"
            placeholder="Valor de Venda (R$)"
            value={saleValue}
            onChange={(e) => setSaleValue(e.target.value)}
            className="floating-input"
          />
          <input
            type="number"
            placeholder="Valor de Comissão (R$)"
            value={commissionValue}
            onChange={(e) => setCommissionValue(e.target.value)}
            className="floating-input"
          />
          <button
            onClick={handleAnalyzeMetrics}
            disabled={isAnalyzeButtonDisabled}
            className="floating-button"
            title="Análise de Métrica"
          >
            <FaChartLine /> Análise de Métrica
          </button>
        </div>
      )}
      {!isMetricsPanelVisible && (
        <button
          className="floating-open-button"
          onClick={() => setIsMetricsPanelVisible(true)}
          title="Abrir Análise de Métrica"
        >
          <FaChartLine />
        </button>
      )}

      {isTutorialOpen && (
        <div
          className="tutorial-popup-overlay"
          onClick={() => setIsTutorialOpen(false)}
        >
          <div
            className="tutorial-popup"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="tutorial-popup-close"
              onClick={() => setIsTutorialOpen(false)}
            >
              <FaTimes />
            </button>
            <div className="tutorial-video-container">
              <iframe 
                id="panda-6c45fb35-df32-4cef-9332-d3a098945565" 
                src="https://player-vz-2290ca43-e85.tv.pandavideo.com.br/embed/?v=6c45fb35-df32-4cef-9332-d3a098945565" 
                style={{ border: 'none', position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} 
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" 
                allowFullScreen 
                width="100%" 
                height="100%" 
                fetchPriority="high"
                title="Tutorial Video"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Chat.propTypes = {
  metricsData: PropTypes.shape({
    level: PropTypes.oneOf(['campaigns', 'adSets', 'ads']).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        metrics: PropTypes.object.isRequired,
        type: PropTypes.string,
        objective: PropTypes.string,
        budgetOptimization: PropTypes.string,
        conversionType: PropTypes.string,
        creativeType: PropTypes.string,
        adSetName: PropTypes.string,
        dateRange: PropTypes.shape({
          since: PropTypes.string,
          until: PropTypes.string,
        }),
        ads: PropTypes.arrayOf(
          PropTypes.shape({
            creativeType: PropTypes.string.isRequired,
            adSetName: PropTypes.string,
            budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            budgetOptimization: PropTypes.string,
            campaignObjective: PropTypes.string,
            metrics: PropTypes.object,
            dateRange: PropTypes.shape({
              since: PropTypes.string,
              until: PropTypes.string,
            }),
          })
        ),
        adSets: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            conversionType: PropTypes.string,
            budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            budgetOptimization: PropTypes.string,
            campaignObjective: PropTypes.string,
            metrics: PropTypes.object,
            dateRange: PropTypes.shape({
              since: PropTypes.string,
              until: PropTypes.string,
            }),
            creativeTypes: PropTypes.arrayOf(PropTypes.string),
            ads: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string,
                creativeType: PropTypes.string,
                budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                budgetOptimization: PropTypes.string,
                campaignObjective: PropTypes.string,
                metrics: PropTypes.object,
                dateRange: PropTypes.shape({
                  since: PropTypes.string,
                  until: PropTypes.string,
                }),
              })
            ),
          })
        ),
      })
    ).isRequired,
    selectedMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
    analysisPeriod: PropTypes.string.isRequired,
  }).isRequired,
  selectedMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentLevel: PropTypes.oneOf(['campaigns', 'adSets', 'ads']).isRequired,
  onAnalyzeMetrics: PropTypes.func.isRequired,
};

export default Chat;
