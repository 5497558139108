import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PlanejadorProsperidade.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faChartLine, faCalendarAlt, faMoneyBillWave, faAd, faBullhorn, faChartPie } from '@fortawesome/free-solid-svg-icons';
import * as htmlToImage from 'html-to-image';

const PlanejadorProsperidade = () => {
  const [budget, setBudget] = useState('');
  const [plan, setPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Estado para carregamento do botão
  const [errorMessage, setErrorMessage] = useState(''); // Estado para mensagens de erro
  const navigate = useNavigate(); // Hook para navegação

  const userEmail = localStorage.getItem('userEmail');
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redirecionamento utilizando useNavigate
    }

    // Efeito de movimento na entrada
    const inputContainer = document.querySelector('.planejador-slide-in-container');
    if (inputContainer) {
      inputContainer.classList.add('planejador-slide-in');
    }
  }, [navigate]);

  // Estruturas de Campanha
  const structures = {
    creative: {
      veryLow: { // <= 10
        type: 'ABO',
        structure: '1-1-2',
        description: 'Estrutura econômica para teste de criativos',
        strategy: 'Teste 2 criativos diferentes com público amplo'
      },
      low: { // <= 20
        type: 'CBO',
        structure: '1-2-2',
        description: 'Estrutura otimizada para teste de criativos',
        strategy: 'Teste A/B com 2 conjuntos e 2 criativos cada'
      },
      medium: { // <= 40
        type: 'CBO',
        structure: '1-2-3',
        description: 'Estrutura expandida para teste de criativos',
        strategy: 'Teste múltiplos criativos com otimização automática'
      },
      high: { // > 40
        type: 'CBO',
        structure: '1-3-3',
        description: 'Estrutura completa para teste de criativos',
        strategy: 'Teste abrangente com múltiplos conjuntos e criativos'
      }
    },
    audience: {
      veryLow: {
        type: 'ABO',
        structure: '1-2-1',
        description: 'Estrutura básica para teste de públicos',
        strategy: 'Teste 2 públicos diferentes com criativo validado'
      },
      low: {
        type: 'CBO',
        structure: '1-3-1',
        description: 'Estrutura expandida para teste de públicos',
        strategy: 'Teste 3 públicos diferentes com otimização automática'
      },
      medium: {
        type: 'CBO',
        structure: '1-3-2',
        description: 'Estrutura otimizada para teste de públicos',
        strategy: 'Teste 3 públicos com 2 criativos validados cada'
      },
      high: {
        type: 'CBO',
        structure: '1-4-2',
        description: 'Estrutura avançada para teste de públicos',
        strategy: 'Teste amplo de públicos com criativos validados'
      }
    },
    scale: {
      veryLow: {
        type: 'CBO',
        structure: '1-2-2',
        description: 'Estrutura de escala inicial',
        strategy: 'Escala controlada com públicos e criativos validados'
      },
      low: {
        type: 'CBO',
        structure: '2-2-2',
        description: 'Estrutura de escala dividida',
        strategy: 'Escala com duas campanhas paralelas para comparação'
      },
      medium: {
        type: 'CBO',
        structure: '2-3-2',
        description: 'Estrutura de escala otimizada',
        strategy: 'Escala com múltiplos conjuntos e campanhas'
      },
      high: {
        type: 'CBO',
        structure: '2-4-2',
        description: 'Estrutura de escala avançada',
        strategy: 'Escala completa com múltiplas campanhas e conjuntos'
      }
    }
  };

  // Função para determinar a faixa de orçamento
  const getBudgetTier = (budget) => {
    if (budget <= 10) return 'veryLow';
    if (budget <= 20) return 'low';
    if (budget <= 40) return 'medium';
    return 'high';
  };

  // Função para determinar a estrutura das campanhas com base no orçamento diário e tipo de teste
  const determineCampaignStructure = (dailyBudget, testType) => {
    const budgetTier = getBudgetTier(dailyBudget);
    return structures[testType][budgetTier];
  };

  // Função para calcular o orçamento diário
  const calculateDailyBudget = (totalBudget, days) => totalBudget / days;

  // Função para determinar a distribuição de dias entre as fases
  const distributeDays = (totalDays) => {
    const creativeDays = Math.min(3, totalDays); // Fase de criativos fixa para até 3 dias
    const audienceDays = totalDays - creativeDays;
    return { creativeDays, audienceDays };
  };

  // Função para validar se o orçamento por campanha ou conjunto de anúncios atende ao mínimo de R$6
  const validateStructure = (structure, dailyBudget) => {
    if (!structure) return true; // Se a estrutura for null, não precisa validar

    const [campaigns, adSets, ads] = structure.structure.split('-').map(Number);
    if (structure.type === 'CBO') {
      const budgetPerCampaign = dailyBudget / campaigns;
      if (budgetPerCampaign < 6) {
        return false;
      }
    } else if (structure.type === 'ABO') {
      const budgetPerAdSet = dailyBudget / adSets;
      if (budgetPerAdSet < 6) {
        return false;
      }
    }
    return true;
  };

  // Função para obter o plano de campanha
  const getCampaignPlan = (totalBudget) => {
    let days;
    if (totalBudget <= 300) {
      days = 7;
    } else if (totalBudget <= 750) {
      days = 15;
    } else {
      days = 30;
    }

    const { creativeDays, audienceDays } = distributeDays(days);
    const dailyBudget = calculateDailyBudget(totalBudget, days);

    // Determinar estruturas
    const creativeStructure = determineCampaignStructure(dailyBudget, 'creative');
    const audienceStructure = determineCampaignStructure(dailyBudget, 'audience');
    const scaleStructure = determineCampaignStructure(dailyBudget, 'scale');

    // Validar estruturas
    if (!validateStructure(creativeStructure, dailyBudget)) {
      setErrorMessage('Orçamento diário insuficiente para a fase de criativos. Cada campanha ou conjunto de anúncios deve ter no mínimo R$6.');
      return null;
    }

    if (!validateStructure(audienceStructure, dailyBudget)) {
      setErrorMessage('Orçamento diário insuficiente para a fase de públicos. Cada campanha ou conjunto de anúncios deve ter no mínimo R$6.');
      return null;
    }

    // Adicionar fase de escala apenas se o orçamento diário for >= R$50
    let scaleApplicable = false;
    let scaleStructureFinal = null;
    if (dailyBudget >= 50) {
      scaleApplicable = true;
      if (!validateStructure(scaleStructure, dailyBudget)) {
        setErrorMessage('Orçamento diário insuficiente para a fase de escala. Cada campanha ou conjunto de anúncios deve ter no mínimo R$6.');
        return null;
      }
      scaleStructureFinal = scaleStructure;
    }

    // Se todas as validações passarem, limpar a mensagem de erro
    setErrorMessage('');

    return {
      totalBudget,
      days,
      creativeDays,
      audienceDays,
      dailyBudget,
      creativeStructure,
      audienceStructure,
      scaleStructure: scaleApplicable ? scaleStructureFinal : null,
    };
  };

  // Função para gerar o plano
  const handleGeneratePlan = () => {
    const totalBudget = parseFloat(budget.replace(/\./g, '').replace(',', '.'));
    if (isNaN(totalBudget) || totalBudget < 6) { // Verificar se o orçamento total é pelo menos R$6
      setErrorMessage('Orçamento mínimo para iniciar um plano é R$6.');
      setPlan(null);
      return;
    }

    const campaignPlan = getCampaignPlan(totalBudget);
    if (campaignPlan) {
      setPlan(campaignPlan);
    } else {
      setPlan(null);
    }
  };

  // Função para lidar com o Enter no input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleGeneratePlan();
    }
  };

  // useEffect para scroll após o plano ser gerado
  useEffect(() => {
    if (plan) {
      const resultsElement = document.getElementById('plan-details');
      if (resultsElement) {
        resultsElement.style.display = 'block';
        window.scrollTo({ top: resultsElement.offsetTop, behavior: 'smooth' });
      }
    }
  }, [plan]);

  // Função para formatar a entrada do orçamento
  const handleBudgetChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d)(\d{2})$/, '$1,$2').replace(/(?=(\d{3})+(\D))\B/g, '.');
    setBudget(formattedValue);
  };

  // Função para renderizar a estrutura da campanha
  const renderCampaignStructure = (structure, dailyBudget) => {
    if (!structure) return null; // Se a estrutura for nula (por exemplo, fase de escala não aplicável), não renderizar

    const { type, structure: struct, description, strategy } = structure;
    const [campaigns, adSets, ads] = struct.split('-').map(Number);
    const campaignType = type === 'CBO' ? 'Campanha CBO' : 'Campanha ABO';
    const budgetPerAdSet = (dailyBudget / adSets).toFixed(2);

    const adSetElements = Array.from({ length: adSets }).map((_, adSetIndex) => (
      <div key={adSetIndex} className="adset">
        <strong>Conjunto {adSetIndex + 1}:</strong> {type === 'ABO' ? `R$ ${budgetPerAdSet}` : 'Auto'}
        {Array.from({ length: ads }).map((_, adIndex) => (
          <div key={adIndex} className="ad">Anúncio {adIndex + 1}</div>
        ))}
      </div>
    ));

    return (
      <div className="campaign-structure">
        <div className="campaign-block">
          <strong>{campaignType}</strong> - {struct}
          {type === 'CBO' && (
            <div className="campaign-budget">
              <strong>Orçamento da Campanha:</strong> R$ {dailyBudget.toFixed(2)}
            </div>
          )}
        </div>
        {adSetElements}
      </div>
    );
  };

  // Função para baixar o plano como imagem com indicador de carregamento
  const downloadAsImage = () => {
    setIsLoading(true); // Iniciar carregamento

    const element = document.getElementById('plan-details');
    if (!element) {
      setIsLoading(false);
      return;
    }

    const logoElement = document.createElement('img');
    logoElement.src = logo;
    logoElement.className = 'print-logo';
    element.insertBefore(logoElement, element.firstChild);

    htmlToImage.toPng(element)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'plano-facebook-ads.png';
        link.click();
        element.removeChild(logoElement);
        setIsLoading(false); // Finalizar carregamento
      })
      .catch((error) => {
        console.error('Erro ao salvar como imagem:', error);
        if (element.contains(logoElement)) {
          element.removeChild(logoElement);
        }
        setIsLoading(false); // Finalizar carregamento mesmo em caso de erro
      });
  };

  // Função para renderizar os detalhes de cada fase
  const renderPhaseDetails = (phase, structureInfo, dailyBudget, startDay, duration) => {
    if (!structureInfo) return null; // Se structureInfo for nulo, não renderizar
    return (
      <div className="phase-details">
        <h3>Fase {phase.number} - {phase.name} ({phase.duration})</h3>
        <div className="phase-info">
          <p>
            <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
            <strong>Período:</strong> Dia {startDay} a Dia {startDay + duration - 1} ({duration} dias)
          </p>
          <p>
            <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
            <strong>Orçamento Diário:</strong> R$ {dailyBudget.toFixed(2)}
          </p>
          <p>
            <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
            <strong>Estrutura:</strong> {structureInfo.structure} ({structureInfo.type})
          </p>
          <p>
            <strong>Descrição:</strong> {structureInfo.description}
          </p>
          <p>
            <strong>Estratégia:</strong> {structureInfo.strategy}
          </p>
        </div>
        {renderCampaignStructure(structureInfo, dailyBudget)}
      </div>
    );
  };

  // Função para renderizar explicações adicionais
  const renderExplanations = () => (
    <div className="explanation">
      <h3 className="explanation-title">Explicações sobre a Estrutura de Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha:</strong> Onde você define o objetivo do anúncio, como conversão ou alcance.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
        <strong>Conjunto de Anúncios:</strong> Onde você define seu público-alvo, orçamento e cronograma.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faAd} className="explanation-icon" />
        <strong>Anúncios:</strong> Onde você cria os anúncios em si, definindo imagens, vídeos e textos.
      </p>
      <h3 className="explanation-title">Explicações sobre o que é CBO e ABO</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>CBO (Campaign Budget Optimization):</strong> O Facebook distribui automaticamente o orçamento entre os conjuntos de anúncios para maximizar os resultados.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>ABO (Ad Set Budget Optimization):</strong> O orçamento é definido manualmente para cada conjunto de anúncios.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Para ativar o CBO na sua campanha do Facebook:</strong>
        <ol>
          <li>Vá para o Gerenciador de Anúncios do Facebook.</li>
          <li>Crie uma nova campanha ou edite uma existente.</li>
          <li>Na configuração da campanha, encontre a opção "Otimização do Orçamento da Campanha" e ative-a.</li>
          <li>Defina o orçamento total da campanha.</li>
          <li>Continue configurando os conjuntos de anúncios e anúncios normalmente.</li>
        </ol>
      </p>

      <h3 className="explanation-title">Escolhendo o Objetivo da Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha de Engajamento com Objetivo de Mensagem para o WhatsApp:</strong> Focada em gerar interações e mensagens no WhatsApp, geralmente atrai um público mais amplo e menos qualificado, mas com um custo por mensagem mais baixo.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha de Conversão com Objetivo de Mensagem para o WhatsApp:</strong> Focada em gerar mensagens no WhatsApp com um público mais qualificado, geralmente com um custo por mensagem mais alto.
      </p>

      <h3 className="explanation-title">Estrutura da Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Teste de Criativos:</strong> Cada conjunto de anúncio deve ter criativos diferentes para testar qual performa melhor.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Teste de Públicos:</strong> Utilize os criativos validados no teste de criativos e teste diferentes públicos para encontrar o melhor público-alvo.
      </p>

      <h3 className="explanation-title">Métricas a Serem Analisadas</h3>
      <ul>
        <li>
          <FontAwesomeIcon icon={faChartLine} className="explanation-icon" />
          <strong>CTR (Click-Through Rate):</strong> Taxa de cliques. Exemplo: Se 100 pessoas viram seu anúncio e 10 clicaram, seu CTR é 10%.
        </li>
        <li>
          <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
          <strong>CPC (Cost Per Click):</strong> Custo por clique. Exemplo: Se você gastou R$ 10 e teve 10 cliques, seu CPC é R$ 1.
        </li>
        <li>
          <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
          <strong>Conversões:</strong> Ações desejadas resultantes dos cliques nos anúncios. Exemplo: Compras, cadastros, etc.
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="explanation-icon" />
          <strong>Custo por Mensagem:</strong> Custo para gerar uma mensagem via WhatsApp.
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="explanation-icon" />
          <strong>Taxa de Conversão no WhatsApp:</strong> Percentual de mensagens que resultam em uma conversão. Recomenda-se uma taxa mínima de 10%.
        </li>
      </ul>
    </div>
  );

  return (
    <div className="planejador-prosperidade-container">
      <div className="planejador-prosperidade-header">
        <img src={logo} alt="Logo" className="planejador-prosperidade-logo" />
        <div className="planejador-prosperidade-user-info">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{userName}</span>
          <button className="planejador-prosperidade-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="planejador-prosperidade-content">
        <h1 className="planejador-prosperidade-title-gradient">Guia de Investimento Fácil</h1>
        <p className="planejador-prosperidade-description">Insira o valor que você tem disponível para investir no seu projeto digital:</p>
        <Link to="/ferramentas" className="planejador-prosperidade-button">← Voltar para o Menu</Link>
        <div className="planejador-slide-in-container">
          <input
            type="text"
            value={budget}
            onChange={handleBudgetChange}
            onKeyPress={handleKeyPress} // Função definida
            placeholder="R$ 0,00"
            className="planejador-budget-input"
          />
          <button onClick={handleGeneratePlan} className="planejador-generate-button">Gerar Plano</button>
        </div>
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        {plan && (
          <div id="plan-details" className="planejador-prosperidade-results">
            {/* Frase Padrão */}
            <h2>
              Seu plano personalizado, <span className="highlighted-name">{userName}</span>, para você começar sua jornada no <span className="highlighted-name">Facebook ADS</span>
            </h2>

            {/* Frase de Duração do Orçamento */}
            <p className="budget-duration">
              <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
              <strong>Duração Total do Orçamento:</strong> {plan.days} dias
            </p>

            {/* Fase 1 - Teste de Criativos */}
            <h3>Fase 1 - Teste de Criativos ({plan.creativeDays} dias)</h3>
            <p>
              <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
              <strong>Estrutura de Campanha:</strong> {plan.creativeStructure.structure} ({plan.creativeStructure.type})
            </p>
            <p>
              <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
              <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
            </p>
            <p>
              <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
              <strong>Duração:</strong> {plan.creativeDays} dias (Tempo destinado para testar criativos)
            </p>
            <p>
              <strong>Descrição:</strong> {plan.creativeStructure.description}
            </p>
            <p>
              <strong>Estratégia:</strong> {plan.creativeStructure.strategy}
            </p>
            {renderCampaignStructure(plan.creativeStructure, plan.dailyBudget)}

            {/* Fase 2 - Teste de Públicos */}
            <h3>Fase 2 - Teste de Públicos ({plan.audienceDays} dias)</h3>
            <p>
              <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
              <strong>Estrutura de Campanha:</strong> {plan.audienceStructure.structure} ({plan.audienceStructure.type})
            </p>
            <p>
              <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
              <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
            </p>
            <p>
              <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
              <strong>Duração:</strong> {plan.audienceDays} dias (Tempo destinado para testar públicos)
            </p>
            <p>
              <strong>Descrição:</strong> {plan.audienceStructure.description}
            </p>
            <p>
              <strong>Estratégia:</strong> {plan.audienceStructure.strategy}
            </p>
            {renderCampaignStructure(plan.audienceStructure, plan.dailyBudget)}

            {/* Fase 3 - Escala (se aplicável) */}
            {plan.scaleStructure && (
              <>
                <h3>Fase 3 - Escala (após validar criativos e públicos)</h3>
                <p>
                  <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
                  <strong>Estrutura de Campanha:</strong> {plan.scaleStructure.structure} ({plan.scaleStructure.type})
                </p>
                <p>
                  <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
                  <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
                </p>
                <p>
                  <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
                  <strong>Duração:</strong> {plan.scaleStructure.type === 'CBO' ? plan.days - plan.creativeDays - plan.audienceDays : plan.days} dias (Tempo total que o orçamento irá durar)
                </p>
                <p>
                  <strong>Descrição:</strong> {plan.scaleStructure.description}
                </p>
                <p>
                  <strong>Estratégia:</strong> {plan.scaleStructure.strategy}
                </p>
                {renderCampaignStructure(plan.scaleStructure, plan.dailyBudget)}
              </>
            )}

            {/* Seção de Recomendações */}
            <div className="recommendations-section">
              <h3>Recomendações Importantes</h3>
              <ul>
                <li>Monitore diariamente o CTR (ideal > 1%) e CPC dos anúncios</li>
                <li>Mantenha os criativos com melhor performance</li>
                <li>Ajuste os públicos conforme os resultados</li>
                <li>Documente todos os testes e resultados</li>
                <li>Mantenha um orçamento de reserva para escalar campanhas bem-sucedidas</li>
              </ul>
              <p><strong>Observação:</strong> O teste de criativos não é uma regra para esperar três dias fixos. Você pode analisar a campanha diariamente e, de acordo com as métricas, decidir se continua ou desativa a campanha.</p>
            </div>

            {/* Seção de Explicações */}
            {renderExplanations()}

            {/* Conclusão e Advertência */}
            <h2>Conclusão e Advertência</h2>
            <p>Este plano permite testar a viabilidade do seu negócio dentro do orçamento estabelecido. Conforme ganha experiência, faça ajustes precisos no seu planejamento financeiro.</p>
            <h3>Importante:</h3>
            <ul>
              <li>Invista apenas o dinheiro que não vai prejudicar sua vida financeira.</li>
              <li>Todo investimento tem riscos.</li>
              <li>Esteja preparado para a possibilidade de não obter resultados imediatos.</li>
              <li>O dinheiro investido deve ser considerado como parte do aprendizado.</li>
              <li>Nunca comprometa suas necessidades básicas ou emergências por esse investimento.</li>
            </ul>

            {/* Botão de Salvar Plano */}
            <button 
              onClick={downloadAsImage} 
              className="planejador-save-button" 
              disabled={isLoading}
            >
              Salvar plano
              {isLoading && <div className="spinner"></div>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanejadorProsperidade;
