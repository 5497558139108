// src/paginas/AnaliseMetricas/MetricsConfig.js

export const metricNames = {
  impressions: 'Impressões',
  reach: 'Alcance',
  frequency: 'Frequência',
  spend: 'Gasto',
  cpm: 'CPM',
  totalResults: 'Resultados Totais',
  costPerResult: 'Custo por Resultado',
  inline_link_clicks: 'Cliques no Link',
  link_click_ctr: 'CTR de Cliques no Link',
  link_click_cpc: 'CPC de Cliques no Link',
  video3SecViews: 'Visualizações de 3 Segundos',
  video75Views: 'Visualizações de 75%',
  hookRate: 'Hook Rate',
  holdRate: 'Hold Rate',
  budget: 'Orçamento', // Adicionado para incluir no Chat
  budgetOptimization: 'Otimização de Orçamento', // Adicionado para incluir no Chat
  campaignObjective: 'Objetivo da Campanha', // Adicionado para incluir no Chat
  // Removido 'roi' das métricas padrão
  // Adicione outras métricas conforme necessário
};

export const defaultMetrics = [
  'impressions',
  'reach',
  'frequency',
  'spend',
  'cpm',
  'totalResults',
  'costPerResult',
  'inline_link_clicks',
  'link_click_ctr',
  'link_click_cpc',
  'video3SecViews',
  'video75Views',
  'hookRate',
  'holdRate',
  // Removido 'roi' e 'budget' para torná-las opcionais
  // Adicione outras métricas conforme necessário
];

export const sumMetrics = [
  'impressions',
  'reach',
  'spend',
  'inline_link_clicks',
  'totalResults',
  'video3SecViews',
  'video75Views',
  // Adicione outras métricas que devem ser somadas
];

export const averageMetrics = [
  'cpm',
  'costPerResult',
  'link_click_ctr',
  'link_click_cpc',
  'hookRate',
  'holdRate',
  // Adicione outras métricas que devem ser médias
];

export const metricOrder = [
  'impressions',
  'reach',
  'frequency',
  'spend',
  'cpm',
  'totalResults',
  'costPerResult',
  'inline_link_clicks',
  'link_click_ctr',
  'link_click_cpc',
  'video3SecViews',
  'video75Views',
  'hookRate',
  'holdRate',
  // Adicione outras métricas conforme necessário
];

// Adicione outras configurações ou métricas conforme necessário
