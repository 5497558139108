// auth.js
import CryptoJS from 'crypto-js';
import { inicializarPalavras } from './palavraControle'; // Injeção de palavras
import { verificarRenovacaoPalavras } from './syncWords'; // Verificação de renovação
import { supabase } from '../supabaseClient'; // Supabase client

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const ADMIN_SECRET_KEY = process.env.REACT_APP_ADMIN_TOKEN_SECRET; // Chave para administradores
const SESSION_DURATION = 6 * 60 * 60 * 1000; // 6 horas

// Função para obter a hora atual no fuso horário de São Paulo
const getBrazilTime = () => {
  const now = new Date();
  const offset = -3;
  const brazilTime = new Date(now.getTime() + offset * 60 * 60 * 1000);
  return brazilTime.toISOString();
};

// Função para obter o nome do plano por extenso com base no ID do plano
const obterNomePlano = (planoId) => {
  switch (planoId) {
    case 1:
      return 'Gratuito';
    case 2:
      return 'Básico';
    case 3:
      return 'Premium';
    default:
      return 'Desconhecido';
  }
};

// Verifica se o usuário está autenticado e se a sessão ainda é válida
export const isAuthenticated = () => {
  const encryptedToken = localStorage.getItem('authToken');
  const encryptedLoginTime = localStorage.getItem('loginTime');

  if (!encryptedToken || !encryptedLoginTime) {
    return false;
  }

  const token = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const loginTime = parseInt(CryptoJS.AES.decrypt(encryptedLoginTime, SECRET_KEY).toString(CryptoJS.enc.Utf8), 10);

  const currentTime = Date.now();
  const timeElapsed = currentTime - loginTime;

  if (timeElapsed > SESSION_DURATION) {
    logout();
    return false;
  }

  return token !== null;
};

// Verifica se o administrador está autenticado
export const isAdminAuthenticated = () => {
  const encryptedAdminToken = localStorage.getItem('adminAuthToken');
  const encryptedAdminLoginTime = localStorage.getItem('adminLoginTime');

  if (!encryptedAdminToken || !encryptedAdminLoginTime) {
    return false;
  }

  const adminToken = CryptoJS.AES.decrypt(encryptedAdminToken, ADMIN_SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const adminLoginTime = parseInt(CryptoJS.AES.decrypt(encryptedAdminLoginTime, ADMIN_SECRET_KEY).toString(CryptoJS.enc.Utf8), 10);

  const currentTime = Date.now();
  const timeElapsed = currentTime - adminLoginTime;

  if (timeElapsed > SESSION_DURATION) {
    adminLogout();
    return false;
  }

  return adminToken !== null;
};

// Função para inserir ou atualizar o status online do usuário no Supabase
const updateUserOnlineStatus = async (userId, email, ativo = true) => {
  try {
    const currentBrazilTime = getBrazilTime();

    // Verifica se o usuário já está na tabela 'usuarios_online'
    const { data: existingUser, error } = await supabase
      .from('usuarios_online')
      .select('*')
      .eq('usuario_id', userId)
      .single();

    if (existingUser) {
      // Se o usuário existir, atualiza seu status
      await supabase
        .from('usuarios_online')
        .update({
          ultima_atividade: currentBrazilTime,
          ativo: ativo,
        })
        .eq('usuario_id', userId);
    } else {
      // Caso não exista, insere o usuário
      await supabase.from('usuarios_online').insert([
        {
          usuario_id: userId,
          email: email,
          data_login: currentBrazilTime,
          ultima_atividade: currentBrazilTime,
          ativo: ativo,
        },
      ]);
    }
  } catch (error) {
    console.error('Erro ao registrar ou atualizar status online no Supabase:', error);
  }
};

// Função de login para usuários comuns
export const login = async (token, email, name, userId) => {
  try {
    // Consulta para obter todas as entradas de plano_id e tipo_produto do usuário
    const { data: usuarioInfos, error: usuarioError } = await supabase
      .from('usuarios')
      .select('plano_id, tipo_produto')
      .eq('email', email);

    if (usuarioError || !usuarioInfos || usuarioInfos.length === 0) {
      throw new Error('Erro ao buscar dados do usuário.');
    }

    // Supondo que plano_id seja o mesmo para todas as entradas do usuário
    const plano_id = usuarioInfos[0].plano_id;
    const nomePlano = obterNomePlano(plano_id); // Obter nome por extenso do plano

    // Extrai todos os tipo_produto em um array
    const tipoProdutosArray = usuarioInfos
      .map((info) => info.tipo_produto)
      .filter((tp) => tp) // Remove valores nulos ou undefined
      .map((tp) => tp.trim().toLowerCase()); // Normaliza os valores

    const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    const encryptedLoginTime = CryptoJS.AES.encrypt(Date.now().toString(), SECRET_KEY).toString();

    localStorage.setItem('authToken', encryptedToken);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userName', name);
    localStorage.setItem('loginTime', encryptedLoginTime);
    localStorage.setItem('userId', userId);
    localStorage.setItem('planoId', plano_id); // Salva o plano ID
    localStorage.setItem('nomePlano', nomePlano); // Salva o nome por extenso do plano
    localStorage.setItem('tipoProduto', JSON.stringify(tipoProdutosArray)); // Salva o tipo de produto como JSON

    // Atualiza status de usuário online
    await updateUserOnlineStatus(userId, email);

    // --- Início do código adicionado ---
    // Puxa os dados da tabela 'compras' e salva no localStorage
    try {
      // Consulta para obter todas as entradas de plano_id e produto_id do usuário na tabela 'compras'
      const { data: comprasData, error: comprasError } = await supabase
        .from('compras')
        .select('plano_id, produto_id')
        .eq('usuario_id', userId);

      if (comprasError) {
        throw new Error('Erro ao buscar dados de compras do usuário.');
      }

      // Extrai os produto_ids e plano_ids únicos
      const compraProdutoIds = [...new Set(comprasData.map((item) => item.produto_id).filter((id) => id !== null))];
      const compraPlanoIds = [...new Set(comprasData.map((item) => item.plano_id).filter((id) => id !== null))];

      // Obter detalhes dos produtos a partir dos IDs obtidos
      let produtosComprados = [];
      if (compraProdutoIds.length > 0) {
        const { data: produtosResult, error: produtosError } = await supabase
          .from('produtos')
          .select('id, nome_produto, tipo_produto')
          .in('id', compraProdutoIds);

        if (produtosError) {
          throw new Error('Erro ao buscar dados dos produtos.');
        }
        produtosComprados = produtosResult;
      }

      // Obter detalhes dos planos a partir dos IDs obtidos
      let planosComprados = [];
      if (compraPlanoIds.length > 0) {
        const { data: planosResult, error: planosError } = await supabase
          .from('tipos_de_plano')
          .select('id, nome_plano')
          .in('id', compraPlanoIds);

        if (planosError) {
          throw new Error('Erro ao buscar dados dos planos.');
        }
        planosComprados = planosResult;
      }

      // Salva os produtos e planos comprados no localStorage em JSON
      localStorage.setItem('produtosComprados', JSON.stringify(produtosComprados));
      localStorage.setItem('planosComprados', JSON.stringify(planosComprados));
    } catch (error) {
      console.error('Erro ao buscar dados de compras:', error);
    }
    // --- Fim do código adicionado ---

    await inicializarPalavras(email);
    await verificarRenovacaoPalavras();
  } catch (error) {
    console.error('Erro ao fazer login e salvar dados no navegador:', error);
  }
};

// Função de login para administradores
export const adminLogin = async (adminToken, email, name) => {
  const encryptedAdminToken = CryptoJS.AES.encrypt(adminToken, ADMIN_SECRET_KEY).toString();
  const encryptedAdminLoginTime = CryptoJS.AES.encrypt(Date.now().toString(), ADMIN_SECRET_KEY).toString();

  localStorage.setItem('adminAuthToken', encryptedAdminToken);
  localStorage.setItem('adminEmail', email);
  localStorage.setItem('adminName', name);
  localStorage.setItem('adminLoginTime', encryptedAdminLoginTime);
};

// Função de logout para remover o usuário da tabela online e do localStorage
export const logout = async () => {
  const email = getUserEmail();
  const userId = getUserId(); // Função para pegar o ID do usuário

  // Atualiza o status do usuário para inativo no Supabase
  await updateUserOnlineStatus(userId, email, false);

  // Limpa o localStorage completamente
  localStorage.removeItem('authToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userName');
  localStorage.removeItem('loginTime');
  localStorage.removeItem('userId'); // Removendo o userId também
  localStorage.removeItem('planoId'); // Removendo o plano ID
  localStorage.removeItem('nomePlano'); // Removendo o nome do plano por extenso
  localStorage.removeItem('tipoProduto'); // Removendo o tipo de produto
  localStorage.removeItem('produtosComprados'); // Removendo os produtos comprados
  localStorage.removeItem('planosComprados'); // Removendo os planos comprados
  // Também remova quaisquer outras chaves se existirem
  localStorage.removeItem('lastLoginTime'); // Se esta chave estiver sendo usada

  window.location.href = '/';
};

// Função de logout para administradores
export const adminLogout = () => {
  localStorage.removeItem('adminAuthToken');
  localStorage.removeItem('adminEmail');
  localStorage.removeItem('adminName');
  localStorage.removeItem('adminLoginTime');
  window.location.href = '/adminlogin';
};

// Função para obter o email do administrador logado
export const getAdminEmail = () => {
  return localStorage.getItem('adminEmail');
};

// Função para obter o email do usuário comum logado
export const getUserEmail = () => {
  return localStorage.getItem('userEmail');
};

// Função para obter o ID do usuário logado
export const getUserId = () => {
  return localStorage.getItem('userId');
};

// Função para obter o nome do plano por extenso
export const getNomePlano = () => {
  return localStorage.getItem('nomePlano');
};

// Função para obter o tipo de produto do usuário logado
export const getTipoProduto = () => {
  return localStorage.getItem('tipoProduto');
};

// Função para obter o nome do usuário logado
export const getUserName = () => {
  return localStorage.getItem('userName');
};
