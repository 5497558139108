import React from 'react';
import './PoliticaPublicidade.css';
import logo from '../imagens/03logo_menu_login.webp'; // Importe a logo
import Rodape from '../componentes/Rodape'; // Importando o rodapé

const PoliticaPublicidade = () => {
  // Função para redirecionar para a tela de login com recarregamento
  const handleBackToLogin = () => {
    window.location.replace("/#/login"); // Navega para a rota de login com recarregamento
  };

  return (
    <div className="politica-page-unique">
      <header className="politica-header-unique">
        <img src={logo} alt="Logo da Empresa" className="politica-logo-unique" />
        <button onClick={handleBackToLogin} className="politica-btn-login-unique">
          Voltar para o Login
        </button>
      </header>
      <main className="politica-content-unique">
        <h1 className="politica-title-unique">Política de Privacidade</h1>
        <p className="politica-text-unique">
          No Transbordo AI, estamos comprometidos em proteger a sua privacidade. Esta política descreve como coletamos, usamos e compartilhamos dados de forma responsável. 
          Nosso sistema utiliza integrações com serviços de terceiros, incluindo a API de Marketing do Facebook, para fornecer métricas e relatórios detalhados.
        </p>

        <h2 className="politica-subtitle-unique">1. Dados que Coletamos</h2>
        <p className="politica-text-unique">
          - **Dados de login**: Coletamos nome, e-mail e senha para permitir o acesso ao sistema. Esses dados são armazenados de forma segura e não são compartilhados com terceiros.<br/>
          - **Tokens Temporários de Acesso**: Para a integração com a API do Facebook, geramos tokens temporários durante a sessão do usuário. Estes tokens são automaticamente descartados ao final da sessão e não são armazenados permanentemente.
        </p>

        <h2 className="politica-subtitle-unique">2. Uso dos Dados</h2>
        <p className="politica-text-unique">
          Utilizamos os dados coletados para:<br/>
          - Facilitar o acesso à plataforma e gerenciar as permissões de sessão.<br/>
          - **Analisar as métricas de campanhas publicitárias**, que são enviadas para o nosso chat com inteligência artificial e processadas para gerar insights.<br/>
          **Importante**: **Não armazenamos as métricas ou dados de campanhas publicitárias nos nossos servidores**; eles permanecem disponíveis apenas durante a sessão ativa.
        </p>

        <h2 className="politica-subtitle-unique">3. Compartilhamento de Dados</h2>
        <p className="politica-text-unique">
          As informações coletadas são utilizadas exclusivamente dentro do nosso sistema para gerar relatórios analíticos para o usuário. **Não compartilhamos ou vendemos dados para terceiros**.
          Os dados são processados diretamente na sessão e transmitidos para APIs de terceiros, como serviços de inteligência artificial, **sem serem armazenados no nosso servidor**.
        </p>

        <h2 className="politica-subtitle-unique">4. Segurança</h2>
        <p className="politica-text-unique">
          Utilizamos as melhores práticas de segurança para proteger as informações pessoais e garantir que tokens e dados de sessões sejam tratados de maneira segura e temporária.
        </p>

        <h2 className="politica-subtitle-unique">5. Seus Direitos</h2>
        <p className="politica-text-unique">
          Os usuários têm o direito de acessar, corrigir ou solicitar a exclusão de suas informações. Para isso, devem entrar em contato pelo e-mail: <a href="mailto:contato@ocaminhoparaprosperidade.com">contato@ocaminhoparaprosperidade.com</a>.
        </p>

        <h2 className="politica-subtitle-unique">6. Atualizações e Disponibilidade</h2>
        <p className="politica-text-unique">
          Nossa política de privacidade será mantida **publicamente acessível** e sempre atualizada. Qualquer alteração significativa será informada aos usuários.
        </p>

        <h1 className="politica-title-unique">Termos de Uso</h1>
        <h2 className="politica-subtitle-unique">1. Objetivo</h2>
        <p className="politica-text-unique">
          O Transbordo AI oferece ferramentas para análise de campanhas publicitárias e automação de processos digitais. Integramos serviços de terceiros para fornecer informações e dados analíticos de forma eficiente.
        </p>

        <h2 className="politica-subtitle-unique">2. Uso da Plataforma</h2>
        <p className="politica-text-unique">
          Para utilizar nossa plataforma, o usuário deve criar uma conta com nome, e-mail e senha. Durante a sessão, tokens de acesso são gerados para integrações com serviços como o Facebook, mas **esses dados não são armazenados** permanentemente.
        </p>

        <h2 className="politica-subtitle-unique">3. Limitações de Responsabilidade</h2>
        <p className="politica-text-unique">
          O Transbordo AI não se responsabiliza por falhas ou interrupções nos serviços de terceiros com os quais o usuário se conecta através da nossa plataforma. A nossa responsabilidade se limita às funcionalidades que oferecemos diretamente.
        </p>

        <h2 className="politica-subtitle-unique">4. Modificações</h2>
        <p className="politica-text-unique">
          Podemos atualizar estes Termos de Uso conforme necessário para refletir mudanças na plataforma ou exigências legais. Notificaremos os usuários sobre quaisquer alterações significativas.
        </p>
      </main>

      <Rodape className="politica-footer-unique" />
    </div>
  );
}

export default PoliticaPublicidade;
