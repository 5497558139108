import React, { useState } from 'react';
import axios from 'axios'; // Para o Webhook
import { supabase } from '../../supabaseClient'; // Cliente Supabase
import { FaKey } from 'react-icons/fa';
import './TrocaDeSenha.css';

const TrocaDeSenha = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [tokenEnviado, setTokenEnviado] = useState(false);
  const [tokenValido, setTokenValido] = useState(false);

  const MIN_PASSWORD_LENGTH = 6;

  // Função para enviar o token ao usuário
  const handleEnviarToken = async () => {
    if (!email) {
      setErro('Por favor, insira um e-mail.');
      return;
    }

    try {
      // Verificar se o usuário existe no Supabase
      const { data: usuarioExistente, error } = await supabase
        .from('usuarios')
        .select('id, email, nome, numero_whatsapp')
        .eq('email', email)
        .single();

      if (error || !usuarioExistente) {
        setErro('E-mail não encontrado. Verifique e tente novamente.');
        return;
      }

      // Gerar um token de 6 dígitos
      const tokenGerado = Math.floor(100000 + Math.random() * 900000).toString();

      // Atualizar o token na tabela de usuários
      const { error: tokenUpdateError } = await supabase
        .from('usuarios')
        .update({ token_recuperacao_senha: tokenGerado })
        .eq('email', email);

      if (tokenUpdateError) {
        setErro('Erro ao gerar token de recuperação.');
        return;
      }

      // Enviar o token para o Webhook via axios
      try {
        await axios.post(process.env.REACT_APP_RECUPERAR_SENHA_N8N_WEBHOOK_URL, {
          nome: usuarioExistente.nome,
          numeroWhatsapp: usuarioExistente.numero_whatsapp,
          token: tokenGerado,
        });

        setMensagem('Token enviado para o seu WhatsApp.');
        setTokenEnviado(true);
      } catch (webhookError) {
        console.error('Erro ao enviar o token para o Webhook:', webhookError);
        setErro('Ocorreu um erro ao enviar o token. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao verificar o e-mail no banco de dados:', error);
      setErro('Ocorreu um erro ao verificar o e-mail. Tente novamente mais tarde.');
    }
  };

  // Função para validar o token inserido
  const handleValidarToken = async () => {
    try {
      const { data: usuarioExistente, error } = await supabase
        .from('usuarios')
        .select('token_recuperacao_senha')
        .eq('email', email)
        .single();

      if (error || usuarioExistente.token_recuperacao_senha !== token) {
        setErro('Token inválido. Tente novamente.');
        return;
      }

      setTokenValido(true);
      setMensagem('Token validado com sucesso.');
    } catch (error) {
      console.error('Erro ao validar o token:', error);
      setErro('Ocorreu um erro ao validar o token. Tente novamente mais tarde.');
    }
  };

  // Função para redefinir a senha do usuário
  const handleRedefinirSenha = async () => {
    if (novaSenha.length < MIN_PASSWORD_LENGTH) {
      setErro(`A senha deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres.`);
      return;
    }

    if (novaSenha !== confirmarNovaSenha) {
      setErro('As senhas não coincidem.');
      return;
    }

    try {
      // Obter o ID do usuário a partir do localStorage
      const userId = localStorage.getItem('userId');

      if (!userId) {
        setErro('Usuário não encontrado. Faça o login novamente.');
        return;
      }

      // Atualizar a senha no Supabase Auth
      const { error: senhaUpdateError } = await supabase.auth.updateUser({
        password: novaSenha, // Atualizar a senha
      });

      if (senhaUpdateError) {
        setErro('Erro ao redefinir a senha. Tente novamente.');
        return;
      }

      // Remover o token de recuperação após a senha ser redefinida
      const { error: tokenClearError } = await supabase
        .from('usuarios')
        .update({ token_recuperacao_senha: null })
        .eq('id', userId); // Usar o user_id para garantir que estamos limpando o token do usuário correto

      if (tokenClearError) {
        console.warn('Erro ao limpar o token de recuperação. Isso não impede a troca de senha.');
      }

      setMensagem('Senha atualizada com sucesso!');
      setTimeout(() => window.location.href = '/', 3000);
    } catch (error) {
      console.error('Erro ao redefinir a senha:', error);
      setErro('Ocorreu um erro ao tentar atualizar a senha. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="troca-senha-container-troca animated fadeIn">
      <div className="troca-senha-header-troca">
        <FaKey className="troca-senha-icon-troca" />
        <h2 className="troca-senha-titulo-troca">Trocar Senha</h2>
      </div>
      <p className="troca-senha-instrucao-troca">Insira o seu e-mail para receber o código de validação no seu WhatsApp.</p>

      {!tokenEnviado && (
        <>
          <div className="input-container-troca-troca">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-troca-troca"
            />
          </div>
          <button className="botao-troca-enviar" onClick={handleEnviarToken}>Enviar Token de Validação</button>
        </>
      )}

      {tokenEnviado && !tokenValido && (
        <>
          <div className="input-container-troca-troca">
            <input
              type="text"
              placeholder="Token de validação"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="input-troca-troca"
            />
          </div>
          <button className="botao-troca-validar" onClick={handleValidarToken}>Validar Token</button>
        </>
      )}

      <div className="input-container-troca-troca">
        <input
          type={mostrarSenha ? 'text' : 'password'}
          placeholder="Nova Senha"
          value={novaSenha}
          onChange={(e) => setNovaSenha(e.target.value)}
          className="input-troca-troca"
          disabled={!tokenValido}
        />
      </div>
      <div className="input-container-troca-troca">
        <input
          type={mostrarSenha ? 'text' : 'password'}
          placeholder="Confirme sua Nova Senha"
          value={confirmarNovaSenha}
          onChange={(e) => setConfirmarNovaSenha(e.target.value)}
          className="input-troca-troca"
          disabled={!tokenValido}
        />
      </div>
      {tokenValido && (
        <button className="botao-troca-redefinir" onClick={handleRedefinirSenha}>Redefinir Senha</button>
      )}

      {erro && <p className="erro-troca-troca">{erro}</p>}
      {mensagem && <p className="mensagem-troca-troca">{mensagem}</p>}
    </div>
  );
};

export default TrocaDeSenha;
