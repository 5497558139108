import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient'; // Certifique-se de que o caminho está correto
import './RelatorioSuporte.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faEnvelope, faTimes, faPaperPlane, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const RelatorioSuporte = () => {
  const [tickets, setTickets] = useState([]);
  const [erro, setErro] = useState('');
  const [loading, setLoading] = useState(true);
  const [mensagem, setMensagem] = useState('');
  const [ticketSelecionado, setTicketSelecionado] = useState(null);
  const [mensagemEnviada, setMensagemEnviada] = useState('');
  const userEmail = localStorage.getItem('userEmail');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userEmail) {
      fetchTickets();
    }
  }, [userEmail]);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const { data: userTickets, error } = await supabase
        .from('tickets')
        .select('*')
        .eq('email_usuario', userEmail)
        .eq('status', 'aberto');

      if (error) throw error;

      const ticketsWithInteractions = await Promise.all(
        userTickets.map(async (ticket) => {
          const interacoes = await fetchInteracoes(ticket.id_ticket);
          return { ...ticket, interacoes };
        })
      );

      setTickets(ticketsWithInteractions);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar os tickets:', error);
      setErro('Erro ao buscar os tickets. Tente novamente mais tarde.');
      setLoading(false);
    }
  };

  const fetchInteracoes = async (ticketId) => {
    try {
      const { data: interacoes, error } = await supabase
        .from('interacoes')
        .select('*')
        .eq('id_ticket', ticketId);

      if (error) throw error;

      return interacoes.map((interacao) => ({
        tipo_interacao: interacao.tipo_interacao,
        conteudo_mensagem: interacao.conteudo_mensagem,
        data_envio: interacao.data_envio,
      }));
    } catch (error) {
      console.error('Erro ao buscar interações:', error);
      return [];
    }
  };

  const handleMensagemChange = (e) => {
    setMensagem(e.target.value);
  };

  const handleEnviarMensagem = async (ticketId) => {
    if (!mensagem.trim()) return;

    try {
      const { error } = await supabase
        .from('interacoes')
        .insert([
          {
            id_ticket: ticketId,
            user_id: userId,
            email_usuario: userEmail,
            tipo_interacao: 'usuário',
            conteudo_mensagem: mensagem,
            data_envio: new Date().toISOString(),
          },
        ]);

      if (error) throw error;

      setMensagem('');
      setMensagemEnviada('Mensagem enviada com sucesso!');
      fetchTickets(); // Recarrega os tickets e as interações para refletir a nova mensagem
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      setErro('Erro ao enviar a mensagem. Tente novamente mais tarde.');
    }
  };

  const handleToggleTicket = (ticketId) => {
    setTicketSelecionado(ticketSelecionado === ticketId ? null : ticketId);
    setMensagemEnviada('');
  };

  const handleFecharTicket = async (ticketId) => {
    try {
      const { error } = await supabase
        .from('tickets')
        .update({ status: 'fechado', ultima_atualizacao: new Date().toISOString() })
        .eq('id_ticket', ticketId);

      if (error) throw error;

      fetchTickets(); // Recarrega os tickets para refletir a atualização
    } catch (error) {
      console.error('Erro ao fechar o ticket:', error);
      setErro('Erro ao fechar o ticket. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="relatorio-suporte-container">
      <h2 className="relatorio-suporte-titulo">Relatório de Suporte</h2>
      {erro && <p className="relatorio-suporte-erro">{erro}</p>}
      {loading ? (
        <p>Carregando tickets...</p>
      ) : (
        tickets.map((ticket) => (
          <div className="ticket-container" key={ticket.id_ticket}>
            <div className="ticket-header" onClick={() => handleToggleTicket(ticket.id_ticket)}>
              <FontAwesomeIcon icon={faFolderOpen} className="ticket-icon" />
              <h3 className="ticket-categoria">{ticket.categoria}</h3>
              <FontAwesomeIcon icon={ticketSelecionado === ticket.id_ticket ? faChevronUp : faChevronDown} />
            </div>
            {ticketSelecionado === ticket.id_ticket && (
              <div className="ticket-detalhes">
                <p><strong>Assunto:</strong> {ticket.assunto}</p>
                <p><strong>Status:</strong> {ticket.status}</p>
                <p><strong>Última Atualização:</strong> {new Date(ticket.ultima_atualizacao).toLocaleString()}</p>
                <div className="interacoes-container">
                  <h4>Interações:</h4>
                  {ticket.interacoes.length > 0 ? (
                    ticket.interacoes.map((interacao, index) => (
                      <div key={index} className="interacao">
                        <p><strong>{interacao.tipo_interacao}:</strong> {interacao.conteudo_mensagem}</p>
                        <p><em>{new Date(interacao.data_envio).toLocaleString()}</em></p>
                      </div>
                    ))
                  ) : (
                    <p>Sem interações para este ticket.</p>
                  )}
                </div>
                {ticket.status === 'aberto' && (
                  <div className="nova-mensagem-container">
                    <textarea
                      className="input-mensagem"
                      placeholder="Digite sua mensagem..."
                      value={mensagem}
                      onChange={handleMensagemChange}
                    ></textarea>
                    <button 
                      className="button-enviar" 
                      onClick={() => handleEnviarMensagem(ticket.id_ticket)}
                      disabled={!mensagem.trim()}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                    </button>
                    {mensagemEnviada && <p className="mensagem-sucesso">{mensagemEnviada}</p>}
                  </div>
                )}
                {ticket.status === 'aberto' && (
                  <button className="button-fechar-ticket" onClick={() => handleFecharTicket(ticket.id_ticket)}>
                    <FontAwesomeIcon icon={faTimes} /> Fechar Ticket
                  </button>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default RelatorioSuporte;
