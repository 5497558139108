import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import useSound from 'use-sound';
import clsx from 'clsx';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PomodoroEGrafico = () => {
  // Timer states
  const [mode, setMode] = useState('produtividade'); // 'produtividade' ou 'estudo'
  const [breakType, setBreakType] = useState('curto'); // 'curto' ou 'longo'
  const [timeLeft, setTimeLeft] = useState(25 * 60); // 25 minutes in seconds
  const [isRunning, setIsRunning] = useState(false);
  const [cycles, setCycles] = useState(0);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [customTime, setCustomTime] = useState('25');
  
  // Weekly data state
  const [weeklyData, setWeeklyData] = useState(() => {
    const saved = localStorage.getItem('pomodoroWeeklyData');
    if (saved) {
      return JSON.parse(saved);
    }
    return Array(7).fill(0); // Initialize with zeros for each day of the week
  });

  // Sound effects
  const [playAlarm] = useSound('/alarm.mp3', { volume: 0.5 });

  // Load data from localStorage
  useEffect(() => {
    const savedWeeklyData = localStorage.getItem('pomodoroWeeklyData');
    if (savedWeeklyData) {
      setWeeklyData(JSON.parse(savedWeeklyData));
    }
  }, []);

  // Save data to localStorage
  useEffect(() => {
    localStorage.setItem('pomodoroWeeklyData', JSON.stringify(weeklyData));
  }, [weeklyData]);

  // Timer logic
  useEffect(() => {
    let interval = null;
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    } else if (timeLeft === 0 && isRunning) {
      playAlarm();
      setShowPauseModal(true);
      setIsRunning(false);
      // Update weekly data
      const today = new Date().getDay();
      const newWeeklyData = [...weeklyData];
      newWeeklyData[today] += 1;
      setWeeklyData(newWeeklyData);
      setCycles(c => c + 1);
      toast.success('Pomodoro concluído!');
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft, playAlarm, weeklyData]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setTimeLeft(newMode === 'produtividade' ? 25 * 60 : 50 * 60);
    setIsRunning(false);
    toast.info(`Modo ${newMode} ativado`);
  };

  const handleBreakTypeChange = (type) => {
    setBreakType(type);
    setTimeLeft(type === 'curto' ? 5 * 60 : 15 * 60);
    setIsRunning(false);
    toast.info(`Pausa ${type}a selecionada`);
  };

  const handleCustomTimeChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) > 0 && Number(value) <= 60)) {
      setCustomTime(value);
      if (value !== '') {
        setTimeLeft(Number(value) * 60);
      }
    }
  };

  const handleStartPause = () => {
    setIsRunning(!isRunning);
    toast.info(isRunning ? 'Timer pausado' : 'Timer iniciado');
  };

  const handleReset = () => {
    setTimeLeft(mode === 'produtividade' ? 25 * 60 : 50 * 60);
    setIsRunning(false);
    toast.info('Timer reiniciado');
  };

  // Chart configuration
  const chartData = {
    labels: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    datasets: [
      {
        label: 'Pomodoros Concluídos',
        data: weeklyData,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        text: 'Desempenho Semanal',
        color: 'white',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={clsx(
        "tailwind-scope",
        "w-[1080px] mx-auto p-8 bg-gradient-to-b from-gray-900 to-black",
        "rounded-lg border border-gray-700 shadow-xl"
      )}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Timer Section */}
        <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
          <h2 className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 mb-6">
            Pomodoro Timer
          </h2>
          <div className="flex justify-center space-x-4 mb-6">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`px-6 py-3 rounded-lg font-semibold ${
                mode === 'produtividade'
                  ? 'bg-blue-600'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => handleModeChange('produtividade')}
            >
              Produtividade
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`px-6 py-3 rounded-lg font-semibold ${
                mode === 'estudo'
                  ? 'bg-purple-600'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => handleModeChange('estudo')}
            >
              Estudo
            </motion.button>
          </div>

          <div className="flex justify-center space-x-4 mb-8">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`px-6 py-3 rounded-lg font-semibold ${
                breakType === 'curto'
                  ? 'bg-green-600'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => handleBreakTypeChange('curto')}
            >
              Pausa Curta
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`px-6 py-3 rounded-lg font-semibold ${
                breakType === 'longo'
                  ? 'bg-yellow-600'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
              onClick={() => handleBreakTypeChange('longo')}
            >
              Pausa Longa
            </motion.button>
          </div>

          <div className="text-center mb-8">
            <div className="text-8xl font-bold mb-8 font-mono">
              {formatTime(timeLeft)}
            </div>
            <div className="flex justify-center space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`px-8 py-4 rounded-lg font-semibold ${
                  isRunning ? 'bg-red-600' : 'bg-green-600'
                }`}
                onClick={handleStartPause}
              >
                {isRunning ? 'Pausar' : 'Iniciar'}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 rounded-lg font-semibold bg-gray-700 hover:bg-gray-600"
                onClick={handleReset}
              >
                Reiniciar
              </motion.button>
            </div>
          </div>

          <div className="flex items-center justify-center space-x-4">
            <span>Personalizar tempo:</span>
            <input
              type="number"
              value={customTime}
              onChange={handleCustomTimeChange}
              className="w-20 px-3 py-2 bg-gray-700 rounded-lg text-center"
              min="1"
              max="60"
              placeholder="25"
            />
            <span>minutos</span>
          </div>

          <div className="text-center mt-4">
            <p className="text-gray-400">Ciclos completados: {cycles}</p>
          </div>
        </div>

        {/* Graph Section */}
        <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
          <h2 className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 mb-6">
            Estatísticas
          </h2>
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>

      {/* Pause Modal */}
      <AnimatePresence>
        {showPauseModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4 text-center"
            >
              <h2 className="text-3xl font-bold mb-4">Hora da Pausa!</h2>
              <p className="text-xl text-gray-300 mb-8">
                Aproveite para Relaxar, e volte quando o intervalo acabar!
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-blue-600 rounded-lg font-semibold"
                onClick={() => {
                  setShowPauseModal(false);
                  setTimeLeft(mode === 'produtividade' ? 25 * 60 : 50 * 60);
                }}
              >
                NÃO QUERO UMA PAUSA!
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <ToastContainer position="bottom-right" />
    </motion.div>
  );
};

export default PomodoroEGrafico;