import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import './Capturapagina.css';
import logo from '../../imagens/logo_menu_login.webp';

const CapturaPagina = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    whatsapp: '',
    sugestao: ''
  });

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Limpa mensagens de erro anteriores

    try {
      // Envia os dados para o Supabase
      const { data, error } = await supabase.from('pagina_de_captura_youtuber').insert([
        {
          nome: formData.nome,
          email: formData.email,
          whatsapp: formData.whatsapp,
          sugestao: formData.sugestao
        }
      ]);

      if (error) {
        throw new Error(error.message); // Lança o erro para ser capturado abaixo
      }

      // Limpa o formulário e exibe o pop-up de sucesso
      setFormData({ nome: '', email: '', whatsapp: '', sugestao: '' });
      setShowPopup(true);
    } catch (err) {
      setErrorMessage(`Erro ao enviar os dados: ${err.message}`);
      console.error('Erro ao enviar dados:', err);
    }
  };

  return (
    <div className="captura-container">
      <header className="captura-header">
        <img src={logo} alt="Transbordo IA Logo" className="captura-logo" />
      </header>

      <main className="captura-main">
        <h1 className="captura-headline">
          Você está prestes a iniciar uma nova fase na sua jornada digital.
        </h1>
        <h1 className="captura02-headline">
          Inscreva-se para ser o primeiro a conhecer o Transbordo IA!
        </h1>

        <p className="captura-subtitle">
          Deixe seu nome, WhatsApp e e-mail para ser avisado em primeira mão sobre as novidades e compartilhe sua sugestão de funcionalidade. Queremos criar uma experiência feita para você!
        </p>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <form className="captura-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              placeholder="Seu Nome"
              required
              className="captura-input"
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Seu E-mail"
              required
              className="captura-input"
            />
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleChange}
              placeholder="Seu WhatsApp"
              required
              className="captura-input"
            />
          </div>

          <div className="form-group">
            <textarea
              name="sugestao"
              value={formData.sugestao}
              onChange={handleChange}
              placeholder="Qual funcionalidade você gostaria de ver na plataforma?"
              className="captura-textarea"
              required
            />
          </div>

          <button type="submit" className="captura-button">
            Quero Fazer Parte do Transbordo!
          </button>
          
          <p className="captura-button-description">
            Seja o primeiro a saber e ajude a moldar nossa plataforma.
          </p>
        </form>
      </main>

      <footer className="captura-footer">
        <p>&copy; {new Date().getFullYear()} Transbordo IA. Todos os direitos reservados.</p>
      </footer>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Cadastro Realizado!</h2>
            <p>Obrigado por se inscrever! Você será avisado em breve sobre as Novidades.</p>
            <button className="popup-close" onClick={() => setShowPopup(false)}>
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CapturaPagina;
