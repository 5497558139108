// commentUtils.js

import { supabase } from '../../supabaseClient';

/**
 * Utilitários para o sistema de comentários
 * Este arquivo contém funções relacionadas a menções de usuários
 * e formatação de tempo para os comentários
 */

/**
 * FUNÇÕES PARA MENÇÕES DE USUÁRIOS
 */

/**
 * Busca usuários no banco de dados baseado em um termo de busca
 * @param {string} searchTerm - Termo para buscar usuários
 * @returns {Promise<Array>} Array de usuários encontrados
 */
export const buscarUsuarios = async (searchTerm) => {
  try {
    const { data, error } = await supabase
      .from('usuarios')
      .select('user_id, nome')
      .ilike('nome', `${searchTerm}%`)
      .limit(5);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    return [];
  }
};

/**
 * Cria uma notificação de menção para um usuário
 * @param {string} mencionadoId - ID do usuário mencionado
 * @param {string} comentarioId - ID do comentário onde ocorreu a menção
 * @param {Object} aulaInfo - Informações da aula
 * @param {string} userId - ID do usuário que fez a menção
 */
export const criarNotificacaoMencao = async (mencionadoId, comentarioId, aulaInfo, userId) => {
  try {
    if (!mencionadoId || !comentarioId || !userId) {
      console.error('Dados incompletos para criar notificação:', { mencionadoId, comentarioId, userId });
      return;
    }

    // Confere se o usuário que menciona existe
    const { data: userData, error: userError } = await supabase
      .from('usuarios')
      .select('nome')
      .eq('user_id', userId)
      .single();

    if (userError) {
      console.error('Erro ao buscar dados do usuário que mencionou:', userError);
      return;
    }

    // Confere se o usuário mencionado existe
    const { data: mencionadoData, error: mencionadoError } = await supabase
      .from('usuarios')
      .select('user_id')
      .eq('user_id', mencionadoId)
      .single();

    if (mencionadoError) {
      console.error('Erro ao verificar se o usuário mencionado existe:', mencionadoError);
      return;
    }

    // Insere a notificação de menção
    const { error } = await supabase
      .from('notificacoes_mencoes')
      .insert({
        usuario_id: mencionadoId,
        comentario_id: comentarioId,
        tipo_notificacao: 'mencao',
        mencao: `${userData?.nome || 'Alguém'} mencionou você na aula "${aulaInfo.nome}"`,
        lida: false
      });

    if (error) {
      console.error('Erro ao inserir notificação de menção:', error);
    } else {
      console.log('Notificação de menção criada com sucesso para o usuário:', mencionadoId);
    }
  } catch (error) {
    console.error('Erro geral ao criar notificação de menção:', error);
  }
};


/**
 * Verifica menções não lidas para um usuário
 * @param {string} userId - ID do usuário
 * @returns {Promise<Object|null>} Última menção não lida
 */
export const verificarMencoes = async (userId) => {
  try {
    if (!userId) {
      console.error('User ID é necessário para verificar menções');
      return null;
    }

    const { data: mencoes, error } = await supabase
      .from('notificacoes_mencoes')
      .select('*')
      .eq('usuario_id', userId)
      .eq('lida', false)
      .order('data_notificacao', { ascending: false })
      .limit(1);

    if (error) throw error;
    return mencoes?.[0] || null;
  } catch (error) {
    console.error('Erro ao verificar menções:', error);
    return null;
  }
};

/**
 * Processa menções em um comentário
 * @param {string} conteudo - Conteúdo do comentário
 * @param {string} comentarioId - ID do comentário
 * @param {string} userId - ID do usuário que fez o comentário
 * @param {Object} aulaInfo - Informações da aula
 */

export const processarMencoes = async (conteudo, comentarioId, userId, aulaInfo) => {
  const mencoes = conteudo.match(/@\[(\w+):[^\]]+\]/g); // Ajuste o regex se necessário
  if (mencoes) {
    const userIdsMencionados = mencoes.map(m => m.match(/\[(\w+):/)[1]);

    for (const mencionadoId of userIdsMencionados) {
      if (mencionadoId !== userId) {
        await criarNotificacaoMencao(mencionadoId, comentarioId, aulaInfo, userId);
      }
    }
  }
};



/**
 * Marca uma notificação como lida
 * @param {number} notificacaoId - ID da notificação
 */
export const marcarNotificacaoComoLida = async (notificacaoId) => {
  try {
    const { error } = await supabase
      .from('notificacoes_mencoes')
      .update({ lida: true })
      .eq('id', notificacaoId);

    if (error) throw error;
  } catch (error) {
    console.error('Erro ao marcar notificação como lida:', error);
  }
};

/**
 * FUNÇÕES PARA FORMATAÇÃO DE TEMPO
 */

/**
 * Formata o tempo decorrido desde uma data até agora
 * @param {string|Date} date - Data a ser formatada
 * @returns {string} Texto formatado (ex: "há 2 horas")
 */
export const formatTimeAgo = (date) => {
  const now = new Date();
  const commentDate = new Date(date);
  const diff = now - commentDate;
  
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) return 'agora mesmo';
  if (minutes === 1) return 'há um minuto';
  if (minutes < 60) return `há ${minutes} minutos`;
  if (hours === 1) return 'há uma hora';
  if (hours < 24) return `há ${hours} horas`;
  if (days === 1) return 'há um dia';
  if (days < 7) return `há ${days} dias`;
  if (days < 30) {
    const weeks = Math.floor(days / 7);
    return weeks === 1 ? 'há uma semana' : `há ${weeks} semanas`;
  }
  if (months === 1) return 'há um mês';
  if (months < 12) return `há ${months} meses`;
  if (years === 1) return 'há um ano';
  return `há ${years} anos`;
};
