import React, { useEffect, useState } from 'react';
import { logout, isAuthenticated } from '../../auth/auth';
import { useNavigate, Link } from 'react-router-dom';
import './Menu.css';
import logo from '../../imagens/logo_menu_login.webp';
import MenuVertical from './MenuVertical';
import { supabase } from '../../supabaseClient';

// Importando os arquivos necessários para a notificação
import welcomeSound from '../../assets/sounds/welcome.MP3';
import mensagens from '../../assets/mensagens.json';

import analiseMetricasImg from '../../imagens/Analise-de-Metricas.webp';
import copyMetaADSImg from '../../imagens/Copy-para-Meta-ADS.webp';
import copyRemarketingImg from '../../imagens/copy-para-remarketing.webp';
import funilVendasImg from '../../imagens/Funil-de-Vendas.webp';
import guiaInvestimentoImg from '../../imagens/Guia-de-Investimento-Facil.webp';
import headlinesImg from '../../imagens/Headlines.webp';
import metaLucroImg from '../../imagens/Meta-de-Lucro.webp';
import provaSocialImg from '../../imagens/Prova-Social.webp';
import scriptVendasImg from '../../imagens/Script-de-Vendas-para-WhatsApp.webp';
import estudioCriacaoImg from '../../imagens/Estudio-de-Criacao.webp';
import mapaDigitalImg from '../../imagens/Mapa-do-Digital.webp';
import taxaConversaoImg from '../../imagens/Taxa-de-Conversao-no-WhatsApp.webp';

import narradorImg from '../../imagens/narrador01.webp';
import personaImg from '../../imagens/persona.webp';
import criadorCriativoImg from '../../imagens/criador-criativo.webp';
import minhaContaImg from '../../imagens/minha-conta.webp';
import suporteClienteImg from '../../imagens/suporte-cliente.webp';
import criadordeprodutoImg from '../../imagens/criador-de-produto01.webp';
import criadordepromessasImg from '../../imagens/criador-de-promessa.webp';
import otimizadordeofertaImg from '../../imagens/otmizador-oferta.webp';
import craiadordeupsellImg from '../../imagens/criador-upsalle.webp';
import criadordenomeImg from '../../imagens/criador-de-nome.webp';
import criadorordembumpImg from '../../imagens/criador-ordembump.webp';

const MenuFerramentas = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [randomMessage, setRandomMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [planoId, setPlanoId] = useState(null);
  const [loading, setLoading] = useState(true);
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    if (isAuthenticated()) {
      const email = localStorage.getItem('userEmail');
      setUserEmail(email);

      // Verifica se os dados estão no localStorage, caso contrário, faz uma consulta ao banco
      const storedProdutos = localStorage.getItem('produtosComprados');
      const storedPlanoId = localStorage.getItem('planoId');

      if (storedProdutos) {
        setProdutos(JSON.parse(storedProdutos));
      } else {
        fetchProdutosDoBanco();
      }

      if (storedPlanoId) {
        setPlanoId(parseInt(storedPlanoId));
      } else {
        fetchPlanoIdDoBanco();
      }

      const lastLoginTime = localStorage.getItem('lastLoginTime');
      const currentTime = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000;

      if (!lastLoginTime || currentTime - lastLoginTime > tenMinutes) {
        localStorage.setItem('lastLoginTime', currentTime);
        setShowMessage(true);
        const randomIndex = Math.floor(Math.random() * mensagens.length);
        setRandomMessage(mensagens[randomIndex] || 'Você nasceu para prosperar!🚀');
      }

      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate]);
  
  const playWelcomeSound = () => {
    const audio = new Audio(welcomeSound);
    audio.play().catch(error => console.error('Erro ao tocar o som:', error));
  };

  useEffect(() => {
    if (showMessage) {
      playWelcomeSound();
    }
  }, [showMessage]);

  const fetchProdutosDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId'); 
      const { data: compras, error } = await supabase
        .from('compras')
        .select('produto_id')
        .eq('usuario_id', userId);

      if (error) {
        console.error('Erro ao buscar produtos no banco de dados:', error);
      } else {
        const produtosDisponiveis = compras.map(c => ({ id: c.produto_id }));
        localStorage.setItem('produtosComprados', JSON.stringify(produtosDisponiveis));
        setProdutos(produtosDisponiveis);
      }
    } catch (error) {
      console.error('Erro ao consultar produtos do banco:', error);
    }
  };

  const fetchPlanoIdDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const { data: usuario, error } = await supabase
        .from('usuarios')
        .select('plano_id')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Erro ao buscar plano do usuário no banco de dados:', error);
      } else {
        localStorage.setItem('planoId', usuario.plano_id);
        setPlanoId(usuario.plano_id);
      }
    } catch (error) {
      console.error('Erro ao buscar plano no banco:', error);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // Verificar acesso do usuário
  const hasAccess = () => {
    const produtosValidos = produtos.some(p => p.id === 4); 
    const planoValido = planoId === 1;
    return produtosValidos || planoValido;
  };

  const itemsFinanceiro = [
    { img: metaLucroImg, alt: 'Meta de Lucro', link: '/lucro-alvo' },
    { img: guiaInvestimentoImg, alt: 'Guia de Investimento Fácil', link: '/planejador-prosperidade' },
    { img: taxaConversaoImg, alt: 'Taxa de Conversão no WhatsApp', link: '/conversao-magica' },
    { img: funilVendasImg, alt: 'Funil de Vendas', link: '/funil-vendas-whatsapp' },
    { img: analiseMetricasImg, alt: 'Análise de Métricas', link: '/Analise-Metricas' },
  ];

  const itemsConversao = [
    { img: copyMetaADSImg, alt: 'Copy para Meta ADS', link: '/criador-de-textos-persuasivos' },
    { img: scriptVendasImg, alt: 'Script de Vendas para WhatsApp', link: '/script-vendas-whatsapp' },
    { img: copyRemarketingImg, alt: 'Copy para Remarketing', link: '/remarketing' },
    { img: headlinesImg, alt: 'Headlines', link: '/headlines' },
    { img: provaSocialImg, alt: 'Prova Social', link: '/prova' },
    { img: narradorImg, alt: 'narrador', link: '/narrador' },
  ];

  const itemsProdutoIA = [
    { img: criadordeprodutoImg, alt: 'Criador de Produto', link: '/criador-de-produto' },
    { img: criadordepromessasImg, alt: 'Criador de Promessas', link: '/criador-de-promessas' },
    { img: otimizadordeofertaImg, alt: 'Otimizador de Oferta', link: '/otmizador-de-oferta' },
    { img: craiadordeupsellImg, alt: 'Criador de Upsell', link: '/criador-de-upsll' },
    { img: criadordenomeImg, alt: 'Criador de Nome', link: '/criador-de-nomes' },
    { img: criadorordembumpImg, alt: 'Criador de Ordem Bump', link: '/criador-de-ordem-bump' },
    { img: criadorCriativoImg, alt: 'Criador de Criativo', link: '/criador-de-criativo' },
    { img: personaImg, alt: 'Criador de Persona', link: '/persona' },
    { img: personaImg, alt: 'Criador de Persona', link: '/sonhos' },
  ];

  const itemsAdministracao = [
    { img: minhaContaImg, alt: 'Minha Conta', link: '/minha-conta' },
    { img: suporteClienteImg, alt: 'Suporte ao Cliente', link: '/suporte' },
  ];

  return (
    <div className="menu-container-unique">
      <MenuVertical />
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {showMessage && (
            <div className="message-popup-unique">
              <h2>Seja muito bem-vindo(a), <span className="user-name-unique">{userName}</span>!</h2>
              <p>{randomMessage} <span className="highlight-unique">Esta mensagem é para você!</span></p>
              <button className="close-btn-unique" onClick={handleCloseMessage}>Fechar</button>
              <p className="footer-message-unique">Você nasceu para prosperar!🚀</p>
            </div>
          )}
          <div className="header-unique">
            <img src={logo} alt="Logo" className="menu-logo-unique" />
            <div className="user-info-unique">
              <span>{userName}</span>
              <button onClick={handleLogout}>Sair</button>
            </div>
          </div>
          <div className="content-unique">
            <h1 className="title-gradient-unique">Ferramentas para Prosperar no Digital</h1>
            <p className="description-unique">"Aqui você encontra todas as ferramentas para otimizar sua jornada digital."</p>

            {/* Categoria Financeiro */}
            <div className="category-title-unique">Financeiro</div>
            <div className="menu-items-unique">
              {itemsFinanceiro.map((item, idx) => (
                <div key={idx} className={`menu-item-unique ${!hasAccess() ? 'blocked' : ''}`}>
                  <img src={item.img} alt={item.alt} className="menu-img" />
                  <Link to={item.link} className="no-underline-unique">
                    <button
                      className="btn-unique"
                      disabled={!hasAccess()}
                      onClick={(e) => {
                        if (!hasAccess()) {
                          e.preventDefault();
                          alert('Acesso restrito. Seu plano atual não permite acessar esta categoria.');
                        }
                      }}
                    >
                      {hasAccess() ? 'Acesse aqui' : 'Bloqueado'}
                    </button>
                  </Link>
                  {!hasAccess() && (
                    <>
                      <div className="overlay-blocked">Bloqueado</div>
                      <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* Categoria Gerador de Conversão */}
            <div className="category-title-unique">Mestre da Persuasão</div>
            <div className="menu-items-unique">
              {itemsConversao.map((item, idx) => (
                <div key={idx} className={`menu-item-unique ${!hasAccess() ? 'blocked' : ''}`}>
                  <img src={item.img} alt={item.alt} className="menu-img" />
                  <Link to={item.link} className="no-underline-unique">
                    <button
                      className="btn-unique"
                      disabled={!hasAccess()}
                      onClick={(e) => {
                        if (!hasAccess()) {
                          e.preventDefault();
                          alert('Acesso restrito. Seu plano atual não permite acessar esta categoria.');
                        }
                      }}
                    >
                      {hasAccess() ? 'Acesse aqui' : 'Bloqueado'}
                    </button>
                  </Link>
                  {!hasAccess() && (
                    <>
                      <div className="overlay-blocked">Bloqueado</div>
                      <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* Categoria Criador de Produto com IA */}
            <div className="category-title-unique">Crie seu Produto com a nossa [IA]</div>
            <div className="menu-items-unique">
              {itemsProdutoIA.map((item, idx) => (
                <div key={idx} className={`menu-item-unique ${!hasAccess() ? 'blocked' : ''}`}>
                  <img src={item.img} alt={item.alt} className="menu-img" />
                  <Link to={item.link} className="no-underline-unique">
                    <button
                      className="btn-unique"
                      disabled={!hasAccess()}
                      onClick={(e) => {
                        if (!hasAccess()) {
                          e.preventDefault();
                          alert('Acesso restrito. Seu plano atual não permite acessar esta categoria.');
                        }
                      }}
                    >
                      {hasAccess() ? 'Acesse aqui' : 'Bloqueado'}
                    </button>
                  </Link>
                  {!hasAccess() && (
                    <>
                      <div className="overlay-blocked">Bloqueado</div>
                      <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* Categoria Administração */}
            <div className="category-title-unique">Administração</div>
            <div className="menu-items-unique">
              {itemsAdministracao.map((item, idx) => (
                <div key={idx} className={`menu-item-unique ${!hasAccess() ? 'blocked' : ''}`}>
                  <img src={item.img} alt={item.alt} className="menu-img" />
                  <Link to={item.link} className="no-underline-unique">
                    <button
                      className="btn-unique"
                      disabled={!hasAccess()}
                      onClick={(e) => {
                        if (!hasAccess()) {
                          e.preventDefault();
                          alert('Acesso restrito. Seu plano atual não permite acessar esta categoria.');
                        }
                      }}
                    >
                      {hasAccess() ? 'Acesse aqui' : 'Bloqueado'}
                    </button>
                  </Link>
                  {!hasAccess() && (
                    <>
                      <div className="overlay-blocked">Bloqueado</div>
                      <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MenuFerramentas;
