// ConversaoMagica.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faChartLine, 
  faInfoCircle, 
  faSave, 
  faTrash, 
  faChevronDown, 
  faChevronUp,
  faEnvelope,
  faShoppingCart,
  faPercentage
} from '@fortawesome/free-solid-svg-icons';
import './ConversaoMagica.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SalesScriptGuide from './SalesScriptGuide.js';

// Importações necessárias do date-fns
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const ConversaoMagica = () => {
  const [mensagens, setMensagens] = useState('');
  const [vendas, setVendas] = useState('');
  const [taxaConversao, setTaxaConversao] = useState(null);
  const [historico, setHistorico] = useState([]);
  const [mediaSemanal, setMediaSemanal] = useState(null);
  const [mostrarDicas, setMostrarDicas] = useState(false);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(null);
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('userEmail');
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
      return;
    }
    carregarHistorico();
  }, [navigate]);

  const carregarHistorico = () => {
    const dadosSalvos = localStorage.getItem(`conversao_${userEmail}`);
    if (dadosSalvos) {
      const historicoParsed = JSON.parse(dadosSalvos);
      // Verificar se os dados estão na nova estrutura (semana com registros)
      if (historicoParsed.length > 0 && historicoParsed[0].registros !== undefined) {
        setHistorico(historicoParsed);
        calcularMediaSemanal(historicoParsed);
      } else {
        // Estrutura antiga: array de registros sem categorização semanal
        const historicoNovaEstrutura = agruparPorSemanas(historicoParsed);
        localStorage.setItem(`conversao_${userEmail}`, JSON.stringify(historicoNovaEstrutura));
        setHistorico(historicoNovaEstrutura);
        calcularMediaSemanal(historicoNovaEstrutura);
      }
    }
  };

  // Função para agrupar registros por semana
  const agruparPorSemanas = (registros) => {
    const semanas = {};

    registros.forEach(registro => {
      const dataRegistro = new Date(registro.data.split('/').reverse().join('-')); // Converter "dd/MM/yyyy" para Date
      const semanaInicio = format(dataRegistro, "dd/MM/yyyy", { locale: ptBR }); // Utilizar dataRegistro diretamente
      const semanaFim = format(endOfWeek(dataRegistro, { weekStartsOn: 1 }), "dd/MM/yyyy", { locale: ptBR });
      const semanaKey = `${semanaInicio} - ${semanaFim}`;

      if (!semanas[semanaKey]) {
        semanas[semanaKey] = {
          semana: semanaKey,
          registros: []
        };
      }

      semanas[semanaKey].registros.push({
        ...registro,
        id: registro.id || Date.now() + Math.random() // Geração de ID único caso não exista
      });
    });

    // Converter o objeto para array e ordenar por semana mais recente primeiro
    const historicoArray = Object.values(semanas).sort((a, b) => {
      const [inicioA] = a.semana.split(' - ');
      const [inicioB] = b.semana.split(' - ');
      return new Date(inicioB.split('/').reverse().join('-')) - new Date(inicioA.split('/').reverse().join('-'));
    });

    return historicoArray;
  };

  const salvarTaxaConversao = () => {
    const dataAtual = new Date();
    const semanaInicio = format(startOfWeek(dataAtual, { weekStartsOn: 1 }), "dd/MM/yyyy", { locale: ptBR });
    const semanaFim = format(endOfWeek(dataAtual, { weekStartsOn: 1 }), "dd/MM/yyyy", { locale: ptBR });

    const novoRegistro = {
      id: Date.now(), // ID único
      data: format(dataAtual, "dd/MM/yyyy", { locale: ptBR }),
      diaSemana: format(dataAtual, "EEEE", { locale: ptBR }),
      hora: format(dataAtual, "HH:mm"),
      taxa: parseFloat(taxaConversao),
      mensagens: parseInt(mensagens),
      vendas: parseInt(vendas),
      semana: `${semanaInicio} - ${semanaFim}` // Identificador da semana
    };

    // Verificar se a semana atual já existe
    let novaHistorico = [...historico];
    const indexSemana = novaHistorico.findIndex(semana => semana.semana === novoRegistro.semana);

    if (indexSemana !== -1) {
      // Adicionar ao registro existente da semana
      novaHistorico[indexSemana].registros = [novoRegistro, ...novaHistorico[indexSemana].registros].slice(0, 7);
    } else {
      // Adicionar uma nova semana
      novaHistorico = [{ semana: novoRegistro.semana, registros: [novoRegistro] }, ...novaHistorico].slice(0, 4); // Mantém 4 semanas
    }

    localStorage.setItem(`conversao_${userEmail}`, JSON.stringify(novaHistorico));
    setHistorico(novaHistorico);
    calcularMediaSemanal(novaHistorico);

    setMensagens('');
    setVendas('');
    setTaxaConversao(null); // Reseta a taxa para ocultar o botão Salvar

    // Notificação de salvamento
    toast.success('Taxa de Conversão do WhatsApp salva com sucesso!');
  };

  const calcularMediaSemanal = (dados) => {
    if (dados.length > 0) {
      let totalTaxa = 0;
      let totalRegistros = 0;

      dados.forEach(semana => {
        if (semana.registros && Array.isArray(semana.registros)) {
          semana.registros.forEach(registro => {
            totalTaxa += registro.taxa;
            totalRegistros += 1;
          });
        }
      });

      if (totalRegistros > 0) {
        const media = totalTaxa / totalRegistros;
        setMediaSemanal(media.toFixed(2));
      } else {
        setMediaSemanal(null);
      }
    } else {
      setMediaSemanal(null);
    }
  };

  const calcularTaxaConversao = () => {
    const mensagensNum = parseFloat(mensagens);
    const vendasNum = parseFloat(vendas);

    if (isNaN(mensagensNum) || isNaN(vendasNum) || mensagensNum === 0) {
      toast.error('Por favor, insira números válidos e certifique-se de que o número de mensagens não seja zero.');
      return;
    }

    const taxa = (vendasNum / mensagensNum) * 100;
    setTaxaConversao(taxa.toFixed(2));
  };

  const limparHistorico = () => {
    if (window.confirm('Tem certeza que deseja limpar todo o histórico?')) {
      localStorage.removeItem(`conversao_${userEmail}`);
      setHistorico([]);
      setMediaSemanal(null);
      toast.info('Histórico de Conversões foi limpo.');
    }
  };

  const excluirRegistro = (semanaId, registroId) => {
    const novaHistorico = historico.map(semana => {
      if (semana.semana === semanaId) {
        const novosRegistros = semana.registros.filter(registro => registro.id !== registroId);
        return { ...semana, registros: novosRegistros };
      }
      return semana;
    }).filter(semana => semana.registros.length > 0); // Remove semanas sem registros

    localStorage.setItem(`conversao_${userEmail}`, JSON.stringify(novaHistorico));
    setHistorico(novaHistorico);
    calcularMediaSemanal(novaHistorico);
    toast.warn('Registro excluído com sucesso!');

    // Se a semana selecionada foi removida, deselecione
    if (selectedWeek && selectedWeek === semanaId && !novaHistorico.find(semana => semana.semana === semanaId)) {
      setSelectedWeek(null);
    }
  };

  const toggleWeekExpansion = (semanaId) => {
    setExpandedWeeks(prev => ({
      ...prev,
      [semanaId]: !prev[semanaId]
    }));
    selecionarSemana(semanaId);
  };

  const selecionarSemana = (semanaId) => {
    if (selectedWeek === semanaId) {
      // Deselect if already selected
      setSelectedWeek(null);
    } else {
      setSelectedWeek(semanaId);
    }
  };

  // Calcular média para a semana selecionada ou global
  const calcularMedia = () => {
    if (selectedWeek) {
      const semana = historico.find(semana => semana.semana === selectedWeek);
      if (semana && semana.registros.length > 0) {
        const totalTaxa = semana.registros.reduce((acc, curr) => acc + curr.taxa, 0);
        const media = totalTaxa / semana.registros.length;
        return media.toFixed(2);
      }
      return null;
    } else {
      return mediaSemanal;
    }
  };

  const mediaAtual = calcularMedia();

  return (
    <div className="conversao-magica-container">
      <ToastContainer />
      <div className="conversao-magica-header">
        <img src={logo} alt="Logo" className="conversao-magica-logo" />
        <div className="conversao-magica-user-info">
          <span className="conversao-magica-user-email">{userName}</span>
          <button className="conversao-magica-logout" onClick={logout}>
            Sair
          </button>
        </div>
      </div>

      <div className="conversao-magica-content">
        <Link to="/ferramentas" className="conversao-magica-back">
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <div className="conversao-magica-main">
          {/* Calculadora de Conversão */}
          <div className="conversao-magica-calculator conversao-magica-fade-in">
            <h1><FontAwesomeIcon icon={faChartLine} /> Calculadora de Conversão do WhatsApp</h1>
            <div className="conversao-magica-form">
              <div className="conversao-magica-input-group">
                <label><FontAwesomeIcon icon={faEnvelope} /> Mensagens Recebidas:</label>
                <input
                  type="number"
                  value={mensagens}
                  onChange={(e) => setMensagens(e.target.value)}
                  placeholder="Ex: 100"
                />
              </div>
              <div className="conversao-magica-input-group">
                <label><FontAwesomeIcon icon={faShoppingCart} /> Vendas Realizadas:</label>
                <input
                  type="number"
                  value={vendas}
                  onChange={(e) => setVendas(e.target.value)}
                  placeholder="Ex: 10"
                />
              </div>
              <div className="conversao-magica-buttons">
                <button onClick={calcularTaxaConversao} className="conversao-magica-calculate">
                  <FontAwesomeIcon icon={faChartLine} /> Calcular
                </button>
                {taxaConversao && (
                  <button onClick={salvarTaxaConversao} className="conversao-magica-save">
                    <FontAwesomeIcon icon={faSave} /> Salvar
                  </button>
                )}
              </div>
            </div>

            {taxaConversao && (
              <div className="conversao-magica-result conversao-magica-fade-in">
                <h2><FontAwesomeIcon icon={faChartLine} /> Taxa de Conversão do WhatsApp: {taxaConversao}%</h2>
                {parseFloat(taxaConversao) < 10 && (
                  <button
                    className="conversao-magica-tips-button"
                    onClick={() => setMostrarDicas(!mostrarDicas)}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} /> 
                    {mostrarDicas ? 'Ocultar Dicas' : 'Ver Dicas de Melhoria'}
                  </button>
                )}
              </div>
            )}
          </div>

          {/* Histórico de Conversões */}
          <div className="conversao-magica-history conversao-magica-fade-in">
            <div className="conversao-magica-history-header">
              <h2><FontAwesomeIcon icon={faChartLine} /> Histórico de Conversões</h2>
              {historico.length > 0 && (
                <button onClick={limparHistorico} className="conversao-magica-clear">
                  <FontAwesomeIcon icon={faTrash} /> Limpar
                </button>
              )}
            </div>

            {selectedWeek ? (
              <div className="conversao-magica-weekly-average conversao-magica-fade-in">
                <FontAwesomeIcon icon={faPercentage} /> 
                <h3>Média da Semana {selectedWeek}: {mediaAtual}%</h3>
              </div>
            ) : (
              mediaSemanal && (
                <div className="conversao-magica-weekly-average conversao-magica-fade-in">
                  <FontAwesomeIcon icon={faPercentage} /> 
                  <h3>Média Semanal da Taxa de Conversão do WhatsApp: {mediaSemanal}%</h3>
                </div>
              )
            )}

            <div className="conversao-magica-history-list">
              {historico.map((semana, semanaIndex) => (
                <div key={semanaIndex} className="conversao-magica-week">
                  <div 
                    className={`conversao-magica-week-header ${selectedWeek === semana.semana ? 'selected' : ''}`}
                    onClick={() => {
                      toggleWeekExpansion(semana.semana);
                    }}
                  >
                    <h3 className="semana-titulo">
                      {semana.semana} 
                    </h3>
                    <button className="toggle-button" aria-label="Toggle Week">
                      <FontAwesomeIcon icon={expandedWeeks[semana.semana] ? faChevronUp : faChevronDown} />
                    </button>
                  </div>
                  {expandedWeeks[semana.semana] && (
                    <div className="conversao-magica-registros conversao-magica-fade-in">
                      {semana.registros.map((registro) => (
                        <div key={registro.id} className="conversao-magica-history-item conversao-magica-fade-in">
                          <div className="conversao-magica-history-date">
                            <span>{registro.data}</span>
                            <span>{registro.diaSemana}</span>
                            <span>{registro.hora}</span>
                          </div>
                          <div className="conversao-magica-history-stats">
                            <span><FontAwesomeIcon icon={faEnvelope} /> Mensagens: {registro.mensagens}</span>
                            <span><FontAwesomeIcon icon={faShoppingCart} /> Vendas: {registro.vendas}</span>
                            <span className="conversao-magica-history-rate"><FontAwesomeIcon icon={faPercentage} /> Taxa de Conversão: {registro.taxa}%</span>
                          </div>
                          <div className="conversao-magica-history-actions">
                            <button
                              className="conversao-magica-history-button delete"
                              onClick={() => excluirRegistro(semana.semana, registro.id)}
                              title="Excluir Registro"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              {historico.length === 0 && (
                <div className="conversao-magica-empty-state">
                  <img src="../../imagens/empty-state.png" alt="Estado Vazio" />
                  <h3>Nenhum registro encontrado.</h3>
                  <p>Comece calculando e salvando sua primeira taxa de conversão do WhatsApp!</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {mostrarDicas && (
          <div className="conversao-magica-tips conversao-magica-fade-in">
            {/* Conteúdo das dicas de melhoria */}
            <p>Aumente o número de mensagens respondidas, melhore a qualidade das respostas e otimize seu atendimento para aumentar a taxa de conversão do WhatsApp!</p>
          </div>
        )}
      </div>
     
      {/* Componente SalesScriptGuide */}
      <div className="conversao-magica-sales-guide">
        <SalesScriptGuide />
      </div>
    </div>
  );
};

export default ConversaoMagica;
