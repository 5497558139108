import React, { useState, useEffect } from 'react';
import {
  listarModulos,
  criarModulo,
  editarModulo,
  excluirModulo,
  listarTarefas,
  criarTarefa,
  editarTarefa,
  excluirTarefa,
  reordenarModulos,
  reordenarTarefas,
  moverTarefa
} from '../../services/checklistService';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { toast } from 'react-toastify';
import './Checklistfilho.css';

const ChecklistFilho = () => {
  // Estado para os módulos e suas tarefas
  const [modulos, setModulos] = useState([]);

  // Estado para controle de modais
  const [showModuloModal, setShowModuloModal] = useState(false);
  const [showTarefaModal, setShowTarefaModal] = useState(false);
  const [moduloSelecionado, setModuloSelecionado] = useState(null);
  const [editingModuleId, setEditingModuleId] = useState(null);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editingTaskData, setEditingTaskData] = useState(null);

  // Estado para nova tarefa
  const [novaTarefa, setNovaTarefa] = useState({
    titulo: '',
    descricao: '',
    nivel: 'Iniciante',
    concluido: {},
    links: []
  });

  // Estado para novo módulo
  const [novoModulo, setNovoModulo] = useState({
    titulo: '',
    descricao: '',
    nivel: 'Iniciante'
  });

  // Estado para controle de expansão das tarefas
  const [tarefasExpandidas, setTarefasExpandidas] = useState({});

  // Estado para barra de progresso
  const [progressoAtual, setProgressoAtual] = useState(0);

  // Estado para controle de colapso dos módulos no mobile
  const [modulosColapsados, setModulosColapsados] = useState({});

  // Estado para tarefa selecionada
  const [selectedTask, setSelectedTask] = useState(null);

  // Estado para modal de confirmação
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null
  });

  // Estado para visualização de detalhes da tarefa
  const [viewingTask, setViewingTask] = useState(null);

  // Novo estado para a porcentagem de progresso
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const carregarModulosComTarefas = async () => {
      try {
        const modulosData = await listarModulos();

        // Ordena os módulos pela ordem
        modulosData.sort((a, b) => a.ordem - b.ordem);

        // Carrega as tarefas de cada módulo
        const modulosComTarefas = await Promise.all(
          modulosData.map(async (modulo) => {
            const tarefas = await listarTarefas(modulo.id);
            // Ordena as tarefas pela ordem
            tarefas.sort((a, b) => a.ordem - b.ordem);
            return { ...modulo, tarefas };
          })
        );

        setModulos(modulosComTarefas);
      } catch (error) {
        console.error('Erro ao carregar módulos e tarefas:', error);
        toast.error('Erro ao carregar módulos e tarefas');
      }
    };

    carregarModulosComTarefas();
  }, []);

  // useEffect para calcular o progresso sempre que os módulos mudarem
  useEffect(() => {
    const calcularProgresso = () => {
      let totalTasks = 0;
      let completedTasks = 0;

      modulos.forEach((modulo) => {
        totalTasks += modulo.tarefas.length;
        modulo.tarefas.forEach((tarefa) => {
          if (tarefa.concluido && tarefa.concluido.status) {
            completedTasks += 1;
          }
        });
      });

      const percentage =
        totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
      setProgressPercentage(percentage);
    };

    calcularProgresso();
  }, [modulos]);

  const handleAddModuleClick = () => {
    setEditingModuleId(null);
    setNovoModulo({ titulo: '', descricao: '', nivel: 'Iniciante' }); // Reset nivel
    setShowModuloModal(true);
  };

  const handleAddTaskClick = (moduleId) => {
    setModuloSelecionado(moduleId);
    setEditingTaskId(null);
    setEditingTaskData(null);
    setNovaTarefa({ titulo: '', descricao: '', nivel: 'Iniciante', concluido: {}, links: [] }); // Reset nivel
    setShowTarefaModal(true);
  };

  const handleSaveModule = async (e, moduleData) => {
    e.preventDefault();
    if (!moduleData.titulo.trim()) {
      toast.error('Por favor, insira um título para o módulo');
      return;
    }

    // Converte o nível para minúsculas
    const nivelMinusculo = moduleData.nivel.toLowerCase();

    if (editingModuleId) {
      // Editando módulo existente
      const updatedModuloData = {
        titulo: moduleData.titulo,
        descricao: moduleData.descricao,
        nivel: nivelMinusculo
      };
      try {
        const data = await editarModulo(editingModuleId, updatedModuloData);
        setModulos(modulos.map(mod => (mod.id === editingModuleId ? { ...mod, ...data } : mod)));
        toast.success('Módulo atualizado com sucesso!');
      } catch (error) {
        console.error('Erro ao atualizar módulo:', error);
        toast.error('Erro ao atualizar módulo');
      }
    } else {
      // Adicionando novo módulo
      const novoModuloData = {
        titulo: moduleData.titulo,
        descricao: moduleData.descricao,
        nivel: nivelMinusculo,
        ordem: modulos.length
      };
      try {
        const data = await criarModulo(novoModuloData);
        // Inicializa tarefas do novo módulo
        data.tarefas = [];
        setModulos([...modulos, data]);
        toast.success('Módulo criado com sucesso!');
      } catch (error) {
        console.error('Erro ao criar módulo:', error);
        toast.error('Erro ao criar módulo');
      }
    }

    setShowModuloModal(false);
    setEditingModuleId(null);
  };

  const handleSaveTask = async (e, taskData) => {
    e.preventDefault();
    if (!taskData.titulo.trim() || !moduloSelecionado) {
      toast.error('Por favor, preencha todos os campos obrigatórios');
      return;
    }

    const modulo = modulos.find(mod => mod.id === moduloSelecionado);
    const tarefasExistentes = modulo?.tarefas || [];

    // Converte o nível para minúsculas
    const nivelMinusculo = taskData.nivel.toLowerCase();

    if (editingTaskId) {
      // Editando tarefa existente
      const updatedTaskData = {
        titulo: taskData.titulo,
        descricao: taskData.descricao,
        nivel: nivelMinusculo,
        links: taskData.links,
        concluido: taskData.concluido
      };
      try {
        const data = await editarTarefa(moduloSelecionado, editingTaskId, updatedTaskData);
        setModulos(modulos.map(mod => {
          if (mod.id === moduloSelecionado) {
            return {
              ...mod,
              tarefas: mod.tarefas.map(t => (t.id === editingTaskId ? { ...t, ...data } : t))
            };
          }
          return mod;
        }));
        toast.success('Tarefa atualizada com sucesso!');
      } catch (error) {
        console.error('Erro ao atualizar tarefa:', error);
        toast.error('Erro ao atualizar tarefa');
      }
    } else {
      // Adicionando nova tarefa
      const novaTarefaData = {
        titulo: taskData.titulo,
        descricao: taskData.descricao,
        nivel: nivelMinusculo,
        links: taskData.links,
        concluido: {},
        ordem: tarefasExistentes.length
      };
      try {
        const data = await criarTarefa(moduloSelecionado, novaTarefaData);
        setModulos(modulos.map(mod => {
          if (mod.id === moduloSelecionado) {
            return {
              ...mod,
              tarefas: [...mod.tarefas, data]
            };
          }
          return mod;
        }));
        toast.success('Tarefa adicionada com sucesso!');
      } catch (error) {
        console.error('Erro ao criar tarefa:', error);
        toast.error('Erro ao criar tarefa');
      }
    }

    setShowTarefaModal(false);
    setEditingTaskId(null);
    setModuloSelecionado(null);
  };

  // Função para lidar com o botão "Definir Progresso"
  const handleDefinirProgresso = async () => {
    if (modulos.length === 0) {
      toast.error('Nenhum módulo encontrado para definir progresso.');
      return;
    }

    const primeiroModulo = modulos[0];

    const novoIndicador = {
      titulo: 'Estou Aqui!',
      descricao: 'Este é o seu progresso atual no curso.',
      nivel: 'indicador',
      links: [],
      concluido: {},
      ordem: 0
    };

    try {
      // Cria a nova tarefa no backend
      const data = await criarTarefa(primeiroModulo.id, novoIndicador);
      // Insere a nova tarefa no início do array de tarefas
      setModulos(modulos.map(mod => {
        if (mod.id === primeiroModulo.id) {
          return {
            ...mod,
            tarefas: [data, ...mod.tarefas]
          };
        }
        return mod;
      }));
      toast.success('Indicador de progresso criado!');
    } catch (error) {
      console.error('Erro ao criar indicador de progresso:', error);
      toast.error('Erro ao criar indicador de progresso');
    }
  };

  // Funções para reordenar módulos e tarefas
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    if (type === 'modulo') {
      await handleModuleDragEnd(result);
    } else if (type === 'tarefa') {
      if (source.droppableId === destination.droppableId) {
        // Reordenar tarefas dentro do mesmo módulo
        await handleTaskDragEnd(result);
      } else {
        // Mover tarefa para outro módulo
        await handleMoveTaskToAnotherModule(result);
      }
    }
  };

  const handleModuleDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedModules = Array.from(modulos);
    const [movedModule] = updatedModules.splice(result.source.index, 1);
    updatedModules.splice(result.destination.index, 0, movedModule);

    // Atualizar ordens no backend
    const reorderedModules = updatedModules.map((modulo, index) => ({
      id: modulo.id,
      ordem: index,
    }));

    console.log('Reordenando módulos:', reorderedModules);

    try {
      await reordenarModulos(reorderedModules);
      setModulos(updatedModules);
      toast.success('Módulos reordenados com sucesso!');
    } catch (error) {
      console.error('Erro ao reordenar módulos:', error);
      toast.error('Erro ao reordenar módulos.');
    }
  };

  const handleTaskDragEnd = async (result) => {
    if (!result.destination) return;

    const moduleIndex = modulos.findIndex((mod) => mod.id === result.source.droppableId);
    const updatedTasks = Array.from(modulos[moduleIndex].tarefas);
    const [movedTask] = updatedTasks.splice(result.source.index, 1);
    updatedTasks.splice(result.destination.index, 0, movedTask);

    // Atualizar ordens no backend
    const reorderedTasks = updatedTasks.map((tarefa, index) => ({
      id: tarefa.id,
      ordem: index,
    }));

    try {
      await reordenarTarefas(modulos[moduleIndex].id, reorderedTasks);
      const updatedModules = [...modulos];
      updatedModules[moduleIndex].tarefas = updatedTasks;
      setModulos(updatedModules);
      toast.success('Tarefas reordenadas com sucesso!');
    } catch (error) {
      console.error('Erro ao reordenar tarefas:', error);
      toast.error('Erro ao reordenar tarefas.');
    }
  };

  const handleMoveTaskToAnotherModule = async (result) => {
    if (!result.destination) return;

    const sourceModuleIndex = modulos.findIndex((mod) => mod.id === result.source.droppableId);
    const destModuleIndex = modulos.findIndex((mod) => mod.id === result.destination.droppableId);

    if (sourceModuleIndex === destModuleIndex) {
      await handleTaskDragEnd(result);
      return;
    }

    const sourceTasks = Array.from(modulos[sourceModuleIndex].tarefas);
    const [movedTask] = sourceTasks.splice(result.source.index, 1);
    const destTasks = Array.from(modulos[destModuleIndex].tarefas);
    destTasks.splice(result.destination.index, 0, movedTask);

    try {
      await moverTarefa(movedTask.id, modulos[destModuleIndex].id, result.destination.index);
      const updatedModules = [...modulos];
      updatedModules[sourceModuleIndex].tarefas = sourceTasks;
      updatedModules[destModuleIndex].tarefas = destTasks;

      setModulos(updatedModules);
      toast.success('Tarefa movida com sucesso!');
    } catch (error) {
      console.error('Erro ao mover tarefa:', error);
      toast.error('Erro ao mover tarefa.');
    }
  };

  const handleDeleteTask = (moduleId, taskId) => {
    setConfirmModal({
      isOpen: true,
      title: 'Confirmar Exclusão',
      message: 'Tem certeza que deseja excluir esta tarefa?',
      onConfirm: async () => {
        try {
          await excluirTarefa(moduleId, taskId);
          setModulos(modulos.map(mod => {
            if (mod.id === moduleId) {
              return {
                ...mod,
                tarefas: mod.tarefas.filter(t => t.id !== taskId)
              };
            }
            return mod;
          }));
          toast.success('Tarefa excluída com sucesso!');
          setConfirmModal({ isOpen: false });
        } catch (error) {
          console.error('Erro ao excluir tarefa:', error);
          toast.error('Erro ao excluir tarefa');
        }
      }
    });
  };

  const handleDeleteModule = (moduleId) => {
    setConfirmModal({
      isOpen: true,
      title: 'Confirmar Exclusão',
      message: 'Tem certeza que deseja excluir este módulo e todas as suas tarefas?',
      onConfirm: async () => {
        try {
          await excluirModulo(moduleId);
          setModulos(modulos.filter(mod => mod.id !== moduleId));
          toast.success('Módulo excluído com sucesso!');
          setConfirmModal({ isOpen: false });
        } catch (error) {
          console.error('Erro ao excluir módulo:', error);
          toast.error('Erro ao excluir módulo');
        }
      }
    });
  };

  const handleEditModule = (moduleId) => {
    const module = modulos.find(mod => mod.id === moduleId);
    setEditingModuleId(moduleId);
    setNovoModulo({
      titulo: module.titulo,
      descricao: module.descricao || '',
      nivel: module.nivel || 'Iniciante'
    });
    setShowModuloModal(true);
  };

  const handleEditTask = (moduleId, taskId) => {
    const module = modulos.find(mod => mod.id === moduleId);
    const task = module.tarefas.find(t => t.id === taskId);
    setModuloSelecionado(moduleId);
    setEditingTaskId(taskId);
    setEditingTaskData(task);
    setShowTarefaModal(true);
  };

  const handleTaskClick = (task) => {
    setViewingTask(task);
  };

  const toggleTarefaExpansao = (tarefaId) => {
    setTarefasExpandidas(prev => ({
      ...prev,
      [tarefaId]: !prev[tarefaId]
    }));
  };

  const toggleModuloColapso = (moduloId) => {
    setModulosColapsados(prev => ({
      ...prev,
      [moduloId]: !prev[moduloId]
    }));
  };

  // Função atualizada para marcar tarefa como concluída
  const handleComplete = async (e, moduleId, taskId, taskData) => {
    e.stopPropagation();
    try {
      const updatedConcluido = {
        ...taskData.concluido,
        status: !taskData.concluido?.status,
      };
      await editarTarefa(moduleId, taskId, { concluido: updatedConcluido });

      const updatedModules = modulos.map((mod) => {
        if (mod.id === moduleId) {
          return {
            ...mod,
            tarefas: mod.tarefas.map((tarefa) =>
              tarefa.id === taskId ? { ...tarefa, concluido: updatedConcluido } : tarefa
            ),
          };
        }
        return mod;
      });

      setModulos(updatedModules);
      toast.success(
        updatedConcluido.status
          ? 'Tarefa concluída com sucesso!'
          : 'Tarefa desmarcada!'
      );
    } catch (error) {
      console.error('Erro ao atualizar tarefa:', error);
      toast.error('Erro ao atualizar tarefa.');
    }
  };

  const TaskCard = ({ task, moduleId }) => {
    const truncateText = (text, maxLength = 150) => {
      if (!text) return '';
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + '...';
    };

    return (
      <div
        className={`tarefa-card ${task.nivel === 'indicador' ? 'indicador-card' : ''} ${task.concluido?.status ? 'concluido' : ''}`}
        onClick={() => handleTaskClick(task)}
        data-nivel={task.nivel}
      >
        <div className="task-content">
          <h3>{task.titulo}</h3>
          <p className={tarefasExpandidas[task.id] ? 'expanded' : ''}>
            {tarefasExpandidas[task.id] ? task.descricao : truncateText(task.descricao)}
          </p>
          {task.descricao && task.descricao.length > 150 && (
            <button
              className="ver-mais-btn"
              onClick={(e) => {
                e.stopPropagation();
                toggleTarefaExpansao(task.id);
              }}
            >
              {tarefasExpandidas[task.id] ? 'Ver menos' : 'Ver mais'}
            </button>
          )}
          {task.links && task.links.length > 0 && (
            <div className="task-links">
              {task.links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className="task-link"
                >
                  {link.titulo || 'Link ' + (index + 1)}
                </a>
              ))}
            </div>
          )}
          {/* Exibir o nível da tarefa */}
          <p className="task-nivel">Nível: {task.nivel}</p>
        </div>
        <div className="task-actions">
          <div className="action-buttons">
            <button
              className="edit-button"
              onClick={(e) => {
                e.stopPropagation();
                handleEditTask(moduleId, task.id);
              }}
            >
              ✏️
            </button>
            <button
              className="edit-button delete-button"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteTask(moduleId, task.id);
              }}
            >
              🗑️
            </button>
          </div>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={task.concluido?.status || false}
              onChange={(e) => handleComplete(e, moduleId, task.id, task)}
              className="custom-checkbox"
              onClick={(e) => e.stopPropagation()}
            />
            <label className="checkbox-label">
              Concluído
            </label>
          </div>
          {/* Exibir o nível da tarefa */}
          <div className="task-nivel-info">
            <span>{task.nivel}</span>
          </div>
        </div>
      </div>
    );
  };

  const TaskModal = ({ isOpen, onClose, onSave, taskData, isEditing }) => {
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [nivel, setNivel] = useState('Iniciante');
    const [links, setLinks] = useState([]);
    const [newLink, setNewLink] = useState({ titulo: '', url: '' });

    useEffect(() => {
      if (taskData) {
        setTitulo(taskData.titulo || '');
        setDescricao(taskData.descricao || '');
        setNivel(taskData.nivel || 'Iniciante');
        setLinks(taskData.links || []);
      } else {
        setTitulo('');
        setDescricao('');
        setNivel('Iniciante');
        setLinks([]);
      }
    }, [taskData]);

    const handleAddLink = (e) => {
      e.preventDefault();
      if (newLink.titulo.trim() && newLink.url.trim()) {
        setLinks([...links, { ...newLink }]);
        setNewLink({ titulo: '', url: '' });
      }
    };

    const handleRemoveLink = (index) => {
      setLinks(links.filter((_, i) => i !== index));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(e, { titulo, descricao, nivel, links });
    };

    if (!isOpen) return null;

    return (
      <div className="modal" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>{isEditing ? 'Editar Tarefa' : 'Adicionar Tarefa'}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Título</label>
              <input
                type="text"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                placeholder="Digite o título da tarefa"
                required
                autoFocus
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <textarea
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                placeholder="Digite a descrição da tarefa"
                rows="4"
              />
            </div>
            <div className="form-group">
              <label>Nível</label>
              <select
                value={nivel}
                onChange={(e) => setNivel(e.target.value)}
                required
              >
                <option value="Iniciante">Iniciante</option>
                <option value="Avançado">Avançado</option>
                <option value="Ambos">Ambos</option>
              </select>
            </div>

            <div className="form-group">
              <label>Links</label>
              <div className="links-container">
                {links.map((link, index) => (
                  <div key={index} className="link-item">
                    <input
                      type="text"
                      value={link.titulo}
                      readOnly
                      placeholder="Título do link"
                    />
                    <input
                      type="url"
                      value={link.url}
                      readOnly
                      placeholder="URL"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveLink(index)}
                      className="remove-link-button"
                    >
                      Remover
                    </button>
                  </div>
                ))}

                <div className="add-link-form">
                  <input
                    type="text"
                    value={newLink.titulo}
                    onChange={(e) => setNewLink({ ...newLink, titulo: e.target.value })}
                    placeholder="Título do link"
                  />
                  <input
                    type="url"
                    value={newLink.url}
                    onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                    placeholder="URL do link"
                  />
                  <button
                    type="button"
                    onClick={handleAddLink}
                    className="add-link-button"
                  >
                    Adicionar Link
                  </button>
                </div>
              </div>
            </div>

            <div className="modal-actions">
              <button type="button" onClick={onClose} className="cancel-button">
                Cancelar
              </button>
              <button type="submit" className="confirm-button">
                {isEditing ? 'Salvar' : 'Adicionar'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const ModuleModal = ({ isOpen, onClose, onSave, moduleData, isEditing }) => {
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [nivel, setNivel] = useState('Iniciante');

    useEffect(() => {
      if (moduleData) {
        setTitulo(moduleData.titulo || '');
        setDescricao(moduleData.descricao || '');
        setNivel(moduleData.nivel || 'Iniciante');
      } else {
        setTitulo('');
        setDescricao('');
        setNivel('Iniciante');
      }
    }, [moduleData]);

    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(e, { titulo, descricao, nivel });
    };

    if (!isOpen) return null;

    return (
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>{isEditing ? 'Editar Módulo' : 'Adicionar Módulo'}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Título</label>
              <input
                type="text"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                placeholder="Digite o título do módulo"
                required
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <textarea
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                placeholder="Digite a descrição do módulo"
                rows="4"
              />
            </div>
            <div className="form-group">
              <label>Nível</label>
              <select
                value={nivel}
                onChange={(e) => setNivel(e.target.value)}
                required
              >
                <option value="Iniciante">Iniciante</option>
                <option value="Avançado">Avançado</option>
                <option value="Ambos">Ambos</option>
              </select>
            </div>
            <div className="modal-actions">
              <button type="button" onClick={onClose} className="cancel-button">
                Cancelar
              </button>
              <button type="submit" className="confirm-button">
                {isEditing ? 'Salvar' : 'Adicionar'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const TaskDetailsModal = ({ task, onClose }) => {
    if (!task) return null;

    return (
      <div className="modal" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>{task.titulo}</h2>
          <div className="task-details">
            <p className="task-description">{task.descricao}</p>
            <p className="task-nivel">Nível: {task.nivel}</p> {/* Exibir nível */}
            {task.links && task.links.length > 0 && (
              <div className="task-links">
                <h3>Links</h3>
                <div className="links-list">
                  {task.links.map((link, index) => (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="task-link"
                    >
                      {link.titulo}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="modal-actions">
            <button onClick={onClose} className="confirm-button">
              Fechar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="modal-actions">
            <button onClick={onClose} className="cancel-button">Cancelar</button>
            <button onClick={onConfirm} className="confirm-button">Confirmar</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="checklist-container">
      <div className="checklist-header">
        <h1>Checklist para Prosperar Sem Sair de Casa</h1>
        <div className="header-buttons">
          <button onClick={handleAddModuleClick}>Adicionar Módulo</button>
          <button onClick={handleDefinirProgresso}>
            Definir Progresso
          </button>
        </div>
      </div>

      {/* Barra de Progresso */}
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <p className="progress-percentage">{progressPercentage}% Concluído</p>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="modulos" type="modulo" direction="horizontal">
          {(provided) => (
            <div
              className="modulos-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {modulos.map((modulo, index) => (
                <Draggable key={modulo.id} draggableId={modulo.id.toString()} index={index}>
                  {(provided) => (
                    <div
                      className="modulo-card"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="modulo-header" {...provided.dragHandleProps}>
                        <div>
                          <h2 className="modulo-titulo">{modulo.titulo}</h2>
                          <p className="modulo-descricao">{modulo.descricao}</p>
                          <p className="modulo-nivel">Nível: {modulo.nivel}</p>
                        </div>
                        <div className="modulo-actions">
                          <button onClick={() => handleEditModule(modulo.id)} className="edit-button">
                            Editar
                          </button>
                          <button onClick={() => handleDeleteModule(modulo.id)} className="edit-button delete-button">
                            Excluir
                          </button>
                          <button
                            className="toggle-collapse"
                            onClick={() => toggleModuloColapso(modulo.id)}
                          >
                            {modulosColapsados[modulo.id] ? '▼' : '▲'}
                          </button>
                          <button
                            onClick={() => handleAddTaskClick(modulo.id)}
                            className="add-task-button"
                          >
                            + Tarefa
                          </button>
                        </div>
                      </div>

                      {!modulosColapsados[modulo.id] && (
                        <Droppable droppableId={modulo.id.toString()} type="tarefa">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="tarefas01-container"
                            >
                              {Array.isArray(modulo.tarefas) && modulo.tarefas.map((tarefa, index) => (
                                <Draggable
                                  key={tarefa.id}
                                  draggableId={tarefa.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`tarefa-card ${tarefa.concluido?.status ? 'concluido' : ''}`}
                                    >
                                      <TaskCard task={tarefa} moduleId={modulo.id} />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ModuleModal
        isOpen={showModuloModal}
        onClose={() => setShowModuloModal(false)}
        onSave={handleSaveModule}
        moduleData={editingModuleId ? novoModulo : null}
        isEditing={!!editingModuleId}
      />

      <TaskModal
        isOpen={showTarefaModal}
        onClose={() => setShowTarefaModal(false)}
        onSave={handleSaveTask}
        taskData={editingTaskData}
        isEditing={!!editingTaskId}
      />

      {viewingTask && (
        <TaskDetailsModal
          task={viewingTask}
          onClose={() => setViewingTask(null)}
        />
      )}

      <ConfirmationModal
        isOpen={confirmModal.isOpen}
        title={confirmModal.title}
        message={confirmModal.message}
        onConfirm={confirmModal.onConfirm}
        onClose={() => setConfirmModal({ isOpen: false })}
      />
    </div>
  );
};

export default ChecklistFilho;
