import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Alterado para useNavigate
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import './LucroAlvo.css';
import Metricas from './Metricas';
import { 
  FaBullseye, FaShoppingCart, FaCalendarDay, FaBriefcase, FaEnvelope, FaPercentage,
  FaCommentsDollar, FaChartBar, FaCreditCard, FaShoppingBag, FaDollarSign, FaBan, FaFrown, 
  FaTrophy, FaClock, FaAd, FaArrowUp, FaChartLine, FaHandshake, FaLaptop, FaExclamationTriangle, FaCalendarAlt, FaRedo, FaRocket, FaArrowLeft, FaTarget, FaDownload 
} from 'react-icons/fa';
import * as htmlToImage from 'html-to-image';

const LucroAlvo = () => {
  const navigate = useNavigate(); // Hook para navegação
  const [result, setResult] = useState(null);

 useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  const userEmail = localStorage.getItem('userEmail');

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirecionamento utilizando useNavigate
  };

  const handleBackToMenu = () => {
    navigate('/ferramentas');
  };

  const formatNumber = (num) => {
    return num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const aplicarMascaraNumero = (input) => {
    input.addEventListener('input', function (e) {
      let value = e.target.value.replace(/\D/g, '');
      value = (parseFloat(value) / 100).toFixed(2);
      e.target.value = formatNumber(parseFloat(value));
    });
  };

  const aplicarMascaraPorcentagem = (input) => {
    input.addEventListener('input', function (e) {
      let value = e.target.value.replace(/[^\d,]/g, '');
      if (value.indexOf(',') !== -1) {
        value = value.substring(0, value.indexOf(',') + 3);
      }
      e.target.value = value + '%';
    });
  };

  const calcularProsperidade = (e) => {
    e.preventDefault();

    const lucroDesejado = parseFloat(e.target.lucroDesejado.value.replace(/\D/g, '')) / 100;
    const comissaoVenda = parseFloat(e.target.comissaoVenda.value.replace(/\D/g, '')) / 100;
    const taxaPlataforma = parseFloat(e.target.taxaPlataforma.value.replace(/[^\d,]/g, '').replace(',', '.')) / 100;
    const taxaFrustracao = parseFloat(e.target.taxaFrustracao.value.replace(/[^\d,]/g, '').replace(',', '.')) / 100;
    const periodoLucro = parseInt(e.target.periodoLucro.value);

    const faturamentoAproximado = lucroDesejado / (1 - taxaPlataforma - 0.3);
    const investimentoDiario = (faturamentoAproximado * 0.3) / periodoLucro;
    const investimentoTotal = investimentoDiario * periodoLucro;
    const vendasDiarias = Math.round(faturamentoAproximado / (comissaoVenda * periodoLucro));
    const mensagensDiarias = Math.round(vendasDiarias * 10);
    const faturamentoPeriodo = faturamentoAproximado;
    const taxaConversaoWhatsApp = (vendasDiarias / mensagensDiarias) * 100;
    const custoPorMensagem = investimentoDiario / mensagensDiarias;

    const quantidadeVendas = vendasDiarias * periodoLucro;
    const valorTotalTaxas = faturamentoAproximado * taxaPlataforma;
    const valorTotalTrafego = investimentoTotal;
    const pedidosFrustrados = Math.round(quantidadeVendas * taxaFrustracao);
    const custoFrustracao = pedidosFrustrados * 4.99;
    const valorLiquido = faturamentoAproximado - valorTotalTaxas - valorTotalTrafego - custoFrustracao;

    const cpmMedio = (Math.random() * (15 - 11) + 11).toFixed(2);
    const ctrIdealMin = 1;
    const ctrIdealMax = 3;
    const ctrIdeal = `${ctrIdealMin} - ${ctrIdealMax}`;
    const cpcIdealMin = cpmMedio / (ctrIdealMax * 10);
    const cpcIdealMax = cpmMedio / (ctrIdealMin * 10);
    const cpcIdeal = `${cpcIdealMin.toFixed(2)} - ${cpcIdealMax.toFixed(2)}`;
    const cpaIdeal = investimentoTotal / Math.max(quantidadeVendas, 1);

    setResult({
      cpmMedio: formatNumber(cpmMedio),
      cpmMaximo: formatNumber(cpmMedio * 1.5),
      cpcIdeal,
      ctrIdeal,
      cpaIdeal: formatNumber(cpaIdeal),
      investimentoDiario: formatNumber(investimentoDiario),
      investimentoTotal: formatNumber(investimentoTotal),
      vendasDiarias,
      mensagensDiarias,
      custoPorMensagem: formatNumber(custoPorMensagem),
      taxaConversaoWhatsApp: formatNumber(taxaConversaoWhatsApp),
      faturamentoPeriodo: formatNumber(faturamentoPeriodo),
      quantidadeVendas,
      valorTotalTaxas: formatNumber(valorTotalTaxas),
      valorTotalTrafego: formatNumber(valorTotalTrafego),
      valorLiquido: formatNumber(valorLiquido),
      pedidosFrustrados,
      custoFrustracao: formatNumber(custoFrustracao),
      periodoNecessario: Math.ceil(lucroDesejado / (comissaoVenda * vendasDiarias))
    });

    const resultsElement = document.getElementById('results');
    resultsElement.style.display = 'block';
    window.scrollTo({ top: resultsElement.offsetTop, behavior: 'smooth' });
  };

  const downloadAsImage = () => {
    const element = document.getElementById('results');
    const logoElement = document.createElement('img');
    logoElement.src = logo;
    logoElement.className = 'print-logo';
    element.insertBefore(logoElement, element.firstChild);
    htmlToImage.toPng(element)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'plano.png';
        link.click();
        element.removeChild(logoElement);
      })
      .catch((error) => {
        console.error('Erro ao salvar como imagem:', error);
        element.removeChild(logoElement);
      });
  };

  return (
    <div className="lucro-alvo-container">
      <div className="lucro-alvo-header">
        <img src={logo} alt="Logo" className="lucro-alvo-logo" />
        <div className="lucro-alvo-user-info">
          <span>{userEmail}</span>
          <button className="lucro-alvo-button" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="lucro-alvo-content">
        <h1 className="lucro-alvo-title-gradient">Meta de Lucro</h1>
        <p className="lucro-alvo-description">Defina seu objetivo de lucro e descubra quanto investir diariamente no Facebook para alcançar sua meta em 30 dias.</p>
        <button className="lucro-alvo-button" onClick={handleBackToMenu}><FaArrowLeft /> Voltar para Menu</button>
        <form className="lucro-alvo-form" id="prosperityForm" onSubmit={calcularProsperidade}>
          <h3>Insira seus dados</h3>
          <label htmlFor="lucroDesejado" className="lucro-alvo-label"><FaDollarSign className="lucro-alvo-icon" /> Lucro desejado (R$):</label>
          <p className="input-description">Quanto você deseja lucrar em 30 dias.</p>
          <input type="text" id="lucroDesejado" required onInput={(e) => aplicarMascaraNumero(e.target)} />

          <label htmlFor="comissaoVenda" className="lucro-alvo-label"><FaHandshake className="lucro-alvo-icon" /> Comissão por venda (R$):</label>
          <p className="input-description">Quanto você ganha por cada venda.</p>
          <input type="text" id="comissaoVenda" required onInput={(e) => aplicarMascaraNumero(e.target)} />

          <label htmlFor="taxaPlataforma" className="lucro-alvo-label"><FaLaptop className="lucro-alvo-icon" /> Taxa da plataforma (%):</label>
          <p className="input-description">Porcentagem cobrada pela plataforma.</p>
          <input type="text" id="taxaPlataforma" required onInput={(e) => aplicarMascaraPorcentagem(e.target)} />

          <label htmlFor="taxaFrustracao" className="lucro-alvo-label"><FaExclamationTriangle className="lucro-alvo-icon" /> Taxa de Frustração da Logística (%):</label>
          <p className="input-description">Porcentagem estimada de pedidos frustrados. Padrão: 30%</p>
          <input type="text" id="taxaFrustracao" defaultValue="30%" required onInput={(e) => aplicarMascaraPorcentagem(e.target)} />

          <label htmlFor="periodoLucro" className="lucro-alvo-label"><FaCalendarAlt className="lucro-alvo-icon" /> Período para alcançar o lucro (dias):</label>
          <p className="input-description">Período máximo: 30 dias.</p>
          <input type="number" id="periodoLucro" min="1" max="30" required />

          <div id="error-message" className="lucro-alvo-error"></div>

          <button type="submit" className="lucro-alvo-button calcular"><FaRocket /> Calcular 🚀</button>
        </form>

        <div id="results-container">
          <div id="results" className="lucro-alvo-results">
            <div className="lucro-alvo-result-block">
              <h3>📊 Métricas do Facebook</h3>
              <div className="lucro-alvo-result-item">
                <FaBullseye className="lucro-alvo-icon cpm-icon" /> 
                <span className="lucro-alvo-metric">CPM médio:</span> 
                <span className="lucro-alvo-number">R$ {result?.cpmMedio}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaArrowUp className="lucro-alvo-icon cpm-max-icon" /> 
                <span className="lucro-alvo-metric">CPM máximo aceitável:</span> 
                <span className="lucro-alvo-number">R$ {result?.cpmMaximo}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaBullseye className="lucro-alvo-icon cpc-icon" /> 
                <span className="lucro-alvo-metric">CPC ideal:</span> 
                <span className="lucro-alvo-number">R$ {result?.cpcIdeal}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaChartLine className="lucro-alvo-icon ctr-icon" /> 
                <span className="lucro-alvo-metric">CTR ideal:</span> 
                <span className="lucro-alvo-number">{result?.ctrIdeal}%</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaShoppingCart className="lucro-alvo-icon cpa-icon" /> 
                <span className="lucro-alvo-metric">CPA ideal:</span> 
                <span className="lucro-alvo-number">R$ {result?.cpaIdeal}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaCalendarDay className="lucro-alvo-icon investment-daily-icon" /> 
                <span className="lucro-alvo-metric">Investimento em anúncio diário:</span> 
                <span className="lucro-alvo-number">R$ {result?.investimentoDiario}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaBriefcase className="lucro-alvo-icon investment-total-icon" /> 
                <span className="lucro-alvo-metric">Investimento total em Facebook:</span> 
                <span className="lucro-alvo-number">R$ {result?.investimentoTotal}</span>
              </div>
            </div>
            <div className="lucro-alvo-result-block">
              <h3>📈 Métricas de Conversão Para WhatsApp</h3>
              <div className="lucro-alvo-result-item">
                <FaEnvelope className="lucro-alvo-icon messages-daily-icon" /> 
                <span className="lucro-alvo-metric">Mensagens geradas por dia:</span> 
                <span className="lucro-alvo-number">{result?.mensagensDiarias}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaCommentsDollar className="lucro-alvo-icon cost-per-message-icon" /> 
                <span className="lucro-alvo-metric">Custo por mensagem:</span> 
                <span className="lucro-alvo-number">R$ {result?.custoPorMensagem}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaPercentage className="lucro-alvo-icon conversion-rate-icon" /> 
                <span className="lucro-alvo-metric">Taxa de conversão do WhatsApp:</span> 
                <span className="lucro-alvo-number">{result?.taxaConversaoWhatsApp}%</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaShoppingCart className="lucro-alvo-icon sales-daily-icon" /> 
                <span className="lucro-alvo-metric">Vendas esperadas por dia:</span> 
                <span className="lucro-alvo-number">{result?.vendasDiarias}</span>
              </div>
            </div>
            <div className="lucro-alvo-result-block">
              <h3>📊 Resultados Financeiros</h3>
              <div className="lucro-alvo-result-item">
                <FaCreditCard className="lucro-alvo-icon revenue-icon" /> 
                <span className="lucro-alvo-metric">Faturamento no período:</span> 
                <span className="lucro-alvo-number">R$ {result?.faturamentoPeriodo}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaShoppingBag className="lucro-alvo-icon total-sales-icon" /> 
                <span className="lucro-alvo-metric">Quantidade total de vendas:</span> 
                <span className="lucro-alvo-number">{result?.quantidadeVendas}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaDollarSign className="lucro-alvo-icon total-taxes-icon" /> 
                <span className="lucro-alvo-metric">Valor total de taxas:</span> 
                <span className="lucro-alvo-number">R$ {result?.valorTotalTaxas}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaAd className="lucro-alvo-icon total-traffic-cost-icon" /> 
                <span className="lucro-alvo-metric">Valor total gasto em tráfego:</span> 
                <span className="lucro-alvo-number">R$ {result?.valorTotalTrafego}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaBan className="lucro-alvo-icon frustrated-orders-icon" /> 
                <span className="lucro-alvo-metric">Pedidos frustrados estimados:</span> 
                <span className="lucro-alvo-number">{result?.pedidosFrustrados}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaFrown className="lucro-alvo-icon frustration-cost-icon" /> 
                <span className="lucro-alvo-metric">Custo total de frustração:</span> 
                <span className="lucro-alvo-number">R$ {result?.custoFrustracao}</span>
              </div>
              <div className="lucro-alvo-result-item">
                <FaTrophy className="lucro-alvo-icon net-profit-icon" /> 
                <span className="lucro-alvo-metric">Valor Líquido (Descontado a taxa de frustração):</span> 
                <span className="lucro-alvo-number">R$ {result?.valorLiquido}</span>
              </div>
              {result?.periodoNecessario && (
                <div className="lucro-alvo-result-item">
                  <FaClock className="lucro-alvo-icon period-required-icon" /> 
                  <span className="lucro-alvo-metric">Período necessário para alcançar o lucro:</span> 
                  <span className="lucro-alvo-number">{result?.periodoNecessario} dias</span>
                </div>
              )}
            </div>
            <div className="lucro-alvo-button-group">
              <button onClick={() => setResult(null)} className="lucro-alvo-button-secondary"><FaRedo /> Novo Cálculo</button>
              <button className="lucro-alvo-button-secondary" onClick={handleBackToMenu}><FaArrowLeft /> Voltar para Menu</button>
              <button onClick={downloadAsImage} className="lucro-alvo-button"><FaDownload /> Salvar como Imagem</button>
            </div>
          </div>
        </div>
      </div>
      {result && <Metricas />}
    </div>
  );
};

export default LucroAlvo;
