import React, { useState, useEffect, useRef, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import { FaTrash, FaReply, FaEdit, FaHeart } from 'react-icons/fa';
import { AiOutlineSend } from 'react-icons/ai';
import EmojiPicker from 'emoji-picker-react';
import debounce from 'lodash/debounce';
import './Comentarios.css';
import { 
  formatTimeAgo, 
   buscarUsuarios,
  criarNotificacaoMencao,
  verificarMencoes,
  processarMencoes,
  marcarNotificacaoComoLida
} from './commentUtils';

const Comentarios = ({ aulaId, moduloId, userId, containerWidth }) => {
  const [comentarios, setComentarios] = useState([]);
  const [novoComentario, setNovoComentario] = useState('');
  const [loading, setLoading] = useState(false);
  const [usuarioNome, setUsuarioNome] = useState('');
  const [fotoPerfil, setFotoPerfil] = useState('');
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [replyContent, setReplyContent] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const textareaRef = useRef(null);
  const suggestionsRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const [mencaoNotification, setMencaoNotification] = useState(null);

  useEffect(() => {
    if (aulaId) {
      carregarComentarios();
      configurarTempoReal();
      carregarNotificacoes();
    }
    carregarUsuarioDados();

    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [aulaId]);

  const carregarComentarios = async () => {
    try {
      setLoading(true);
      // Modifique esta parte no carregarComentarios
      const { data: comentariosData, error: comentariosError } = await supabase
        .from('comentarios')
        .select(`
          *,
          curtidas_comentarios (
            id,
            usuario_id,
            tipo
          )
        `)
        .eq('aula_id', aulaId)
        .is('comentario_pai_id', null) // Busca apenas comentários principais
        .order('criado_em', { ascending: false }); // Mudou para false para mostrar mais recentes primeiro

      if (comentariosError) throw comentariosError;

      // Buscar respostas separadamente
      const { data: respostasData, error: respostasError } = await supabase
        .from('comentarios')
        .select(`
          *,
          curtidas_comentarios (
            id,
            usuario_id,
            tipo
          )
        `)
        .eq('aula_id', aulaId)
        .not('comentario_pai_id', 'is', null)
        .order('criado_em', { ascending: true }); // Mantém ascending true para respostas aparecerem em ordem cronológica
      if (respostasError) throw respostasError;

      // Organizar respostas em seus respectivos comentários pais
      const comentariosProcessados = comentariosData.map(comentario => {
        const respostas = respostasData.filter(
          resposta => resposta.comentario_pai_id === comentario.id
        );
        return {
          ...comentario,
          curtido: comentario.curtidas_comentarios?.some(
            curtida => curtida.usuario_id === userId
          ),
          curtidas_count: comentario.curtidas_comentarios?.length || 0,
          respostas_comentarios: respostas.map(resposta => ({
            ...resposta,
            curtido: resposta.curtidas_comentarios?.some(
              curtida => curtida.usuario_id === userId
            ),
            curtidas_count: resposta.curtidas_comentarios?.length || 0
          }))
        };
      });

      setComentarios(comentariosProcessados);
    } catch (error) {
      console.error('Erro ao carregar comentários:', error);
    } finally {
      setLoading(false);
    }
  };

  const carregarUsuarioDados = async () => {
    try {
      const { data: userData, error: userError } = await supabase
        .from('usuarios')
        .select('nome, foto_perfil')
        .eq('user_id', userId)
        .single();

      if (userError) throw userError;

      if (userData) {
        setUsuarioNome(userData.nome);
        setFotoPerfil(userData.foto_perfil);
      }
    } catch (error) {
      console.error('Erro ao carregar dados do usuário:', error);
    }
  };

  const configurarTempoReal = () => {
    const comentariosChannel = supabase
      .channel('comentarios-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'comentarios',
          filter: `aula_id=eq.${aulaId}`
        },
        (payload) => {
          carregarComentarios();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(comentariosChannel);
    };
  };

  const carregarNotificacoes = async () => {
    try {
      const { data, error } = await supabase
        .from('notificacoes_comentarios')
        .select('*')
        .eq('usuario_id', userId)
        .eq('lida', false)
        .order('data_notificacao', { ascending: false });

      if (error) throw error;
      setNotifications(data || []);
    } catch (error) {
      console.error('Erro ao carregar notificações:', error);
    }
  };

  const buscarUsuarios = async (searchTerm) => {
    try {
      const { data, error } = await supabase
        .from('usuarios')
        .select('user_id, nome')
        .ilike('nome', `${searchTerm}%`)
        .limit(5);

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      return [];
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      const usuarios = await buscarUsuarios(searchTerm);
      setUserSuggestions(usuarios);
      setShowSuggestions(true);
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const text = e.target.value;
    setNovoComentario(text);

    const cursorPos = e.target.selectionStart;
    const textUntilCursor = text.slice(0, cursorPos);
    const match = textUntilCursor.match(/@(\w*)$/);

    if (match) {
      setCursorPosition(cursorPos);
      debouncedSearch(match[1]);
    } else {
      setShowSuggestions(false);
    }
  };

  const onEmojiClick = (emojiObject) => {
    const cursor = textareaRef.current.selectionStart;
    const text = novoComentario;
    const newText = text.slice(0, cursor) + emojiObject.emoji + text.slice(cursor);
    setNovoComentario(newText);
    setShowEmojiPicker(false);
  };

  const inserirMencao = (usuario) => {
  const beforeMention = novoComentario.slice(0, cursorPosition).replace(/@\w*$/, '');
  const afterMention = novoComentario.slice(cursorPosition);
  const newText = `${beforeMention}@[${usuario.user_id}:${usuario.nome}] ${afterMention}`;
  
  setNovoComentario(newText);
  setShowSuggestions(false);
  
  if (textareaRef.current) {
    textareaRef.current.focus();
  }
};


  const criarNotificacao = async (usuarioId, tipo, comentarioId = null) => {
    try {
      const tiposValidos = ['curtida', 'resposta'];
      if (!tiposValidos.includes(tipo)) {
        console.error('Tipo de notificação inválido:', tipo);
        return;
      }

      const { error } = await supabase
        .from('notificacoes_comentarios')
        .insert({
          usuario_id: usuarioId,
          comentario_id: comentarioId,
          tipo_notificacao: tipo,
          lida: false
        });

      if (error) throw error;
    } catch (error) {
      console.error('Erro ao criar notificação:', error);
    }
  };

  const enviarComentario = async (e) => {
  e.preventDefault();
  if (!novoComentario.trim()) return;

  try {
    let comentarioData = {
      usuario_id: userId,
      usuario_nome: usuarioNome,
      usuario_foto: fotoPerfil,
      aula_id: aulaId,
      modulo_id: moduloId,
      conteudo: novoComentario.trim()
    };

    if (replyingTo) {
      const comentarioPai = comentarios.find(c => c.id === replyingTo);
      if (!comentarioPai) {
        console.error('Comentário pai não encontrado');
        return;
      }
      comentarioData.comentario_pai_id = replyingTo;
    }

    const { data, error } = await supabase
      .from('comentarios')
      .insert(comentarioData)
      .single();

    if (error) throw error;

    // Processar menções apenas se o comentário foi salvo com sucesso e "data.id" existir
    if (data?.id && novoComentario.includes('@')) {
      await processarMencoes(novoComentario.trim(), data.id, userId, { nome: 'Nome da Aula' });
    }

    setNovoComentario('');
    setReplyingTo(null);
    await carregarComentarios();
  } catch (error) {
    console.error('Erro ao enviar comentário:', error);
  }
};



  const curtirComentario = async (comentarioId, e) => {
    e.preventDefault(); // Previne o comportamento padrão
    try {
      const { data: existingLike, error: checkError } = await supabase
        .from('curtidas_comentarios')
        .select('id, tipo')
        .eq('comentario_id', comentarioId)
        .eq('usuario_id', userId)
        .single();

      if (checkError && checkError.code !== 'PGRST116') throw checkError;

      if (existingLike) {
        const { error: deleteError } = await supabase
          .from('curtidas_comentarios')
          .delete()
          .eq('id', existingLike.id);

        if (deleteError) throw deleteError;
      } else {
        const { error: insertError } = await supabase
          .from('curtidas_comentarios')
          .insert({
            comentario_id: comentarioId,
            usuario_id: userId,
            tipo: 'curtida'
          });

        if (insertError) throw insertError;

        const comentario = comentarios.find(c => c.id === comentarioId);
        if (comentario && comentario.usuario_id !== userId) {
          await criarNotificacao(comentario.usuario_id, 'curtida', comentarioId);
        }
      }

      // Atualiza localmente o estado dos comentários para uma atualização instantânea
      const atualizarComentarios = (comentarios) => {
        return comentarios.map(comment => {
          if (comment.id === comentarioId) {
            const novoEstadoCurtida = !comment.curtido;
            return {
              ...comment,
              curtido: novoEstadoCurtida,
              curtidas_count: novoEstadoCurtida 
                ? comment.curtidas_count + 1 
                : comment.curtidas_count - 1
            };
          }
          if (comment.respostas_comentarios) {
            return {
              ...comment,
              respostas_comentarios: atualizarComentarios(comment.respostas_comentarios)
            };
          }
          return comment;
        });
      };

      setComentarios(atualizarComentarios(comentarios));
    } catch (error) {
      console.error('Erro ao curtir comentário:', error);
    }
  };

  const excluirComentario = async (comentarioId) => {
    try {
      const { error: likesError } = await supabase
        .from('curtidas_comentarios')
        .delete()
        .eq('comentario_id', comentarioId);

      if (likesError) throw likesError;

      const { error: notificationsError } = await supabase
        .from('notificacoes_comentarios')
        .delete()
        .eq('comentario_id', comentarioId);

      if (notificationsError) throw notificationsError;

      const { error: commentError } = await supabase
        .from('comentarios')
        .delete()
        .eq('id', comentarioId)
        .eq('usuario_id', userId);

      if (commentError) throw commentError;

      setShowDeleteConfirm(null);
      await carregarComentarios();
    } catch (error) {
      console.error('Erro ao excluir comentário:', error);
    }
  };

  const handleEdit = async (comentarioId) => {
    try {
      if (!editContent.trim()) return;

      const { error } = await supabase
        .from('comentarios')
        .update({ conteudo: editContent })
        .eq('id', comentarioId)
        .eq('usuario_id', userId);

      if (error) throw error;

      setEditingCommentId(null);
      setEditContent('');
      await carregarComentarios();
    } catch (error) {
      console.error('Erro ao editar comentário:', error);
    }
  };

  const handleReply = async (comentarioId) => {
  try {
    if (!replyContent.trim()) return;

    const comentarioData = {
      usuario_id: userId,
      usuario_nome: usuarioNome || 'Usuário',
      usuario_foto: fotoPerfil || '/default-avatar.png',
      aula_id: aulaId,
      modulo_id: moduloId,
      conteudo: replyContent.trim(),
      comentario_pai_id: comentarioId
    };

    const { data, error } = await supabase
      .from('comentarios')
      .insert(comentarioData)
      .single();

    if (error) throw error;

    // Verifica e processa menções se o comentário foi salvo com sucesso e "data.id" existir
    if (data?.id && replyContent.includes('@')) {
      await processarMencoes(replyContent.trim(), data.id, userId, { nome: 'Nome da Aula' });
    }

    setReplyingTo(null);
    setReplyContent('');
    await carregarComentarios();
  } catch (error) {
    console.error('Erro ao responder comentário:', error);
  }
};


  // Adicionar verificação de menções ao useEffect inicial
 useEffect(() => {
  if (userId) {
    carregarComentarios();
    configurarTempoReal();
    carregarNotificacoes();

    const verificarNotificacoesMencao = async () => {
      const mencaoNaoLida = await verificarMencoes(userId);
      if (mencaoNaoLida) {
        setMencaoNotification(mencaoNaoLida);
      }
    };
    verificarNotificacoesMencao();
  }
  carregarUsuarioDados();

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, [userId, aulaId]);



  return (
    <div className="comments-container">
      {mencaoNotification && (
        <div className="mention-notification">
          <p>{mencaoNotification.mensagem}</p>
          <button onClick={() => setMencaoNotification(null)}>×</button>
        </div>
      )}
      <form onSubmit={enviarComentario} className="comments-input-area">
        <img
          src={fotoPerfil || '/default-avatar.png'}
          alt="Avatar"
          className="comments-user-avatar"
        />
        <div className="comments-input-container">
          <textarea
            ref={textareaRef}
            value={novoComentario}
            onChange={handleInputChange}
            placeholder="Digite seu comentário..."
            className="comments-textarea"
          />
          <div className="comments-actions">
            <div className="comments-emoji-picker" ref={emojiPickerRef}>
              <button
                type="button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="comments-emoji-button"
              >
                😊
              </button>
              {showEmojiPicker && (
                <div className="emoji-picker-container">
                  <EmojiPicker onEmojiClick={onEmojiClick} />
                </div>
              )}
            </div>
            <button type="submit" className="comments-submit-button">
              <AiOutlineSend />
              Enviar
            </button>
          </div>
        </div>
      </form>

      {loading ? (
        <div className="comments-loading">Carregando comentários...</div>
      ) : (
        <div className="comments-list">
          {comentarios.map((comentario) => (
            <div key={comentario.id} className="comment-item">
              <div className="comment-header">
                <img
                  src={comentario.usuario_foto || '/default-avatar.png'}
                  alt="Avatar"
                  className="comments-user-avatar"
                />
                <div className="comment-user-info">
                  <div className="comment-username">{comentario.usuario_nome}</div>
                  <div className="comment-timestamp">
                    {formatTimeAgo(comentario.criado_em)}
                  </div>
                </div>
              </div>
              
              {editingCommentId === comentario.id ? (
                <div className="comment-edit-container">
                  <textarea
                    value={editContent || comentario.conteudo}
                    onChange={(e) => setEditContent(e.target.value)}
                    className="comments-textarea"
                  />
                  <div className="comment-edit-actions">
                    <button
                      onClick={() => handleEdit(comentario.id)}
                      className="comment-action-button"
                    >
                      Salvar
                    </button>
                    <button
                      onClick={() => {
                        setEditingCommentId(null);
                        setEditContent('');
                      }}
                      className="comment-action-button"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="comment-content">{comentario.conteudo}</div>
              )}
              
              <div className="comment-actions">
                <button
                  onClick={(e) => curtirComentario(comentario.id, e)}
                  className="comment-action-button"
                >
                  <div className="comment-likes">
                    <FaHeart color={comentario.curtido ? '#FF4444' : 'inherit'} />
                    <span>{comentario.curtidas_count}</span>
                  </div>
                </button>
                <button
                  onClick={() => setReplyingTo(replyingTo === comentario.id ? null : comentario.id)}
                  className="comment-action-button"
                >
                  <FaReply />
                  Responder
                </button>
                {comentario.usuario_id === userId && (
                  <>
                    <button
                      onClick={() => {
                        setEditingCommentId(comentario.id);
                        setEditContent(comentario.conteudo);
                      }}
                      className="comment-action-button"
                    >
                      <FaEdit />
                      Editar
                    </button>
                    <button
                      onClick={() => setShowDeleteConfirm(comentario.id)}
                      className="comment-action-button"
                    >
                      <FaTrash />
                      Excluir
                    </button>
                    {showDeleteConfirm === comentario.id && (
                      <div className="delete-confirm">
                        <p>Tem certeza que deseja excluir este comentário?</p>
                        <button
                          onClick={() => excluirComentario(comentario.id)}
                          className="confirm-button"
                        >
                          Sim
                        </button>
                        <button
                          onClick={() => setShowDeleteConfirm(null)}
                          className="cancel-button"
                        >
                          Não
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>

              {replyingTo === comentario.id && (
                <div className="reply-container">
                  <textarea
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    placeholder="Digite sua resposta..."
                    className="comments-textarea"
                  />
                  <div className="reply-actions">
                    <button
                      onClick={() => handleReply(comentario.id)}
                      className="comment-action-button"
                    >
                      Enviar
                    </button>
                    <button
                      onClick={() => {
                        setReplyingTo(null);
                        setReplyContent('');
                      }}
                      className="comment-action-button"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              )}

              {comentario.respostas_comentarios?.length > 0 && (
                <div className="comment-replies">
                  {comentario.respostas_comentarios.map((resposta) => (
                    <div key={resposta.id} className="reply-item">
                      <div className="comment-header">
                        <img
                          src={resposta.usuario_foto || '/default-avatar.png'}
                          alt="Avatar"
                          className="comments-user-avatar"
                        />
                        <div className="comment-user-info">
                          <div className="comment-username">
                            {resposta.usuario_nome}
                          </div>
                          <div className="comment-timestamp">
                            {formatTimeAgo(resposta.criado_em)}
                          </div>
                        </div>
                      </div>
                      
                      {editingCommentId === resposta.id ? (
                        <div className="comment-edit-container">
                          <textarea
                            value={editContent || resposta.conteudo}
                            onChange={(e) => setEditContent(e.target.value)}
                            className="comments-textarea"
                          />
                          <div className="comment-edit-actions">
                            <button
                              onClick={() => handleEdit(resposta.id)}
                              className="comment-action-button"
                            >
                              Salvar
                            </button>
                            <button
                              onClick={() => {
                                setEditingCommentId(null);
                                setEditContent('');
                              }}
                              className="comment-action-button"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="comment-content">{resposta.conteudo}</div>
                      )}
                      
                      <div className="comment-actions">
                        <button
                          onClick={(e) => curtirComentario(resposta.id, e)}
                          className="comment-action-button"
                        >
                          <div className="comment-likes">
                            <FaHeart
                              color={resposta.curtido ? '#FF4444' : 'inherit'}
                            />
                            <span>{resposta.curtidas_count}</span>
                          </div>
                        </button>
                        <button
                          onClick={() => setReplyingTo(replyingTo === resposta.id ? null : resposta.id)}
                          className="comment-action-button"
                        >
                          <FaReply />
                          Responder
                        </button>
                        {resposta.usuario_id === userId && (
                          <>
                            <button
                              onClick={() => {
                                setEditingCommentId(resposta.id);
                                setEditContent(resposta.conteudo);
                              }}
                              className="comment-action-button"
                            >
                              <FaEdit />
                              Editar
                            </button>
                            <button
                              onClick={() => setShowDeleteConfirm(resposta.id)}
                              className="comment-action-button"
                            >
                              <FaTrash />
                              Excluir
                            </button>
                            {showDeleteConfirm === resposta.id && (
                              <div className="delete-confirm">
                                <p>Tem certeza que deseja excluir este comentário?</p>
                                <button
                                  onClick={() => excluirComentario(resposta.id)}
                                  className="confirm-button"
                                >
                                  Sim
                                </button>
                                <button
                                  onClick={() => setShowDeleteConfirm(null)}
                                  className="cancel-button"
                                >
                                  Não
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {replyingTo === resposta.id && (
                        <div className="reply-container">
                          <textarea
                            value={replyContent}
                            onChange={(e) => setReplyContent(e.target.value)}
                            placeholder="Digite sua resposta..."
                            className="comments-textarea"
                          />
                          <div className="reply-actions">
                            <button
                              onClick={() => handleReply(resposta.id)}
                              className="comment-action-button"
                            >
                              Enviar
                            </button>
                            <button
                              onClick={() => {
                                setReplyingTo(null);
                                setReplyContent('');
                              }}
                              className="comment-action-button"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {showSuggestions && userSuggestions.length > 0 && (
        <div className="comments-mentions" ref={suggestionsRef}>
          {userSuggestions.map((user) => (
            <div
              key={user.user_id}
              className="mention-item"
              onClick={() => inserirMencao(user)}
            >
              {user.nome}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Comentarios;
