import React, { useEffect, useState } from 'react';
import { logout, isAuthenticated } from '../../auth/auth'; // Certifique-se de que o caminho para 'auth' está correto
import { useNavigate, Link } from 'react-router-dom';
import './Menu.css'; // Garanta que este arquivo CSS está no local correto
import logo from '../../imagens/1logo_menu_login.webp'; // Verifique se o logo está no caminho certo
import MenuVertical from './MenuVertical'; // MenuVertical é outro componente, então verifique se ele está corretamente configurado
import { supabase } from '../../supabaseClient'; // Certifique-se de que o Supabase está configurado corretamente
// Importando os arquivos necessários para a notificação
import welcomeSound from '../../assets/sounds/welcome.MP3';
import mensagens from '../../assets/mensagens.json';

const MenuFerramentas = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [userName, setUserName] = useState(''); // Armazena o nome do usuário (pode ser preenchido após autenticação)
  const [randomMessage, setRandomMessage] = useState(''); // Armazena mensagem aleatória de boas-vindas
  const [userEmail, setUserEmail] = useState(''); // Armazena o email do usuário logado
  const [modulos, setModulos] = useState([]); // Armazena os módulos vindos do banco de dados
  const [loading, setLoading] = useState(true); // Gerencia o estado de carregamento

  // Mensagens para boas-vindas
  const mensagens = [
    'Bem-vindo(a)! Aproveite o seu dia de aprendizado.',
    'Você nasceu para prosperar!🚀',
    'É hora de alcançar novos objetivos!'
  ];

  useEffect(() => {
    if (isAuthenticated()) {
      const email = localStorage.getItem('userEmail');
      setUserEmail(email);

      // Função para buscar módulos no banco de dados
      fetchModulosDoBanco();

      const lastLoginTime = localStorage.getItem('lastLoginTime');
      const currentTime = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000; // 10 minutos em milissegundos

      if (!lastLoginTime || currentTime - lastLoginTime > tenMinutes) {
        localStorage.setItem('lastLoginTime', currentTime);
        setShowMessage(true);
        const randomIndex = Math.floor(Math.random() * mensagens.length);
        setRandomMessage(mensagens[randomIndex] || 'Você nasceu para prosperar!🚀');
      }

      setLoading(false);
    } else {
      navigate('/login'); // Redireciona para login se o usuário não estiver autenticado
    }
  }, [navigate]);
  
  const playWelcomeSound = () => {
    const audio = new Audio(welcomeSound);
    audio.play().catch(error => console.error('Erro ao tocar o som:', error));
  };

  useEffect(() => {
    if (showMessage) {
      playWelcomeSound();
    }
  }, [showMessage]);

  const fetchModulosDoBanco = async () => {
    try {
      const { data: modulos, error } = await supabase
        .from('modulos')
        .select('id, nome, descricao, ordem, foto_modulo')
        .order('ordem', { ascending: true }); // Ordena os módulos

      if (error) {
        console.error('Erro ao buscar módulos no banco de dados:', error);
      } else {
        // Verifica se as imagens estão no localStorage e, caso contrário, salva-as
        const modulosComImagens = modulos.map(modulo => {
          const fotoModuloLocal = localStorage.getItem(`foto_modulo_${modulo.id}`);
          if (fotoModuloLocal) {
            // Se a imagem estiver no localStorage, usa a versão local
            return { ...modulo, foto_modulo: fotoModuloLocal };
          } else {
            // Se a imagem não estiver no localStorage, armazena a versão atual e depois retorna
            localStorage.setItem(`foto_modulo_${modulo.id}`, modulo.foto_modulo);
            return modulo;
          }
        });
        setModulos(modulosComImagens); // Atualiza o estado com os módulos
      }
    } catch (error) {
      console.error('Erro ao consultar módulos do banco:', error);
    }
  };

  const handleLogout = () => {
    logout(); // Chama a função de logout
    navigate('/login'); // Redireciona para a página de login após logout
  };

  const handleCloseMessage = () => {
    setShowMessage(false); // Fecha a mensagem de boas-vindas
  };

  return (
    <div className="menu-container-unique">
      <MenuVertical /> {/* Componente do menu lateral */}
      {loading ? (
        <p>Carregando...</p> // Exibe mensagem de carregamento
      ) : (
        <>
          {showMessage && (
            <div className="message-popup-unique">
              <h2>Seja muito bem-vindo(a), <span className="user-name-unique">{userName}</span>!</h2>
              <p>{randomMessage} <span className="highlight-unique">Esta mensagem é para você!</span></p>
              <button className="close-btn-unique" onClick={handleCloseMessage}>Fechar</button>
              <p className="footer-message-unique">Você nasceu para prosperar!🚀</p>
            </div>
          )}
          <div className="header-unique">
            <img src={logo} alt="Logo" className="menu-logo-unique" />
            <div className="user-info-unique">
              <span>{userName}</span>
              <button onClick={handleLogout}>Sair</button>
            </div>
          </div>

          <div className="content-unique">
            <h1 className="title-gradient-unique">Ferramentas para Prosperar no Digital</h1>
            <p className="description-unique">"Aqui você encontra todas as ferramentas para otimizar sua jornada digital."</p>

            {/* Renderiza os módulos dinamicamente */}
            <div className="menu-items-unique">
              {modulos.map((modulo, idx) => (
                <div key={modulo.id} className="menu-item-unique">
                  <img src={modulo.foto_modulo} alt={modulo.nome} className="menu-img" />
                  <Link to={`/modulo/${modulo.id}`} className="no-underline-unique">
                    <button className="btn-unique">Acesse {modulo.nome}</button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MenuFerramentas;
