import React, { useEffect } from 'react';
import './Tutorial.css'; // Alterado para o novo arquivo CSS
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import Aulas from './Aulas'; // Importando o Aulas abaixo da descrição

const Tutorial = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="tutorial-container"> {/* Classe atualizada */}
      <div className="tutorial-header"> {/* Classe atualizada */}
        <img src={logo} alt="Logo" className="tutorial-logo" /> {/* Classe atualizada */}
        <div className="tutorial-user-info"> {/* Classe atualizada */}
          <span>{userEmail}</span>
          <button className="tutorial-button" onClick={logout}>Sair</button> {/* Classe atualizada */}
        </div>
      </div>
      <div className="tutorial-content"> {/* Classe atualizada */}
        <h1 className="tutorial-title-gradient">Todos os Tutoriais para Explorar</h1> {/* Classe atualizada */}
        <p className="tutorial-description"> {/* Classe atualizada */}
          📚 <strong>Explore nossos Tutoriais e Domine a Plataforma!</strong> <br /><br />
          Nesta página, você encontrará todos os tutoriais necessários para aprender a usar cada menu, ferramenta e funcionalidade do nosso projeto. <br /><br />
          🚀 <strong>Domine todas as tecnologias e comece a prosperar com nosso suporte detalhado e fácil de seguir.</strong>
        </p>

        <Link to="/menu" className="tutorial-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Aulas renderizado abaixo do botão de voltar para o menu */}
        <Aulas /> {/* Chat atualizado */}
      </div>
    </div>
  );
};

export default Tutorial;
