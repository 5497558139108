// src/controleDePalavras.js
import { supabase } from '../supabaseClient';
import { DateTime } from 'luxon';

// Função auxiliar para tentar buscar o usuário com repetição
const buscarUsuarioComRechecagem = async (email, tentativasRestantes) => {
  const { data: usuario, error: usuarioError } = await supabase
    .from('usuarios')
    .select('*')
    .eq('email', email)
    .single();

  if (usuarioError || !usuario) {
    if (tentativasRestantes > 0) {
      console.warn(`Tentativa falhou. Tentativas restantes: ${tentativasRestantes}`);
      await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos antes de tentar novamente
      return await buscarUsuarioComRechecagem(email, tentativasRestantes - 1); // Repetir a busca
    } else {
      console.error('Erro ao buscar usuário no Supabase após várias tentativas:', usuarioError);
      return null;
    }
  }

  return usuario; // Retorna o usuário quando encontrado
};

// Função para inicializar ou verificar palavras com delay
export const inicializarPalavras = async (email) => {
  try {
    // Tentar buscar o usuário até 5 vezes
    const usuario = await buscarUsuarioComRechecagem(email, 5);

    if (!usuario) {
      return null; // Retornar null se não encontrar o usuário após todas as tentativas
    }

    // Inicialmente, nenhum limite de palavras
    let limitePalavras = 0;
    let usuarioDeveReceberPalavras = false;

    // Verificar se o usuário já possui um plano_id na tabela 'usuarios'
    if (!usuario.plano_id) {
      // Se não possui plano_id, verificar se existe um plano_id nas compras
      const { data: compras, error: compraError } = await supabase
        .from('compras')
        .select('*')
        .eq('usuario_id', usuario.user_id);

      if (compraError) {
        console.error('Erro ao buscar compras no Supabase:', compraError);
        return null;
      }

      if (compras && compras.length > 0) {
        // Filtrar compras que possuem plano_id válido
        const comprasComPlano = compras.filter(compra => compra.plano_id);

        if (comprasComPlano.length > 0) {
          // Usuário possui plano, pegar o plano_id da compra mais recente
          const ultimaCompraComPlano = comprasComPlano[comprasComPlano.length - 1];

          // Atualizar o plano_id do usuário na tabela 'usuarios'
          const { error: updatePlanoError } = await supabase
            .from('usuarios')
            .update({ plano_id: ultimaCompraComPlano.plano_id })
            .eq('user_id', usuario.user_id);

          if (updatePlanoError) {
            console.error('Erro ao atualizar plano_id do usuário:', updatePlanoError);
            return null;
          }

          // Atualizar o objeto usuario com o novo plano_id
          usuario.plano_id = ultimaCompraComPlano.plano_id;
        }
      }
    }

    // Após atualizar ou verificar o plano_id do usuário, se ele possuir um plano_id, buscamos o limite de palavras
    if (usuario.plano_id) {
      const { data: planoData, error: planoError } = await supabase
        .from('tipos_de_plano')
        .select('limite_palavras')
        .eq('id', usuario.plano_id)
        .single();

      if (planoError || !planoData) {
        console.error('Erro ao buscar plano no Supabase:', planoError);
        return null;
      }

      limitePalavras = planoData.limite_palavras;
      usuarioDeveReceberPalavras = true;
    }

    if (!usuarioDeveReceberPalavras) {
      // Usuário não deve receber palavras (não possui plano_id)
      console.log('Usuário não tem direito a palavras disponíveis.');
      return null;
    }

    // Atualizar saldo de palavras se não houver palavras disponíveis ou for a primeira vez
    if (!usuario.palavras_disponiveis || usuario.palavras_disponiveis === 0) {
      const brazilTime = DateTime.utc().setZone('America/Sao_Paulo').toISO();

      const { error: updateError } = await supabase
        .from('usuarios')
        .update({
          palavras_disponiveis: limitePalavras,
          data_renovacao_palavras: brazilTime,
        })
        .eq('user_id', usuario.user_id);

      if (updateError) {
        console.error('Erro ao atualizar palavras no Supabase:', updateError);
        return null;
      }

      // Salvando o saldo de palavras no localStorage usando a chave com o e-mail do usuário
      localStorage.setItem(`${email}-palavrasDisponiveis`, JSON.stringify(limitePalavras));
      return limitePalavras; // Retornar saldo inicial
    }

    // Salvando o saldo de palavras existente no localStorage usando a chave com o e-mail do usuário
    localStorage.setItem(`${email}-palavrasDisponiveis`, JSON.stringify(usuario.palavras_disponiveis));
    return usuario.palavras_disponiveis; // Retornar saldo existente
  } catch (error) {
    console.error('Erro ao inicializar/verificar palavras:', error.message);
    return null;
  }
};

// Função para consumir palavras
export const consumirPalavras = async (email, palavrasConsumidas) => {
  try {
    const usuario = await buscarUsuarioComRechecagem(email, 5); // Buscar usuário com rechecagem
    if (!usuario) return false;

    const novasPalavras = usuario.palavras_disponiveis - palavrasConsumidas;

    if (novasPalavras < 0) {
      console.error('Erro: Limite de palavras excedido.');
      return false;
    }

    const { error: updateError } = await supabase
      .from('usuarios')
      .update({ palavras_disponiveis: novasPalavras })
      .eq('user_id', usuario.user_id);

    if (updateError) {
      console.error('Erro ao atualizar palavras no Supabase:', updateError);
      return false;
    }

    // Salvando o saldo de palavras atualizado no localStorage usando a chave com o e-mail do usuário
    localStorage.setItem(`${email}-palavrasDisponiveis`, JSON.stringify(novasPalavras));

    return novasPalavras; // Retornar saldo atualizado
  } catch (error) {
    console.error('Erro ao consumir palavras:', error.message);
    return false;
  }
};

// Função para verificar saldo de palavras
export const verificarPalavras = async (email) => {
  try {
    const usuario = await buscarUsuarioComRechecagem(email, 5); // Buscar usuário com rechecagem
    return usuario ? usuario.palavras_disponiveis : 0;
  } catch (error) {
    console.error('Erro ao verificar palavras:', error.message);
    return null;
  }
};

// Função para atualizar saldo de palavras
export const atualizarSaldoPalavras = async (email, novoSaldo) => {
  try {
    const usuario = await buscarUsuarioComRechecagem(email, 5); // Buscar usuário com rechecagem
    if (!usuario) return null;

    const { error: updateError } = await supabase
      .from('usuarios')
      .update({ palavras_disponiveis: novoSaldo })
      .eq('user_id', usuario.user_id);

    if (updateError) {
      console.error('Erro ao atualizar saldo de palavras no Supabase:', updateError);
      return null;
    }

    // Salvando o novo saldo de palavras no localStorage usando a chave com o e-mail do usuário
    localStorage.setItem(`${email}-palavrasDisponiveis`, JSON.stringify(novoSaldo));

    return novoSaldo;
  } catch (error) {
    console.error('Erro ao atualizar saldo de palavras:', error.message);
    return null;
  }
};
