import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import Menu from './paginas/menu/Menu';
import Menuferramentas from './paginas/menu/Menuferramentas';
import Menutreinamentos from './paginas/menu/Treinamentos';
import Cadastro from './paginas/Cadastro';
import Login from './paginas/Login';
import RecuperarSenha from './paginas/RecuperarSenha';
import LucroAlvo from './paginas/lucroAlvo/LucroAlvo';
import PlanejadorProsperidade from './paginas/planejadorProsperidade/PlanejadorProsperidade';
import AnaliseMetricas from './paginas/AnaliseMetricas/AnaliseMetricas';
import EstudioCriacao from './paginas/EstudioCriacao/EstudioCriacao';
import CriadorDeTextosPersuasivos from './paginas/Criador-deTextos-Persuasivos/CriadorDeTextosPersuasivos';
import ConversaoMagica from './paginas/ConversaoMagica/ConversaoMagica';
import FunilVendasWhatsApp from './paginas/funil/funil';
import MapaDigital from './paginas/mapaDigital/MapaDigital';
import MinhaConta from './paginas/minhaConta/MinhaConta';
import Suporte from './paginas/suporte/Suporte';
import PoliticaPublicidade from './paginas/PoliticaPublicidade';
import ScriptVendasWhatsapp from './paginas/script-vendas-whatsapp/ScriptVendasWhatsapp';
import Remarketing from './paginas/Remarketing/Remarketing';
import Headlines from './paginas/Headlines/Headlines';
import ProvaSocial from './paginas/prova-social/ProvaSocial';
import Persona from './paginas/persona/Persona';
import Tutorial from './paginas/tutorial/Tutorial';
import Narrador from './paginas/narrador/Narrador';
import ModuloZero from './paginas/ModuloZero/ModuloZero';
import Modulo from './paginas/modulo/Modulo';
import AdminLogin from './paginas/admin/adminLogin';
import PainelAdmin from './paginas/admin/painelAdmin';
import AdminUsuario from './paginas/admin/adminUsuario';
import SuporteAdmin from './paginas/admin/suporteAdmin';
import Cursos from './paginas/Cursos/Cursos';
import Criadordeproduto from './paginas/criador-de-produto/Criadordeproduto';
import Criadordepromesa from './paginas/Criadordepromesa/Criadordepromesa';
import Otimizadordeoferta from './paginas/Otimizadordeoferta/Otimizadordeoferta';
import Criadordeupssel from './paginas/Criadordeupssel/Criadordeupssel';
import Criadordenomes from './paginas/Criadordenomes/Criadordenomes';
import Criadordeordembumbe from './paginas/Criadordeordembumbe/Criadordeordembumbe';
import Criadordecriativo from './paginas/Criadordecriativo/Criadordecriativo';
import Sonhos from './paginas/Sonhos/Sonhos';
import Checklistpai from './paginas/Checklist/Checklistpai';
import Capturapagina from './paginas/Captura-pagina/Capturapagina';
// Componente para verificar se é uma rota administrativa
const isAdminRoute = (pathname) => {
  const adminPaths = ['/adminLogin', '/painelAdmin', '/admin-usuarios', '/cursos', '/suporte-admin'];
  return adminPaths.some(path => pathname.startsWith(path));
};

// Componente para rotas de usuário
const UserRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/modulo/:id/*" element={<Modulo />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/ferramentas" element={<Menuferramentas />} />
        <Route path="/treinamentos" element={<Menutreinamentos />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route path="/lucro-alvo" element={<LucroAlvo />} />
        <Route path="/planejador-prosperidade" element={<PlanejadorProsperidade />} />
        <Route path="/analise-metricas" element={<AnaliseMetricas />} />
        <Route path="/estudio-criacao" element={<EstudioCriacao />} />
        <Route path="/criador-de-textos-persuasivos" element={<CriadorDeTextosPersuasivos />} />
        <Route path="/conversao-magica" element={<ConversaoMagica />} />
        <Route path="/funil-vendas-whatsapp" element={<FunilVendasWhatsApp />} />
        <Route path="/mapa-digital" element={<MapaDigital />} />
        <Route path="/minha-conta" element={<MinhaConta />} />
        <Route path="/suporte" element={<Suporte />} />
        <Route path="/script-vendas-whatsapp" element={<ScriptVendasWhatsapp />} />
        <Route path="/remarketing" element={<Remarketing />} />
        <Route path="/headlines" element={<Headlines />} />
        <Route path="/prova" element={<ProvaSocial />} />
        <Route path="/persona" element={<Persona />} />
        <Route path="/tutoriais" element={<Tutorial />} />
        <Route path="/narrador" element={<Narrador />} />
        <Route path="/modulo-zero" element={<ModuloZero />} />
        <Route path="/cursos" element={<Cursos />} />
        <Route path="/criador-de-produto" element={<Criadordeproduto />} />
        <Route path="criador-de-promessas" element={<Criadordepromesa />} />
        <Route path="otmizador-de-oferta" element={<Otimizadordeoferta />} />
        <Route path="criador-de-upsll" element={<Criadordeupssel />} />
        <Route path="criador-de-nomes" element={<Criadordenomes />} /> 
        <Route path="criador-de-ordem-bump" element={<Criadordeordembumbe />} />  
        <Route path="criador-de-criativo" element={<Criadordecriativo />} />  
      
        <Route path="Checklist" element={<Checklistpai />} /> 
        <Route path="/" element={<Login />} />
        {/* Redireciona qualquer rota de usuário não reconhecida para login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </HashRouter>
  );
};

// Componente para rotas administrativas
const AdminRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/painelAdmin" element={<PainelAdmin />} />
        <Route path="/admin-usuarios" element={<AdminUsuario />} />
        <Route path="/suporte-admin" element={<SuporteAdmin />} />
        <Route path="/cursos" element={<Cursos />} />
        {/* Redireciona qualquer rota administrativa não reconhecida para adminLogin */}
        <Route path="*" element={<Navigate to="/adminLogin" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

// Componente para rotas públicas específicas usando BrowserRouter
const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/politica-de-publicidade" element={<PoliticaPublicidade />} />
         <Route path="/Captura-pagina" element={<Capturapagina />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  // Pega a URL atual
  const currentPath = window.location.pathname;
  
  // Se for rota administrativa, renderiza AdminRoutes
  if (isAdminRoute(currentPath)) {
    return <AdminRoutes />;
  }
  
   // Se for a rota pública "Política de Publicidade", renderiza PublicRoutes
 // Verifica se é uma das rotas públicas
if (
  currentPath === "/politica-de-publicidade" ||
  currentPath === "/Captura-pagina"
) {
  return <PublicRoutes />;
}
  



 // Caso contrário, renderiza UserRoutes
  return <UserRoutes />;
}

export default App;
