// src/paginas/minhaConta/PalavrasUsuario.js
import React, { useEffect, useState } from 'react';
import { getUserEmail, getNomePlano } from '../../auth/auth';  // Funções de autenticação
import { FaUser, FaEnvelope, FaCheckCircle } from 'react-icons/fa'; // Importação dos ícones
import './PalavrasUsuario.css'; // Importação do CSS original
import { supabase } from '../../supabaseClient'; // Importação do Supabase client

const PalavrasUsuario = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState(getUserEmail());
  const [palavrasDisponiveis, setPalavrasDisponiveis] = useState(0);
  const [erro, setErro] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [plano, setPlano] = useState(getNomePlano()); // Obter nome do plano já salvo no localStorage

  useEffect(() => {
    const fetchUserData = async () => {
      if (!email) {
        setErro('Usuário não autenticado.');
        return;
      }

      // Recupera dados do localStorage para nome e foto de perfil
      const nomeLocalStorage = localStorage.getItem(`${email}-nomeUsuario`);
      const fotoPerfilLocalStorage = localStorage.getItem(`${email}-fotoPerfil`);

      // Setar os dados de nome e foto de perfil do localStorage, se disponíveis
      if (nomeLocalStorage) {
        setNome(nomeLocalStorage);
      }
      if (fotoPerfilLocalStorage) {
        setFotoPerfil(fotoPerfilLocalStorage);
      }

      // Sempre buscar 'palavras_disponiveis' do Supabase para garantir dados atualizados
      try {
        const { data, error } = await supabase
          .from('usuarios')
          .select('nome, palavras_disponiveis, foto_perfil')
          .eq('email', email)
          .single();

        if (error) {
          setErro('Erro ao buscar dados do usuário no Supabase.');
          return;
        }

        // Atualizar estados com os dados recebidos do Supabase
        if (data) {
          setNome(data.nome);
          setPalavrasDisponiveis(data.palavras_disponiveis);

          // Atualizar nome no localStorage se diferente
          if (data.nome !== nomeLocalStorage) {
            localStorage.setItem(`${email}-nomeUsuario`, data.nome);
          }

          // Verifica se há uma foto de perfil e salva no localStorage
          if (data.foto_perfil) {
            setFotoPerfil(data.foto_perfil);
            if (data.foto_perfil !== fotoPerfilLocalStorage) {
              localStorage.setItem(`${email}-fotoPerfil`, data.foto_perfil);
            }
          } else {
            setFotoPerfil(null);
            localStorage.removeItem(`${email}-fotoPerfil`);
          }
        }
      } catch (error) {
        setErro('Erro ao buscar dados do usuário.');
      }
    };

    // Chama a função quando o componente é montado
    fetchUserData();

    // Limpa o intervalo quando o componente é desmontado
    return () => {};
  }, [email]);

  // Função para calcular o tamanho da barra de progresso de acordo com o plano do usuário
  const calcularProgresso = () => {
    let limitePalavras;

    if (plano === 'Gratuito') {
      limitePalavras = 6000; // Limite para plano gratuito
    } else if (plano === 'Básico') {
      limitePalavras = 12000; // Limite para plano básico
    } else {
      limitePalavras = 100000; // Limite para plano premium (ou plano padrão)
    }

    return (palavrasDisponiveis / limitePalavras) * 100;
  };

  const progresso = calcularProgresso();

  const formatarNumero = (num) => {
    return num.toLocaleString('pt-BR');
  };

  if (erro) {
    return <div className="erro">{erro}</div>;
  }

  return (
    <div className="usuario-info">
      <div className="linha">
        {fotoPerfil ? (
          <img src={fotoPerfil} alt="Foto de Perfil" className="foto-perfil-vertical" />
        ) : (
          <FaUser className="foto-perfil" />
        )}
        <div className="nome">{nome}</div>
      </div>
      <div className="linha">
        <FaEnvelope className="icone-usuario" />
        <div className="email">{email}</div>
      </div>
      <div className="barra-palavras">
        <div
          className="progresso"
          style={{ width: `${progresso}%` }}
        ></div>
      </div>
      <div className="quantidade-palavras">
        <FaCheckCircle className="icone" />
        {formatarNumero(palavrasDisponiveis)} palavras restantes
      </div>
    </div>
  );
};

export default PalavrasUsuario;
