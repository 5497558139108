import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient'; // Certifique-se de que o caminho está correto
import axios from 'axios'; // Para o webhook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEnvelope, faCommentDots, faHeadset } from '@fortawesome/free-solid-svg-icons';
import './ChatSuporte.css'; // Use o CSS específico para ChatSuporte

// Função para obter a data e hora de São Paulo (UTC-3)
const getHorarioSaoPaulo = () => {
    const data = new Date();
    return new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'America/Sao_Paulo' // Fuso horário de São Paulo
    }).format(data);
};

const ChatSuporte = () => {
    const [categoria, setCategoria] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [interacoes, setInteracoes] = useState([]);
    const [erro, setErro] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [ticketAtual, setTicketAtual] = useState(null); // Armazena o ticket em aberto
    const [adminResponse, setAdminResponse] = useState(''); // Armazena a resposta do admin

    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId'); // O Supabase usa o userId como identificador
    const webhookUrl = process.env.REACT_APP_WEBHOOK_SUPORTE_URL; // URL do Webhook

    useEffect(() => {
        // Função para carregar as interações do ticket atual
        const carregarInteracoes = async () => {
            if (ticketAtual) {
                const { data: interacoesData, error } = await supabase
                    .from('interacoes')
                    .select('*')
                    .eq('id_ticket', ticketAtual.id_ticket);

                if (!error) {
                    setInteracoes(interacoesData);
                } else {
                    console.error('Erro ao carregar interações:', error);
                }
            }
        };

        carregarInteracoes();
    }, [ticketAtual]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErro('');
        setSucesso('');

        if (!userEmail || !categoria || !assunto || !mensagem) {
            setErro('Por favor, preencha todos os campos.');
            return;
        }

        try {
            // Verificar se há um ticket em aberto para este usuário e categoria
            const { data: tickets, error: ticketError } = await supabase
                .from('tickets')
                .select('*')
                .eq('email_usuario', userEmail)
                .eq('categoria', categoria)
                .eq('status', 'aberto');

            if (ticketError) throw ticketError;

            let ticketAtualTemp = tickets && tickets.length > 0 ? tickets[0] : null;

            // Se não houver ticket em aberto para este usuário e categoria, criar um novo
            if (!ticketAtualTemp) {
                const { data: newTicket, error: newTicketError } = await supabase
                    .from('tickets')
                    .insert([
                        {
                            user_id: userId,
                            email_usuario: userEmail,
                            assunto,
                            categoria,
                            status: 'aberto',
                            data_criacao: getHorarioSaoPaulo(), // Data e hora de São Paulo
                            ultima_atualizacao: getHorarioSaoPaulo(), // Data e hora de São Paulo
                        },
                    ])
                    .select()
                    .single();

                if (newTicketError) throw newTicketError;

                ticketAtualTemp = newTicket;
            }

            // Agora que o ticket foi criado/recuperado, certifique-se que o ID existe
            if (!ticketAtualTemp || !ticketAtualTemp.id_ticket) {
                throw new Error('Erro ao recuperar o ID do ticket.');
            }

            // Inserir na tabela de Interações associada ao ticket atual
            const { error: interacaoError } = await supabase
                .from('interacoes')
                .insert([
                    {
                        id_ticket: ticketAtualTemp.id_ticket,
                        user_id: userId,
                        email_usuario: userEmail,
                        tipo_interacao: 'usuário',
                        conteudo_mensagem: mensagem,
                        data_envio: getHorarioSaoPaulo(), // Data e hora de São Paulo
                    },
                ]);

            if (interacaoError) throw interacaoError;

            // Enviar o webhook após inserir a mensagem
            try {
                await axios.post(webhookUrl, {
                    email: userEmail,
                    assunto,
                    mensagem,
                    categoria,
                });
            } catch (webhookError) {
                console.error('Erro ao enviar o webhook:', webhookError);
            }

            setSucesso('Sua mensagem foi enviada com sucesso! Em breve nossa equipe entrará em contato.');
            setCategoria('');
            setAssunto('');
            setMensagem('');

            setTicketAtual(ticketAtualTemp); // Atualiza o ticket atual para ser usado nas interações
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
            setErro('Ocorreu um erro ao enviar sua mensagem. Tente novamente mais tarde.');
        }
    };

    const handleAdminResponse = async () => {
        if (!adminResponse) {
            setErro('A resposta do administrador não pode estar vazia.');
            return;
        }

        try {
            const { error: interacaoError } = await supabase
                .from('interacoes')
                .insert([
                    {
                        id_ticket: ticketAtual.id_ticket,
                        user_id: userId,
                        email_usuario: userEmail,
                        tipo_interacao: 'admin',
                        conteudo_mensagem: adminResponse,
                        data_envio: getHorarioSaoPaulo(), // Data e hora de São Paulo
                    },
                ]);

            if (interacaoError) throw interacaoError;

            setAdminResponse('');
            setSucesso('Resposta enviada com sucesso.');
        } catch (error) {
            console.error('Erro ao enviar resposta do admin:', error);
            setErro('Ocorreu um erro ao enviar a resposta. Tente novamente mais tarde.');
        }
    };

    const handleCloseTicket = async () => {
        try {
            const { error: closeError } = await supabase
                .from('tickets')
                .update({ status: 'fechado', ultima_atualizacao: getHorarioSaoPaulo() }) // Data e hora de São Paulo
                .eq('id_ticket', ticketAtual.id_ticket);

            if (closeError) throw closeError;

            setSucesso('Ticket fechado com sucesso.');
            setTicketAtual(null); // Reseta o ticket atual
        } catch (error) {
            console.error('Erro ao fechar ticket:', error);
            setErro('Ocorreu um erro ao fechar o ticket.');
        }
    };

    return (
        <div className="chat-suporte-container">
            <form onSubmit={handleSubmit} className="chat-suporte-form">
                <h2>
                    <FontAwesomeIcon icon={faHeadset} className="icon" /> Suporte ao Cliente
                </h2>
                <div className="chat-suporte-input-group">
                    <label htmlFor="categoria">
                        <FontAwesomeIcon icon={faCommentDots} className="icon" /> Categoria
                    </label>
                    <div className="input-with-icon">
                        <select
                            id="categoria"
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                        >
                            <option value="">Selecione uma categoria</option>
                            <option value="Problemas Técnicos">Problemas Técnicos</option>
                            <option value="Ideias de Melhorias">Ideias de Melhorias</option>
                            <option value="Outros">Outros</option>
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} className="icon-dropdown" />
                    </div>
                </div>

                <div className="chat-suporte-input-group">
                    <label htmlFor="assunto">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" /> Assunto
                    </label>
                    <div className="input-with-icon">
                        <input
                            id="assunto"
                            type="text"
                            value={assunto}
                            onChange={(e) => setAssunto(e.target.value)}
                        />
                    </div>
                </div>

                <div className="chat-suporte-input-group">
                    <label htmlFor="mensagem">
                        <FontAwesomeIcon icon={faCommentDots} className="icon" /> Mensagem
                    </label>
                    <div className="input-with-icon">
                        <textarea
                            id="mensagem"
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                        />
                    </div>
                </div>

                {erro && <p className="chat-suporte-erro">{erro}</p>}
                {sucesso && <p className="chat-suporte-sucesso">{sucesso}</p>}

                <button type="submit" className="chat-suporte-button">Enviar Mensagem</button>
            </form>
        </div>
    );
};

export default ChatSuporte;
