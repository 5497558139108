import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { FaDownload, FaCheckCircle, FaLock, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { supabase } from '../../supabaseClient';
import { logout, isAuthenticated, getUserEmail, getUserName } from '../../auth/auth';
import './VideoPlayer.css';

const VideoPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [aulas, setAulas] = useState([]);
  const [moduloNome, setModuloNome] = useState('');
  const [videoAtual, setVideoAtual] = useState(null);
  const [descricaoAula, setDescricaoAula] = useState('');
  const [linkArquivo, setLinkArquivo] = useState('');
  const [erro, setErro] = useState('');
  const [progresso, setProgresso] = useState(0);
  const [aulasConcluidas, setAulasConcluidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    nome: '',
    email: '',
    fotoPerfil: ''
  });

  // Verificar autenticação
  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!isAuthenticated()) {
          navigate('/login', { replace: true });
          return;
        }

        const email = getUserEmail();
        const nome = getUserName();
        const fotoPerfil = localStorage.getItem(`${email}-fotoPerfil`);

        setUserInfo({
          nome,
          email,
          fotoPerfil
        });
      } catch (error) {
        console.error('Erro na verificação de autenticação:', error);
        navigate('/login', { replace: true });
      }
    };

    checkAuth();
  }, [navigate]);

  // Carregar módulo e aulas
  useEffect(() => {
    const carregarDados = async () => {
      try {
        setLoading(true);
        if (!id) {
          setErro('ID do módulo não fornecido');
          return;
        }

        // Carregar módulo
        const { data: modulo, error: moduloError } = await supabase
          .from('modulos')
          .select('id, nome')
          .eq('id', id)
          .single();

        if (moduloError) {
          throw new Error('Erro ao carregar módulo: ' + moduloError.message);
        }

        if (!modulo) {
          throw new Error('Módulo não encontrado');
        }

        setModuloNome(modulo.nome);

        // Carregar aulas
        const { data: aulasData, error: aulasError } = await supabase
          .from('aulas')
          .select('*')
          .eq('modulo_id', id)
          .order('ordem', { ascending: true });

        if (aulasError) {
          throw new Error('Erro ao carregar aulas: ' + aulasError.message);
        }

        if (aulasData && aulasData.length > 0) {
          setAulas(aulasData);
          selecionarAula(aulasData[0]);
          await carregarProgresso(id);
        }
      } catch (error) {
        setErro(error.message);
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userInfo.email) {
      carregarDados();
    }
  }, [id, userInfo.email]);

  // Carregar progresso
  const carregarProgresso = async (moduloId) => {
    try {
      const { data: progressoData, error } = await supabase
        .from('usuarios_progresso')
        .select('progresso_aula')
        .eq('modulo_id', moduloId)
        .eq('usuario_email', userInfo.email)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw new Error('Erro ao carregar progresso: ' + error.message);
      }

      if (progressoData) {
        setAulasConcluidas(progressoData.progresso_aula || []);
        calcularProgresso(progressoData.progresso_aula || []);
      } else {
        // Criar novo registro de progresso
        await supabase.from('usuarios_progresso').insert({
          usuario_email: userInfo.email,
          modulo_id: moduloId,
          progresso_aula: []
        });
        setAulasConcluidas([]);
        calcularProgresso([]);
      }
    } catch (error) {
      console.error('Erro ao carregar progresso:', error);
      setErro(error.message);
    }
  };

  const selecionarAula = (aula) => {
    if (!aula) return;
    setVideoAtual(aula.link_video);
    setDescricaoAula(aula.descricao);
    setLinkArquivo(aula.link_arquivos ? JSON.parse(aula.link_arquivos)[0] : '');
  };

  const marcarComoConcluida = async (aulaId) => {
    try {
      const novasAulasConcluidas = aulasConcluidas.includes(aulaId)
        ? aulasConcluidas.filter((id) => id !== aulaId)
        : [...aulasConcluidas, aulaId];

      const { error } = await supabase
        .from('usuarios_progresso')
        .update({ progresso_aula: novasAulasConcluidas })
        .eq('modulo_id', id)
        .eq('usuario_email', userInfo.email);

      if (error) throw error;

      setAulasConcluidas(novasAulasConcluidas);
      calcularProgresso(novasAulasConcluidas);
    } catch (error) {
      console.error('Erro ao atualizar progresso:', error);
      setErro('Erro ao atualizar progresso: ' + error.message);
    }
  };

  const calcularProgresso = (aulasConcluidas) => {
    if (!aulas.length) return setProgresso(0);
    const progressoCalculado = Math.round((aulasConcluidas.length / aulas.length) * 100);
    setProgresso(Math.min(progressoCalculado, 100));
  };

  const avancarParaProximaAula = () => {
    const currentIndex = aulas.findIndex((aula) => aula.link_video === videoAtual);
    if (currentIndex < aulas.length - 1) {
      selecionarAula(aulas[currentIndex + 1]);
    }
  };

  const handleConcluirAvancar = async () => {
    const currentAula = aulas.find((aula) => aula.link_video === videoAtual);
    if (currentAula) {
      await marcarComoConcluida(currentAula.id);
      avancarParaProximaAula();
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };

  if (loading) {
    return <div className="vp-loading">Carregando...</div>;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="vp-container">
      <header className="vp-header">
        <div className="vp-header-logo">
          <img src="../../imagens/logo_menu_login.webp" alt="Logo" className="vp-logo" />
        </div>
        <div className="vp-header-user">
          <span className="vp-user-name">{userInfo.nome}</span>
          {userInfo.fotoPerfil ? (
            <img src={userInfo.fotoPerfil} alt="Perfil" className="vp-user-photo" />
          ) : (
            <FaUser className="vp-user-icon" />
          )}
          <button onClick={handleLogout} className="vp-logout-btn">
            <FaSignOutAlt />
          </button>
        </div>
      </header>

      <div className="vp-content">
        {erro ? (
          <div className="vp-error">{erro}</div>
        ) : (
          <>
            <div className="vp-main">
              <div className="vp-video-section">
                {videoAtual && (
                  <iframe
                    src={videoAtual}
                    title="Aula"
                    className="vp-video-frame"
                    allowFullScreen
                  />
                )}
                <div className="vp-video-controls">
                  <button onClick={handleConcluirAvancar} className="vp-button">
                    {aulasConcluidas.includes(
                      aulas.find((aula) => aula.link_video === videoAtual)?.id
                    )
                      ? 'Desmarcar'
                      : 'Concluir e Avançar'}
                  </button>
                </div>
              </div>

              <div className="vp-description">
                <h4>Descrição da Aula</h4>
                <p>{descricaoAula}</p>
                {linkArquivo && (
                  <a href={linkArquivo} download className="vp-download-link">
                    <FaDownload /> Material complementar
                  </a>
                )}
              </div>
            </div>

            <div className="vp-sidebar">
              <div className="vp-progress">
                <h3>Progresso do Módulo</h3>
                <div className="vp-progress-bar">
                  <div 
                    className="vp-progress-fill"
                    style={{ width: `${progresso}%` }}
                  />
                </div>
                <span>{progresso}% Concluído</span>
              </div>

              <div className="vp-aulas-lista">
                <h3>{moduloNome}</h3>
                <ul>
                  {aulas.map((aula) => (
                    <li
                      key={aula.id}
                      className={`vp-aula-item ${
                        videoAtual === aula.link_video ? 'vp-aula-atual' : ''
                      } ${aulasConcluidas.includes(aula.id) ? 'vp-aula-concluida' : ''}`}
                      onClick={() => selecionarAula(aula)}
                    >
                      {aulasConcluidas.includes(aula.id) ? (
                        <FaCheckCircle className="vp-aula-icon" />
                      ) : (
                        <FaLock className="vp-aula-icon" />
                      )}
                      <span>{aula.nome}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;