import React, { useState, useEffect } from 'react';
import { 
  Info, X, Save, RefreshCw, Copy, FileDown, History, Edit, Trash2, Repeat 
} from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, getUserEmail } from '../../auth/auth';
import { consumirPalavras } from '../../auth/palavraControle';

const RedLineGenerator = () => {
  const navigate = useNavigate();
  const userEmail = getUserEmail();
  const userName = localStorage.getItem('userName') || 'Usuário';

  const [config, setConfig] = useState({
    productName: '',
    productDescription: '',
    targetAudience: '',
    advertisingObjective: 'conversao-site',
    communicationTone: 'inspiracional',
    headlines: [],
    impactScores: [],
    aggressivenessLevel: 5,
    headlineLength: 5,
    headlineQuantity: 10,
    toneMixing: false,
    productHighlights: ['', '', ''],
    toneDistribution: {
      inspiracional: 2,
      curioso: 2,
      factual: 2,
      urgente: 2,
      aspiracional: 2,
    },
    followFacebookPolicy: true,
    usePsychologicalTriggers: false,
    psychologicalTriggers: {
      escassez: false,
      autoridade: false,
      curiosidade: false,
      transformacao: false,
      beneficioDireto: false,
      provaSocial: false,
    },
    audience: {
      minAge: 18,
      maxAge: 65,
      genders: [], // 'homem', 'mulher', 'ambos'
    },
  });

  const [showExplanations, setShowExplanations] = useState({
    objective: false,
    tone: false,
    mixDescription: false,
    duration: false,
    aggressiveness: false,
    psychologicalTriggers: false,
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [threads, setThreads] = useState([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [editingHeadlineIndex, setEditingHeadlineIndex] = useState(null);
  const [editedHeadlineText, setEditedHeadlineText] = useState('');
  const [isCustomizeToneVisible, setIsCustomizeToneVisible] = useState(false);
  const [chatBloqueado, setChatBloqueado] = useState(false);
  const [regeneratingIndex, setRegeneratingIndex] = useState(null);

  // Carregar threads e saldo de palavras do localStorage ao montar o componente
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }

    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`);
    if (storedWords && parseInt(storedWords, 10) <= 0) {
      setChatBloqueado(true);
    } else {
      setChatBloqueado(false);
    }

    const storedThreads = localStorage.getItem(`threads_${userEmail}`);
    if (storedThreads) {
      setThreads(JSON.parse(storedThreads));
    }
  }, [navigate, userEmail]);

  // Atualizar threads no localStorage sempre que elas mudarem
  useEffect(() => {
    localStorage.setItem(`threads_${userEmail}`, JSON.stringify(threads));
  }, [threads, userEmail]);

  // Funções de controle de palavras
  const contarTokensPorPalavra = (palavra) => {
    const separadores = /[-_]/g;
    const subPalavras = palavra.split(separadores).filter(sub => sub.length > 0).length;

    const palavraLimpa = palavra.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
    const length = palavraLimpa.length;

    let palavrasConsumidas = 0;
    if (length <= 3) palavrasConsumidas = 2;
    else if (length <= 6) palavrasConsumidas = 4;
    else if (length <= 9) palavrasConsumidas = 6;
    else palavrasConsumidas = 8;

    palavrasConsumidas *= subPalavras;

    const containsNonASCII = /[^\x00-\x7F]/.test(palavraLimpa);
    if (containsNonASCII) {
      palavrasConsumidas = Math.ceil(palavrasConsumidas * 1.5);
    }

    return palavrasConsumidas;
  };

  const calcularTokensUsados = (message) => {
    const tokens = message.match(/[\w'-]+|[.,!?;:]/g) || [];
    return tokens.reduce((total, token) => {
      if (/^[.,!?;:]$/.test(token)) {
        return total + 1;
      }
      return total + contarTokensPorPalavra(token);
    }, 0);
  };

  const verificarSaldoLocalStorage = () => {
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`);
    if (storedWords && parseInt(storedWords, 10) <= 0) {
      setChatBloqueado(true);
      return false;
    }
    return true;
  };

  // Função para enviar mensagem ao assistente da OpenAI
  const sendMessageToAssistant = async (message) => {
    const assistantId = process.env.REACT_APP_OPENAI_ASSISTANT_HEADILINES_ID;

    // Sempre inicia uma nova thread para garantir interações independentes
    const threadResponse = await axios.post(
      'https://api.openai.com/v1/threads',
      {},
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );
    const threadId = threadResponse.data.id;

    await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        role: 'user',
        content: message
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );

    const runResponse = await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/runs`,
      {
        assistant_id: assistantId
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );

    let runStatus = 'queued';
    while (runStatus !== 'completed') {
      await new Promise(resolve => setTimeout(resolve, 2000));
      const statusResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runResponse.data.id}`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );
      runStatus = statusResponse.data.status;
    }

    const messagesResponse = await axios.get(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );

    const assistantMessages = messagesResponse.data.data.filter(
      (msg) => msg.role === 'assistant'
    );

    if (assistantMessages.length > 0) {
      const assistantMessage = assistantMessages[assistantMessages.length - 1];
      const assistantText = assistantMessage.content;

      if (Array.isArray(assistantText)) {
        // Para mensagens do assistente que são arrays (por exemplo, conteúdo é um array de objetos)
        const text = assistantText
          .map((content) => content.text?.value || '')
          .join(' ');
        return text;
      } else {
        // Para mensagens do assistente que são strings
        return assistantText;
      }
    } else {
      throw new Error('Nenhuma resposta do assistente');
    }
  };

  // Função para parsear a resposta do assistente
  const parseAssistantResponse = (response) => {
    const lines = response.split('\n').filter(line => line.trim() !== '');
    const headlines = lines.map(line => {
      const parts = line.split('|');
      const headlineText = parts[0].replace(/^\d+\.\s*/, '').trim();
      const percentage = parts[1] ? parseInt(parts[1].trim().replace('%', ''), 10) : 0;
      return { text: headlineText, impact: percentage };
    }).filter(headline => headline.text !== '' && !isNaN(headline.impact));
    return headlines;
  };

  // Função para gerar o prompt a ser enviado ao assistente
  const generatePrompt = () => {
    let message = `
Por favor, gere ${config.headlineQuantity} headlines para o seguinte produto:

Nome do Produto: ${config.productName}

Descrição do Produto: ${config.productDescription}
    `;

    // Incluindo os Destaques do Produto
    if (config.productHighlights.some(h => h.trim() !== '')) {
      message += `\nDestaques do Produto:`;
      config.productHighlights.filter(h => h.trim() !== '').forEach(highlight => {
        message += `\n- ${highlight}`;
      });
    }

    // Incluindo o público-alvo
    if (config.audience.genders.length > 0 || config.audience.minAge !== 18 || config.audience.maxAge !== 65) {
      message += `\nPúblico Alvo: `;
      if (config.audience.genders.length > 0) {
        message += `Gênero(s): ${config.audience.genders.join(', ')}; `;
      }
      if (config.audience.minAge !== 18 || config.audience.maxAge !== 65) {
        message += `Idade: de ${config.audience.minAge} até ${config.audience.maxAge} anos`;
      }
    }

    // Incluindo os gatilhos psicológicos
    if (config.usePsychologicalTriggers) {
      const selectedTriggers = Object.entries(config.psychologicalTriggers)
        .filter(([key, value]) => value)
        .map(([key]) => key);

      if (selectedTriggers.length > 0) {
        message += `\nGatilhos Psicológicos a serem utilizados: ${selectedTriggers.join(', ')}`;
      } else {
        message += `\nGatilhos Psicológicos a serem utilizados: todos`;
      }
    }

    // Incluindo o objetivo do anúncio com descrição
    const objectiveDescriptions = {
      'conversao-site': 'Conversão (Site) - Direcionar clientes para comprar no site.',
      'conversao-whatsapp': 'Conversão (WhatsApp) - Levar clientes para iniciar uma conversa no WhatsApp.',
      'engajamento-whatsapp': 'Engajamento (WhatsApp) - Estimular curtidas, comentários ou mensagens no WhatsApp.'
    };

    message += `\nObjetivo do Anúncio: ${objectiveDescriptions[config.advertisingObjective]}`;

    // Se a mesclagem de tons estiver desativada, incluir o tom individual com descrição
    if (!config.toneMixing) {
      const toneDescriptions = {
        'inspiracional': 'Inspiracional - Motivador e positivo.',
        'curioso': 'Curioso - Instiga o público a descobrir algo novo.',
        'factual': 'Factual - Informações claras e diretas.',
        'urgente': 'Urgente - Dá um senso de tempo limitado.',
        'aspiracional': 'Aspiracional - Apela para sonhos e objetivos.'
      };

      message += `\nTom da Comunicação: ${toneDescriptions[config.communicationTone]}`;
    } else {
      // Se a mesclagem de tons estiver ativada, incluir a distribuição com descrições
      message += `\nMesclagem de Tons Ativada: Sim`;
      message += `\nDistribuição de Tons:`;
      Object.entries(config.toneDistribution).forEach(([tone, count]) => {
        message += `\n- ${tone}: ${count}`;
      });
    }

    // Incluindo outros parâmetros com explicações
    message += `
Nível de Agressividade: ${config.aggressivenessLevel} (onde 1 é suave e informativo, e 10 é muito direto e provocador)

Duração da Headline: ${config.headlineLength} (onde 1 é curta e objetiva, e 10 é mais detalhada)

Seguir Políticas do Facebook: ${config.followFacebookPolicy ? 'Sim' : 'Não'}

Formato da resposta: "Headline | Porcentagem%"
    `;

    return message;
  };

  // Função para gerar headlines
  const generateHeadlines = async () => {
    // Validação de campos obrigatórios
    if (!config.productName.trim() || !config.productDescription.trim()) {
      toast.error('Por favor, preencha os campos obrigatórios.');
      return;
    }

    if (isGenerating || chatBloqueado) {
      return; // Impede múltiplos cliques enquanto está gerando ou quando bloqueado
    }

    setIsGenerating(true);

    // Limpar as headlines anteriores
    setConfig(prev => ({ ...prev, headlines: [] }));

    // Prepare a mensagem para enviar
    const message = generatePrompt();

    // Calcular palavras consumidas
    const palavrasConsumidas = calcularTokensUsados(message);

    if (!verificarSaldoLocalStorage()) {
      toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      setIsGenerating(false);
      return;
    }

    let saldoAtual = parseInt(localStorage.getItem(`${userEmail}-palavrasDisponiveis`) || '0', 10);

    if (saldoAtual < palavrasConsumidas) {
      toast.error('Você não tem palavras disponíveis suficientes para enviar essa mensagem.');
      setIsGenerating(false);
      return;
    }

    saldoAtual -= palavrasConsumidas;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual.toString());

    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    await consumirPalavras(userEmail, palavrasConsumidas);

    try {
      const assistantResponse = await sendMessageToAssistant(message);

      // Parsear a resposta do assistente
      const headlinesArray = parseAssistantResponse(assistantResponse).slice(0, config.headlineQuantity);

      setConfig(prev => ({
        ...prev,
        headlines: headlinesArray,
      }));

      // Salvar no histórico
      setThreads(prev => {
        const updatedThreads = [...prev];
        const newThread = { id: Date.now(), headlines: headlinesArray };
        updatedThreads.push(newThread);
        return updatedThreads;
      });

      // Calcular palavras consumidas pela resposta do assistente
      const palavrasConsumidasResposta = calcularTokensUsados(assistantResponse);
      saldoAtual -= palavrasConsumidasResposta;
      localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual.toString());

      if (saldoAtual <= 0) {
        setChatBloqueado(true);
        toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      }

      await consumirPalavras(userEmail, palavrasConsumidasResposta);

      toast.success('Headlines geradas com sucesso!');
    } catch (error) {
      console.error('Erro ao comunicar com o assistente:', error.response ? error.response.data : error.message);
      toast.error('Erro ao processar a solicitação.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Função para regenerar uma headline específica
  const regenerateHeadline = async (index) => {
    if (!config.productName.trim() || !config.productDescription.trim()) {
      toast.error('Por favor, preencha os campos obrigatórios.');
      return;
    }

    setRegeneratingIndex(index); // Indica qual headline está sendo regenerada

    // Prepare a mensagem para enviar
    const message = `
Por favor, gere uma nova versão da seguinte headline para o produto:

Nome do Produto: ${config.productName}

Descrição do Produto: ${config.productDescription}

Headline Original: ${config.headlines[index].text}

Mantenha o mesmo formato de resposta: "Headline | Porcentagem%"
    `;

    // Calcular palavras consumidas
    const palavrasConsumidas = calcularTokensUsados(message);

    if (!verificarSaldoLocalStorage()) {
      toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      setRegeneratingIndex(null);
      return;
    }

    let saldoAtual = parseInt(localStorage.getItem(`${userEmail}-palavrasDisponiveis`) || '0', 10);

    if (saldoAtual < palavrasConsumidas) {
      toast.error('Você não tem palavras disponíveis suficientes para enviar essa mensagem.');
      setRegeneratingIndex(null);
      return;
    }

    saldoAtual -= palavrasConsumidas;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual.toString());

    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    await consumirPalavras(userEmail, palavrasConsumidas);

    try {
      const assistantResponse = await sendMessageToAssistant(message);

      // Obter a nova headline
      const newHeadlines = parseAssistantResponse(assistantResponse).slice(0, 1); // Pega a primeira nova headline

      if (newHeadlines.length === 0) {
        throw new Error('Nenhuma headline foi retornada pelo assistente.');
      }

      const newHeadline = newHeadlines[0];

      // Atualiza a headline específica
      const updatedHeadlines = [...config.headlines];
      updatedHeadlines[index] = newHeadline;
      setConfig(prev => ({ ...prev, headlines: updatedHeadlines }));

      // Atualiza no histórico
      setThreads(prev => {
        const updatedThreads = [...prev];
        const currentThread = updatedThreads[updatedThreads.length - 1];
        if (currentThread) {
          currentThread.headlines[index] = newHeadline;
        }
        return updatedThreads;
      });

      // Calcular palavras consumidas pela resposta do assistente
      const palavrasConsumidasResposta = calcularTokensUsados(assistantResponse);
      saldoAtual -= palavrasConsumidasResposta;
      localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual.toString());

      if (saldoAtual <= 0) {
        setChatBloqueado(true);
        toast.error('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      }

      await consumirPalavras(userEmail, palavrasConsumidasResposta);

      toast.success('Headline regenerada com sucesso!');
    } catch (error) {
      console.error('Erro ao comunicar com o assistente:', error.response ? error.response.data : error.message);
      toast.error('Erro ao processar a solicitação.');
    } finally {
      setRegeneratingIndex(null);
    }
  };

  // Função para copiar headline
  const copyHeadline = (headline) => {
    navigator.clipboard.writeText(headline);
    toast.info('Headline copiada!');
  };

  // Função para exportar headlines
  const exportHeadlines = (threadToExport = null) => {
    const thread = threadToExport || threads[threads.length - 1];
    if (!thread) return;

    const headlinesText = thread.headlines.map(h => `${h.text} | ${h.impact}%`).join('\n\n');
    const blob = new Blob([headlinesText], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `headlines_${new Date(thread.id).toISOString()}.txt`;
    link.click();
  };

  // Função para excluir uma thread específica
  const deleteThread = (threadId) => {
    const updatedThreads = threads.filter(thread => thread.id !== threadId);
    setThreads(updatedThreads);
    // Atualizar no localStorage
    localStorage.setItem(`threads_${userEmail}`, JSON.stringify(updatedThreads));
    toast.info('Histórico excluído com sucesso!');
  };

  // Função para limpar as headlines atuais
  const clearHeadlines = () => {
    setConfig(prev => ({ ...prev, headlines: [] }));
    toast.info('Headlines limpas.');
  };

  // Componente de Item do Histórico
  const HistoryItem = ({ thread }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="border-b pb-4">
        <div 
          className="flex justify-between items-center mb-2 cursor-pointer bg-gray-100 px-4 py-2 rounded hover:bg-gray-200 transition" 
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className="font-semibold text-gray-800">{new Date(thread.id).toLocaleString()}</span>
          <div className="flex space-x-2">
            <button onClick={(e) => { e.stopPropagation(); deleteThread(thread.id); }} className="text-red-500 hover:text-red-700">
              <Trash2 size={20} />
            </button>
            <button onClick={(e) => { e.stopPropagation(); exportHeadlines(thread); }} className="text-blue-500 hover:text-blue-700">
              <FileDown size={20} />
            </button>
          </div>
        </div>
        {isExpanded && (
          <ul className="list-disc list-inside space-y-1 max-h-48 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {thread.headlines.map((headline, i) => (
              <li key={i} className="flex justify-between items-center px-4 py-1">
                <span className="text-gray-700">{headline.text}</span>
                <div className="flex space-x-2 items-center">
                  <span className="text-sm text-gray-600">Impacto: {headline.impact}%</span>
                  <button onClick={() => copyHeadline(headline.text)} className="text-blue-500 hover:text-blue-700">
                    <Copy size={16} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="tailwind-scope min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 p-4 relative">
      {/* Toast Container para notificações */}
      <ToastContainer />

      {/* Mensagem fixa quando saldo de palavras chega a zero */}
      {chatBloqueado && (
        <div className="absolute top-0 left-0 w-full bg-red-600 text-white text-center py-2">
          Seu saldo de palavras acabou! Aguarde a próxima renovação.
        </div>
      )}

      {/* Cabeçalho Atraente */}
      <header className="mb-6 bg-blue-600 rounded-lg p-6 shadow-lg">
        <h1 className="text-5xl font-extrabold text-white text-center">
          Gerador de Headlines
        </h1>
      </header>

      {/* Botão de Histórico */}
      <div className="flex justify-end mb-4 relative">
        <button
          onClick={() => setIsHistoryOpen(!isHistoryOpen)}
          className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300"
        >
          <History size={20} className="mr-2" />
          Histórico
        </button>

        {/* Dropdown de Histórico */}
        {isHistoryOpen && (
          <div className="absolute right-0 mt-2 w-full max-w-3xl bg-white rounded-lg shadow-lg overflow-auto z-20 max-h-[70vh] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Histórico de Headlines</h3>
                <button onClick={() => setIsHistoryOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
              {threads.length === 0 ? (
                <div className="text-center text-gray-500 py-10">
                  Nenhum histórico disponível.
                </div>
              ) : (
                <div className="space-y-4">
                  {/* Exibir histórico */}
                  {threads
                    .slice()
                    .reverse()
                    .map((thread, historyIndex) => (
                      <HistoryItem 
                        key={thread.id} 
                        thread={thread} 
                      />
                    ))}
                  <button
                    onClick={() => {
                      setThreads([]);
                      localStorage.removeItem(`threads_${userEmail}`);
                      toast.success('Todo o histórico foi excluído!');
                    }}
                    className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition duration-300 flex items-center justify-center space-x-2"
                  >
                    <Trash2 size={20} />
                    <span>Excluir Todo Histórico</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Conteúdo Principal */}
      <div className="max-w-full mx-auto">
        <div className="flex flex-col lg:flex-row gap-6 animate__animated animate__fadeIn">
          {/* Painel de Configuração */}
          <div className="flex-1 bg-white rounded-lg shadow-md p-6 space-y-6">
            {/* Nome do Produto */}
            <div>
              <label className="block text-gray-700 font-bold mb-2">
                Nome do Produto <span className="text-red-500">*</span>
              </label>
              <input 
                type="text" 
                value={config.productName}
                onChange={(e) => setConfig(prev => ({...prev, productName: e.target.value}))}
                placeholder="Ex: Escova Multifuncional 5 em 1"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition text-gray-800 shadow"
              />
            </div>

            {/* Descrição do Produto */}
            <div>
              <label className="block text-gray-700 font-bold mb-2">
                Descrição do Produto <span className="text-red-500">*</span>
              </label>
              <textarea 
                value={config.productDescription}
                onChange={(e) => setConfig(prev => ({...prev, productDescription: e.target.value}))}
                placeholder="Ex: Descreva os benefícios da escova e o público que deseja atingir."
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition text-gray-800 shadow"
              />
            </div>

            {/* Público Alvo */}
            <div>
              <h3 className="text-lg font-semibold mb-2">Público Alvo</h3>

              {/* Seleção de Gênero */}
              <div>
                <label className="block text-gray-700 font-bold mb-2">
                  Gênero
                </label>
                <div className="flex flex-wrap gap-2">
                  {[
                    { key: 'homem', label: 'Homem' },
                    { key: 'mulher', label: 'Mulher' },
                    { key: 'ambos', label: 'Ambos' }
                  ].map(genderOption => (
                    <button
                      key={genderOption.key}
                      className={classNames(
                        'px-4 py-2 rounded-lg text-sm shadow transition duration-300',
                        config.audience.genders.includes(genderOption.key)
                          ? 'bg-blue-500 text-white'
                          : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                      )}
                      onClick={() => {
                        let newGenders = [...config.audience.genders];
                        if (newGenders.includes(genderOption.key)) {
                          newGenders = newGenders.filter(g => g !== genderOption.key);
                        } else {
                          newGenders.push(genderOption.key);
                        }
                        setConfig(prev => ({
                          ...prev,
                          audience: {
                            ...prev.audience,
                            genders: newGenders
                          }
                        }));
                      }}
                    >
                      {genderOption.label}
                    </button>
                  ))}
                </div>
              </div>

              {/* Seleção de Idade */}
              <div className="mt-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Idade
                </label>
                <div className="flex space-x-4">
                  {/* Idade Mínima */}
                  <div>
                    <label className="block text-black mb-1">Mínima</label> {/* Alterado para text-black */}
                    <div className="border rounded-lg">
                      <select
                        value={config.audience.minAge}
                        onChange={(e) => {
                          let minAge = parseInt(e.target.value);
                          if (minAge > config.audience.maxAge) {
                            minAge = config.audience.maxAge;
                          }
                          setConfig(prev => ({
                            ...prev,
                            audience: {
                              ...prev.audience,
                              minAge: minAge
                            }
                          }));
                        }}
                        className="w-full px-2 py-1 text-black"
                      >
                        {Array.from({ length: 48 }, (_, i) => 18 + i).map(age => (
                          <option key={age} value={age}>{age}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Idade Máxima */}
                  <div>
                    <label className="block text-black mb-1">Máxima</label> {/* Alterado para text-black */}
                    <div className="border rounded-lg">
                      <select
                        value={config.audience.maxAge}
                        onChange={(e) => {
                          let maxAge = parseInt(e.target.value);
                          if (maxAge < config.audience.minAge) {
                            maxAge = config.audience.minAge;
                          }
                          setConfig(prev => ({
                            ...prev,
                            audience: {
                              ...prev.audience,
                              maxAge: maxAge
                            }
                          }));
                        }}
                        className="w-full px-2 py-1 text-black"
                      >
                        {Array.from({ length: 48 }, (_, i) => 18 + i).map(age => (
                          <option key={age} value={age}>{age}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Objetivo do Anúncio */}
            <div className="relative">
              <label className="block text-gray-700 font-bold mb-2">
                Objetivo do Anúncio
                <button 
                  className="ml-2 text-blue-500 relative"
                  onMouseEnter={() => setShowExplanations(prev => ({...prev, objective: true}))}
                  onMouseLeave={() => setShowExplanations(prev => ({...prev, objective: false}))}
                >
                  <Info size={16} />
                  {showExplanations.objective && (
                    <div className="absolute z-10 bg-gray-800 text-white text-sm p-2 rounded shadow-lg mt-2 left-0 w-64">
                      <p><strong>Conversão (Site):</strong> Direcione clientes para comprar no site.</p>
                      <p><strong>Conversão (WhatsApp):</strong> Leve clientes para iniciar uma conversa no WhatsApp.</p>
                      <p><strong>Engajamento (WhatsApp):</strong> Estimule curtidas, comentários ou mensagens no WhatsApp.</p>
                    </div>
                  )}
                </button>
              </label>
              <div className="flex flex-wrap gap-2">
                {[
                  { key: 'conversao-site', label: 'Conversão (Site)' },
                  { key: 'conversao-whatsapp', label: 'Conversão (WhatsApp)' },
                  { key: 'engajamento-whatsapp', label: 'Engajamento (WhatsApp)' }
                ].map(obj => (
                  <button 
                    key={obj.key}
                    className={classNames(
                      'px-4 py-2 rounded-lg text-sm shadow transition duration-300',
                      config.advertisingObjective === obj.key 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                    )}
                    onClick={() => setConfig(prev => ({...prev, advertisingObjective: obj.key}))}
                  >
                    {obj.label}
                  </button>
                ))}
              </div>
            </div>

            {/* Tom da Comunicação */}
            <div className="relative">
              <label className="block text-gray-700 font-bold mb-2">
                Tom da Comunicação
              </label>
              <div className="flex flex-wrap gap-2">
                {[
                  { key: 'inspiracional', label: 'Inspiracional', description: 'Motivador e positivo.' },
                  { key: 'curioso', label: 'Curioso', description: 'Instiga o público a descobrir algo novo.' },
                  { key: 'factual', label: 'Factual', description: 'Informações claras e diretas.' },
                  { key: 'urgente', label: 'Urgente', description: 'Dá um senso de tempo limitado.' },
                  { key: 'aspiracional', label: 'Aspiracional', description: 'Apela para sonhos e objetivos.' },
                ].map(tone => (
                  <div key={tone.key} className="relative group">
                    <button 
                      className={classNames(
                        'px-4 py-2 rounded-lg text-sm shadow transition duration-300',
                        config.communicationTone === tone.key 
                          ? 'bg-blue-500 text-white' 
                          : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                      )}
                      onClick={() => setConfig(prev => ({...prev, communicationTone: tone.key}))}
                    >
                      {tone.label}
                    </button>
                    {/* Tooltip individual */}
                    <div className="absolute z-10 bg-gray-800 text-white text-xs rounded py-1 px-2 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none left-0 w-48">
                      {tone.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Nível de Agressividade */}
            <div className="relative">
              <label className="block text-gray-700 font-bold mb-2">
                Nível de Agressividade: {config.aggressivenessLevel}
                <button 
                  className="ml-2 text-blue-500 relative"
                  onMouseEnter={() => setShowExplanations(prev => ({...prev, aggressiveness: true}))}
                  onMouseLeave={() => setShowExplanations(prev => ({...prev, aggressiveness: false}))}
                >
                  <Info size={16} />
                  {showExplanations.aggressiveness && (
                    <div className="absolute z-10 bg-gray-800 text-white text-sm p-2 rounded shadow-lg mt-2 left-0 w-64">
                      <p><strong>Nível de Agressividade:</strong> Define o quão direto e provocador será o tom da headline.</p>
                      <p><strong>1:</strong> Suave e informativo.</p>
                      <p><strong>10:</strong> Muito direto e provocador.</p>
                    </div>
                  )}
                </button>
              </label>
              <input
                type="range"
                min="1"
                max="10"
                value={config.aggressivenessLevel}
                onChange={(e) => setConfig(prev => ({...prev, aggressivenessLevel: Number(e.target.value)}))}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-600">
                <span>1 - Suave e informativo</span>
                <span>10 - Muito direto e provocador</span>
              </div>
            </div>

            {/* Duração da Headline */}
            <div className="relative">
              <label className="block text-gray-700 font-bold mb-2">
                Duração da Headline: {config.headlineLength}
                <button 
                  className="ml-2 text-blue-500 relative"
                  onMouseEnter={() => setShowExplanations(prev => ({...prev, duration: true}))}
                  onMouseLeave={() => setShowExplanations(prev => ({...prev, duration: false}))}
                >
                  <Info size={16} />
                  {showExplanations.duration && (
                    <div className="absolute z-10 bg-gray-800 text-white text-sm p-2 rounded shadow-lg mt-2 left-0 w-64">
                      <p><strong>Duração da Headline:</strong> Define o comprimento das headlines geradas.</p>
                      <p><strong>1:</strong> Curta e objetiva (Ex: "Aproveite a oferta!").</p>
                      <p><strong>10:</strong> Mais detalhada (Ex: "Descubra como economizar tempo e dinheiro com nosso novo produto!").</p>
                    </div>
                  )}
                </button>
              </label>
              <input
                type="range"
                min="1"
                max="10"
                value={config.headlineLength}
                onChange={(e) => setConfig(prev => ({...prev, headlineLength: Number(e.target.value)}))}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-600">
                <span>1 - Curta e objetiva</span>
                <span>10 - Mais detalhada</span>
              </div>
            </div>

            {/* Quantidade de Headlines */}
            <div>
              <label className="block text-gray-700 font-bold mb-2">Quantidade de Headlines</label>
              <div className="flex flex-wrap gap-2">
                {[5, 10, 15].map(quantity => (
                  <button 
                    key={quantity}
                    className={classNames(
                      'px-4 py-2 rounded-lg text-sm shadow transition duration-300',
                      config.headlineQuantity === quantity 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                    )}
                    onClick={() => {
                      setConfig(prev => {
                        const newHeadlineQuantity = quantity;
                        // Ajustar a distribuição de tons se necessário
                        const totalToneDistribution = Object.values(prev.toneDistribution).reduce((sum, val) => sum + val, 0);
                        let newToneDistribution = { ...prev.toneDistribution };
                        if (totalToneDistribution > newHeadlineQuantity) {
                          // Reduzir a distribuição proporcionalmente
                          const factor = newHeadlineQuantity / totalToneDistribution;
                          Object.keys(newToneDistribution).forEach(tone => {
                            newToneDistribution[tone] = Math.floor(newToneDistribution[tone] * factor);
                          });
                        }
                        return {
                          ...prev,
                          headlineQuantity: newHeadlineQuantity,
                          toneDistribution: newToneDistribution,
                        };
                      });
                    }}
                  >
                    {quantity}
                  </button>
                ))}
              </div>
            </div>

            {/* Mesclagem de Tons */}
            <div className="flex items-center relative">
              <input
                type="checkbox"
                id="toneMixing"
                checked={config.toneMixing}
                onChange={(e) => setConfig(prev => ({...prev, toneMixing: e.target.checked}))}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition"
              />
              <label htmlFor="toneMixing" className="ml-2 block text-gray-700 font-bold">
                Mesclar Tons Automaticamente
              </label>
              <button 
                className="ml-2 text-blue-500 relative"
                onMouseEnter={() => setShowExplanations(prev => ({...prev, mixDescription: true}))}
                onMouseLeave={() => setShowExplanations(prev => ({...prev, mixDescription: false}))}
              >
                <Info size={16} />
                {showExplanations.mixDescription && (
                  <div className="absolute z-10 bg-gray-800 text-white text-sm p-2 rounded shadow-lg mt-2 left-0 w-64">
                    <p>Ao ativar, o sistema irá gerar headlines com diferentes estilos, combinando tom inspiracional, curioso, factual, urgente e aspiracional, de forma balanceada.</p>
                  </div>
                )}
              </button>
            </div>

            {/* Botão para Personalizar Mesclagem */}
            {config.toneMixing && (
              <div>
                <button
                  onClick={() => setIsCustomizeToneVisible(!isCustomizeToneVisible)}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300 mt-2 shadow"
                >
                  Personalizar Mesclagem
                </button>

                {/* Campos de Personalização de Mesclagem */}
                {isCustomizeToneVisible && (
                  <div className="mt-4 p-4 bg-blue-200 text-gray-800 rounded-lg shadow-inner">
                    <h3 className="text-xl font-semibold mb-4">Personalizar Mesclagem de Tons</h3>
                    <div className="space-y-4">
                      {Object.keys(config.toneDistribution).map(tone => {
                        const totalToneDistribution = Object.values(config.toneDistribution).reduce((sum, val) => sum + val, 0);
                        const maxAllowed = config.headlineQuantity - (totalToneDistribution - config.toneDistribution[tone]);
                        return (
                          <div key={tone} className="flex justify-between items-center">
                            <span className="capitalize text-gray-700">{tone}:</span>
                            <input
                              type="number"
                              min="0"
                              max={maxAllowed}
                              value={config.toneDistribution[tone]}
                              onChange={(e) => {
                                const newValue = Number(e.target.value);
                                if (newValue >= 0 && newValue <= config.headlineQuantity) {
                                  const newDistribution = { ...config.toneDistribution, [tone]: newValue };
                                  const total = Object.values(newDistribution).reduce((sum, val) => sum + val, 0);
                                  if (total <= config.headlineQuantity) {
                                    setConfig(prev => ({ ...prev, toneDistribution: newDistribution }));
                                  } else {
                                    toast.error(`A soma dos tons não pode exceder ${config.headlineQuantity}`);
                                  }
                                }
                              }}
                              className="w-16 px-2 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition text-gray-800"
                            />
                          </div>
                        );
                      })}
                      <div className="mt-2 text-gray-700">
                        Total de Tons Distribuídos: {Object.values(config.toneDistribution).reduce((sum, val) => sum + val, 0)} / {config.headlineQuantity}
                      </div>
                      <button
                        onClick={() => setIsCustomizeToneVisible(false)}
                        className={classNames(
                          'w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300 mt-4 shadow',
                          Object.values(config.toneDistribution).reduce((sum, val) => sum + val, 0) > config.headlineQuantity && 'opacity-50 cursor-not-allowed'
                        )}
                        disabled={Object.values(config.toneDistribution).reduce((sum, val) => sum + val, 0) > config.headlineQuantity}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Ativar Gatilhos Psicológicos */}
            <div className="flex items-center mt-4 relative">
              <input
                type="checkbox"
                id="usePsychologicalTriggers"
                checked={config.usePsychologicalTriggers}
                onChange={(e) => setConfig(prev => ({...prev, usePsychologicalTriggers: e.target.checked}))}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition"
              />
              <label htmlFor="usePsychologicalTriggers" className="ml-2 block text-gray-700 font-bold">
                Ativar Gatilhos Psicológicos
              </label>
              <button
                className="ml-2 text-blue-500 relative"
                onMouseEnter={() => setShowExplanations(prev => ({...prev, psychologicalTriggers: true}))}
                onMouseLeave={() => setShowExplanations(prev => ({...prev, psychologicalTriggers: false}))}
              >
                <Info size={16} />
                {showExplanations.psychologicalTriggers && (
                  <div className="absolute z-10 bg-gray-800 text-white text-sm p-2 rounded shadow-lg mt-2 left-0 w-64">
                    <p>Ao ativar, o sistema irá incluir gatilhos psicológicos como escassez, curiosidade, autoridade, etc., nas headlines geradas.</p>
                  </div>
                )}
              </button>
            </div>

            {/* Seleção de Gatilhos Psicológicos */}
            {config.usePsychologicalTriggers && (
              <div className="mt-4 p-4 bg-blue-200 text-gray-800 rounded-lg shadow-inner">
                <h3 className="text-lg font-semibold mb-2">Gatilhos Psicológicos</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  {[
                    { key: 'escassez', label: 'Escassez', description: 'Últimas Unidades Disponíveis!' },
                    { key: 'autoridade', label: 'Autoridade', description: 'Recomendado por Especialistas!' },
                    { key: 'curiosidade', label: 'Curiosidade', description: 'Descubra o Segredo por Trás...' },
                    { key: 'transformacao', label: 'Transformação', description: 'Mude Sua Rotina com...' },
                    { key: 'beneficioDireto', label: 'Benefício Direto', description: 'Tenha Resultados Agora!' },
                    { key: 'provaSocial', label: 'Prova Social', description: 'Veja o que nossos clientes dizem!' },
                  ].map(trigger => (
                    <div key={trigger.key} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`trigger-${trigger.key}`}
                        checked={config.psychologicalTriggers[trigger.key]}
                        onChange={(e) => {
                          setConfig(prev => ({
                            ...prev,
                            psychologicalTriggers: {
                              ...prev.psychologicalTriggers,
                              [trigger.key]: e.target.checked
                            }
                          }));
                          toast.info(`${e.target.checked ? 'Ativado' : 'Desativado'}: ${trigger.label}`);
                        }}
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition"
                      />
                      <label htmlFor={`trigger-${trigger.key}`} className="ml-2 block text-gray-700 font-medium">
                        {trigger.label}
                      </label>
                      {/* Descrição do gatilho apenas para o usuário */}
                      <div className="ml-2 text-gray-500 text-sm">
                        ({trigger.description})
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Destaques do Produto */}
            <div>
              <label className="block text-gray-700 font-bold mb-2">Destaques do Produto</label>
              {[0, 1, 2].map(index => (
                <input
                  key={index}
                  type="text"
                  value={config.productHighlights[index]}
                  onChange={(e) => {
                    const newHighlights = [...config.productHighlights];
                    newHighlights[index] = e.target.value;
                    setConfig(prev => ({...prev, productHighlights: newHighlights}));
                  }}
                  placeholder={
                    index === 0 ? "Ex: Seca e alisa em minutos." :
                    index === 1 ? "Ex: Tecnologia avançada que protege os fios." :
                    "Ex: Leve e fácil de usar."
                  }
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition text-gray-800 mb-2 shadow"
                />
              ))}
            </div>

            {/* Resumo das Configurações de Tons */}
            {config.toneMixing && (
              <div className="p-4 bg-gray-700 rounded-lg">
                <h4 className="text-lg font-semibold mb-2 text-white">Resumo das Configurações de Tons</h4>
                <ul className="list-disc list-inside text-white">
                  {Object.entries(config.toneDistribution).map(([tone, count], index) => (
                    <li key={index} className="capitalize">{tone}: {count}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Botões para Gerar Headlines e Seguir Políticas */}
            <div className="flex flex-col items-center space-y-4">
              {/* Botão para Gerar Headlines */}
              <button 
                onClick={generateHeadlines}
                disabled={isGenerating || chatBloqueado}
                className={classNames(
                  'w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg transition duration-300 flex items-center justify-center space-x-2 shadow-lg',
                  isGenerating || chatBloqueado ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-purple-700'
                )}
              >
                {isGenerating ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>Gerando Headlines...</span>
                  </>
                ) : (
                  <>
                    <RefreshCw size={20} />
                    <span>Gerar Headlines</span>
                  </>
                )}
              </button>

              {/* Checkbox para Seguir Políticas do Facebook */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="followFacebookPolicy"
                  checked={config.followFacebookPolicy}
                  onChange={(e) => setConfig(prev => ({ ...prev, followFacebookPolicy: e.target.checked }))}
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition"
                />
                <label htmlFor="followFacebookPolicy" className="ml-2 block text-gray-700 font-bold">
                  Seguir Políticas do Facebook
                </label>
              </div>
            </div>
          </div>

          {/* Painel de Resultados das Headlines */}
          <div className="flex-1 bg-white rounded-lg shadow-md p-6 overflow-auto">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Headlines Geradas</h2>
            
            {config.headlines.length === 0 ? (
              <div className="text-center text-gray-500 py-10">
                Clique em "Gerar Headlines" para começar
              </div>
            ) : (
              <div className="space-y-4">
                {config.headlines.map((headline, index) => (
                  <div 
                    key={index} 
                    className="bg-gray-100 rounded-lg p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between hover:bg-gray-200 transition"
                  >
                    <div className="flex-grow">
                      {editingHeadlineIndex === index ? (
                        <div>
                          <input
                            type="text"
                            value={editedHeadlineText}
                            onChange={(e) => setEditedHeadlineText(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition text-gray-800 shadow"
                          />
                          <button
                            onClick={() => {
                              if (!editedHeadlineText.trim()) {
                                toast.error('A headline não pode estar vazia.');
                                return;
                              }

                              const updatedHeadlines = [...config.headlines];
                              updatedHeadlines[index].text = editedHeadlineText;
                              setConfig(prev => ({ ...prev, headlines: updatedHeadlines }));
                              setEditingHeadlineIndex(null);
                              toast.success('Headline editada com sucesso!');
                            }}
                            className="mt-2 bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600 transition"
                          >
                            Salvar
                          </button>
                        </div>
                      ) : (
                        <p className="text-gray-800 font-medium">{headline.text}</p>
                      )}
                      <div className="w-full bg-gray-300 rounded-full h-2 mt-2">
                        <div 
                          className="bg-green-500 h-2 rounded-full" 
                          style={{width: `${headline.impact}%`}}
                        />
                      </div>
                      <span className="text-sm text-gray-600">
                        Impacto: {headline.impact}%
                      </span>
                      {/* Sugestões de Melhoria */}
                      <div className="mt-2 text-xs text-gray-500">
                        {headline.impact < 90 && <p>Sugestão: Adicionar mais urgência pode melhorar o impacto.</p>}
                        {headline.impact < 85 && <p>Sugestão: Tente um tom mais aspiracional para conectar melhor com o público.</p>}
                      </div>
                    </div>
                    <div className="flex space-x-2 mt-2 sm:mt-0">
                      <button 
                        className="text-blue-500 hover:bg-blue-50 p-2 rounded-full transition"
                        onClick={() => {
                          copyHeadline(headline.text);
                        }}
                      >
                        <Copy size={20} />
                      </button>
                      <button 
                        className="text-green-500 hover:bg-green-50 p-2 rounded-full transition"
                        onClick={() => {
                          toast.success('Headline salva!');
                        }}
                      >
                        <Save size={20} />
                      </button>
                      <button 
                        className="text-yellow-500 hover:bg-yellow-50 p-2 rounded-full transition"
                        onClick={() => {
                          setEditingHeadlineIndex(index);
                          setEditedHeadlineText(headline.text); // Preenche o campo com o texto atual
                        }}
                      >
                        <Edit size={20} />
                      </button>
                      <button 
                        className={classNames(
                          'text-purple-500 hover:bg-purple-50 p-2 rounded-full transition',
                          regeneratingIndex === index && 'opacity-50 cursor-not-allowed'
                        )}
                        onClick={() => {
                          if (regeneratingIndex === null) {
                            regenerateHeadline(index);
                          }
                        }}
                        disabled={regeneratingIndex !== null}
                      >
                        {regeneratingIndex === index ? (
                          <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                          </svg>
                        ) : (
                          <Repeat size={20} />
                        )}
                      </button>
                    </div>
                  </div>
                ))}

                {/* Botão para Exportar e Limpar Headlines */}
                <div className="flex flex-col space-y-4">
                  <button
                    onClick={exportHeadlines}
                    className="w-full bg-gradient-to-r from-green-500 to-teal-600 text-white py-3 rounded-lg hover:from-green-600 hover:to-teal-700 transition duration-300 flex items-center justify-center space-x-2 shadow-lg"
                  >
                    <FileDown size={20} />
                    <span>Exportar Headlines</span>
                  </button>
                  <button
                    onClick={clearHeadlines}
                    className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-300 flex items-center justify-center space-x-2 shadow-lg"
                  >
                    <Trash2 size={20} />
                    <span>Limpar Headlines</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedLineGenerator;
