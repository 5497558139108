import { supabase } from '../supabaseClient';
import { startSync, stopSync } from './syncWords'; // Certifique-se de que os caminhos estejam corretos
import { getUserEmail } from './auth'; // Importa a função de autenticação para obter o email do usuário

// Função para monitorar a atividade do usuário
export const startMonitoring = () => {
  document.addEventListener('mousemove', resetTimer, false);
  document.addEventListener('mousedown', resetTimer, false);
  document.addEventListener('keypress', resetTimer, false);
  document.addEventListener('touchmove', resetTimer, false);
  document.addEventListener('click', resetTimer, false);

  startSync(); // Inicia a verificação a cada 24 horas
};

// Função para interromper o monitoramento da atividade do usuário
export const stopMonitoring = () => {
  document.removeEventListener('mousemove', resetTimer, false);
  document.removeEventListener('mousedown', resetTimer, false);
  document.removeEventListener('keypress', resetTimer, false);
  document.removeEventListener('touchmove', resetTimer, false);
  document.removeEventListener('click', resetTimer, false);

  stopSync(); // Interrompe a verificação de 24 horas quando a monitorização para
};

// Função para remover o usuário da tabela de usuários online após inatividade
const removerUsuarioInativo = async () => {
  const email = getUserEmail(); // Usa a função de autenticação para pegar o email do usuário

  if (!email) {
    console.error("Usuário não autenticado.");
    return;
  }

  try {
    // Verifica se o usuário está na tabela 'usuarios_online'
    const { data: usuarios, error } = await supabase
      .from('usuarios_online')
      .select('*')
      .eq('email', email);

    if (error) {
      console.error('Erro ao buscar usuário na tabela usuarios_online:', error);
      return;
    }

    if (usuarios && usuarios.length > 0) {
      const usuarioId = usuarios[0].id; // Obtém o ID do usuário online

      // Remove o usuário da tabela usuarios_online (não exclui do banco de dados)
      const { error: deleteError } = await supabase
        .from('usuarios_online')
        .delete()
        .eq('id', usuarioId);

      if (deleteError) {
        console.error('Erro ao remover usuário da tabela usuarios_online:', deleteError);
      } else {
        console.log('Usuário inativo removido da tabela usuarios_online.');
      }
    } else {
      console.log('Usuário não encontrado na tabela usuarios_online.');
    }
  } catch (error) {
    console.error('Erro ao remover usuário inativo:', error);
  }
};

// Função para reiniciar o temporizador de inatividade
let timer;
const resetTimer = () => {
  clearTimeout(timer);
  timer = setTimeout(async () => {
    await removerUsuarioInativo(); // Remove o usuário da tabela de usuários online após 15 minutos de inatividade
    stopMonitoring(); // Interrompe o monitoramento
  }, 15 * 60 * 1000); // Interrompe o monitoramento após 15 minutos de inatividade
};
