// src/components/Aulas.js

import React, { useState, useEffect } from 'react';
import './Aulas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faArrowLeft, faArrowRight, faListUl, faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getUserEmail } from '../../auth/auth'; // Importa a função de autenticação

const Aulas = () => {
  const [currentLesson, setCurrentLesson] = useState(0);
  const [completedLessons, setCompletedLessons] = useState([]);
  const [showList, setShowList] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const aulas = [
    {
      titulo: 'Introdução à Plataforma Transbordo',
      descricao: 'Nesta aula, você será introduzido à nossa plataforma e aprenderá os primeiros passos para usar todas as funcionalidades.',
      video: 'https://www.youtube.com/embed/OhfaMoyuKdw?si=0noGJKQu9nfy3xUa'
    },
    {
      titulo: 'Meta de Lucro',
      descricao: 'Aprenda a definir metas de lucro para suas campanhas e como utilizá-las para otimizar sua estratégia financeira.',
      video: 'link-do-video-meta-lucro'
    },
    {
      titulo: 'Guia de Investimento Fácil',
      descricao: 'Veja como utilizar o Guia de Investimento Fácil para maximizar seus retornos financeiros.',
      video: 'link-do-video-guia-investimento'
    },
    {
      titulo: 'Taxa de Conversão no WhatsApp',
      descricao: 'Nesta aula, você aprenderá a aumentar a taxa de conversão das suas interações no WhatsApp.',
      video: 'link-do-video-taxa-conversao-whatsapp'
    },
    {
      titulo: 'Funil de Vendas para WhatsApp',
      descricao: 'Configure seu funil de vendas no WhatsApp e aprenda como otimizar o fluxo de vendas.',
      video: 'link-do-video-funil-vendas-whatsapp'
    },
    {
      titulo: 'Análise de Métricas',
      descricao: 'Aprenda a analisar as métricas de suas campanhas para melhorar os resultados e obter insights valiosos.',
      video: 'link-do-video-analise-metricas'
    },
    {
      titulo: 'Copy para Meta ADS',
      descricao: 'Descubra como criar textos persuasivos otimizados para anúncios no Meta ADS.',
      video: 'link-do-video-copy-meta-ads'
    },
    {
      titulo: 'Script de Vendas para WhatsApp',
      descricao: 'Crie scripts de vendas poderosos para WhatsApp e aumente suas chances de conversão.',
      video: 'link-do-video-script-vendas'
    },
    {
      titulo: 'Copy para Remarketing',
      descricao: 'Aprenda a desenvolver copy para remarketing que reengaje clientes e aumente as conversões.',
      video: 'link-do-video-copy-remarketing'
    },
    {
      titulo: 'Headlines',
      descricao: 'Entenda como criar headlines impactantes que chamam a atenção e aumentam as conversões dos seus anúncios.',
      video: 'link-do-video-headlines'
    },
    {
      titulo: 'Prova Social',
      descricao: 'Veja como utilizar provas sociais para aumentar a credibilidade do seu produto e suas vendas.',
      video: 'link-do-video-prova-social'
    },
    {
      titulo: 'Estúdio de Criação',
      descricao: 'Descubra como utilizar o Estúdio de Criação para desenvolver conteúdos visuais atrativos para suas campanhas.',
      video: 'link-do-video-estudio-criacao'
    },
    {
      titulo: 'Mapa do Digital',
      descricao: 'Explore o Mapa do Digital para entender o ecossistema digital e criar estratégias bem fundamentadas.',
      video: 'link-do-video-mapa-digital'
    },
    {
      titulo: 'Criador de Criativos',
      descricao: 'Aprenda a criar criativos visuais que capturam a atenção e impulsionam suas campanhas.',
      video: 'link-do-video-criador-criativos'
    },
    {
      titulo: 'Persona',
      descricao: 'Entenda como definir personas para suas campanhas e garantir que suas mensagens alcancem o público certo.',
      video: 'link-do-video-persona'
    },
    {
      titulo: 'Minha Conta',
      descricao: 'Aprenda a gerenciar sua conta dentro da plataforma de forma eficiente.',
      video: 'link-do-video-minha-conta'
    },
    {
      titulo: 'Suporte ao Cliente',
      descricao: 'Veja como utilizar a ferramenta de suporte ao cliente para resolver dúvidas e problemas rapidamente.',
      video: 'link-do-video-suporte-cliente'
    }
  ];

  const userEmail = getUserEmail(); // Obtém o e-mail do usuário autenticado

  useEffect(() => {
    if (!userEmail) {
      console.error('Usuário não autenticado.');
      return;
    }

    const savedProgress = localStorage.getItem(`completedLessons_${userEmail}`);
    if (savedProgress) {
      setCompletedLessons(JSON.parse(savedProgress));
    }
  }, [userEmail]);

  const handleNext = () => {
    if (currentLesson < aulas.length - 1) {
      setCurrentLesson(currentLesson + 1);
    }
  };

  const handlePrevious = () => {
    if (currentLesson > 0) {
      setCurrentLesson(currentLesson - 1);
    }
  };

  const toggleCompletion = (index) => {
    if (!userEmail) {
      console.error('Usuário não autenticado.');
      return;
    }

    const updatedCompletedLessons = completedLessons.includes(index)
      ? completedLessons.filter((i) => i !== index)
      : [...completedLessons, index];
    setCompletedLessons(updatedCompletedLessons);
    localStorage.setItem(`completedLessons_${userEmail}`, JSON.stringify(updatedCompletedLessons));
  };

  const progress = (completedLessons.length / aulas.length) * 100;

  return (
    <div className="aulas-container">
      <div className="aulas-header">
        <h1 className="aulas-title-gradient">Aulas e Tutoriais</h1>
        <p className="aulas-description">Aprenda a utilizar todas as ferramentas disponíveis em nossa plataforma.</p>
        <div className="progress-container">
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={buildStyles({
              textColor: '#FFC871',
              pathColor: '#FFC871',
              trailColor: 'rgba(255, 255, 255, 0.2)',
            })}
          />
          <p>Progresso do Curso</p>
        </div>
      </div>

      <div className="aulas-content">
        <div className="aulas-video">
          <div className="video-overlay" onClick={() => setIsPlaying(!isPlaying)}>
            {!isPlaying && <FontAwesomeIcon icon={faPlayCircle} className="play-icon" />}
          </div>
          <iframe
            src={`${aulas[currentLesson].video}${isPlaying ? '?autoplay=1' : ''}`}
            title={aulas[currentLesson].titulo}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="aulas-info">
          <h2>{aulas[currentLesson].titulo}</h2>
          <p>{aulas[currentLesson].descricao}</p>

          <div className="lesson-actions">
            <button
              onClick={() => toggleCompletion(currentLesson)}
              className={`completion-button ${completedLessons.includes(currentLesson) ? 'completed' : ''}`}
            >
              <FontAwesomeIcon icon={completedLessons.includes(currentLesson) ? faCheck : faClock} />
              {completedLessons.includes(currentLesson) ? 'Concluída' : 'Marcar como concluída'}
            </button>
          </div>

          <div className="aulas-navigation">
            <button onClick={handlePrevious} disabled={currentLesson === 0} className="aulas-button">
              <FontAwesomeIcon icon={faArrowLeft} /> Aula Anterior
            </button>
            <button onClick={handleNext} disabled={currentLesson === aulas.length - 1} className="aulas-button">
              Próxima Aula <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>

      <button className="toggle-list-button" onClick={() => setShowList(!showList)}>
        <FontAwesomeIcon icon={faListUl} /> {showList ? 'Esconder Lista' : 'Mostrar Lista'}
      </button>

      {showList && (
        <div className="aulas-list">
          <h3>Lista de Aulas</h3>
          <ul>
            {aulas.map((aula, index) => (
              <li
                key={index}
                onClick={() => setCurrentLesson(index)}
                className={`${index === currentLesson ? 'aula-active' : ''} ${
                  completedLessons.includes(index) ? 'aula-completed' : ''
                }`}
              >
                {aula.titulo}
                {completedLessons.includes(index) && <FontAwesomeIcon icon={faCheck} className="completion-icon" />}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Aulas;
