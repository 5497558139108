import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { FaDownload, FaCheckCircle, FaLock, FaSignOutAlt, FaUser, FaHome, FaList, FaTimes, FaStar, FaComment } from 'react-icons/fa';
import { supabase } from '../../supabaseClient';
import { logout, isAuthenticated, getUserEmail, getUserName } from '../../auth/auth';
import './VideoPlayer.css';
import Comentarios from './Comentarios';
import Gameficacao from './Gameficacao';


const VideoPlayer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [aulas, setAulas] = useState([]);
  const [moduloNome, setModuloNome] = useState('');
  const [videoAtual, setVideoAtual] = useState(null);
  const [aulaAtualId, setAulaAtualId] = useState(null);
  const [descricaoAula, setDescricaoAula] = useState('');
  const [linkArquivo, setLinkArquivo] = useState('');
  const [erro, setErro] = useState('');
  const [progresso, setProgresso] = useState(0);
  const [aulasConcluidas, setAulasConcluidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [avaliacao, setAvaliacao] = useState(0);
  const [userId, setUserId] = useState(null);
  const [totalModulos, setTotalModulos] = useState(0);
  const [showComentarios, setShowComentarios] = useState(false);
  const [aulaAtualNome, setAulaAtualNome] = useState('');
  const [userInfo, setUserInfo] = useState({
    nome: '',
    email: '',
    fotoPerfil: ''
  });

  // Verificar autenticação e carregar userId
  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!isAuthenticated()) {
          navigate('/login', { replace: true });
          return;
        }

        const email = getUserEmail();
        const nome = getUserName();
        const fotoPerfil = localStorage.getItem(`${email}-fotoPerfil`);

        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('user_id')  // Alterado de 'id' para 'user_id'
          .eq('email', email)
          .single();

        if (userError) throw userError;
        setUserId(userData.user_id);  // Alterado para user_id

        setUserInfo({
          nome,
          email,
          fotoPerfil
        });
      } catch (error) {
        console.error('Erro na verificação de autenticação:', error);
        navigate('/login', { replace: true });
      }
    };

    checkAuth();
  }, [navigate]);

  // Carregar módulo, aulas e total de módulos
  useEffect(() => {
    const carregarDados = async () => {
      try {
        setLoading(true);
        if (!id || !userId) return;

        const { data: modulosData, error: modulosError } = await supabase
          .from('modulos')
          .select('id');

        if (modulosError) throw modulosError;
        setTotalModulos(modulosData.length);

        const { data: modulo, error: moduloError } = await supabase
          .from('modulos')
          .select('id, nome')
          .eq('id', id)
          .single();

        if (moduloError) throw moduloError;
        setModuloNome(modulo.nome);

        const { data: aulasData, error: aulasError } = await supabase
          .from('aulas')
          .select('*')
          .eq('modulo_id', id)
          .order('ordem', { ascending: true });

        if (aulasError) throw aulasError;

        if (aulasData?.length > 0) {
          setAulas(aulasData);
          selecionarAula(aulasData[0]);
          await carregarProgresso(id);
        }
      } catch (error) {
        setErro(error.message);
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    carregarDados();
  }, [id, userId]);

  // Modifique a função carregarProgresso para arredondar o valor ao carregar do banco
  const carregarProgresso = async (moduloId) => {
    try {
      const { data: progressoData, error } = await supabase
        .from('usuarios_progresso')
        .select('progresso_aula, avaliacao_aula, progresso_modulo')
        .eq('modulo_id', moduloId)
        .eq('usuario_id', userId)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      if (progressoData) {
        setAulasConcluidas(progressoData.progresso_aula || []);
        setAvaliacao(progressoData.avaliacao_aula?.[aulaAtualId] || 0);
        // Arredonda o valor do progresso ao carregar do banco
        setProgresso(Math.round(progressoData.progresso_modulo || 0));
      } else {
        await supabase.from('usuarios_progresso').insert({
          usuario_id: userId,
          modulo_id: moduloId,
          progresso_aula: [],
          avaliacao_aula: {},
          progresso_modulo: 0
        });
        setAulasConcluidas([]);
        setAvaliacao(0);
        setProgresso(0);
      }
    } catch (error) {
      console.error('Erro ao carregar progresso:', error);
      setErro(error.message);
    }
  };

 const selecionarAula = (aula) => {
    if (!aula) return;
    setVideoAtual(aula.link_video);
    setAulaAtualId(aula.id);
    setDescricaoAula(aula.descricao);
    setAulaAtualNome(aula.nome);
    setLinkArquivo(aula.link_arquivos ? JSON.parse(aula.link_arquivos)[0] : '');
    carregarAvaliacao(aula.id);
  };

  const carregarAvaliacao = async (aulaId) => {
    try {
      const { data, error } = await supabase
        .from('usuarios_progresso')
        .select('avaliacao_aula')
        .eq('modulo_id', id)
        .eq('usuario_id', userId)
        .single();

      if (error) throw error;
      setAvaliacao(data?.avaliacao_aula?.[aulaId] || 0);
    } catch (error) {
      console.error('Erro ao carregar avaliação:', error);
    }
  };

  const avaliarAula = async (nota) => {
    try {
      const { data: currentData, error: fetchError } = await supabase
        .from('usuarios_progresso')
        .select('avaliacao_aula')
        .eq('modulo_id', id)
        .eq('usuario_id', userId)
        .single();

      if (fetchError) throw fetchError;

      const avaliacoes = currentData?.avaliacao_aula || {};
      avaliacoes[aulaAtualId] = nota;

      const { error: updateError } = await supabase
        .from('usuarios_progresso')
        .update({ avaliacao_aula: avaliacoes })
        .eq('modulo_id', id)
        .eq('usuario_id', userId);

      if (updateError) throw updateError;
      setAvaliacao(nota);
    } catch (error) {
      console.error('Erro ao avaliar aula:', error);
      setErro('Erro ao salvar avaliação');
    }
  };

 // Modifique a função que atualiza o progresso no desmarcarAulaConcluida
  const desmarcarAulaConcluida = async (aulaId) => {
    try {
      const novasAulasConcluidas = aulasConcluidas.filter(id => id !== aulaId);
      const novoProgresso = Math.round((novasAulasConcluidas.length / aulas.length) * 100);

      const { error } = await supabase
        .from('usuarios_progresso')
        .update({ 
          progresso_aula: novasAulasConcluidas,
          progresso_modulo: novoProgresso
        })
        .eq('modulo_id', id)
        .eq('usuario_id', userId);

      if (error) throw error;

      setAulasConcluidas(novasAulasConcluidas);
      setProgresso(novoProgresso);
    } catch (error) {
      console.error('Erro ao desmarcar aula:', error);
      setErro('Erro ao desmarcar aula: ' + error.message);
    }
  };

  // Modifique a função que atualiza o progresso no marcarComoConcluida
  const marcarComoConcluida = async (aulaId) => {
    if (!aulaId) return;

    try {
      const novasAulasConcluidas = [...aulasConcluidas, aulaId];
      const novoProgresso = Math.round((novasAulasConcluidas.length / aulas.length) * 100);
      
      const { error } = await supabase
        .from('usuarios_progresso')
        .update({ 
          progresso_aula: novasAulasConcluidas,
          progresso_modulo: novoProgresso
        })
        .eq('modulo_id', id)
        .eq('usuario_id', userId);

      if (error) throw error;

      setAulasConcluidas(novasAulasConcluidas);
      setProgresso(novoProgresso);
    } catch (error) {
      console.error('Erro ao atualizar progresso:', error);
      setErro('Erro ao atualizar progresso: ' + error.message);
    }
  };

  const avancarParaProximaAula = () => {
    const currentIndex = aulas.findIndex((aula) => aula.link_video === videoAtual);
    if (currentIndex < aulas.length - 1) {
      selecionarAula(aulas[currentIndex + 1]);
    }
  };

  const handleAulaAction = async () => {
    if (aulasConcluidas.includes(aulaAtualId)) {
      await desmarcarAulaConcluida(aulaAtualId);
    } else {
      await marcarComoConcluida(aulaAtualId);
      avancarParaProximaAula();
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };

  if (loading) {
    return <div className="vp-loading">Carregando...</div>;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

    return (
    <div className="vp-container">
      <header className="vp-header">
        <div className="vp-header-logo">
          <img src="../../imagens/logo_menu_login.webp" alt="Logo" className="vp-logo" />
        </div>
        <div className="vp-header-user">
          <button 
            onClick={() => navigate('/treinamentos')} 
            className="vp-home-btn"
          >
            <FaHome />
          </button>
          <span className="vp-user-name">Olá, {userInfo.nome}</span>
          {userInfo.fotoPerfil ? (
            <img src={userInfo.fotoPerfil} alt="Perfil" className="vp-user-photo" />
          ) : (
            <FaUser className="vp-user-icon" />
          )}
          <button onClick={handleLogout} className="vp-logout-btn">
            <FaSignOutAlt />
          </button>
        </div>
      </header>

      <div className="vp-content">
        {erro ? (
          <div className="vp-error">{erro}</div>
        ) : (
          <>
            <div className="vp-main">
              <div className="vp-video-section">
                {videoAtual && (
                  <>
                    <iframe
                      ref={videoRef}
                      src={videoAtual}
                      title="Aula"
                      className="vp-video-frame"
                      allowFullScreen
                    />
                    <h3 className="vp-aula-titulo">{aulaAtualNome}</h3>
                  </>
                )}
                <div className="vp-video-controls">
                  <button 
                    onClick={handleAulaAction} 
                    className="vp-button"
                  >
                    {aulasConcluidas.includes(aulaAtualId) 
                      ? 'Desmarcar conclusão da aula'
                      : 'Marcar como concluído e avançar'}
                  </button>
                </div>
                
                <div className="vp-avaliacao">
                  {[1, 2, 3, 4, 5].map((estrela) => (
                    <FaStar
                      key={estrela}
                      className={`vp-star ${estrela <= avaliacao ? 'vp-star-active' : ''}`}
                      onClick={() => avaliarAula(estrela)}
                    />
                  ))}
                </div>
              </div>
                 <div className="vp-gamificacao">
                  <Gameficacao aulaId={aulaAtualId} moduloId={id} userId={userId} />
              <div className="vp-description">
                <div className="vp-description-header">
                  <button 
                    className={`vp-tab-button ${!showComentarios ? 'active' : ''}`}
                    onClick={() => setShowComentarios(false)}
                  >
                    Descrição da Aula
                  </button>
                  <button 
                    className={`vp-tab-button ${showComentarios ? 'active' : ''}`}
                    onClick={() => setShowComentarios(true)}
                  >
                    <FaComment /> Comentários
                  </button>
                </div>
                
                {showComentarios ? (
                  <Comentarios aulaId={aulaAtualId} moduloId={id} userId={userId}/>
                ) : (
                  <>
                    <p>{descricaoAula}</p>
                    {linkArquivo && (
                      <a href={linkArquivo} download className="vp-download-link">
                        <FaDownload /> Material complementar
                      </a>
                    )}
                  </>
                )}
              </div>

              <button
                className="vp-mobile-menu-toggle"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                {showMobileMenu ? <FaTimes /> : <FaList />}
                Ver Aulas
              </button>

              <div className={`vp-sidebar ${showMobileMenu ? 'vp-sidebar-mobile-active' : ''}`}>
                <div className="vp-progress">
                  <h3>Progresso do Módulo</h3>
                  <div className="vp-progress-bar">
                    <div 
                      className="vp-progress-fill"
                      style={{ width: `${progresso}%` }}
                    />
                  </div>
                  <span>{progresso}% Concluído</span>
                </div>

                <div className="vp-aulas-lista">
                  <h3>{moduloNome}</h3>
                  <ul>
                    {aulas.map((aula) => (
                      <li
                        key={aula.id}
                        className={`vp-aula-item ${
                          videoAtual === aula.link_video ? 'vp-aula-atual' : ''
                        } ${aulasConcluidas.includes(aula.id) ? 'vp-aula-concluida' : ''}`}
                        onClick={() => selecionarAula(aula)}
                      >
                        {aulasConcluidas.includes(aula.id) ? (
                          <FaCheckCircle className="vp-aula-icon" />
                        ) : (
                          <FaLock className="vp-aula-icon" />
                        )}
                        <span>{aula.nome}</span>
                      </li>
                    ))}
                  </ul>
                </div>
               
                 </div>
              </div>
             
            </div>
          </>
        )}
      </div>
     </div>
  );
};

export default VideoPlayer;