import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Alterado para useNavigate
import {
  FaUserTie, FaBriefcase, FaUserAltSlash, FaGraduationCap, FaUserCheck, FaBuilding, FaChalkboardTeacher,
  FaBalanceScale, FaLaptopCode, FaNetworkWired, FaBrain, FaMicrophoneAlt, FaVideo, FaPenAlt, FaUsers, FaHeadset, FaLaptopHouse
} from 'react-icons/fa';
import './MapaDigital.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import Oportunidades from './Oportunidades';
import SalaryMap from './SalaryMap';

const ProfileIcon = ({ icon, name, iconClass }) => (
  <div className={`profile-icon ${iconClass}`}>
    <div className="icon-container">
      {icon}
    </div>
    <span className="icon-label">{name}</span>
  </div>
);

const JobCategory = ({ title, titleClass, iconClass, blockClass, jobs }) => (
  <div className={`job-category ${blockClass}`}>
    <h3 className={`job-category-title ${titleClass}`}>{title}</h3>
    <div className="jobs-grid">
      {jobs.map((job, index) => (
        <ProfileIcon key={index} name={job.name} icon={job.icon} iconClass={iconClass} />
      ))}
    </div>
  </div>
);

const MapaDigital = () => {
  const navigate = useNavigate(); // Hook para navegação
  const userEmail = localStorage.getItem('userEmail');

useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="mapa-digital-container">
      <header className="mapa-digital-header">
        <img src={logo} alt="Logo" className="mapa-digital-logo" />
        <div className="mapa-digital-user-info">
          <span>{userEmail}</span>
          <button onClick={logout} className="mapa-digital-logout">Sair</button>
        </div>
      </header>
      <main className="mapa-digital-content">
        <button className="mapa-digital-back-button" onClick={() => navigate('/ferramentas')}>← Voltar para o Menu</button>
        <h1 className="mapa-digital-title">Mapa do Mercado Digital</h1>
        <div className="profile-section">
          <JobCategory
            title="Seu Perfil Hoje"
            titleClass="profile-today-title"
            blockClass="profile-today-block"
            iconClass="profile-today-icon"
            jobs={[
              { name: "EMPREGADO CLT", icon: <FaUserTie size={32} /> },
              { name: "EMPREGADO PJ", icon: <FaBriefcase size={32} /> },
              { name: "DESEMPREGADO", icon: <FaUserAltSlash size={32} /> },
              { name: "ESTUDANTE", icon: <FaGraduationCap size={32} /> },
              { name: "APOSENTADO", icon: <FaUserCheck size={32} /> },
              { name: "FUNCIONÁRIO PÚBLICO", icon: <FaBuilding size={32} /> },
              { name: "EMPREENDEDOR EM NEGÓCIOS NÃO DIGITAIS", icon: <FaBriefcase size={32} /> },
            ]}
          />
        </div>
        <div className="market-section">
          <JobCategory
            title="Exige Formação Formal"
            titleClass="formal-training-title"
            blockClass="formal-training-block"
            iconClass="formal-training-icon"
            jobs={[
              { name: "CONTADOR", icon: <FaChalkboardTeacher size={32} /> },
              { name: "TRADUTOR", icon: <FaBalanceScale size={32} /> },
              { name: "ADVOGADO", icon: <FaBalanceScale size={32} /> },
              { name: "INVESTIDOR", icon: <FaLaptopCode size={32} /> },
              { name: "TELEMEDICINA", icon: <FaNetworkWired size={32} /> },
              { name: "PSICÓLOGO", icon: <FaBrain size={32} /> },
              { name: "PERSONAL TRAINER", icon: <FaMicrophoneAlt size={32} /> },
              { name: "TERAPEUTA", icon: <FaVideo size={32} /> }
            ]}
          />
          <JobCategory
            title="Não Exige Formação Formal"
            titleClass="informal-training-title"
            blockClass="informal-training-block"
            iconClass="informal-training-icon"
            jobs={[
              { name: "PRODUTOR DE CONTEÚDO", icon: <FaPenAlt size={32} /> },
              { name: "INFOPRODUTOR", icon: <FaUsers size={32} /> },
              { name: "COPYWRITER", icon: <FaPenAlt size={32} /> },
              { name: "GESTOR DE TRÁFEGO", icon: <FaNetworkWired size={32} /> },
              { name: "SOCIAL MEDIA", icon: <FaUsers size={32} /> },
              { name: "WEB DEV", icon: <FaLaptopCode size={32} /> },
              { name: "EDITOR DE VÍDEOS", icon: <FaVideo size={32} /> },
              { name: "DESIGNER", icon: <FaPenAlt size={32} /> }
            ]}
          />
        </div>
        <div className="work-section">
          <JobCategory
            title="Trabalhadores Formados"
            titleClass="trained-workers-title"
            blockClass="trained-workers-block"
            iconClass="trained-workers-icon"
            jobs={[
              { name: "ENGENHEIRO DE COMPUTAÇÃO", icon: <FaLaptopCode size={32} /> },
              { name: "ANALISTA DE CIBERSEGURANÇA", icon: <FaNetworkWired size={32} /> },
              { name: "UX/UI DESIGNER", icon: <FaPenAlt size={32} /> },
              { name: "ANALISTA DE MARKETING DIGITAL", icon: <FaUsers size={32} /> },
              { name: "ENGENHEIRO DE DADOS", icon: <FaLaptopCode size={32} /> },
              { name: "ANALISTA DE INVESTIMENTOS", icon: <FaUsers size={32} /> },
              { name: "GERENTE DE PROJETOS", icon: <FaPenAlt size={32} /> }
            ]}
          />
          <JobCategory
            title="Trabalhadores Sem Formação"
            titleClass="untrained-workers-title"
            blockClass="untrained-workers-block"
            iconClass="untrained-workers-icon"
            jobs={[
              { name: "PROGRAMADOR", icon: <FaLaptopCode size={32} /> },
              { name: "DESIGNER", icon: <FaPenAlt size={32} /> },
              { name: "ATENDIMENTO", icon: <FaHeadset size={32} /> },
              { name: "CUSTOMER SUCCESS", icon: <FaUsers size={32} /> },
              { name: "INSIDE SALES", icon: <FaLaptopHouse size={32} /> },
              { name: "ANALISTA DE SEO", icon: <FaUsers size={32} /> },
              { name: "SECRETÁRIA VIRTUAL", icon: <FaLaptopHouse size={32} /> },
              { name: "ASSISTENTE DE E-COMMERCE", icon: <FaLaptopHouse size={32} /> }
            ]}
          />
        </div>
        <div className="future-section">
          <h2 className="future-section-title">Seu Perfil Futuro</h2>
          <div className="future-options">
            <div className="future-option green">É UM EMPREENDEDOR DIGITAL</div>
            <div className="future-option blue">TEM LIBERDADE FINANCEIRA</div>
            <div className="future-option purple">É RESPONSÁVEL PELAS DECISÕES</div>
            <div className="future-option indigo">TRABALHA VIAJANDO</div>
            <div className="future-option pink">TRABALHA DE CASA</div>
            <div className="future-option yellow">TRABALHA DE QUALQUER LUGAR DO MUNDO</div>
            <div className="future-option orange">GERENCIA SEUS HORÁRIOS COMO QUER</div>
          </div>
        </div>
        {/* Seção Oportunidades */}
        <Oportunidades />
        {/* Nova seção Animação */}
        <SalaryMap />        
      </main>
    </div>
  );
};

export default MapaDigital;
