import { supabase } from '../supabaseClient';
import { getUserEmail } from './auth';
import { atualizarSaldoPalavras } from './palavraControle';
import { DateTime } from 'luxon';

let syncInterval; // Variável para armazenar o ID do intervalo

// Função para verificar e renovar palavras para usuários pagos
export const verificarRenovacaoPalavras = async () => {
  const userEmail = getUserEmail();

  if (!userEmail) {
    console.error("Usuário não autenticado.");
    return;
  }

  try {
    // Obter a data atual do servidor e converter para horário de São Paulo
    const serverTime = DateTime.utc();
    const brazilTime = serverTime.setZone('America/Sao_Paulo');

    // Buscar o usuário no Supabase
    const { data: usuario, error: usuarioError } = await supabase
      .from('usuarios')
      .select('user_id, plano_id, palavras_disponiveis, data_renovacao_palavras')
      .eq('email', userEmail)
      .single();

    if (usuarioError || !usuario) {
      console.error('Usuário não encontrado no Supabase:', usuarioError);
      return;
    }

    // Verificar se o usuário possui um plano_id válido
    if (!usuario.plano_id) {
      console.log('Usuário não possui plano. Renovação de palavras não aplicável.');
      return;
    }

    // Buscar o limite de palavras do plano do usuário
    const { data: planoData, error: planoError } = await supabase
      .from('tipos_de_plano')
      .select('limite_palavras')
      .eq('id', usuario.plano_id)
      .single();

    if (planoError || !planoData) {
      console.error('Erro ao buscar plano no Supabase:', planoError);
      return;
    }

    const limitePalavras = planoData.limite_palavras;

    // Se o usuário for pago, verificamos a data de renovação
    let dataRenovacao = DateTime.fromISO(usuario.data_renovacao_palavras, { zone: 'America/Sao_Paulo' });

    // Se 'data_renovacao_palavras' não estiver definida, consideramos a data atual
    if (!dataRenovacao.isValid) {
      console.log('Data de renovação inválida ou não definida. Definindo para data atual.');
      dataRenovacao = brazilTime;
    }

    const diffInDays = brazilTime.diff(dataRenovacao, 'days').days;

    // Verifica se passaram 30 dias desde a última renovação
    if (diffInDays >= 30) {
      // Atualiza o saldo de palavras para o limite do plano do usuário
      await atualizarSaldoPalavras(userEmail, limitePalavras);

      // Atualiza a data de renovação para hoje
      const { error: updateError } = await supabase
        .from('usuarios')
        .update({
          data_renovacao_palavras: brazilTime.toISO(),
        })
        .eq('user_id', usuario.user_id);

      if (updateError) {
        console.error('Erro ao atualizar data de renovação no Supabase:', updateError);
        return;
      }

      console.log(`Palavras renovadas para o usuário ${userEmail}. Nova data de renovação: ${brazilTime.toISO()}`);
    } else {
      console.log(`Ainda não é hora de renovar as palavras. Dias restantes: ${Math.ceil(30 - diffInDays)}`);
    }
  } catch (error) {
    console.error('Erro ao verificar renovação de palavras:', error.message);
  }
};


// Função para iniciar a verificação no login e a cada 24 horas
export const startSync = () => {
  // Executa a verificação imediatamente no login
  verificarRenovacaoPalavras();

  // Define o intervalo para verificar a cada 24 horas
  syncInterval = setInterval(() => {
    verificarRenovacaoPalavras();
  }, 24 * 60 * 60 * 1000); // 24 horas
};

// Função para interromper a verificação de renovação de palavras
export const stopSync = () => {
  clearInterval(syncInterval); // Interrompe o intervalo definido na função startSync
};

// Adicione uma função para garantir que a verificação seja chamada no login
export const verificarNoLogin = () => {
  // Faz a verificação assim que o usuário faz o login
  verificarRenovacaoPalavras();
};
