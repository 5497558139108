// GerenciamentoAnuncios.js
// Este arquivo contém todas as funções para editar, alterar status, alterar orçamento e duplicar itens.
// Ele exporta funções que o componente pai (FacebookAdsDashboard) pode chamar passando o token, IDs e parâmetros necessários.

import axios from 'axios';
import { toast } from 'react-toastify';

// Mapeamento de objetivos da campanha para objetivos válidos na API do Facebook
// Ajuste conforme necessário
const objectiveMap = {
  OUTCOME_ENGAGEMENT: 'ENGAGEMENT',
  OUTCOME_SALES: 'CONVERSIONS',
  OUTCOME_LEAD_GENERATION: 'LEAD_GENERATION'
  // Adicione outros mapeamentos se houver mais tipos
};

// Versão da API do Facebook (Atualizada para v21.0 conforme a documentação)
const FB_API_VERSION = 'v21.0';

// Obtém o token master da variável de ambiente
const masterToken = process.env.REACT_APP_FACEBOOK_API_TOKEN;

// Verifica se o token master está definido
if (!masterToken) {
  console.error('Erro: REACT_APP_FACEBOOK_API_TOKEN não está definido nas variáveis de ambiente.');
  toast.error('Erro de configuração: Token master não encontrado.');
}

// Função auxiliar para enviar requisições POST com dados no corpo em formato URL-encoded
const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, new URLSearchParams(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.error('Erro detalhado da API do Facebook:', error.response.data);
      toast.error(`Erro: ${error.response.data.error.message}`);
    } else {
      console.error('Erro na requisição:', error);
      toast.error('Ocorreu um erro na requisição.');
    }
    throw error;
  }
};

// -------------------- Funções de Edição Simples ---------------------

export async function editarNomeItem(facebookToken, itemId, nivel, novoNome) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const data = {
      access_token: masterToken, // Substituição do token
      name: novoNome,
    };

    // Opcional: Log para verificar o token usado (remova em produção)
    // console.log('Token usado para editar nome:', masterToken);

    await postRequest(endpoint, data);
    toast.success(`${nivel === 'campaign' ? 'Campanha' : nivel === 'adset' ? 'Conjunto de Anúncio' : 'Anúncio'} atualizado com sucesso!`);
  } catch (error) {
    console.error(`Erro ao editar o nome do ${nivel}:`, error);
    // O toast de erro já é chamado na função postRequest
  }
}

export async function alterarStatusItem(facebookToken, itemId, nivel, novoStatus) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const data = {
      access_token: masterToken, // Substituição do token
      status: novoStatus,
    };

    // Opcional: Log para verificar o token usado (remova em produção)
    // console.log('Token usado para alterar status:', masterToken);

    await postRequest(endpoint, data);
    toast.success(`${nivel === 'campaign' ? 'Campanha' : nivel === 'adset' ? 'Conjunto de Anúncio' : 'Anúncio'} ${novoStatus === 'ACTIVE' ? 'ativado' : 'desativado'} com sucesso!`);
  } catch (error) {
    console.error(`Erro ao alterar status do ${nivel}:`, error);
    // O toast de erro já é chamado na função postRequest
  }
}

export async function alterarOrcamentoItem(facebookToken, itemId, nivel, novoOrcamento, isLifetimeBudget = false) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const budgetValue = Math.round(novoOrcamento * 100).toString(); // Orçamento em centavos como string
    const data = {
      access_token: masterToken, // Substituição do token
    };

    if (isLifetimeBudget) {
      data.lifetime_budget = budgetValue;
    } else {
      data.daily_budget = budgetValue;
    }

    // Opcional: Log para verificar o token usado (remova em produção)
    // console.log('Token usado para alterar orçamento:', masterToken);

    await postRequest(endpoint, data);
    toast.success(`${nivel === 'campaign' ? 'Campanha' : nivel === 'adset' ? 'Conjunto de Anúncio' : 'Anúncio'} atualizado com novo orçamento!`);
  } catch (error) {
    console.error(`Erro ao alterar orçamento do ${nivel}:`, error);
    // O toast de erro já é chamado na função postRequest
  }
}

// -------------------- Funções de Duplicação Completa ---------------------

// Funções auxiliares para duplicação

async function getItemData(facebookToken, itemId, fields) {
  try {
    const response = await axios.get(`https://graph.facebook.com/${FB_API_VERSION}/${itemId}`, {
      params: {
        access_token: masterToken, // Substituição do token
        fields: fields.join(',')
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Erro ao obter dados do item ${itemId}:`, error);
    toast.error('Erro ao obter dados do item.');
    throw error;
  }
}

async function getAdSetsForCampaign(facebookToken, campaignId) {
  try {
    const response = await axios.get(`https://graph.facebook.com/${FB_API_VERSION}/${campaignId}/adsets`, {
      params: {
        access_token: masterToken, // Substituição do token
        fields: 'id,name,optimization_goal,destination_type,daily_budget,lifetime_budget,status,bid_strategy,campaign_id,billing_event,promoted_object'
      }
    });

    return response.data.data;
  } catch (error) {
    console.error(`Erro ao obter ad sets da campanha ${campaignId}:`, error);
    toast.error('Erro ao obter conjuntos de anúncios da campanha.');
    throw error;
  }
}

async function getAdsForAdSet(facebookToken, adSetId) {
  try {
    const response = await axios.get(`https://graph.facebook.com/${FB_API_VERSION}/${adSetId}/ads`, {
      params: {
        access_token: masterToken, // Substituição do token
        fields: 'id,name,status,creative{object_story_spec},adset_id,campaign_id'
      }
    });

    return response.data.data;
  } catch (error) {
    console.error(`Erro ao obter anúncios do conjunto de anúncios ${adSetId}:`, error);
    toast.error('Erro ao obter anúncios do conjunto de anúncios.');
    throw error;
  }
}

async function createCampaign(facebookToken, accountId, originalCampaignData) {
  let objective = originalCampaignData.objective;
  if (objectiveMap[objective]) {
    objective = objectiveMap[objective];
  }

  const data = {
    access_token: masterToken, // Substituição do token
    name: `${originalCampaignData.name} (Cópia)`,
    objective: objective,
    status: ['ACTIVE', 'PAUSED'].includes(originalCampaignData.status) ? originalCampaignData.status : 'PAUSED',
    buying_type: originalCampaignData.buying_type || 'AUCTION', // caso necessário
  };

  // Se a campanha original tiver orçamentos, repassar
  if (originalCampaignData.daily_budget) {
    data.daily_budget = originalCampaignData.daily_budget;
  }
  if (originalCampaignData.lifetime_budget) {
    data.lifetime_budget = originalCampaignData.lifetime_budget;
  }

  try {
    const response = await postRequest(`https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/campaigns`, data);
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar campanha duplicada:`, error);
    throw error;
  }
}

async function createAdSet(facebookToken, accountId, newCampaignId, originalAdSetData) {
  const data = {
    access_token: masterToken, // Substituição do token
    name: `${originalAdSetData.name} (Cópia)`,
    campaign_id: newCampaignId,
    billing_event: originalAdSetData.billing_event || 'IMPRESSIONS',
    optimization_goal: originalAdSetData.optimization_goal || 'REACH',
    promoted_object: JSON.stringify(originalAdSetData.promoted_object || {}), // Necessário como string
    status: ['ACTIVE', 'PAUSED'].includes(originalAdSetData.status) ? originalAdSetData.status : 'PAUSED'
  };

  // orçamento
  if (originalAdSetData.daily_budget) {
    data.daily_budget = originalAdSetData.daily_budget;
  } else if (originalAdSetData.lifetime_budget) {
    data.lifetime_budget = originalAdSetData.lifetime_budget;
  } else {
    // Caso não tenha orçamento definido, define um mínimo
    data.daily_budget = "1000"; // Exemplo: R$10,00
  }

  try {
    const response = await postRequest(`https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/adsets`, data);
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar conjunto de anúncios duplicado:`, error);
    throw error;
  }
}

async function createAdCreative(facebookToken, accountId, originalCreativeObject) {
  // originalCreativeObject é o object_story_spec do anúncio original
  // Precisamos recriar o mesmo creative
  const data = {
    access_token: masterToken, // Substituição do token
    name: `${originalCreativeObject.title || 'Criativo'} (Cópia)`,
    object_story_spec: JSON.stringify(originalCreativeObject) // Necessário como string
  };

  try {
    const response = await postRequest(`https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/adcreatives`, data);
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar criativo duplicado:`, error);
    throw error;
  }
}

async function createAd(facebookToken, accountId, newAdSetId, newCreativeId, originalAdData) {
  const data = {
    access_token: masterToken, // Substituição do token
    name: `${originalAdData.name} (Cópia)`,
    adset_id: newAdSetId,
    status: ['ACTIVE', 'PAUSED'].includes(originalAdData.status) ? originalAdData.status : 'PAUSED',
    creative: `{"creative_id":"${newCreativeId}"}`
  };

  try {
    const response = await postRequest(`https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/ads`, data);
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar anúncio duplicado:`, error);
    throw error;
  }
}

// Duplicar Campanha Completa
async function duplicarCampanhaCompleta(facebookToken, accountId, campaignId) {
  try {
    // Obter dados da campanha original
    const campaignFields = ['name','objective','status','buying_type','daily_budget','lifetime_budget'];
    const originalCampaignData = await getItemData(facebookToken, campaignId, campaignFields);

    // Criar nova campanha
    const newCampaignId = await createCampaign(facebookToken, accountId, originalCampaignData);

    // Obter adsets originais
    const originalAdSets = await getAdSetsForCampaign(facebookToken, campaignId);

    // Duplicar cada adset e seus anúncios
    for (const originalAdSet of originalAdSets) {
      const newAdSetId = await createAdSet(facebookToken, accountId, newCampaignId, originalAdSet);

      // Obter anúncios do adset original
      const originalAds = await getAdsForAdSet(facebookToken, originalAdSet.id);

      // Duplicar anúncios
      for (const originalAd of originalAds) {
        const originalCreative = originalAd.creative?.object_story_spec;
        if (!originalCreative) {
          continue; // Se não houver creative, pula
        }
        // Criar novo criativo
        const newCreativeId = await createAdCreative(facebookToken, accountId, originalCreative);
        // Criar novo anúncio
        await createAd(facebookToken, accountId, newAdSetId, newCreativeId, originalAd);
      }
    }

    toast.success('Campanha e todos os seus conjuntos e anúncios duplicados com sucesso!');
  } catch (error) {
    console.error('Erro ao duplicar campanha completa:', error);
    // O toast de erro já é chamado nas funções auxiliares
  }
}

// Duplicar Conjunto de Anúncios Completo
async function duplicarAdSetCompleto(facebookToken, accountId, adSetId) {
  try {
    // Obter dados do adset original
    const adSetFields = ['name','optimization_goal','destination_type','daily_budget','lifetime_budget','status','billing_event','campaign_id','promoted_object'];
    const originalAdSetData = await getItemData(facebookToken, adSetId, adSetFields);

    // Criar novo adset na mesma campanha do original
    const newAdSetId = await createAdSet(facebookToken, accountId, originalAdSetData.campaign_id, originalAdSetData);

    // Obter anúncios do adset original
    const originalAds = await getAdsForAdSet(facebookToken, adSetId);

    // Duplicar anúncios
    for (const originalAd of originalAds) {
      const originalCreative = originalAd.creative?.object_story_spec;
      if (!originalCreative) continue;
      const newCreativeId = await createAdCreative(facebookToken, accountId, originalCreative);
      await createAd(facebookToken, accountId, newAdSetId, newCreativeId, originalAd);
    }

    toast.success('Conjunto de anúncios e todos seus anúncios duplicados com sucesso!');
  } catch (error) {
    console.error('Erro ao duplicar conjunto de anúncios completo:', error);
    // O toast de erro já é chamado nas funções auxiliares
  }
}

// Duplicar Anúncio
async function duplicarAnuncioCompleto(facebookToken, accountId, adId) {
  try {
    // Obter dados do anúncio original
    const adFields = ['name','status','adset_id','creative{object_story_spec}'];
    const originalAdData = await getItemData(facebookToken, adId, adFields);
    const originalCreative = originalAdData.creative?.object_story_spec;
    if (!originalCreative) {
      toast.error('Não foi possível duplicar anúncio: não há criativo definido.');
      return;
    }

    // Criar novo criativo
    const newCreativeId = await createAdCreative(facebookToken, accountId, originalCreative);

    // Criar novo anúncio
    await createAd(facebookToken, accountId, originalAdData.adset_id, newCreativeId, originalAdData);

    toast.success('Anúncio duplicado com sucesso!');
  } catch (error) {
    console.error('Erro ao duplicar anúncio completo:', error);
    // O toast de erro já é chamado nas funções auxiliares
  }
}

// Função principal para duplicar seleção completa
export async function duplicarSelecaoCompleta(facebookToken, selectedItems, currentLevel, selectedAccount) {
  if (!masterToken) {
    toast.error('Token master não encontrado para duplicação.');
    return;
  }

  const accountId = selectedAccount.replace('act_', '');

  try {
    if (currentLevel === 'campaigns' && selectedItems.campaigns.length > 0) {
      for (const campaignId of selectedItems.campaigns) {
        await duplicarCampanhaCompleta(facebookToken, accountId, campaignId);
      }
    } else if (currentLevel === 'adSets' && selectedItems.adSets.length > 0) {
      for (const adSetId of selectedItems.adSets) {
        await duplicarAdSetCompleto(facebookToken, accountId, adSetId);
      }
    } else if (currentLevel === 'ads' && selectedItems.ads.length > 0) {
      for (const adId of selectedItems.ads) {
        await duplicarAnuncioCompleto(facebookToken, accountId, adId);
      }
    } else {
      toast.info('Nenhum item selecionado para duplicar.');
    }
  } catch (error) {
    console.error('Erro na duplicação da seleção completa:', error);
    // O toast de erro já é chamado nas funções auxiliares
  }
}
