import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { inicializarPalavras } from '../auth/palavraControle';
import './Login.css'; 

const Cadastro = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [numeroWhatsapp, setNumeroWhatsapp] = useState('');
  const [codigoAcesso, setCodigoAcesso] = useState(''); 
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');
  const [contador, setContador] = useState(30); 
  const [sucesso, setSucesso] = useState(false);

  const limparFormulario = () => {
    setNome('');
    setEmail('');
    setSenha('');
    setConfirmarSenha('');
    setNumeroWhatsapp('');
    setCodigoAcesso('');
  };

  useEffect(() => {
    let interval = null;
    if (sucesso && contador > 0) {
      interval = setInterval(() => {
        setContador((prev) => prev - 1);
      }, 1000);
    } else if (contador === 0 && sucesso) {
      navigate('/login');
    }
    return () => clearInterval(interval);
  }, [contador, sucesso, navigate]);

  const handleCadastro = async (e) => {
    e.preventDefault();

    // Resetar mensagens de erro e sucesso
    setErro('');
    setSucesso(false);

    // Validação de campos obrigatórios
    if (!nome || !email || !senha || !confirmarSenha || !numeroWhatsapp) {
      setErro('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Validação de confirmação de senha
    if (senha !== confirmarSenha) {
      setErro('As senhas não coincidem.');
      return;
    }

    // Validação de tamanho mínimo da senha
    if (senha.length < 6) {
      setErro('A senha deve ter no mínimo 6 caracteres.');
      return;
    }

    // Validação do número de WhatsApp
    const numeroSemCaracteresEspeciais = numeroWhatsapp.replace(/\D/g, '');
    if (!/^\d{10,11}$/.test(numeroSemCaracteresEspeciais)) {
      setErro('Por favor, insira um número de WhatsApp válido com DDD, sem espaços, traços ou parênteses.');
      return;
    }

    const numeroCompleto = `55${numeroSemCaracteresEspeciais}`;

    try {
      // Verificar se o usuário já existe
      const { data: usuarioExistente, error: erroUsuarioExistente } = await supabase
        .from('usuarios')
        .select('email, user_id, plano_id')
        .eq('email', email)
        .single();

      if (erroUsuarioExistente && erroUsuarioExistente.code !== 'PGRST116') { // 'PGRST116' é o erro de "no rows found"
        setErro('Erro ao verificar o cadastro do usuário.');
        return;
      }

      if (usuarioExistente) {
        console.log('Usuário existente encontrado:', usuarioExistente);

        // Atualizar o nome e WhatsApp diretamente no Supabase
        const { error: erroAtualizacaoUsuario } = await supabase
          .from('usuarios')
          .update({
            nome: nome,
            numero_whatsapp: numeroCompleto,
          })
          .eq('email', email);

        if (erroAtualizacaoUsuario) {
          setErro('Erro ao atualizar os dados do usuário.');
          console.error('Erro ao atualizar o usuário na tabela usuarios:', erroAtualizacaoUsuario);
          return;
        }

        // Fazer uma requisição para o backend para atualizar a senha
        const response = await fetch('https://servidor.ocaminhoparaprosperidade.com/api/atualizar-senha', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: usuarioExistente.user_id,
            novaSenha: senha,
          }),
        });

        if (!response.ok) {
          setErro('Erro ao atualizar a senha no backend.');
          console.error('Erro ao atualizar a senha via backend:', response.statusText);
          return;
        }

        setSucesso(true);
        setErro('Dados atualizados com sucesso! Por favor, confirme seu e-mail para concluir o cadastro.');
        limparFormulario();
        setContador(30);
        return;
      }

      // Se o usuário não existe, proceder com o cadastro utilizando o código de acesso
      if (codigoAcesso.trim() !== '') {
        // Validar o código de acesso
        const { data: codigoValido, error: erroCodigo } = await supabase
          .from('codigos_acesso')
          .select('codigo, plano_id, tipo_produto, usos_restantes')
          .eq('codigo', codigoAcesso)
          .single();

        if (erroCodigo || !codigoValido) {
          setErro('Código de acesso inválido.');
          return;
        }

        if (codigoValido.usos_restantes <= 0) {
          setErro('O código de acesso já foi utilizado o número máximo de vezes.');
          return;
        }

        // Atualizar o número de usos restantes
        const { error: erroAtualizacaoCodigo } = await supabase
          .from('codigos_acesso')
          .update({ usos_restantes: codigoValido.usos_restantes - 1 })
          .eq('codigo', codigoAcesso);

        if (erroAtualizacaoCodigo) {
          setErro('Erro ao atualizar o código de acesso.');
          return;
        }

        // Cadastrar o novo usuário
        const { data: signUpData, error: erroCadastro } = await supabase.auth.signUp({
          email: email,
          password: senha,
          options: {
            data: {
              nome,
              numero_whatsapp: numeroCompleto,
              plano_id: codigoValido.plano_id,
              tipo_produto: codigoValido.tipo_produto,
            },
          },
        });

        if (erroCadastro) {
          setErro('Erro ao cadastrar o usuário. Tente novamente mais tarde.');
          return;
        }

        // Inserir dados adicionais na tabela 'usuarios'
        const { error: erroInjecaoUsuario } = await supabase
          .from('usuarios')
          .insert([
            {
              user_id: signUpData.user.id,
              email: email,
              nome: nome,
              numero_whatsapp: numeroCompleto,
              plano_id: codigoValido.plano_id,
              tipo_produto: codigoValido.tipo_produto,
              data_renovacao_palavras: new Date().toISOString(), // Define a data de renovação para o momento do cadastro
            },
          ]);

        if (erroInjecaoUsuario) {
          setErro('Erro ao injetar dados na tabela de usuários.');
          return;
        }

        // Inicializar palavras (presumivelmente uma função para configuração inicial)
        await inicializarPalavras(email);
        limparFormulario();
        setSucesso(true);
        setErro('Cadastro realizado com sucesso! Verifique seu e-mail para confirmar o cadastro.');
        setContador(30);
      } else {
        setErro('Por favor, insira o e-mail que você utilizou na compra ou insira um código de acesso válido.');
      }
    } catch (error) {
      console.error('Erro ao cadastrar:', error);
      setErro('Ocorreu um erro ao tentar cadastrar. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="login-container">
  <div className="form-container">
    <div className="gradient-border"></div>
    {/* Inserindo a logo dentro do formulário, no lugar do título */}
    <img src={require('../imagens/03logo_menu_login.webp')} alt="Logo" className="login-logo" />
    <p className="gradient-text">Sua jornada para o sucesso começa aqui!</p>
    <h2 className="register-title">Cadastre-se</h2> {/* Classe adicionada para o título "Cadastre-se" */}
    <p className="login-page-description">Preencha o formulário abaixo para criar uma conta.</p>
    <p className="login-page-description">Digite um número válido com DDD, pois ele será utilizado para recuperar sua senha.</p>
        <form onSubmit={handleCadastro}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Nome Completo"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type={mostrarSenha ? 'text' : 'password'}
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              className="password-input"
              required
            />
            <span
              className="toggle-password"
              onClick={() => setMostrarSenha(!mostrarSenha)}
            >
              <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
          </div>
          <div className="input-container">
            <input
              type={mostrarSenha ? 'text' : 'password'}
              placeholder="Confirme sua Senha"
              value={confirmarSenha}
              onChange={(e) => setConfirmarSenha(e.target.value)}
              className="password-input"
              required
            />
            <span
              className="toggle-password"
              onClick={() => setMostrarSenha(!mostrarSenha)}
            >
              <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
          </div>
          <div className="input-container">
            <input
              type="tel"
              placeholder="Número do WhatsApp"
              value={numeroWhatsapp}
              onChange={(e) => setNumeroWhatsapp(e.target.value.replace(/\D/g, ''))}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Código de Acesso (Opcional)"
              value={codigoAcesso}
              onChange={(e) => setCodigoAcesso(e.target.value)}
            />
          </div>
          {erro && <p className={`error ${sucesso ? 'sucesso' : ''}`}>{erro}</p>}
          {sucesso && (
            <p className="contador">
              {contador > 0 
                ? `Redirecionando em ${contador} segundos...` 
                : 'Redirecionando para a tela de login...'}
            </p>
          )}
          <button type="submit" className="btn-cadastrar">
            Cadastrar
          </button>
          <button 
            type="button" 
            className="button-cadastrar" 
            onClick={() => navigate('/login')}
          >
            Voltar para a tela de login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Cadastro;
