import React, { useState, useEffect } from 'react';
import {
  ArrowLeft,
  BarChart,
  DollarSign,
  Users,
  Percent,
  ShoppingCart,
  HelpCircle,
  ThumbsUp,
  CheckCircle,
  Info,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';

const FunilVendas = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('userEmail') || 'Usuário';
  const [numVisitors, setNumVisitors] = useState('');
  const [commission, setCommission] = useState('');

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const calculateSales = (rate) =>
    Math.floor((numVisitors || 0) * (rate / 100));
  const calculateRevenue = (rate) =>
    calculateSales(rate) * (commission || 0);

  const clients10 = calculateSales(10);
  const clients5 = calculateSales(5);
  const clients3 = calculateSales(3);
  const revenue10 = calculateRevenue(10);
  const revenue5 = calculateRevenue(5);
  const revenue3 = calculateRevenue(3);

  return (
    <div className="tailwind-scope">
      {/* Contêiner principal com background cobrindo toda a tela e animação de fade-in */}
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 w-full w-screen overflow-x-hidden animate-fadeIn">
        {/* Cabeçalho fixo e ocupando toda a largura */}
        <header className="fixed top-0 left-0 w-full bg-black backdrop-blur-lg shadow-lg border-b border-yellow-500 z-50">
          <div className="flex justify-between items-center px-6 py-4">
            <img
              src="../../imagens/logo_menu_login.webp"
              alt="Logo"
              className="h-16 w-auto object-contain transition-transform duration-300 ease-in-out hover:scale-105"
            />
            <div className="flex items-center gap-4 animate-fadeInRight">
              <span className="text-white font-medium relative pb-1 hover:after:w-full after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-green-500 after:transition-all after:duration-300">
                {userEmail}
              </span>
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 px-4 py-2 bg-red-600 bg-opacity-25 border border-red-600 text-white rounded-lg hover:bg-opacity-50 hover:-translate-y-0.5 transition-all duration-300 ease-in-out"
              >
                Sair
              </button>
            </div>
          </div>
        </header>

        {/* Espaçamento ajustado entre o cabeçalho e o conteúdo */}
        <div className="pt-[2000px]"></div>

        {/* Conteúdo principal */}
        <main className="w-full w-screen flex-1 flex flex-col items-center px-6 box-border animate-fadeInUp">
          {/* Seção do título */}
          <div className="w-full flex flex-col items-center mb-8 self-stretch">
            <h1 className="text-4xl font-bold text-black mb-4 relative pb-2">
              Funil de Vendas WhatsApp
            </h1>
            <button
              onClick={() => navigate('/ferramentas')}
              className="inline-flex items-center gap-2 px-6 py-3 bg-gray-800 text-white rounded-full hover:bg-gray-700 hover:-translate-y-0.5 transition-all duration-300"
            >
              <ArrowLeft className="w-5 h-5" />
              Voltar para o Menu
            </button>
          </div>

          {/* Descrição */}
          <div className="w-full bg-white rounded-lg shadow-lg p-8 mb-8 border border-gray-200 transition-all duration-300 hover:shadow-2xl self-stretch">
            <p className="text-gray-700 text-lg">
              Nosso aplicativo facilita o entendimento de como funciona um funil
              de vendas. Acompanhe a jornada do cliente, da captação ao
              fechamento.
            </p>
          </div>

          {/* Seção de Input com melhorias */}
          <div className="w-full flex flex-col gap-8 sm:flex-row mb-8 self-stretch">
            {/* Número de visitantes */}
            <div className="flex-1 bg-white rounded-lg shadow-lg p-6 border border-gray-200 transition-all duration-300 hover:shadow-2xl relative">
              <Users className="w-6 h-6 text-indigo-500 absolute top-4 left-4" />
              <label className="text-gray-700 font-medium mb-2 block pl-12">
                Número de visitantes:
              </label>
              <input
                type="number"
                value={numVisitors}
                onChange={(e) => setNumVisitors(e.target.value)}
                className="w-full bg-white border border-gray-300 rounded-lg p-4 pl-12 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                placeholder="Ex: 1000"
              />
            </div>
            {/* Comissão por venda */}
            <div className="flex-1 bg-white rounded-lg shadow-lg p-6 border border-gray-200 transition-all duration-300 hover:shadow-2xl relative">
              <DollarSign className="w-6 h-6 text-green-500 absolute top-4 left-4" />
              <label className="text-gray-700 font-medium mb-2 block pl-12">
                Comissão por venda (R$):
              </label>
              <input
                type="number"
                value={commission}
                onChange={(e) => setCommission(e.target.value)}
                className="w-full bg-white border border-gray-300 rounded-lg p-4 pl-12 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                placeholder="Ex: 100"
              />
            </div>
          </div>

          {/* Visualização do Funil como um funil de vendas */}
          <div className="w-full bg-white rounded-lg shadow-lg p-8 mb-8 border border-gray-200 transition-all duration-300 hover:shadow-2xl self-stretch">
            <div className="flex flex-col items-center">
              {[
                {
                  color: 'from-blue-500 to-blue-600',
                  icon: BarChart,
                  title: 'Anúncio Facebook',
                  value: numVisitors || 0,
                  suffix: 'visitantes',
                  width: 'w-full',
                  description:
                    'O anúncio no Facebook atrai visitantes para o seu funil de vendas.',
                },
                {
                  color: 'from-indigo-500 to-indigo-600',
                  icon: Users,
                  title: 'Boas-vindas WhatsApp',
                  value: Math.floor((numVisitors || 0) * 0.8),
                  suffix: 'leads',
                  width: 'w-11/12',
                  description:
                    'Mensagens automáticas de boas-vindas qualificam os leads no WhatsApp.',
                },
                {
                  color: 'from-purple-500 to-purple-600',
                  icon: ShoppingCart,
                  title: 'Apresentação do Produto',
                  value: Math.floor((numVisitors || 0) * 0.5),
                  suffix: 'interessados',
                  width: 'w-10/12',
                  description:
                    'Informações detalhadas sobre o produto aumentam o interesse dos leads.',
                },
                {
                  color: 'from-pink-500 to-pink-600',
                  icon: HelpCircle,
                  title: 'Esclarecimento de Dúvidas',
                  value: Math.floor((numVisitors || 0) * 0.3),
                  suffix: 'qualificados',
                  width: 'w-9/12',
                  description:
                    'Responder dúvidas é crucial para qualificar os leads para a negociação.',
                },
                {
                  color: 'from-red-500 to-red-600',
                  icon: ThumbsUp,
                  title: 'Negociação',
                  value: Math.floor((numVisitors || 0) * 0.2),
                  suffix: 'negociando',
                  width: 'w-8/12',
                  description:
                    'Negociação das condições para fechar a venda de forma eficaz.',
                },
                {
                  color: 'from-green-500 to-green-600',
                  icon: CheckCircle,
                  title: 'Fechamento',
                  value: Math.floor((numVisitors || 0) * 0.1),
                  suffix: 'clientes',
                  width: 'w-7/12',
                  description:
                    'Conclua a venda e oriente o cliente para o pós-venda.',
                },
              ].map((step, index) => (
                <div
                  key={index}
                  className={`relative flex items-center justify-between bg-gradient-to-r ${step.color} ${step.width} rounded-lg p-6 transform hover:scale-105 transition-transform duration-300 mb-4`}
                >
                  <div className="flex items-center gap-4">
                    <step.icon className="w-6 h-6 text-white" />
                    <h3 className="text-xl font-semibold text-white">
                      {step.title}
                    </h3>
                  </div>
                  <span className="text-white font-medium text-lg">
                    {step.value} {step.suffix}
                  </span>
                  {/* Tooltip */}
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-70 rounded-lg transition-opacity duration-300">
                    <div className="text-center text-white p-4">
                      <p className="mb-2">{step.description}</p>
                      <p className="text-sm text-gray-300">
                        Exemplo: {step.title} gera {step.value} {step.suffix}.
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Relatório de Conversão */}
          <div className="w-full flex flex-col gap-6 sm:flex-row mb-8 self-stretch">
            {[
              {
                color: 'from-green-500 to-green-600',
                rate: 10,
                clients: clients10,
                revenue: revenue10,
                description:
                  'Uma taxa de conversão de 10% indica que 10% dos leads se tornaram clientes.',
              },
              {
                color: 'from-blue-500 to-blue-600',
                rate: 5,
                clients: clients5,
                revenue: revenue5,
                description:
                  'Uma taxa de conversão de 5% representa um bom desempenho em determinadas indústrias.',
              },
              {
                color: 'from-purple-500 to-purple-600',
                rate: 3,
                clients: clients3,
                revenue: revenue3,
                description:
                  'Mesmo uma taxa de 3% pode ser lucrativa dependendo do valor da comissão.',
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`flex-1 bg-gradient-to-br ${item.color} rounded-2xl shadow-lg p-6 text-white transform hover:scale-105 transition-transform duration-300 relative`}
              >
                <div className="flex items-center gap-3 mb-4">
                  <Percent className="w-6 h-6" />
                  <h3 className="text-xl font-semibold">
                    Conversão: {item.rate}%
                  </h3>
                  {/* Tooltip Trigger */}
                  <div className="relative group">
                    <Info className="w-5 h-5 ml-2 cursor-pointer" />
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 bg-gray-800 text-white text-sm rounded-lg p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {item.description}
                    </div>
                  </div>
                </div>
                <p className="text-2xl font-bold mb-2">
                  {item.clients} vendas
                </p>
                <p className="text-lg">
                  Receita: R$ {item.revenue.toFixed(2)}
                </p>
              </div>
            ))}
          </div>

          {/* Texto Explicativo sobre Conversão */}
          <div className="w-full bg-white rounded-lg shadow-lg p-6 mb-8 border border-gray-200 transition-all duration-300 hover:shadow-2xl self-stretch">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Importância da Conversão
            </h2>
            <p className="text-gray-700 mb-2">
              Uma boa taxa de conversão é essencial para aumentar sua margem de
              lucro. Quanto mais leads você converte em clientes, maior será
              seu faturamento.
            </p>
            <p className="text-gray-700">
              Otimizar cada etapa do funil de vendas pode levar a um aumento
              significativo nas vendas e na eficiência do seu negócio.
            </p>
          </div>

          {/* Notas Explicativas */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-6 self-stretch">
            {[
              {
                icon: BarChart,
                color: 'text-blue-600',
                title: 'Anúncio Facebook',
                description:
                  'O anúncio no Facebook é a primeira etapa do funil, responsável por atrair visitantes.',
              },
              {
                icon: Users,
                color: 'text-indigo-600',
                title: 'Boas-vindas no WhatsApp',
                description:
                  'Fase de envio de mensagens automáticas de boas-vindas e qualificação inicial.',
              },
              {
                icon: ShoppingCart,
                color: 'text-purple-600',
                title: 'Apresentação do Produto',
                description:
                  'Fornecimento de informações detalhadas sobre o produto/serviço.',
              },
              {
                icon: HelpCircle,
                color: 'text-pink-600',
                title: 'Esclarecimento de Dúvidas',
                description:
                  'Responder às dúvidas e objeções é crucial para a próxima fase.',
              },
              {
                icon: ThumbsUp,
                color: 'text-red-600',
                title: 'Negociação',
                description:
                  'Apresentação de oferta clara e negociação das condições.',
              },
              {
                icon: CheckCircle,
                color: 'text-green-600',
                title: 'Fechamento',
                description:
                  'Fase final onde a venda é concluída e o cliente é orientado.',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 border border-gray-200 transform hover:shadow-2xl transition-all duration-300 relative group"
              >
                <div className="flex items-center gap-3 mb-3">
                  <item.icon className={`w-6 h-6 ${item.color}`} />
                  <h3 className="text-xl font-semibold text-gray-800">
                    {item.title}
                  </h3>
                  {/* Tooltip Trigger */}
                  <div className="relative group">
                    <Info className="w-4 h-4 text-gray-400 ml-1 cursor-pointer" />
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-56 bg-gray-800 text-white text-sm rounded-lg p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {item.description}
                    </div>
                  </div>
                </div>
                <p className="text-gray-700 text-base">{item.description}</p>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default FunilVendas;
