import React, { useState } from 'react';
import './GeradorDeAudio.css';
import axios from 'axios';
import { getUserEmail } from '../../auth/auth';

const TOKEN = process.env.REACT_APP_VOZ_VIDEO_API_TOKEN;

const GeradorDeAudio = () => {
  const [vozes, setVozes] = useState([]);
  const [audioGerado, setAudioGerado] = useState(null);
  const [text, setText] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [audiosUsuario, setAudiosUsuario] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audiosListados, setAudiosListados] = useState(false);
  const [vozesListadas, setVozesListadas] = useState(false);

  const userEmail = getUserEmail();

  // Função para listar as vozes
  const listarVozes = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://voz-pra-video-api.fly.dev/api/ai-voices/list', {
        headers: { Authorization: `Token ${TOKEN}` },
      });
      setVozes(response.data);
      setVozesListadas(true);
    } catch (error) {
      console.error('Erro ao listar vozes:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para listar áudios gerados pelo usuário
  const listarAudiosUsuario = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://voz-pra-video-api.fly.dev/api/audio/list', {
        headers: { Authorization: `Token ${TOKEN}` },
      });
      setAudiosUsuario(response.data);
      setAudiosListados(true);
    } catch (error) {
      console.error('Erro ao listar áudios do usuário:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para gerar áudio
  const gerarAudio = async () => {
    if (!text || !selectedVoice) {
      alert('Por favor, insira um texto e selecione uma voz.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        'https://voz-pra-video-api.fly.dev/api/audio/generate',
        {
          voiceId: selectedVoice,
          text: text,
          email: userEmail,
        },
        {
          headers: { Authorization: `Token ${TOKEN}` },
        }
      );
      setAudioGerado(response.data.audioUrl);
    } catch (error) {
      console.error('Erro ao gerar áudio:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="gerador-audio-container-unique">
      <h2>Gerador de Áudio com IA</h2>

      {/* Bloco para gerar áudio */}
      <div className="bloco-gerar-audio">
        <h3>Gerar Novo Áudio</h3>
        <textarea 
          value={text} 
          onChange={(e) => setText(e.target.value)} 
          placeholder="Insira seu texto aqui..."
          className="textarea-unique"
        />
        {vozesListadas && (
          <select 
            value={selectedVoice} 
            onChange={(e) => setSelectedVoice(e.target.value)} 
            disabled={loading}
            className="select-unique"
          >
            <option value="">Selecione uma voz</option>
            {vozes.map(voz => (
              <option key={voz._id} value={voz._id}>{voz.name}</option>
            ))}
          </select>
        )}
        <button onClick={gerarAudio} disabled={loading} className="button-unique">
          {loading ? 'Gerando...' : 'Gerar Áudio'}
        </button>
      </div>

      {/* Bloco para listar áudios */}
      <div className="bloco-listar-audios">
        <h3>Listar Áudios Gerados</h3>
        <button onClick={listarAudiosUsuario} disabled={loading} className="button-unique">
          {loading ? 'Carregando...' : 'Listar Áudios'}
        </button>
        {audiosListados && audiosUsuario.length > 0 && (
          <div className="audios-salvos-unique">
            <h4>Seus Áudios:</h4>
            {audiosUsuario.map((audio, index) => (
              <div key={index}>
                <audio controls>
                  <source src={audio.url} type="audio/mp3" />
                </audio>
                <a href={audio.url} download={`audio_${index}.mp3`}>Baixar</a>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Bloco para listar vozes */}
      <div className="bloco-listar-vozes">
        <h3>Listar Vozes Disponíveis</h3>
        <button onClick={listarVozes} disabled={loading} className="button-unique">
          {loading ? 'Carregando...' : 'Listar Vozes'}
        </button>
        {vozesListadas && (
          <div className="vozes-disponiveis-unique">
            <h4>Vozes Disponíveis:</h4>
            {vozes.map((voz, index) => (
              <div key={index}>
                <p>{voz.name}</p>
                <audio controls>
                  <source src={voz.audioPreview} type="audio/mp3" />
                </audio>
              </div>
            ))}
          </div>
        )}
      </div>

    </div>
  );
};

export default GeradorDeAudio;
