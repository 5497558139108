import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminAuthenticated, adminLogout } from '../../auth/auth';
import logo from '../../imagens/logo_menu_login.webp'; // Caminho correto da logo
import './cursos.css'; // Novo arquivo CSS adaptado
import CriarCursos from './CriarCursos'; // Importando o novo componente

const Cursos = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o administrador está autenticado
    if (!isAdminAuthenticated()) {
      navigate('/adminLogin');
    }
  }, [navigate]);

  const handleLogout = () => {
    adminLogout(); // Função para deslogar o administrador
  };

  const handleBackToMenu = () => {
    navigate('/painelAdmin'); // Navega de volta para o menu da administração
  };

  return (
    <div className="cursos-container">
      <div className="cursos-header">
        <img src={logo} alt="Logo" className="cursos-logo" />
        <div className="cursos-user-info">
          <span>Bem-vindo(a), Admin</span>
          <button className="cursos-button-logout" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="cursos-content">
        <h1 className="cursos-title">Gerenciamento de Cursos e Aulas</h1>
        <p className="cursos-description">
          Aqui você pode gerenciar a criação de cursos, módulos e aulas.
        </p>
        <button className="cursos-button-menu" onClick={handleBackToMenu}>
          Voltar para o Menu
        </button>

        {/* Renderizando o componente de criação de cursos, módulos e aulas */}
        <CriarCursos />

      </div>
    </div>
  );
};

export default Cursos;
