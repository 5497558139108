import { createClient } from '@supabase/supabase-js';
import { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChevronDown, ChevronUp, Edit, Trash2, Plus, Video, File, Save } from 'lucide-react';
import './CriarCursos.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const CriarCursos = () => {
  const [novoModulo, setNovoModulo] = useState({ nome: '', descricao: '' });
  const [novaAula, setNovaAula] = useState({ nome: '', descricao: '', link_video: '', link_arquivos: [''], modulo_id: '' });
  const [modulos, setModulos] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [modulosAbertos, setModulosAbertos] = useState({});
  const [moduloEditando, setModuloEditando] = useState(null);
  const [aulaEditando, setAulaEditando] = useState(null);

  useEffect(() => {
    carregarModulos();
  }, []);

  const carregarModulos = async () => {
    const { data: modulosData, error } = await supabase
      .from('modulos')
      .select('*')
      .order('ordem', { ascending: true });
    if (error) {
      console.error('Erro ao carregar módulos:', error);
    } else {
      setModulos(modulosData);
      carregarTodasAulas(modulosData);
    }
  };

  const carregarTodasAulas = async (modulos) => {
    const aulasPromises = modulos.map(modulo => 
      supabase
        .from('aulas')
        .select('*')
        .eq('modulo_id', modulo.id)
        .order('ordem', { ascending: true })
    );

    const resultados = await Promise.all(aulasPromises);
    const todasAulas = resultados.flatMap(resultado => resultado.data || []);
    setAulas(todasAulas);
  };

  const toggleModulo = (moduloId) => {
    setModulosAbertos(prev => ({
      ...prev,
      [moduloId]: !prev[moduloId]
    }));
  };

  const adicionarModulo = async () => {
    const { data, error } = await supabase
      .from('modulos')
      .insert([{ nome: novoModulo.nome, descricao: novoModulo.descricao, ordem: modulos.length }])
      .select();

    if (error) {
      console.error('Erro ao adicionar módulo:', error);
    } else {
      setModulos([...modulos, data[0]]);
      exibirMensagemSucesso('Módulo criado com sucesso!');
      setNovoModulo({ nome: '', descricao: '' });
    }
  };

  const adicionarAula = async () => {
    if (!novaAula.modulo_id) {
      alert('Por favor, selecione um módulo para adicionar a aula.');
      return;
    }

    const aulasNoModulo = aulas.filter(aula => aula.modulo_id === novaAula.modulo_id);
    const { data, error } = await supabase
      .from('aulas')
      .insert([{ 
        nome: novaAula.nome, 
        descricao: novaAula.descricao, 
        link_video: novaAula.link_video, 
        link_arquivos: novaAula.link_arquivos,  
        modulo_id: novaAula.modulo_id,
        ordem: aulasNoModulo.length
      }])
      .select();

    if (error) {
      console.error('Erro ao adicionar aula:', error);
    } else {
      setAulas([...aulas, data[0]]);
      exibirMensagemSucesso('Aula adicionada com sucesso!');
      setNovaAula({ nome: '', descricao: '', link_video: '', link_arquivos: [''], modulo_id: '' });
    }
  };

  const excluirModulo = async (moduloId) => {
    const { error } = await supabase
      .from('modulos')
      .delete()
      .eq('id', moduloId);
    if (error) {
      console.error('Erro ao excluir módulo:', error);
    } else {
      setModulos(modulos.filter(modulo => modulo.id !== moduloId));
      setAulas(aulas.filter(aula => aula.modulo_id !== moduloId));
      atualizarOrdemModulos(modulos.filter(modulo => modulo.id !== moduloId));
      exibirMensagemSucesso('Módulo excluído com sucesso!');
    }
  };

  const excluirAula = async (aulaId, moduloId) => {
    const { error } = await supabase
      .from('aulas')
      .delete()
      .eq('id', aulaId);
    if (error) {
      console.error('Erro ao excluir aula:', error);
    } else {
      const aulasAtualizadas = aulas.filter(aula => aula.id !== aulaId);
      setAulas(aulasAtualizadas);
      atualizarOrdemAulas(aulasAtualizadas.filter(aula => aula.modulo_id === moduloId));
      exibirMensagemSucesso('Aula excluída com sucesso!');
    }
  };

  const handleDragEnd = async (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    // Reordenar Módulos
    if (type === 'modulos') {
      if (source.droppableId === destination.droppableId && source.index === destination.index) return;

      const modulosReordenados = Array.from(modulos);
      const [movedModulo] = modulosReordenados.splice(source.index, 1);
      modulosReordenados.splice(destination.index, 0, movedModulo);

      setModulos(modulosReordenados);
      await atualizarOrdemModulos(modulosReordenados);
    }

    // Reordenar Aulas
    if (type === 'aulas') {
      const sourceDroppableId = source.droppableId;
      const destinationDroppableId = destination.droppableId;

      // Garantir que estamos lidando com o mesmo módulo
      if (sourceDroppableId !== destinationDroppableId) {
        alert('Mover aulas entre módulos não está implementado.');
        return;
      }

      const moduloId = sourceDroppableId.split('-')[1];
      const aulasDoModulo = aulas
        .filter(aula => aula.modulo_id.toString() === moduloId.toString())
        .sort((a, b) => a.ordem - b.ordem);

      // Remover a aula do local original
      const [movedAula] = aulasDoModulo.splice(source.index, 1);

      // Inserir a aula na nova posição
      aulasDoModulo.splice(destination.index, 0, movedAula);

      // Atualizar a ordem das aulas no módulo
      const aulasReordenadas = aulasDoModulo.map((aula, index) => ({
        ...aula,
        ordem: index
      }));

      // Atualizar o estado global de aulas
      const novasAulas = aulas.map(aula => {
        if (aula.modulo_id.toString() === moduloId.toString()) {
          const aulaReordenada = aulasReordenadas.find(a => a.id === aula.id);
          return aulaReordenada ? aulaReordenada : aula;
        }
        return aula;
      });

      setAulas(novasAulas);

      // Atualizar a ordem no banco de dados
      await atualizarOrdemAulas(aulasReordenadas);
    }
  };

  const atualizarOrdemModulos = async (modulosReordenados) => {
    const updates = modulosReordenados.map((modulo, index) => {
      return supabase
        .from('modulos')
        .update({ ordem: index })
        .eq('id', modulo.id);
    });

    const resultados = await Promise.all(updates);
    resultados.forEach(({ error }) => {
      if (error) {
        console.error('Erro ao atualizar ordem dos módulos:', error);
      }
    });
  };

  const atualizarOrdemAulas = async (aulasReordenadas) => {
    const updates = aulasReordenadas.map((aula, index) => {
      return supabase
        .from('aulas')
        .update({ ordem: index })
        .eq('id', aula.id);
    });

    const resultados = await Promise.all(updates);
    resultados.forEach(({ error }) => {
      if (error) {
        console.error('Erro ao atualizar ordem das aulas:', error);
      }
    });
  };

  const iniciarEdicaoModulo = (modulo) => {
    setModuloEditando({ ...modulo });
  };

  const cancelarEdicaoModulo = () => {
    setModuloEditando(null);
  };

  const salvarEdicaoModulo = async () => {
    const { error } = await supabase
      .from('modulos')
      .update({ nome: moduloEditando.nome, descricao: moduloEditando.descricao })
      .eq('id', moduloEditando.id);

    if (error) {
      console.error('Erro ao atualizar módulo:', error);
    } else {
      setModulos(modulos.map(m => m.id === moduloEditando.id ? moduloEditando : m));
      setModuloEditando(null);
      exibirMensagemSucesso('Módulo atualizado com sucesso!');
    }
  };

  const iniciarEdicaoAula = (aula) => {
    setAulaEditando({ 
      ...aula, 
      link_arquivos: Array.isArray(aula.link_arquivos) ? aula.link_arquivos : [aula.link_arquivos] 
    });
  };

  const cancelarEdicaoAula = () => {
    setAulaEditando(null);
  };

  const salvarEdicaoAula = async () => {
    const { error } = await supabase
      .from('aulas')
      .update({
        nome: aulaEditando.nome,
        descricao: aulaEditando.descricao,
        link_video: aulaEditando.link_video,
        link_arquivos: aulaEditando.link_arquivos
      })
      .eq('id', aulaEditando.id);

    if (error) {
      console.error('Erro ao atualizar aula:', error);
    } else {
      setAulas(aulas.map(a => a.id === aulaEditando.id ? aulaEditando : a));
      setAulaEditando(null);
      exibirMensagemSucesso('Aula atualizada com sucesso!');
    }
  };

  const exibirMensagemSucesso = (mensagem) => {
    setMensagemSucesso(mensagem);
    setTimeout(() => setMensagemSucesso(''), 3000);
  };

  return (
    <div className="cc-container">
      <div className="cc-form">
        <h2 className="cc-title">Criar Novo Módulo</h2>
        <div className="cc-input-group">
          <input
            type="text"
            placeholder="Nome do Módulo"
            value={novoModulo.nome}
            onChange={(e) => setNovoModulo({ ...novoModulo, nome: e.target.value })}
            className="cc-input"
          />
          <textarea
            placeholder="Descrição do Módulo"
            value={novoModulo.descricao}
            onChange={(e) => setNovoModulo({ ...novoModulo, descricao: e.target.value })}
            className="cc-textarea"
          />
          <button onClick={adicionarModulo} className="cc-btn-primary">
            <Plus size={20} /> Criar Módulo
          </button>
        </div>

        <div className="cc-separator" />

        <h2 className="cc-title">Adicionar Nova Aula</h2>
        <div className="cc-input-group">
          <select
            value={novaAula.modulo_id}
            onChange={(e) => setNovaAula({ ...novaAula, modulo_id: e.target.value })}
            className="cc-select"
          >
            <option value="">Selecione o Módulo</option>
            {modulos.map((modulo) => (
              <option key={modulo.id} value={modulo.id}>{modulo.nome}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Nome da Aula"
            value={novaAula.nome}
            onChange={(e) => setNovaAula({ ...novaAula, nome: e.target.value })}
            className="cc-input"
          />
          <textarea
            placeholder="Descrição da Aula"
            value={novaAula.descricao}
            onChange={(e) => setNovaAula({ ...novaAula, descricao: e.target.value })}
            className="cc-textarea"
          />
          <div className="cc-input-icon">
            <Video size={20} />
            <input
              type="text"
              placeholder="Link do Vídeo"
              value={novaAula.link_video}
              onChange={(e) => setNovaAula({ ...novaAula, link_video: e.target.value })}
              className="cc-input"
            />
          </div>
          {novaAula.link_arquivos.map((link, index) => (
            <div key={index} className="cc-input-icon">
              <File size={20} />
              <input
                type="text"
                placeholder="Link do Arquivo"
                value={link}
                onChange={(e) => {
                  const novosArquivos = [...novaAula.link_arquivos];
                  novosArquivos[index] = e.target.value;
                  setNovaAula({ ...novaAula, link_arquivos: novosArquivos });
                }}
                className="cc-input"
              />
            </div>
          ))}
          <button 
            onClick={() => setNovaAula({ 
              ...novaAula, 
              link_arquivos: [...novaAula.link_arquivos, ''] 
            })}
            className="cc-btn-secondary"
          >
            <Plus size={20} /> Adicionar Arquivo
          </button>
          <button onClick={adicionarAula} className="cc-btn-primary">
            <Plus size={20} /> Adicionar Aula
          </button>
        </div>
      </div>

      <div className="cc-modulos-lista">
        <h2 className="cc-title">Módulos e Aulas</h2>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="modulos" type="modulos">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {modulos.map((modulo, index) => (
                  <Draggable key={modulo.id} draggableId={modulo.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="cc-modulo-card"
                      >
                        {moduloEditando && moduloEditando.id === modulo.id ? (
                          <div className="cc-modulo-edit">
                            <input
                              type="text"
                              value={moduloEditando.nome}
                              onChange={(e) => setModuloEditando({...moduloEditando, nome: e.target.value})}
                              className="cc-input"
                            />
                            <textarea
                              value={moduloEditando.descricao}
                              onChange={(e) => setModuloEditando({...moduloEditando, descricao: e.target.value})}
                              className="cc-textarea"
                            />
                            <div className="cc-btn-group">
                              <button onClick={salvarEdicaoModulo} className="cc-btn-save">
                                <Save size={20} /> Salvar
                              </button>
                              <button onClick={cancelarEdicaoModulo} className="cc-btn-cancel">
                                Cancelar
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div 
                            className="cc-modulo-header"
                            onClick={() => toggleModulo(modulo.id)}
                          >
                            <h3 className="cc-modulo-titulo">{modulo.nome}</h3>
                            <div className="cc-modulo-actions">
                              <button className="cc-btn-icon" onClick={(e) => {
                                e.stopPropagation();
                                iniciarEdicaoModulo(modulo);
                              }}>
                                <Edit size={20} />
                              </button>
                              <button 
                                className="cc-btn-icon-delete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  excluirModulo(modulo.id);
                                }}
                              >
                                <Trash2 size={20} />
                              </button>
                              {modulosAbertos[modulo.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                            </div>
                          </div>
                        )}
                        
                        {modulosAbertos[modulo.id] && (
                          <Droppable droppableId={`modulo-${modulo.id}`} type="aulas">
                            {(provided) => (
                              <div 
                                className="cc-aulas-lista"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {aulas
                                  .filter(aula => aula.modulo_id.toString() === modulo.id.toString())
                                  .sort((a, b) => a.ordem - b.ordem)
                                  .map((aula, index) => (
                                    <Draggable 
                                      key={aula.id} 
                                      draggableId={aula.id.toString()} 
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="cc-aula-item"
                                        >
                                          {aulaEditando && aulaEditando.id === aula.id ? (
                                            <div className="cc-aula-edit">
                                              <input
                                                type="text"
                                                value={aulaEditando.nome}
                                                onChange={(e) => setAulaEditando({...aulaEditando, nome: e.target.value})}
                                                className="cc-input"
                                              />
                                              <textarea
                                                value={aulaEditando.descricao}
                                                onChange={(e) => setAulaEditando({...aulaEditando, descricao: e.target.value})}
                                                className="cc-textarea"
                                              />
                                              <input
                                                type="text"
                                                value={aulaEditando.link_video}
                                                onChange={(e) => setAulaEditando({...aulaEditando, link_video: e.target.value})}
                                                className="cc-input"
                                                placeholder="Link do Vídeo"
                                              />
                                              {aulaEditando.link_arquivos.map((link, index) => (
                                                <input
                                                  key={index}
                                                  type="text"
                                                  value={link}
                                                  onChange={(e) => {
                                                    const novosArquivos = [...aulaEditando.link_arquivos];
                                                    novosArquivos[index] = e.target.value;
                                                    setAulaEditando({...aulaEditando, link_arquivos: novosArquivos});
                                                  }}
                                                  className="cc-input"
                                                  placeholder={`Link do Arquivo ${index + 1}`}
                                                />
                                              ))}
                                              <div className="cc-btn-group">
                                                <button onClick={salvarEdicaoAula} className="cc-btn-save">
                                                  <Save size={20} /> Salvar
                                                </button>
                                                <button onClick={cancelarEdicaoAula} className="cc-btn-cancel">
                                                  Cancelar
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <span>{aula.nome}</span>
                                              <div className="cc-aula-actions">
                                                <button className="cc-btn-icon" onClick={() => iniciarEdicaoAula(aula)}>
                                                  <Edit size={18} />
                                                </button>
                                                <button 
                                                  className="cc-btn-icon-delete"
                                                  onClick={() => excluirAula(aula.id, modulo.id)}
                                                >
                                                  <Trash2 size={18} />
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {mensagemSucesso && (
        <div className="cc-mensagem-sucesso">
          {mensagemSucesso}
        </div>
      )}
    </div>
  );
};

export default CriarCursos;
