// src/paginas/minhaConta/PainelDeControle.js
import React, { useEffect, useState } from 'react';
import { 
    FaUser, 
    FaEnvelope, 
    FaPhone, 
    FaSyncAlt, 
    FaRegFileAlt, 
    FaBullseye, 
    FaChartBar,
    FaCalendarAlt,
    FaClock
} from 'react-icons/fa';
import './PainelDeControle.css';
import { isAuthenticated, getUserEmail } from '../../auth/auth';
import { supabase } from '../../supabaseClient';

const PainelDeControle = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [palavrasDisponiveis, setPalavrasDisponiveis] = useState(0);
  const [erro, setErro] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);
  const [tipoProduto, setTipoProduto] = useState([]);
  const [plano, setPlano] = useState({ nome: '', palavrasMax: 100000 });
  const [isUploading, setIsUploading] = useState(false);
  const [dataCriacao, setDataCriacao] = useState(null);
  const [planoSelecionado, setPlanoSelecionado] = useState('');
  const [mostrarSelector, setMostrarSelector] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [compraInfo, setCompraInfo] = useState([]);

  // Mapeamento de plano_id para nome do plano e limite de palavras
  const planoMap = {
    '1': { nome: 'Gratuito', palavrasMax: 6000 },
    '2': { nome: 'Básico', palavrasMax: 12000 },
    '3': { nome: 'Premium', palavrasMax: 100000 },
  };

  const fetchPurchaseData = async (userUUID) => {
    try {
      const { data: comprasData, error: comprasError } = await supabase
        .from('compras')
        .select('*')
        .eq('usuario_id', userUUID);

      if (comprasError) {
        console.error('Erro ao buscar compras do usuário:', comprasError);
        setCompraInfo([]); // Continua mesmo com erro
        return;
      }

      if (!comprasData || comprasData.length === 0) {
        // Usuário não possui compras
        setCompraInfo([]);
        return;
      }

      // Obter detalhes de cada compra
      const comprasDetalhadas = await Promise.all(comprasData.map(async (compra) => {
        // Buscar informações do produto
        let produto = null;
        if (compra.produto_id) {
          const { data: produtoData, error: produtoError } = await supabase
            .from('produtos')
            .select('*')
            .eq('id', compra.produto_id)
            .single();

          if (produtoError) {
            console.error('Erro ao buscar produto:', produtoError);
          } else {
            produto = produtoData;
          }
        }

        // Buscar informações do plano
        let planoCompra = null;
        if (compra.plano_id) {
          const { data: planoData, error: planoError } = await supabase
            .from('tipos_de_plano')
            .select('*')
            .eq('id', compra.plano_id)
            .single();

          if (planoError) {
            console.error('Erro ao buscar plano:', planoError);
          } else {
            planoCompra = planoData;
          }
        }

        return {
          compra,
          produto,
          plano: planoCompra,
        };
      }));

      setCompraInfo(comprasDetalhadas);

    } catch (error) {
      console.error('Erro inesperado ao buscar compras:', error);
      setCompraInfo([]); // Continua mesmo com erro
    }
  };

  const fetchUserData = async (userEmail) => {
    try {
      const { data, error } = await supabase
        .from('usuarios')
        .select('*')
        .eq('email', userEmail)
        .single();

      if (error) throw error;

      setNome(data.nome || '');
      setTelefone(data.numero_whatsapp || 'Não disponível');
      setUsuarioId(data.user_id); // Aqui usamos data.user_id como UUID
      setPalavrasDisponiveis(data.palavras_disponiveis || 0);
      setPlano(planoMap[data.plano_id] || { nome: 'Desconhecido', palavrasMax: 100000 });
      setDataCriacao(data.data_criacao);

      // Preparado para múltiplos tipos de produto
      setTipoProduto(data.tipo_produto ? data.tipo_produto.split(',') : []);

      if (data.foto_perfil) {
        setFotoPerfil(data.foto_perfil);
        localStorage.setItem(`${userEmail}-fotoPerfil`, data.foto_perfil);
      } else {
        setFotoPerfil(null);
        localStorage.removeItem(`${userEmail}-fotoPerfil`);
      }

      // Buscar informações de compras após obter o UUID do usuário
      await fetchPurchaseData(data.user_id);

    } catch (error) {
      setErro('Erro ao buscar dados do usuário.');
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setErro('Usuário não autenticado.');
      return;
    }

    const userEmail = getUserEmail();
    setEmail(userEmail);

    const storedFotoPerfil = localStorage.getItem(`${userEmail}-fotoPerfil`);
    if (storedFotoPerfil) setFotoPerfil(storedFotoPerfil);

    fetchUserData(userEmail);
    const interval = setInterval(() => fetchUserData(userEmail), 1800000); // Atualiza a cada 30 minutos

    return () => clearInterval(interval);
  }, []);

  const formatarNumero = (num) => {
    return num.toLocaleString('pt-BR');
  };

  const formatarTelefone = (numero) => {
    if (!numero) return 'Não disponível';
    const cleaned = numero.replace(/\D/g, '');
    let formatted = '';

    if (cleaned.length === 13) {
      formatted = cleaned.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
    } else if (cleaned.length === 12) {
      formatted = cleaned.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
    } else if (cleaned.length === 11) {
      formatted = cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else if (cleaned.length === 10) {
      formatted = cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    } else if (cleaned.length === 9) {
      formatted = cleaned.replace(/^(\d{5})(\d{4})$/, '$1-$2');
    } else if (cleaned.length === 8) {
      formatted = cleaned.replace(/^(\d{4})(\d{4})$/, '$1-$2');
    } else {
      formatted = cleaned.replace(/(\d{2})(\d+)/, '($1) $2');
    }
    return formatted;
  };

  const formatarData = (data) => {
    if (!data) return '';
    const dataObj = new Date(data);
    return dataObj.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const calcularDiasRestantes = (dataExpiracao) => {
    if (!dataExpiracao) return null;
    const dataExpiracaoDate = new Date(dataExpiracao);
    const agora = new Date();
    const umDiaEmMs = 24 * 60 * 60 * 1000;
    const diff = dataExpiracaoDate - agora;
    const diasRestantes = Math.ceil(diff / umDiaEmMs);
    return diasRestantes >= 0 ? diasRestantes : 0;
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/png'];

      if (!allowedTypes.includes(file.type)) {
        alert('Por favor, selecione uma imagem nos formatos PNG ou JPG.');
        return;
      }

      await uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file || !usuarioId) {
      alert('Nenhuma imagem selecionada ou usuário inválido.');
      return;
    }

    try {
      setIsUploading(true);

      const bucketName = process.env.REACT_APP_SUPABASE_BUCKET_NAME?.trim();
      if (!bucketName) throw new Error('Bucket name not defined.');

      const filePath = `${usuarioId}/${file.name}`;
      const { error: uploadError } = await supabase
        .storage
        .from(bucketName)
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data: publicURLData, error: urlError } = supabase
        .storage
        .from(bucketName)
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      const publicURL = publicURLData.publicUrl;
      if (!publicURL) throw new Error('URL pública não foi gerada corretamente.');

      const { error: updateError } = await supabase
        .from('usuarios')
        .update({ foto_perfil: publicURL })
        .eq('user_id', usuarioId);

      if (updateError) throw updateError;

      setFotoPerfil(publicURL);
      localStorage.setItem(`${email}-fotoPerfil`, publicURL);
      setUploadMessage('Foto de perfil atualizada com sucesso!');

      await fetchUserData(email);
    } catch (error) {
      setUploadMessage('Erro ao atualizar a foto de perfil: ' + error.message);
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpgrade = () => {
    if (planoSelecionado) {
      let link = '';
      if (planoSelecionado === '2') {
        link = 'LINK_PARA_PAGAMENTO_PLANO_BASIC';
      } else if (planoSelecionado === '3') {
        link = 'LINK_PARA_PAGAMENTO_PLANO_PREMIUM';
      }
      window.location.href = link;
    } else {
      alert('Por favor, selecione um plano para upgrade.');
    }
  };

  if (erro) {
    return <div className="erro-painel">{erro}</div>;
  }

  const palavrasMax = plano.palavrasMax || 100000;
  const progresso = (palavrasDisponiveis / palavrasMax) * 100;

  return (
    <div className="painel-de-controle-container-painel">
      <div className="painel-assinatura-painel">
        <div className="painel-icone-titulo-painel">
          <FaSyncAlt className="icone-assinatura-painel" />
          <h2 className="titulo-assinatura-painel">Assinatura</h2>
        </div>
        <p className="descricao-assinatura-painel">Gerencie sua assinatura e veja as palavras restantes e usadas.</p>

        <div className="plano-info">
          <div className="linha-info-painel">
            <FaBullseye className="icone-visao-geral-painel" />
            <span>Plano do usuário: {(plano.nome || '').toUpperCase()}</span>
          </div>
          {/* Iterar sobre as compras e exibir cada uma em um bloco separado */}
          {compraInfo && compraInfo.length > 0 ? (
            compraInfo.map((compraItem, index) => (
              <div key={index} className="bloco-compra">
                {compraItem.produto && (
                  <div className="linha-info-painel">
                    <FaChartBar className="icone-visao-geral-painel" />
                    <span>Produto: {compraItem.produto.nome_produto}</span>
                  </div>
                )}
                {compraItem.plano && (
                  <div className="linha-info-painel">
                    <FaBullseye className="icone-visao-geral-painel" />
                    <span>Plano: {compraItem.plano.nome_plano}</span>
                  </div>
                )}
                {compraItem.compra && (
                  <>
                    <div className="linha-info-painel">
                      <FaCalendarAlt className="icone-visao-geral-painel" />
                      <span>Data de início: {formatarData(compraItem.compra.data_compra)}</span>
                    </div>
                    <div className="linha-info-painel">
                      <FaClock className="icone-visao-geral-painel" />
                      <span>Data de expiração: {formatarData(compraItem.compra.data_expiracao)}</span>
                    </div>
                    <div className="linha-info-painel">
                      <FaClock className="icone-visao-geral-painel" />
                      <span>Dias restantes: {calcularDiasRestantes(compraItem.compra.data_expiracao)} dias</span>
                    </div>
                    <div className="linha-info-painel">
                      <FaRegFileAlt className="icone-visao-geral-painel" />
                      <span>Status: {compraItem.compra.status}</span>
                    </div>
                  </>
                )}
                <hr />
              </div>
            ))
          ) : (
            <>
              <div className="linha-info-painel">
                <FaCalendarAlt className="icone-visao-geral-painel" />
                <span>Data de início da assinatura: {formatarData(dataCriacao)}</span>
              </div>
              <div className="linha-info-painel">
                <FaClock className="icone-visao-geral-painel" />
                <span>Seu acesso gratuito expira em {calcularDiasRestantes(dataCriacao)} dias</span>
              </div>
            </>
          )}
          <div className="linha-info-painel">
            <FaRegFileAlt className="icone-visao-geral-painel" />
            <span>Palavras disponíveis: {formatarNumero(palavrasDisponiveis)} de {formatarNumero(palavrasMax)}</span>
          </div>
        </div>

        <div className="painel-barra-container-painel">
          <div className="painel-barra-painel">
            <div
              className="painel-barra-progresso-painel"
              style={{ width: `${progresso}%`, backgroundColor: '#FFD700' }}
            />
          </div>
          <div className="painel-barra-texto-painel">
            <FaRegFileAlt className="icone-palavras-painel" />
            {formatarNumero(palavrasDisponiveis)} palavras restantes
          </div>
        </div>

        {(plano.nome || '').toUpperCase() !== 'PREMIUM' && (
          <>
            <button className="painel-botao-upgrade-painel" onClick={() => setMostrarSelector(!mostrarSelector)}>
              Faça um upgrade no seu plano
            </button>
            {mostrarSelector && (
              <div className="plano-selector">
                <p>Selecione o seu plano para fazer o upgrade:</p>
                <select value={planoSelecionado} onChange={(e) => setPlanoSelecionado(e.target.value)}>
                  <option value="">Selecione um plano</option>
                  {(plano.nome || '').toUpperCase() === 'GRATUITO' && (
                    <>
                      <option value="2">Plano Básico</option>
                      <option value="3">Plano Premium</option>
                    </>
                  )}
                  {(plano.nome || '').toUpperCase() === 'BÁSICO' && (
                    <>
                      <option value="3">Plano Premium</option>
                    </>
                  )}
                </select>
                <button onClick={handleUpgrade}>Prosseguir para o pagamento</button>
              </div>
            )}
          </>
        )}
      </div>

      <div className="painel-visao-geral-painel">
        <h2 className="titulo-visao-geral-painel">Visão Geral</h2>
        <p className="descricao-visao-geral-painel">Entenda e gerencie suas informações pessoais.</p>

        <div className="foto-perfil-section">
          {fotoPerfil ? (
            <img src={fotoPerfil} alt="Foto de Perfil" className="foto-perfill" />
          ) : (
            <div className="foto-perfil-placeholder">Sem Foto</div>
          )}
          <button
            className={`botao-alterar-foto ${isUploading ? 'loading' : ''}`}
            onClick={() => document.getElementById('fileInput').click()}
            disabled={isUploading}
          >
            {isUploading ? 'Carregando...' : 'Alterar Foto'}
          </button>
          <input
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
        </div>

        <div className="visao-geral-info-painel">
          <div className="linha-info-painel">
            <FaUser className="icone-visao-geral-painel" />
            <span>{nome}</span>
          </div>
          <div className="linha-info-painel">
            <FaEnvelope className="icone-visao-geral-painel" />
            <span>{email}</span>
          </div>
          <div className="linha-info-painel">
            <FaPhone className="icone-visao-geral-painel" />
            <span>{formatarTelefone(telefone)}</span>
          </div>
          <div className="linha-info-painel">
            <FaCalendarAlt className="icone-visao-geral-painel" />
            <span>Data de entrada: {formatarData(dataCriacao)}</span>
          </div>
          <div className="linha-info-painel">
            <FaBullseye className="icone-visao-geral-painel" />
            <span>Plano: {(plano.nome || '').toUpperCase()}</span>
          </div>
          <div className="linha-info-painel">
            <FaChartBar className="icone-visao-geral-painel" />
            <span>Tipo de Produto: {tipoProduto.map((tipo, index) => (
              <span key={index}>{tipo.toUpperCase()}{index < tipoProduto.length -1 ? ', ' : ''}</span>
            ))}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainelDeControle;
