import React, { useEffect } from 'react';
import './Narrador.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
// Importação do novo componente (a ser criado)
import GeradorDeAudio from './GeradorDeAudio'; 

const EstudioCriacao = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="estudio-narrador-container">
      <div className="estudio-narrador-header">
        <img src={logo} alt="Logo" className="estudio-narrador-logo" />
        <div className="estudio-narrador-user-info">
          <span>{userEmail}</span>
          <button className="estudio-narrador-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="estudio-narrador-content">
        <h1 className="estudio-narrador-title-gradient">Estúdio de Criação</h1>
        <p className="estudio-narrador-description">
          Esta tela permite que você crie áudios com inteligência artificial, ideal para narrar scripts de vendas no WhatsApp e outros conteúdos.
        </p>

        {/* Botão "Voltar para o Menu" */}
        <Link to="/ferramentas" className="estudio-narrador-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        {/* Espaço para o novo componente */}
        <div style={{ marginTop: '20px' }}>
          <GeradorDeAudio />
        </div>
      </div>
    </div>
  );
};

export default EstudioCriacao;
