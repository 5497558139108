import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './adminLogin.css';  // Novo arquivo de estilo
import logo from '../../imagens/03logo_menu_login.webp';  // Caminho corrigido para o logo
import { adminLogin, isAdminAuthenticated } from '../../auth/auth';  // Função correta para administradores

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdminAuthenticated()) {
      navigate('/painelAdmin'); // Redireciona automaticamente para o painel se já estiver autenticado
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_ADMIN}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_ADMIN}`,
          },
        }
      );

      const admins = response.data.results;
      const admin = admins.find(
        (admin) => admin['E-mail'] === email && admin.senha === senha
      );

      if (admin) {
        console.log('Login de administrador bem-sucedido, redirecionando para o painel...');
        
        // Chama a função de login para administradores
        await adminLogin('adminToken', email, admin.Nome || 'Admin');  

        // Redireciona para o painel de administração
        navigate('/painelAdmin');
      } else {
        setErro('Email ou senha incorretos.');
      }
    } catch (error) {
      console.error('Erro ao verificar login de administrador:', error);
      setErro('Ocorreu um erro ao tentar fazer login. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="admin-login-container"> {/* Sem background inline */}
      <div className="admin-logo-container">
        <img src={logo} alt="Logo" className="admin-login-logo" />
      </div>
      <div className="admin-form-container">
        <h1 className="admin-title">Administração</h1>
        <form onSubmit={handleLogin}>
          <div className="admin-input-container">
            <i className="fas fa-envelope"></i>
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="admin-email-input"
            />
          </div>
          <div className="admin-input-container">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              id="admin-password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
              className="admin-password-input"
            />
          </div>
          {erro && <p className="admin-error">{erro}</p>}
          <button type="submit" className="admin-login-button">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
