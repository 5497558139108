import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminAuthenticated, adminLogout } from '../../auth/auth';
import logo from '../../imagens/logo_menu_login.webp'; // Caminho correto da logo
import suporteImg from '../../imagens/suporte.webp'; // Imagem de suporte
import usuarioImg from '../../imagens/usuario.webp'; // Imagem de usuário
import './painelAdmin.css'; // Novo arquivo CSS adaptado

const PainelAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o administrador está autenticado
    if (!isAdminAuthenticated()) {
      // Se não estiver autenticado, redireciona para a página de login de administrador
      navigate('/adminLogin');
    }
  }, [navigate]);

  const handleLogout = () => {
    adminLogout(); // Função para deslogar o administrador
    navigate('/adminLogin'); // Redireciona para a página de login
  };

  return (
    <div className="painel-admin-container">
      <div className="painel-admin-header">
        <img src={logo} alt="Logo" className="painel-admin-logo" />
        <div className="painel-admin-user-info">
          <span>Bem-vindo(a), Admin</span>
          <button className="painel-admin-button" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="painel-admin-content">
        <h1 className="painel-admin-title-gradient">Painel de Controle</h1>
        <p className="painel-admin-description">
          Bem-vindo ao painel administrativo. Aqui você pode gerenciar todas as funcionalidades do sistema.
        </p>

        <div className="painel-admin-menu">
          <div className="painel-admin-item">
            <img src={suporteImg} alt="Suporte" className="painel-admin-item-img" />
            <button className="painel-admin-item-button" onClick={() => navigate('/suporte-admin')}>
              Acesse o Suporte Admin
            </button>
          </div>
           <div className="painel-admin-item">
            <img src={suporteImg} alt="Cursos" className="painel-admin-item-img" />
            <button className="painel-admin-item-button" onClick={() => navigate('/cursos')}>
              Acesse o curso Admin
            </button>
          </div>
          <div className="painel-admin-item">
            <img src={usuarioImg} alt="Usuários" className="painel-admin-item-img" />
            <button className="painel-admin-item-button" onClick={() => navigate('/admin-usuarios')}>
              Gerenciar Usuários
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainelAdmin;
