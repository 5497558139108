// MenuVertical.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
    FaChevronLeft, 
    FaTimes, 
    FaChevronDown, 
    FaRobot, 
    FaMoneyBillWave, 
    FaChartLine, 
    FaChartBar, 
    FaBullseye, 
    FaPencilAlt, 
    FaFileAlt, 
    FaTools, 
    FaMapMarkedAlt, 
    FaMagic, 
    FaBullhorn, 
    FaRedo, 
    FaCheckCircle, 
    FaStar, // Adicionado para resolver erro
    FaUser,
    FaChalkboardTeacher // Adicionado para resolver erro
} from 'react-icons/fa';
import './MenuVertical.css';
import { isAuthenticated, getNomePlano, getTipoProduto } from '../../auth/auth'; // Importando funções de autenticação
import PalavrasUsuario from './PalavrasUsuario'; // Importe o componente corretamente

const MenuVertical = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
    const [userPlan, setUserPlan] = useState('');
    const [tipoProduto, setTipoProduto] = useState([]);

    // Função para alternar o menu vertical
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Função para alternar submenus
    const toggleSubMenu = (menu) => {
        setIsSubMenuOpen((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu],
        }));
    };

    // Funções para gerenciar hover
    const handleMouseEnter = (item) => {
        if (!isOpen) {
            setHoveredItem(item);
            setIsSubMenuOpen((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    };

    const handleMouseLeave = () => {
        if (!isOpen) {
            setHoveredItem(null);
            setIsSubMenuOpen({});
        }
    };

    // Função para verificar acesso a uma categoria
    const hasAccess = (categoryAccess) => {
        return categoryAccess(userPlan, tipoProduto);
    };

    // Inicialização das permissões do usuário
    useEffect(() => {
        if (isAuthenticated()) {
            const plano = getNomePlano();
            const produto = getTipoProduto();

            setUserPlan(plano ? plano.toLowerCase() : 'gratuito');

            // Parse do tipoProduto para array
            let parsedTipoProduto = [];

            if (produto) {
                try {
                    // Tenta parsear como JSON
                    const parsed = JSON.parse(produto);
                    if (Array.isArray(parsed)) {
                        parsedTipoProduto = parsed.map(p => p.toLowerCase());
                    } else if (typeof parsed === 'string') {
                        // Caso tipoProduto seja uma string, divida por vírgula ou espaço
                        parsedTipoProduto = parsed
                            .split(/[\s,]+/)
                            .map(p => p.trim().toLowerCase())
                            .filter(p => p.length > 0);
                    }
                } catch (error) {
                    console.error('Erro ao parsear tipoProduto:', error);
                    // Tenta tratar como uma string única sem JSON
                    const trimmed = produto.trim().toLowerCase();
                    if (trimmed.length > 0) {
                        parsedTipoProduto = [trimmed];
                    }
                }
            }

            setTipoProduto(parsedTipoProduto); // Define o tipo de produto como array
        }
    }, []);

    // Definição das categorias e suas condições de acesso
    const categories = [
        {
            title: 'Assistente AI', // <--- [Comentário: Renomeie aqui a categoria se necessário]
            submenus: [
                {
                    title: 'Financeiro', // <--- [Comentário: Renomeie aqui a subcategoria se necessário]
                    items: [
                        { title: 'Meta de Lucro', link: '/lucro-alvo' }, // <--- [Comentário: Adicione ou remova itens conforme necessário]
                        { title: 'Guia de Investimento Fácil', link: '/planejador-prosperidade' },
                        { title: 'Conversão Mágica', link: '/conversao-magica' },
                        { title: 'Funil de Vendas', link: '/funil-de-vendas-whatsapp' },
                        { title: 'Análise de Métricas Meta ADS', link: '/analise-metricas-meta' },
                    ],
                    access: (plano, tipoProdutos) =>
                        ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
                },
                {
                    title: 'Gerador de Conversão', // <--- [Comentário: Renomeie aqui a subcategoria se necessário]
                    items: [
                        { title: 'Copy para Meta ADS', link: '/criador-de-textos-persuasivos' },
                        { title: 'Script de Vendas para WhatsApp', link: '/script-vendas-whatsapp' },
                        { title: 'Remarketing', link: '/remarketing' },
                        { title: 'Headlines', link: '/headlines' },
                        { title: 'Prova', link: '/prova' },
                    ],
                    access: (plano, tipoProdutos) =>
                        ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
                },
                {
                    title: 'CRIE SEU PRODUTO COM A NOSSA [IA]', // <--- [Comentário: Renomeie aqui a subcategoria se necessário]
                    items: [
                        { title: 'Criador de Produto', link: '/criador-de-produto' },
                        { title: 'Criador de Promessas', link: '/criador-de-promessas' },
                        { title: 'Otimizador de Oferta', link: '/otmizador-de-oferta' },
                        { title: 'Criador de Upsell', link: '/criador-de-upsll' },
                        { title: 'Criador de Nome', link: '/criador-de-nomes' },
                        { title: 'Criador de Bump', link: '/criador-ordembump' },
                    ],
                    access: (plano, tipoProdutos) =>
                        ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
                },
                // Removida a categoria "RESUMO DO PROJETO MEU PRIMEIRO SALÁRIO SEM SAIR DE CASA" pois é treinamento
                {
                    title: 'Estúdio de Criação',
                    link: '/estudio-criacao',
                    items: [], // Sem subitens
                    access: (plano, tipoProdutos) =>
                        ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
                },
                {
                    title: 'Mapa do Digital',
                    link: '/mapa-digital',
                    items: [], // Sem subitens
                    access: (plano, tipoProdutos) =>
                        ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
                },
            ],
            access: (plano, tipoProdutos) =>
                ['gratuito', 'básico', 'premium'].includes(plano) && tipoProdutos.includes('assinatura'),
        },
        // Exclua a categoria de 'treinamento' aqui, pois não será incluída no MenuVertical
        // Exclua a categoria de 'Administração' aqui, pois já está presente separadamente no Menu.js
    ];

    return (
        <div className={`menu-vertical-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="logo-section">
                <img 
                    src={require('../../imagens/logo_menu_login.webp')} 
                    alt="Logo" 
                    className={`menu-logo ${isOpen ? 'expanded' : 'collapsed'}`} 
                />
                <button className="menu-toggle" onClick={toggleMenu}>
                    {isOpen ? <FaTimes /> : <FaChevronLeft />}
                </button>
            </div>
            <nav className="menu-vertical-nav">
                <ul className="menu-vertical-list">
                    {/* Menus Internos (Assistente AI e Submenus) */}
                    {categories.map((category, index) => (
                        <li 
                            key={index} 
                            className="menu-vertical-item"
                            onMouseEnter={() => handleMouseEnter(category.title)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="menu-vertical-link" onClick={() => toggleSubMenu(category.title)}>
                                <FaRobot className="menu-vertical-icon" />
                                {isOpen && <span>{category.title}</span>}
                                {!isOpen && hoveredItem === category.title && <span className="tooltip">{category.title}</span>}
                                {isOpen && <FaChevronDown className="submenu-icon" />}
                            </div>
                            {isSubMenuOpen[category.title] && (
                                <ul className="submenu-list">
                                    {category.submenus.map((submenu, subIndex) => {
                                        const access = hasAccess(submenu.access);
                                        return (
                                            <li key={subIndex} className="submenu-item">
                                                {submenu.items.length > 0 ? (
                                                    <>
                                                        <div className="menu-vertical-link" onClick={() => toggleSubMenu(submenu.title)}>
                                                            <FaBullseye className="menu-vertical-icon" />
                                                            <span>{submenu.title}</span>
                                                            <FaChevronDown className="submenu-icon" />
                                                        </div>
                                                        {isSubMenuOpen[submenu.title] && (
                                                            <ul className="submenu-list">
                                                                {submenu.items.map((item, itemIndex) => {
                                                                    const itemAccess = hasAccess(submenu.access);
                                                                    return (
                                                                        <li key={itemIndex} className="submenu-item">
                                                                            <Link 
                                                                                to={item.link} 
                                                                                className={`submenu-link ${itemAccess ? '' : 'disabled-link'}`}
                                                                                onClick={(e) => {
                                                                                    if (!itemAccess) {
                                                                                        e.preventDefault();
                                                                                        alert('Acesso restrito. Seu plano atual não permite acessar este menu.');
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <FaBullhorn className="unique-submenu-icon" />
                                                                                <span className="submenu-title">{item.title}</span>
                                                                            </Link>
                                                                            {!itemAccess && (
                                                                                <div className="overlay-blocked">Bloqueado</div>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Link 
                                                            to={submenu.link} 
                                                            className={`submenu-link ${access ? '' : 'disabled-link'}`}
                                                            onClick={(e) => {
                                                                if (!access) {
                                                                    e.preventDefault();
                                                                    alert('Acesso restrito. Seu plano atual não permite acessar este menu.');
                                                                }
                                                            }}
                                                        >
                                                            <FaTools className="unique-submenu-icon" />
                                                            <span className="submenu-title">{submenu.title}</span>
                                                        </Link>
                                                        {!access && (
                                                            <div className="overlay-blocked">Bloqueado</div>
                                                        )}
                                                    </>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    ))}

                    {/* Menus Externos - Sempre Acessíveis */}
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('Tutoriais')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/tutoriais" className="menu-vertical-link">
                            <FaFileAlt className="menu-vertical-icon" />
                            {isOpen && <span>TUTORIAIS</span>}
                            {!isOpen && hoveredItem === 'Tutoriais' && <span className="tooltip">Tutoriais</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('Suporte')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/suporte" className="menu-vertical-link">
                            <FaBullhorn className="menu-vertical-icon" />
                            {isOpen && <span>SUPORTE</span>}
                            {!isOpen && hoveredItem === 'Suporte' && <span className="tooltip">Suporte</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('Favoritos')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/favoritos" className="menu-vertical-link">
                            <FaStar className="menu-vertical-icon" />
                            {isOpen && <span>FAVORITOS</span>}
                            {!isOpen && hoveredItem === 'Favoritos' && <span className="tooltip">Favoritos</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('Minha Conta')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/minha-conta" className="menu-vertical-link">
                            <FaUser className="menu-vertical-icon" />
                            {isOpen && <span>MINHA CONTA</span>}
                            {!isOpen && hoveredItem === 'Minha Conta' && <span className="tooltip">Minha Conta</span>}
                            
                             
                        </Link>
                    </li>
                </ul>
                {isOpen && <PalavrasUsuario />}
            </nav>
        </div>
    );

};

export default MenuVertical;
