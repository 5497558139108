// SalesScriptGuide.js

import React, { useState, useEffect } from 'react';
import { 
  MessageCircle, Brain, Cog, Gift, 
  Users, Clock, Shield, CheckCircle, ChevronRight, 
  Check, ArrowLeft, ArrowRight, Play, Pause, Lightbulb as LightbulbIcon
} from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
const SalesScriptGuide = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [showCompleteScript, setShowCompleteScript] = useState(false);

  useEffect(() => {
    let interval;
    if (isAutoPlay) {
      interval = setInterval(() => {
        setActiveStep((prev) => {
          if (prev === steps.length - 1) {
            setIsAutoPlay(false);
            return prev;
          }
          return prev + 1;
        });
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlay]);

  const steps = [
    {
      id: 1,
      title: 'Conexão',
      icon: <MessageCircle className="w-6 h-6" />,
      description: 'Estabeleça uma conexão inicial personalizada com o cliente',
      content: 'Comece com uma saudação calorosa e personalizada, demonstrando que você prestou atenção no interesse específico do cliente pelo produto.',
      example: `"Olá, [NOME]! Tudo bem por aí? 😊
Vi que você está interessada na Escova 5 em 1, e já vou te passar o valor dela.
Mas antes de falar o preço, deixa eu te mostrar por que ela tem feito tanto sucesso e como pode te ajudar no dia a dia!"`,
      tip: 'Pesquise o perfil do cliente nas redes sociais antes do contato para personalizar ainda mais a abordagem.',
      color: 'from-blue-500 to-blue-600',
      media: {
        type: 'audio',
        description: '**Aqui você pode incluir um áudio curto** explicando as funcionalidades principais.',
        narration: 'A Escova 5 em 1 vai revolucionar seu dia a dia. Ela seca, alisa, modela e faz babyliss, garantindo um resultado incrível, sem complicação!'
      }
    },
    {
      id: 2,
      title: 'Curiosidade',
      icon: <Brain className="w-6 h-6" />,
      description: 'Desperte o interesse com informações intrigantes',
      content: 'Apresente fatos interessantes e únicos sobre o produto que despertem a curiosidade do cliente e o incentivem a querer saber mais.',
      example: 'Você sabia que com a Escova 5 em 1 você pode alisar, secar e modelar seu cabelo, além de fazer babyliss, tudo em um único aparelho?\n\nSem complicação e sem precisar de vários aparelhos diferentes!',
      tip: 'Use estatísticas surpreendentes e fatos únicos sobre o produto.',
      color: 'from-purple-500 to-purple-600',
      media: {
        type: 'none'
      }
    },
    {
      id: 3,
      title: 'Conscientização',
      icon: <LightbulbIcon className="w-6 h-6" />,
      description: 'Faça o cliente reconhecer o problema',
      content: 'Ajude o cliente a identificar as dores e frustrações que o produto pode resolver, conectando-se emocionalmente com suas necessidades.',
      example: 'Perder tempo arrumando o cabelo todos os dias pode ser frustrante, né? 😓\n\nImagine acordar sabendo que seu cabelo vai ficar impecável em poucos minutos, sem esforço e sem complicação!',
      tip: 'Faça perguntas que levem à reflexão sobre o problema.',
      color: 'from-yellow-500 to-yellow-600',
      media: {
        type: 'audio',
        description: '**Sugestão de texto para narração do áudio**:',
        narration: 'Sabemos como é desgastante perder tempo arrumando o cabelo diariamente. Com a Escova 5 em 1, seu cabelo fica perfeito rapidamente, sem estresse!'
      }
    },
    {
      id: 4,
      title: 'Mecanismo',
      icon: <Cog className="w-6 h-6" />,
      description: 'Explique como o produto funciona',
      content: 'Detalhe o funcionamento do produto de forma clara e objetiva, destacando as tecnologias ou características que o tornam único.',
      example: 'A Escova 5 em 1 utiliza tecnologia de ponta, permitindo que você seque, alise, modele e faça babyliss, tudo com um único aparelho.\n\nEconomiza tempo e espaço no seu nécessaire!',
      tip: 'Use analogias simples para explicar conceitos técnicos.',
      color: 'from-gray-500 to-gray-600',
      media: {
        type: 'video',
        description: '**Sugestão de incluir um vídeo demonstrativo** mostrando como a escova funciona na prática.',
        videoUrl: 'https://www.youtube.com/watch?v=exemplo' // Substitua pelo URL do seu vídeo
      }
    },
    {
      id: 5,
      title: 'Benefícios',
      icon: <Gift className="w-6 h-6" />,
      description: 'Liste as vantagens principais',
      content: 'Apresente os benefícios mais relevantes do produto, focando tanto nos aspectos funcionais quanto nos emocionais que ele proporciona.',
      example: '💼 Versatilidade: Alisa, seca, modela e faz babyliss com um só aparelho.\n🕒 Economia de Tempo: Fique pronta em minutos, sem complicações.\n🚚 Entrega Rápida: Receba na sua casa em até 24 horas.\n💸 Pagamento na Entrega: Você pode pagar na entrega, seja em PIX, cartão de crédito ou dinheiro em espécie.',
      tip: 'Foque nos benefícios emocionais além dos funcionais.',
      color: 'from-green-500 to-green-600',
      media: {
        type: 'none'
      }
    },
    {
      id: 6,
      title: 'Prova Social',
      icon: <Users className="w-6 h-6" />,
      description: 'Mostre depoimentos reais',
      content: 'Compartilhe experiências positivas de outros clientes para aumentar a confiança e credibilidade do produto.',
      example: `"Estou apaixonada pela minha Escova 5 em 1! É tudo o que eu precisava para facilitar minha rotina." – Ana P.`,
      tip: 'Use depoimentos específicos que abordem as principais objeções.',
      color: 'from-pink-500 to-pink-600',
      media: {
        type: 'audio',
        description: '**Aqui você pode incluir um áudio com depoimento** ou uma **imagem com o testemunho** de clientes.',
        narration: 'A Escova 5 em 1 transformou a forma como cuido do meu cabelo. É prática e dá um resultado maravilhoso! – Ana P.'
      }
    },
    {
      id: 7,
      title: 'Oportunidade',
      icon: <Clock className="w-6 h-6" />,
      description: 'Apresente a oferta especial',
      content: 'Revele o preço e as condições especiais de pagamento de forma atrativa, criando um incentivo para a compra imediata.',
      example: 'Hoje, você pode garantir a sua Escova 5 em 1 por apenas R$ 197, e o melhor: só paga quando receber em casa! 🚚💳\nAlém disso, você recebe frete grátis e entrega rápida.',
      tip: 'Crie senso de urgência sem parecer forçado.',
      color: 'from-orange-500 to-orange-600',
      media: {
        type: 'none'
      }
    },
    {
      id: 8,
      title: 'Riscos e Perdas',
      icon: <Shield className="w-6 h-6" />,
      description: 'Elimine objeções',
      content: 'Aborde e elimine possíveis receios do cliente, oferecendo garantias ou condições que reduzam o risco percebido.',
      example: 'E o melhor, você não tem risco nenhum. Só paga quando a escova chegar na sua casa!\nAo garantir a sua escova hoje, você evita:\n\n⌛ Perder tempo com ferramentas que não funcionam.\n💸 Gastar dinheiro com produtos caros e ineficientes.',
      tip: 'Antecipe as objeções mais comuns e prepare respostas convincentes.',
      color: 'from-red-500 to-red-600',
      media: {
        type: 'audio',
        description: '**Sugestão de texto para narração do áudio**:',
        narration: 'Com a Escova 5 em 1, você tem garantia total: só paga quando o produto chegar na sua casa. Nada de riscos!'
      }
    },
    {
      id: 9,
      title: 'Fechamento',
      icon: <CheckCircle className="w-6 h-6" />,
      description: 'Conduza à ação',
      content: 'Guie o cliente para a finalização da compra com perguntas de confirmação e próximos passos claros.',
      example: '[NOME], estamos quase lá! Agora, só preciso que você me diga para qual endereço devo enviar a sua Escova 5 em 1.\n\nMe fala o seguinte:\n\n🏠 Seu CEP\n📍 Endereço completo (Rua, Número e Complemento)\n📅 Dia que prefere receber o produto\n\nAssim que eu receber essas informações, já vou confirmar o envio e te enviar o comprovante com todos os detalhes da entrega!',
      tip: 'Use perguntas de confirmação para facilitar o fechamento.',
      color: 'from-emerald-500 to-emerald-600',
      media: {
        type: 'none'
      }
    }
  ];

  // Função para gerar o script completo
  const gerarScriptCompleto = () => {
    const script = steps.map(step => {
      return `${step.title}:\n\n${step.content}\n\nExemplo:\n${step.example}\n\n`;
    }).join('\n');

    toast.info('Script completo gerado! Confira abaixo.');

    // Exibir o script completo no final da página ou em um modal
    setShowCompleteScript(true);
  };

  return (
    <div className="tailwind-scope bg-black p-8 animate-fadeIn mt-40 md:mt-16">
      <ToastContainer />
      <div className="max-w-7xl mx-auto bg-gray-900 rounded-2xl shadow-2xl overflow-hidden border border-gray-800">
        <div className="p-8 bg-gray-800">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">Script de Vendas Vencedor</h1>
              <p className="text-gray-300 text-lg">Domine as 9 etapas fundamentais para um atendimento de excelência</p>
            </div>
            <button
              onClick={() => setIsAutoPlay(!isAutoPlay)}
              className="flex items-center space-x-2 px-4 py-2 bg-green-600 rounded-lg hover:bg-green-500 transition-all duration-300"
            >
              {isAutoPlay ? (
                <>
                  <Pause className="w-5 h-5 text-white" />
                  <span className="text-white">Pausar</span>
                </>
              ) : (
                <>
                  <Play className="w-5 h-5 text-white" />
                  <span className="text-white">Auto Play</span>
                </>
              )}
            </button>
          </div>
        </div>

        <div className="grid md:grid-cols-12 gap-8 p-8">
          {/* Sidebar com os passos */}
          <div className="md:col-span-4 space-y-4">
            {steps.map((step, index) => (
              <button
                key={step.id}
                onClick={() => setActiveStep(index)}
                className={`w-full group relative flex items-center p-4 rounded-xl transition-all duration-500 ${
                  activeStep === index
                    ? `bg-gradient-to-r ${step.color} shadow-lg scale-105`
                    : 'bg-gray-800 hover:bg-gray-700'
                }`}
              >
                <div className="flex items-center space-x-4">
                  <div className={`p-2 rounded-lg ${
                    activeStep === index ? 'bg-white/20' : 'bg-gray-700'
                  }`}>
                    {step.icon}
                  </div>
                  <span className={`font-medium ${
                    activeStep === index ? 'text-white' : 'text-gray-300'
                  }`}>{step.title}</span>
                </div>
                {activeStep > index && (
                  <Check className="w-5 h-5 ml-auto text-green-400" />
                )}
                {activeStep === index && (
                  <ChevronRight className="w-5 h-5 ml-auto text-white animate-bounce" />
                )}
              </button>
            ))}
          </div>

          {/* Conteúdo principal */}
          <div className="md:col-span-8">
            <div className="bg-gray-800 rounded-xl p-8 h-full relative overflow-hidden">
              <div className={`absolute inset-0 bg-gradient-to-br ${steps[activeStep].color} opacity-10`} />
              
              <div className="relative space-y-8">
                <div className="flex items-center space-x-4">
                  <div className={`p-3 rounded-xl bg-gradient-to-br ${steps[activeStep].color}`}>
                    {steps[activeStep].icon}
                  </div>
                  <h2 className="text-3xl font-bold text-white">{steps[activeStep].title}</h2>
                </div>
                
                <p className="text-xl font-medium text-gray-300">
                  {steps[activeStep].description}
                </p>

                <div className="bg-gray-900/50 rounded-xl p-6 backdrop-blur-sm border border-gray-700">
                  <h3 className="text-white font-medium mb-3">Como fazer:</h3>
                  <p className="text-gray-300 leading-relaxed">{steps[activeStep].content}</p>
                </div>

                <div className="bg-gray-900/50 rounded-xl p-6 backdrop-blur-sm border border-gray-700">
                  <h3 className="text-white font-medium mb-3">Exemplo:</h3>
                  <p className="text-gray-300 whitespace-pre-line leading-relaxed">{steps[activeStep].example}</p>
                </div>

                {/* Mídia */}
                {steps[activeStep].media.type !== 'none' && (
                  <div className="bg-gray-900/50 rounded-xl p-6 backdrop-blur-sm border border-gray-700">
                    <h3 className="text-white font-medium mb-3">Mídia:</h3>
                    {steps[activeStep].media.type === 'audio' && (
                      <div>
                        <p className="text-gray-300 mb-2">{steps[activeStep].media.description}</p>
                        <audio controls className="w-full">
                          <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" type="audio/mpeg" />
                          Seu navegador não suporta o elemento de áudio.
                        </audio>
                        <p className="text-gray-300 mt-2">{steps[activeStep].media.narration}</p>
                      </div>
                    )}
                    {steps[activeStep].media.type === 'video' && (
                      <div>
                        <p className="text-gray-300 mb-2">{steps[activeStep].media.description}</p>
                        <video controls className="w-full">
                          <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                          Seu navegador não suporta o elemento de vídeo.
                        </video>
                        <p className="text-gray-300 mt-2">Vídeo demonstrativo mostrando como a escova funciona na prática.</p>
                      </div>
                    )}
                  </div>
                )}

                {/* Dica Sempre Visível */}
                <div className="flex items-center bg-gray-900/50 border border-gray-700 text-gray-300 p-4 rounded-lg space-x-2">
                  <LightbulbIcon className="w-5 h-5 text-yellow-500" />
                  <span>{steps[activeStep].tip}</span>
                </div>

                <div className="flex items-center justify-between pt-4">
                  <div></div> {/* Espaço vazio para alinhar os botões */}
                  
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setActiveStep(Math.max(0, activeStep - 1))}
                      className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-300 ${
                        activeStep === 0
                          ? 'bg-green-700 text-gray-500 cursor-not-allowed'
                          : 'bg-green-700 text-white hover:bg-green-600'
                      }`}
                      disabled={activeStep === 0}
                    >
                      <ArrowLeft className="w-4 h-4" />
                      <span>Anterior</span>
                    </button>
                    <button
                      onClick={() => setActiveStep(Math.min(steps.length - 1, activeStep + 1))}
                      className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-300 ${
                        activeStep === steps.length - 1
                          ? 'bg-green-700 text-gray-500 cursor-not-allowed'
                          : `bg-gradient-to-r ${steps[activeStep].color} text-white hover:opacity-90`
                      }`}
                      disabled={activeStep === steps.length - 1}
                    >
                      <span>Próximo</span>
                      <ArrowRight className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                {/* Botão para gerar o script completo */}
                {activeStep === steps.length - 1 && (
                  <div className="mt-8">
                    <button
                      onClick={gerarScriptCompleto}
                      className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-all duration-300"
                    >
                      <CheckCircle className="w-5 h-5" />
                      <span>Gerar Script Completo</span>
                    </button>
                  </div>
                )}

                {/* Exibir o script completo */}
                {showCompleteScript && (
                  <div className="mt-8 bg-gray-800 rounded-xl p-6 border border-gray-700">
                    <h3 className="text-2xl font-bold text-white mb-4">Script Completo para WhatsApp</h3>
                    <pre className="text-gray-300 whitespace-pre-wrap">
                      {steps.map(step => (
                        `${step.title}:\n\n${step.content}\n\nExemplo:\n${step.example}\n\n`
                      )).join('\n')}
                    </pre>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Adicione a animação de fade-in no Tailwind CSS
// No seu arquivo tailwind.config.js, adicione:

/*
module.exports = {
  // ...
  theme: {
    extend: {
      keyframes: {
        fadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        }
      },
      animation: {
        fadeIn: 'fadeIn 1s ease-in-out',
      }
    },
  },
  // ...
}
*/

export default SalesScriptGuide;
